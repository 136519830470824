import { INSERT_STATUS, UPDATE_STATUS } from '../constants/actionTypes';
import { loadState } from '../sessionStorage';

const persistedState = loadState();
const initialState = Object.assign({}, (persistedState || { status: {} }).status); //combineReducers jagt da 'undefined' durch!

export default function status(state = initialState, action) {
	switch (action.type) {
		case INSERT_STATUS:
			return Object.assign({},
				state,
				{
					[action.id]: {
						id: action.id,
						timestamps: [action.timestamp]
					}
				});
		case UPDATE_STATUS:
			return Object.assign({},
				state,
				{
					[action.id]: {
						id: action.id,
						timestamps: [...(state[action.id] || { timestamps: [] }).timestamps, action.timestamp]
					}
				}
			);
		default:
			return state;
	}
}

