import Items from '.';
import { getCurrentFilename, writeNullToKeys } from '../../functions';

class FetchAssetDetails extends Items {

  constructor(props, signal, id) {
    super(props, signal);
    if (!id || isNaN(id)) throw new TypeError(`[${getCurrentFilename(__filename)}] assetId nicht vorhanden`);
    this.path = {
      string: `stammdaten/assets/${id}`,
			id
    };
    this.fetch = this.fetch.bind(this);
  }

  fetch = () => new Promise((resolve, reject) => {
    super.fetchDbOrServerGet('Asset Details', this.path).then(json => {

      if (!json.path || !json.results || !json.results.assetDetails) {
        reject(`[${getCurrentFilename(__filename)}] !json.path || !json.results || !json.results.assetDetails`);
        return;
      }

      resolve(writeNullToKeys(json.results.assetDetails));

    }).catch(err => reject(err)); //super.fetchDbOrServerGet('Beleg'
  }).catch(err => {
    console.error(err);
    console.log(94385714528238);
  }); //fetch()
}

export default FetchAssetDetails;
