import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(1)
	},
	adresse: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	name1: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold'
	}
});

const CardAdresseKlein = props => {

	const { classes } = props, { adressen } = props;
	if (!adressen) return null;
	const dash = '—', { StyledCard } = props;

	const StyledTypography = withStyles({
		root: {
			display: 'flex',
			justifyContent: 'space-between'
		}
	})(Typography);

	return adressen.map((item, index) => (
		<StyledCard key={index}>
			<List>
				<ListItem button onClick={() => props.handleClick(item.adresse.id)}>
					<Avatar className={classes.avatar}>
						<PeopleIcon />
					</Avatar>

					<ListItemText
						disableTypography
						primary={
							<Typography
								noWrap
								variant="body1"
								className={classes.name1}
							>
								{item.adresse.name1}
							</Typography>
						}
						secondary={item.adresse.strasse && item.adresse.plz && item.adresse.ort &&
							<>
								<Typography
									component="p"
									variant="body2"
									color="textSecondary"
									noWrap
								>
									{item.adresse.strasse}
								</Typography>
								<StyledTypography
									component="p"
									variant="body2"
									color="textSecondary"
								>
									<span className={classes.adresse}>{`${item.adresse.plz !== dash ? item.adresse.plz : ''} ${item.adresse.ort}`}</span>
									<span>{item.name}</span>
								</StyledTypography>
							</>
						}
					/>
				</ListItem>
			</List>
		</StyledCard>
	));
};

export default withStyles(styles)(CardAdresseKlein);
