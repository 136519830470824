import Fetch from '../fetch';
import FetchAdresse from './FetchAdresse';

class FetchAdresseEditieren {

	constructor(props, signal, sendData, id) {
		this.path = {
			string: `stammdaten/adressen/${id}`,
			id: null
		};
		this.sendData = sendData;
		this.props = props;
		this.signal = signal;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		new Fetch(this.props).patch(this.path, this.signal, 'Adresse editieren', this.sendData).then(() => {
			// Request für den Cache erneuern
			// TODO deleteRowsFromCache() nutzen!!!
			new FetchAdresse(this.props, this.signal, this.sendData.data.id, true).fetch().then(() => {
				resolve(true);
			});
		}).catch(err => {
			console.error(err);
			console.error(8905427520570);
			reject(false);
		});
	});
}

export default FetchAdresseEditieren;