import Items from '.';
import { removeDuplicates } from '../../functions';

class StatistikWarengruppenUmsatz extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.path = {
			string: `auswertungen/sql`,
			id: null
		}
		this.sendData = {
			sql: `
				select
					art.warengruppe,
					buc.monat,
					buc.jahr,
					sum(buc.endpreis * buc.bewertungsfaktor * -1)	as summe
				from
					buc
				join
					art on art.id = buc.art_id
				where
					buc.belegtyp in ('04', '05', '06', '11', '12')
					and buc.aus_beb = 'N'
					and dateadd(day,  -180, current_date) <= buc.datum
					and buc.datum  <= current_date
				group by 1, 2, 3
				order by 3, 2
		`};
		this.signal = signal;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerPost('Umsatz Nach Warengruppe', this.path, this.sendData).then(json => {

			const results = json.results.umsatzNachWarengruppe;
			if (results instanceof Array) {
				results.push({}); // damit der letzte Monat ins if geht
			} else {
				resolve(
					{
						graphs: [],
						dataProvider: []
					}
				);
				return;
			}

			const dataProvider = [], warengruppenProMonat = [], summenProMonat = [], warengruppen = [];
			const monatWort = {
				'01': 'Jan',
				'02': 'Feb',
				'03': 'Mär',
				'04': 'Apr',
				'05': 'Mai',
				'06': 'Jun',
				'07': 'Jul',
				'08': 'Aug',
				'09': 'Sep',
				10: 'Okt',
				11: 'Nov',
				12: 'Dez'
			};

			for (const result of results) {
				if (typeof result !== 'object') return;
				let aktuellerMonat = results[0].monat;

				const { monat, warengruppe, summe } = result;

				if (monat !== aktuellerMonat) {
					// neuer Monat, neues Element im Array
					const obj = { monat: monatWort[aktuellerMonat] };

					for (let j = 0; j < warengruppenProMonat.length; j++) {
						const wg = warengruppenProMonat[j];
						obj[wg] = summenProMonat[j];
					}
					dataProvider.push(obj);

					// Arrays leeren für nächsten Monat
					warengruppenProMonat.length = 0;
					summenProMonat.length = 0;

					// neuen Monat in aktuellen Monat speichern
					aktuellerMonat = monat;
				}

				// Warengruppe und Summe einfügen
				warengruppenProMonat.push(warengruppe);
				summenProMonat.push(summe);

				if (warengruppe) warengruppen.push(warengruppe);
			}

			const warengruppenUnique = removeDuplicates(warengruppen);
			const graphs = [];
			for (let k = 0; k < warengruppenUnique.length; k++) {
				const warengruppe = warengruppenUnique[k];
				graphs.push({
					balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]] €</b></span>",
					fillAlphas: 0.8,
					labelText: "[[title]]",
					lineAlpha: 0.3,
					title: warengruppe,
					type: "column",
					color: "#000000",
					valueField: warengruppe
				});
			}

			const data = {
				graphs: graphs || [],
				dataProvider: dataProvider || []
			};
			resolve(data);
		}).catch(err => reject(err));
	});
}

export default StatistikWarengruppenUmsatz;
