import { getCurrentFilename, getWriteFetchObj, deleteRowsFromCache } from '../../functions';

class FetchBelegEdit {

	constructor(props, signal, sendData, unterschriftImageURL, belegId, belegNummer) {
		this.props = props;
		this.sendData = sendData;
		this.belegId = belegId;
		this.belegNummer = belegNummer;
		this.unterschriftImageURL = unterschriftImageURL || null;
		this.signal = signal;
		if (!belegId || isNaN(belegId)) throw new TypeError(`[${getCurrentFilename(__filename)}] belegId nicht vorhanden`);
		if (!belegNummer || isNaN(belegNummer)) throw new TypeError(`[${getCurrentFilename(__filename)}] belegNummer nicht vorhanden`);
	}

	myFetch = async () => {
		let url = `${window.RESTserver}/vario-api/verkauf/belege/${this.belegId}`;
		const response = await fetch(url, getWriteFetchObj(this.signal, 'PATCH', this.sendData));
		if (!response.ok) throw response;
		if (response.status !== 204) return 'Es gab ein Problem beim Speichern.';

		if (this.unterschriftImageURL) {
			const sendDataUnterschriftBild = {
				data: {
					Betreff: 'Unterschrift',
					Dateiname: 'Unterschrift.png',
					Daten: this.unterschriftImageURL
				}
			};
			// Unterschrift hochladen
			url = `${window.RESTserver}/vario-api/verkauf/belege/${this.belegId}/dateien`;
			await fetch(url, getWriteFetchObj(this.signal, 'POST', sendDataUnterschriftBild))
				.catch(err => {
					console.error(`[${getCurrentFilename(__filename)}] Upload Unterschriftbild Problem`, err);
				});
		}

		await deleteRowsFromCache(this.props.db, 'belege');

		return {
			kommentar: `Beleg mit der Nummer ${this.belegNummer} erfolgreich geändert!`,
			belegId: this.belegId,
			belegnummer: this.belegNummer
		};
	}
}

export default FetchBelegEdit;
