import Items from '.';
import { formatDatum, formatPreis, writeNullToKeys, getBelegartFromCode } from '../../functions';
import $ from '../../config';

class FetchBeleg extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		this.props = props;
		if (!id || isNaN(id)) throw new TypeError(`[FetchBeleg.js] id nicht vorhanden`);
		this.path = {
			string: `verkauf/belege/${id}`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Beleg', this.path).then(json => {

			if (!json.path || !json.results || !json.results.beleg) {
				reject('[Beleg.js] !json.path || !json.results || !json.results.beleg');
				return;
			}

			let { beleg } = json.results;

			getBelegartFromCode(this.props.db, beleg.belegart).then(belegart => {

				// Formatierungen bei Belegen
				beleg.belegdatum = formatDatum(beleg.belegdatum);
				beleg.liefertermin = formatDatum(beleg.liefertermin);
				beleg.betragNetto = formatPreis(beleg.betragNetto);
				beleg.frachtkosten = formatPreis(beleg.frachtkosten);
				beleg.betragBrutto = formatPreis(beleg.betragBrutto);
				beleg.nettoFaellig = formatDatum(beleg.nettoFaellig);
				beleg.mwstBetrag1 = formatPreis(beleg.mwstBetrag1);
				beleg.mwstBetrag2 = formatPreis(beleg.mwstBetrag2);
				beleg.mwstBetrag3 = formatPreis(beleg.mwstBetrag3);
				beleg.nachnahme = formatPreis(beleg.nachnahme);
				beleg.gewicht = `${beleg.gewicht} kg`;

				beleg.skontoBetrag = formatPreis(beleg.skontoBetrag);
				beleg.skontoFaellig = formatDatum(beleg.skontoFaellig);

				beleg.nachnahmeBrutto = formatPreis(beleg.nachnahmeBrutto);
				if (beleg.rabatt1) beleg.rabatt1 = `${beleg.rabatt1.toString().replace(/\./, ',')} %`;
				if (beleg.rabatt2) beleg.rabatt2 = `${beleg.rabatt2.toString().replace(/\./, ',')} %`;
				beleg.rabatt1Betrag = formatPreis(beleg.rabatt1Betrag);
				beleg.rabatt2Betrag = formatPreis(beleg.rabatt2Betrag);
				// Belegstatus-Text holen
				beleg.belegstatusCode = beleg.belegstatus;
				beleg.belegstatus = $.belegstatus[beleg.belegstatus];
				// Belegart-Text einfügen
				beleg.belegart = belegart;

				// Positionen-Formatierungen
				if (beleg.positionen) {
					for (let i = 0; i < beleg.positionen.length; i++) {
						const pos = beleg.positionen[i];
						beleg.positionen[i].einzelpreis = formatPreis(pos.einzelpreis);
						beleg.positionen[i].gesamtpreis = formatPreis(pos.gesamtpreis);
						beleg.positionen[i].endpreis = formatPreis(pos.endpreis);
						beleg.positionen[i].einzelpreisBrutto = formatPreis(pos.einzelpreisBrutto);
						beleg.positionen[i].gesamtpreisBrutto = formatPreis(pos.gesamtpreisBrutto);
						beleg.positionen[i].rabatt1 = pos.rabatt1 ? `${pos.rabatt1}%` : null;
						beleg.positionen[i].rabatt2 = pos.rabatt2 ? `${pos.rabatt2}%` : null;
						beleg.positionen[i].rabatt3 = pos.rabatt3 ? `${pos.rabatt3}%` : null;
						beleg.positionen[i].versicherung = formatPreis(pos.versicherung);
						beleg.positionen[i].versicherungBrutto = formatPreis(pos.versicherungBrutto);
						beleg.positionen[i].eilzuschlagNetto = formatPreis(pos.eilzuschlagNetto);
						beleg.positionen[i].eilzuschlagBrutto = formatPreis(pos.eilzuschlagBrutto);
						beleg.positionen[i].fixTermin = formatDatum(pos.fixTermin);
						beleg.positionen[i].gewicht = `${pos.gewicht} kg`;
						beleg.positionen[i].positionsArt = pos.positionsArt === 'T' ? 'T' : 'A';
					}
				}

				beleg = writeNullToKeys(beleg);
				resolve(beleg);

			}).catch(err => reject(err)); //getBelegartFromCode()
		}).catch(err => {
			reject(err);
			console.error(err);
			console.error(6754236529539);
		}); //super.fetchDbOrServerGet('Beleg'
	}).catch(err => console.error(err));
}

export default FetchBeleg;
