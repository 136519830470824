import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import '../../css/global.css';
import StatistikUmsatz from '../../classes/items/StatistikUmsatz';
import * as Amcharts from '../../functions/Amcharts';
import { grabArrFromObjectArray } from '../../functions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import StatistikRenner from '../../classes/items/StatistikRenner';
import StatistikUmsatzNachAusland from '../../classes/items/StatistikUmsatzNachAusland';
import StatistikGeburtstagskalender from '../../classes/items/StatistikGeburtstagskalender';
import Banner from '../../containers/Banner';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CakeIcon from '@material-ui/icons/Cake';
import SortIcon from '@material-ui/icons/Sort';
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import StatistikWarengruppenUmsatz from '../../classes/items/StatistikWarengruppenUmsatz';
import $ from '../../config';

const styles = theme => ({
	root: {
		padding: theme.spacing(1)
	},
	tableroot: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto',
		padding: 0
	},
	fullheight: {
		height: '100%'
	},
	heute: {
		color: '#A82E68',
		fontSize: 16
	},
	link: {
		cursor: 'pointer'
	},
	row: {
		cursor: 'pointer'
	},
	table: {
		padding: 0
	},
	icon: {
		color: '#2e2e2e'
	},
	header: {
		padding: 4
	},
	title: {
		fontSize: 20
	}
});

class Auswertungen extends Component {

	constructor(props) {
		super(props);
		this.rennerPennerYear = 2018;
		this.controller = new AbortController();
		this._isMounted = false;
		this.charts = [];
		this.data = [{
			fun: Amcharts.zeigeVorjahresvergleich,
			ueberschrift: $.dreiJahresumsatzTitel,
			promise: new StatistikUmsatz(this.props, this.controller.signal).fetch()
		}, {
			fun: Amcharts.zeigeChartRennerPenner,
			ueberschrift: $.rennerPennerTitel,
			promise: new StatistikRenner(this.props, this.controller.signal, this.rennerPennerYear).fetch()
		}, {
			fun: Amcharts.zeigeUmsatzNachAusland,
			ueberschrift: $.umsatzNachAuslandTitel,
			promise: new StatistikUmsatzNachAusland(this.props, this.controller.signal).fetch()
		}, {
			fun: Amcharts.zeigeChartUmsatz,
			ueberschrift: $.umsatzTitel,
			promise: new StatistikUmsatz(this.props, this.controller.signal).fetch()
		}, {
			fun: Amcharts.zeigeVorjahresvergleich,
			ueberschrift: $.dreiJahresumsatzTitel,
			promise: new StatistikUmsatz(this.props, this.controller.signal).fetch()
		}, {
			fun: Amcharts.zeigeChartWarengruppenUmsatz,
			ueberschrift: $.warengruppenUmsatzTitel,
			promise: new StatistikWarengruppenUmsatz(this.props, this.controller.signal).fetch()
		}];
		this.state = {
			belNeuOpen: false,
			charts: [],
			isFetchingCharts: true,
			isFetchingGeburtstage: true,
			geburtstage: null,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		};
		this.updateRennerPennerYear = this.updateRennerPennerYear.bind(this);
		this.getGeburtstageData = this.getGeburtstageData.bind(this);
		this.cancelRequests = this.cancelRequests.bind(this);
		this.printCharts = this.printCharts.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.updateUeberschriftFromChild(`Auswertungen`);
		this.getGeburtstageData();
		this.printCharts();
	}

	componentDidUpdate() {
		if (this.props.getIsFetching() && !this.state.isFetchingCharts && !this.state.isFetchingGeburtstage)
			this.props.setIsFetching(false);
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	cancelRequests() {
		this.controller.abort();
	}

	updateRennerPennerYear(year) {
		this.setState({ isFetchingCharts: true });
		this.rennerPennerYear = year;

		new StatistikRenner(this.props, this.controller.signal, this.rennerPennerYear).fetch().then(chartData => {
			const tempStateCharts = this.state.charts, domId = this.state.charts[1].DOMId;
			tempStateCharts.splice(1, 1, {
				data: chartData,
				DOMId: domId,
				ueberschrift: $.rennerPennerTitel
			});
			if (this._isMounted)
				this.setState({
					charts: tempStateCharts,
					isFetchingCharts: false
				});
			Amcharts.zeigeChartRennerPenner(this.state.charts[1].data, domId);
		});

	}

	getGeburtstageData() {
		this.setState({ isFetchingGeburtstage: true });
		new StatistikGeburtstagskalender(this.props, this.controller.signal).fetch().then(geburtstage => {
			if (this._isMounted) this.setState({
				geburtstage,
				isFetchingGeburtstage: false
			});
		});
	}
	printCharts() {
		const getDOMId = i => `AuswertungenCharts${i}`;
		this.setState({ isFetchingCharts: true });

		Promise.all(grabArrFromObjectArray(this.data, 'promise')).then(chartDatas => {
			const charts = [], newChartDatas = [], newThisData = [];
			let i;

			for (i = 0; i < chartDatas.length; i++) {
				const chartData = chartDatas[i];
				if (chartData === {} || (Array.isArray(chartData) && !chartData.length)) continue;
				newChartDatas.push(chartData);
				newThisData.push(this.data[i]);
			}

			// index umsortieren
			for (i = 0; i < newChartDatas.length; i++) {
				charts.push({
					data: newChartDatas[i],
					DOMId: getDOMId(i),
					ueberschrift: newThisData[i].ueberschrift
				});
			}

			if (this._isMounted)
				this.setState({
					charts,
					isFetchingCharts: false
				}, () => {
					for (i = 0; i < newChartDatas.length; i++) newThisData[i].fun(newChartDatas[i], getDOMId(i));
				});
		});
	}

	render() {
		const { classes } = this.props;

		const rennerPenneryear = new Date().getFullYear();
		const rennerPennerYears = [rennerPenneryear, rennerPenneryear - 1, rennerPenneryear - 2, rennerPenneryear - 3, rennerPenneryear - 4];

		const getAvatar = title => {
			if (title === $.rennerPennerTitel) return <SortIcon className={classes.icon} />;
			if (title === $.umsatzTitel) return <InsertChartIcon className={classes.icon} />;
			if (title === $.dreiJahresumsatzTitel) return <InsertChartOutlinedIcon className={classes.icon} />;
			if (title === $.warengruppenUmsatzTitel) return <BarChartIcon className={classes.icon} />;
			if (title === $.umsatzNachAuslandTitel) return <PieChartIcon className={classes.icon} />;
		};

		return (
			<>
        {(this.state.isFetchingCharts || this.state.isFetchingGeburtstage) && <LinearProgress color="secondary" className="global_progress_bar"/>}

				<div className={classes.root}>

					<Grid container spacing={1}>

						{this.state.charts.map((chart, index) =>
							<Grid key={index} item xs={12} md={6}>
								<Card className={classes.fullheight}>
									<CardHeader
										title={chart.ueberschrift}
										avatar={getAvatar(chart.ueberschrift)}
										classes={{ title: classes.title }}
									/>

									<Divider />

									<CardContent>
										{chart.ueberschrift === $.rennerPennerTitel ?
											<>
												<select
													id="year-select"
													onChange={() => this.updateRennerPennerYear(document.querySelector('#year-select').value)}>
													{rennerPennerYears.map((year, idx) => <option key={idx}>{year}</option>)}
												</select>
											</>
											: null
										}
										<div id={chart.DOMId} className="chartdiv"></div>
									</CardContent>
								</Card>
							</Grid>
						)}
						<Grid item xs={12} md={6}>
							<Card className={classes.fullheight}>
								<CardHeader title="Geburtstage" avatar={<CakeIcon />} />

								<Divider />

								<CardContent className={classes.tableroot}>
									{this.state.geburtstage ?
										<Table className={classes.table}>
											<TableBody>
												<TableRow hover>
													<TableCell component="th" variant="head">Person</TableCell>
													<TableCell component="th" variant="head" align="right">Geburtstag</TableCell>
													<TableCell component="th" variant="head">In ... Tagen</TableCell>
												</TableRow>
												{this.state.geburtstage.map((row, idx) => (
													<TableRow
														className={classes.row}
														hover
														key={idx}
														onClick={() => {
															const links = document.querySelector('.' + classes.tableroot).querySelectorAll('td > a');
															links[idx].click();
														}}
													>
														<TableCell className={row.daysLeft === $.gebHeute ? classes.heute : null}>
															<Link
																component='a'
																className={classes.link}
																href={`/app/adressen/${row.adresseId}?details&tab=1`}>
																{row.name}
															</Link>
														</TableCell>
														<TableCell className={row.daysLeft === $.gebHeute ? classes.heute : null}>{row.datum}</TableCell>
														<TableCell className={row.daysLeft === $.gebHeute ? classes.heute : null}>{row.daysLeft}</TableCell>
													</TableRow>
												))
												}
											</TableBody>
										</Table>
										: <Banner icon={<InfoOutlinedIcon />}>Keine Geburtstage vorhanden</Banner>}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			</>
		);
	}
}

//href={`/app/adressen/${row.adresseId}/ansprechpartners/${row.ansprechpartnerId}?details`}>

Auswertungen.propTypes = {
	classes: PropTypes.object.isRequired,
	db: PropTypes.object.isRequired
};

export default withStyles(styles)(Auswertungen);
