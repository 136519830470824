import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
	paper: {
		padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`
	},
	avatar: {
		backgroundColor: theme.palette.primary.main
	}
});

const Banner = props => {

	const { classes } = props;

	return (
		<Card>
			<Paper elevation={0} className={classes.paper}>
				<Grid container wrap="nowrap" spacing={1} alignItems="center">
					<Grid item>
						<Avatar className={classes.avatar}>{props.icon}</Avatar>
					</Grid>
					<Grid item>
						<Typography>{props.children}</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Card>
	);
};

export default withStyles(styles)(Banner);