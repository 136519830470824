import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	redAvatar: {
		background: red[400],
		marginRight: theme.spacing(1)
	},
	greenAvatar: {
		background: green[400],
		marginRight: theme.spacing(1)
	},
	yellowAvatar: {
		background: amber[400],
		marginRight: theme.spacing(1)
	}
});

const ListViewDeals = props => {

	if (!props.items) return null;

	const icon = item => {
		const { classes } = props;
		if (item.status === 0) {
			return {
				itemStatusIcon: <AutorenewIcon />,
				itemStatusClass: classes.yellowAvatar,
				itemPreis: item.investitionssummeGeplant
			};
		} else if (item.status === 1) {
			return {
				itemStatusIcon: <DoneIcon />,
				itemStatusClass: classes.greenAvatar,
				itemPreis: item.investitionssummeErreicht
			};
		} else if (item.status === 2) {
			return {
				itemStatusIcon: <ClearIcon />,
				itemStatusClass: classes.redAvatar,
				itemPreis: item.investitionssummeGeplant
			};
		}
		return {
			itemStatusIcon: <AutorenewIcon />,
			itemStatusClass: classes.yellowAvatar,
			itemPreis: item.investitionssummeGeplant
		};
	};

	const handleClick = id => {
		if (isNaN(id)) {
			console.warn(7854235789);
			return null;
		}
		if (props.writeUriAndSelectId) return props.writeUriAndSelectId(id);
		else if (props.handleClick) return props.handleClick(id);
		else console.warn('[ListViewDeals.class] kein Clickhandler in den props');
	};

	const StyledTypography = withStyles({
		root: {
			display: 'flex',
			justifyContent: 'space-between'
		}
	})(Typography);

	//console.log(props.items)

	return (
		<List style={props.listStyle}>
			{props.items.map((deal, i) => (
				<ListItem
					key={i}
					button
					divider={i + 1 !== props.items.length ? true : false}
					selected={props.selectedId === deal.id}
					onClick={() => handleClick(deal.id)}
				>
					<Avatar className={icon(deal)['itemStatusClass']}>
						{icon(deal)['itemStatusIcon']}
					</Avatar>

					<ListItemText
						disableTypography
						primary={
							<Typography
								noWrap
								variant="body1"
							>
								{`${deal.id}: ${deal.titel}`}
							</Typography>
						}
						secondary={
							<>
								<StyledTypography
									component="p"
									variant="body2"
									color="textSecondary"
								>
									<span>{deal.status_text}</span>
									<span>{deal.phase_text}</span>
								</StyledTypography>
								<Typography
									component="p"
									variant="body2"
									color="textSecondary"
									noWrap
								>
									{deal.adresse}
								</Typography>
							</>
						}
					/>
				</ListItem>
			))}
		</List>
	);
};

export default withStyles(styles)(ListViewDeals);
