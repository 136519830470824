import { createSelector } from 'reselect';

const getInitial = positionen => Object.entries(positionen.items || {});
const getInitialFiltered = (positionen, ids) => getInitial(positionen).filter(p => ids.includes(+p[0]));
const getTemplate = (positionen, id) => positionen.items[id];
const getCreated = positionen => {
	const entries = Object.entries(positionen || {}).filter(position => position[0] !== 'items');
	return entries;
};

const getPartitions = positionen => getCreated(positionen).map(created => (
		[created[0], Object.entries(created[1]).map(item => (
			Object.assign({}, getTemplate(positionen, created[0]),
				{
					menge: item[1].count,
					index: +item[0],
					status: item[1].status || 1
				})
		))]
	));
const getPartitionsFiltered = (positionen, ids) => getPartitions(positionen).filter(position => ids.includes(+position[0]));

const getUnchanged = positionen => getInitial(positionen).filter(pos => !positionen[pos[0]]);
const getUnchangedFiltered = (positionen, ids) => getInitialFiltered(positionen, ids).filter(pos => !positionen[pos[0]]);

export const getAllPositionen = createSelector(
	[getUnchanged],
	(unchanged) => [...unchanged.map(entry => entry[1])]
);
export const getVisiblePositionen = createSelector(
	[getPartitionsFiltered, getUnchangedFiltered],
	(partitions, unchanged) => [...partitions.map(entry => entry[1]).reduce((prev, curr) => prev.concat(curr), []), ...unchanged.map(entry => entry[1])]
);
