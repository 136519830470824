import Items from '.';
import { writeNullToKeys, getBelegartFromCode, formatDatum, formatPreis, getTageBis } from '../../functions';

class FetchOffenePosten extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.path = {
			string: props.listingPath,
			id: null
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Offene Posten', this.path).then(json => {

			if (!json.path || !json.results || !json.results.offenePosten) {
				resolve([]);
				return;
			}

			const { offenePosten } = json.results;

			// TODO: kann mit functions:formatOPListe formatiert werden

			const promises = [];
			for (let i = 0; i < offenePosten.length; i++) {
				const offenerPosten = offenePosten[i];
				promises.push(getBelegartFromCode(this.props.db, offenerPosten.rechnungsart));
			}

			Promise.all(promises).then(values => {

				for (let i = 0; i < offenePosten.length; i++) {
					const belegart = values[i * 2 + 1];
					offenePosten[i].rechnungsart = belegart;
					offenePosten[i].tageUeberfaellig = getTageBis(offenePosten[i].nettoFaellig) * -1;
					offenePosten[i].rechnungsdatum = formatDatum(offenePosten[i].rechnungsdatum);
					offenePosten[i].offenerBetrag = formatPreis( offenePosten[i].offenerBetrag);
				}

				const obj = {
					totalResultCount: json.results.totalResultCount,
					offeneposten: writeNullToKeys(offenePosten)
				};
				resolve(obj);
			}).catch(err => reject(err));
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaeten zu Adresse'
	});
}

export default FetchOffenePosten;
