import Items from '.';
import FetchGlobaleEinstellung from './FetchGlobaleEinstellung';
import FetchUserEinstellung from './FetchUserEinstellung';
import { writeNullToKeys, getCurrentFilename } from '../../functions';

class FetchEinstellungDetails extends Items {

	constructor(props, signal, einstellungId, itemNode) {
		super(props, signal);
		if (!einstellungId || isNaN(einstellungId)) throw new TypeError(`[${getCurrentFilename(__filename)}] einstellungId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.einstellungId = einstellungId;
		this.itemNode = itemNode;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {

		if (this.itemNode === 'einstellungen') {
			resolve({});
			return;
		}

		let global, user;
		const promises = [
			new FetchGlobaleEinstellung(this.props, this.signal, this.einstellungId).fetch(),
			new FetchUserEinstellung(this.props, this.signal, this.einstellungId, this.userId).fetch()
		];

		Promise.all(promises).then(json => {

			[global, user] = json;

			const obj = {
				global: global || null,
				user: user || null
			};

			resolve(writeNullToKeys(obj));

		}).catch(err => {
			reject(err);
			console.log(14349323458207);
		}); //Promise.all(promises)
	}).catch(err => {
		console.log(1433359034599548);
		console.error(err);
	});
}

export default FetchEinstellungDetails;