import Items from '.';
import { formatPreis } from '../../functions';

class FetchOffenePostenUebersichtAdresse extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		this.path = {
			string: `auswertungen/sql`,
			id
		}
		this.sendData = {
			sql: `
				SELECT
					COUNT(opv.id) AS anzahl,
					SUM(opv.rechnungsbetrag) AS rechnungsbetrag,
					SUM(opv.bishergezahlt) AS bishergezahlt,
					SUM(opv.offenerbetrag) AS offenerbetrag
				FROM
					opv
				WHERE
					opv.adressnr = ${id}
				AND
					opv.opvart = 'O'
				AND
					opv.abgeschlossen = 'N'
				AND
					opv.inklaerung = 'N'
		`};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerPost('Offene Posten Adresse', this.path, this.sendData).then(json => {
			const op = json.results.offenePostenAdresse[0];

			op.rechnungsbetrag = formatPreis( op.rechnungsbetrag);
			op.bishergezahlt = formatPreis( op.bishergezahlt);
			op.offenerbetrag = formatPreis( op.offenerbetrag);
			resolve(op);
		}).catch(err => reject(err));
	}).catch(err => console.error(err))
}

export default FetchOffenePostenUebersichtAdresse;
