import { getCurrentFilename, getWriteFetchObj, deleteRowsFromCache } from '../../functions';
import FetchBeleg from './FetchBeleg';

class FetchBelegSpeichern {

	constructor(props, signal, sendData, unterschriftImageURL) {
		this.props = props;
		this.sendData = sendData;
		this.unterschriftImageURL = unterschriftImageURL || null;
		this.signal = signal;
	}

	getBelegId = locHeader => {
		const match = locHeader.match(/\/(\d+)$/);
		if (!match || !match.length) {
			console.error(`[${getCurrentFilename(__filename)} => getBelegId()]`);
			return;
		}
		return match[1];
	};

	myFetch = async () => {
		let url = `${window.RESTserver}/vario-api/verkauf/belege`;
		const response = await fetch(url, getWriteFetchObj(this.signal, 'POST', this.sendData));
		if (!response.ok) throw response;
		if (response.status !== 201) return 'Es gab ein Problem beim Speichern.';
		const belegId = this.getBelegId(response.headers.get('Location'));

		if (this.unterschriftImageURL) {
			const sendDataUnterschriftBild = {
				data: {
					Betreff: 'Unterschrift',
					Dateiname: 'Unterschrift.png',
					Daten: this.unterschriftImageURL
				}
			};
			// Unterschrift hochladen
			url = `${window.RESTserver}/vario-api/verkauf/belege/${belegId}/dateien`;
			await fetch(url, getWriteFetchObj(this.signal, 'POST', sendDataUnterschriftBild))
				.catch(err => {
					console.error(`[${getCurrentFilename(__filename)}] Upload Unterschriftbild Problem`, err);
				});
		}

		await deleteRowsFromCache(this.props.db, 'belege');

		// Belegnummer holen
		let beleg;
		try {
			beleg = await new FetchBeleg(this.props, this.signal, belegId).fetch();
		} catch (error) {
			console.error(error)
			return error;
		}
		return {
			kommentar: `Beleg mit der Nummer ${beleg.belegnummer} erfolgreich gespeichert!`,
			belegId,
			belegnummer: beleg.belegnummer
		};
	}
}

export default FetchBelegSpeichern;
