import React, { useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import IterableButton from './iterableButton';

const IterableAcceptDecline = props => {
	const [currentStatus, updateStatus] = useState(props.controlled || props.status);
	const [after, before] = [
		<CheckIcon fontSize="large"/>,
		<CloseIcon fontSize="large"/>
	];
	const { acceptStates, rejectStates } = props;
	const length = acceptStates.length > rejectStates.length ? rejectStates.length : acceptStates.length;
	if (currentStatus < length) {
		return (
			<CardActions>
				<IterableButton
					states={acceptStates}
					color="primary"
					after={after}
					status={currentStatus}
					callback={() => {
						updateStatus(currentStatus + 1);
						const accept = true;
						props.callback && props.callback(currentStatus, accept);
					}}
					controlled={props.controlled || currentStatus}
				/>
				<IterableButton
					states={rejectStates}
					color="secondary"
					before={before}
					status={currentStatus}
					callback={() => {
						updateStatus(currentStatus + 1);
						const accept = false;
						props.callback && props.callback(currentStatus, accept);
					}}
					controlled={props.controlled || currentStatus}/>
			</CardActions>
		);
	} else {
		return <></>;
	}
};

export default IterableAcceptDecline;
