import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import $ from '../config';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';

const ListViewAdressen = props => {

	if (!props.items) return null;

	const { globalSearch } = props;
	let lineThrough, MyAvatar;

	const GreenAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);

	const OrangeAvatar = withStyles(theme => ({
		root: {
			backgroundColor: deepOrange[500],
			margin: theme.spacing(1)
		}
	}))(Avatar);

	const IndigoAvatar = withStyles(theme => ({
		root: {
			backgroundColor: indigo[500],
			margin: theme.spacing(1)
		}
	}))(Avatar);

	const handleClick = id => {
		if (isNaN(id)) {
			console.warn(7835657983);
			return null;
		}
		if (props.writeUriAndSelectId) return props.writeUriAndSelectId(id);
		else if (props.handleClick) return props.handleClick(id);
		else console.warn('[ListViewAdressen.class] kein Clickhandler in den props');
	};

	//console.log(props.items);

	return (
		<List style={props.listStyle}>

			{props.items.map((adresse, i) => {

				lineThrough = adresse.gesperrt === 'ja' ? { textDecoration: 'line-through' } : null;
				MyAvatar = globalSearch ? IndigoAvatar : GreenAvatar;

				return (
					<ListItem
						button
						component={globalSearch ? 'a' : 'li'}
						href={globalSearch ? `/app/${$.suchergebnisseNode}/${$.adressenNode}/${adresse.id}` : null}
						onClick={globalSearch ? null : () => handleClick(adresse.id)}
						key={i}
						selected={props.selectedId === adresse.id}
						divider={i + 1 !== props.items.length || (globalSearch && i + 1 === props.items.length) ? true : false}
					>
						<MyAvatar><LocationCityIcon /></MyAvatar>
						<ListItemText
							disableTypography
							primary={
								<Typography
									noWrap
									variant="body1"
									style={lineThrough}
								>
									{adresse.name1}
								</Typography>
							}
							secondary={
								<>
									<Typography
										component="p"
										variant="body2"
										color="textSecondary"
										noWrap
									>
										{adresse.strasse}
									</Typography>
									<Typography
										component="p"
										variant="body2"
										color="textSecondary"
										noWrap
									>
										{`${adresse.plz} ${adresse.ort}`}
									</Typography>
								</>
							}
						/>
					</ListItem>
				)
			})}

			{props.ansPartner ? props.ansPartner.map((ansPartner, i, alleAnsprechpartner) => {

				MyAvatar = globalSearch ? OrangeAvatar : GreenAvatar;

				return (
					<ListItem button
						divider={i + 1 !== alleAnsprechpartner.length ? true : false}
						component="a"
						href={`/app/${$.suchergebnisseNode}/${$.adressenNode}/${ansPartner.adresseId}/${$.ansprechpartnersNode}/${ansPartner.id}`}
						key={i}
					>
						<MyAvatar><PersonIcon /></MyAvatar>
						<ListItemText primary={`${ansPartner.anrede} ${ansPartner.name}`} />
					</ListItem>
				)
			}) : null}

		</List>
	);
};

export default ListViewAdressen;
