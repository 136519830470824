import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import CanvasUnterschrift from './CanvasUnterschrift';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
	root: {
		padding: '16px 8px 8px 8px'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	appBar: {
		position: 'relative'
	},
	unterschrift: {
		width: '100%',
		height: '100%'
	}
});

class FullscreenSignModal extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.suchEingabeTimer = null;
		this.canvasUnterschrift = React.createRef();

		this.state = {
			textposition: '',
			suchEingabe: '',
			suggestions: [],
			isFetching: false,
			zAdressen: [],

			// Canvas geladen?
			canvasMounted: false,

			// Breite des Unterschrift Canvas
			cuWidth: 0,
			cuHeight: 0
		};
		//#region
		this.updateCanvasSize = this.updateCanvasSize.bind(this);
		this.canvasDiv = this.canvasDiv.bind(this);
		this.setCanvasMounted = this.setCanvasMounted.bind(this);
		this.canvas = this.canvas.bind(this);
		this.onSaveClicked = this.onSaveClicked.bind(this);
		//#endregion
	}

	componentDidMount() {
		this._isMounted = true;
		window.addEventListener('resize', this.updateCanvasSize);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.open && !this.props.open) {
			// Modal wurde geschlossen
			this.setState({
				suchEingabe: '',
				textposition: '',
				zAdressen: []
			});
		}
		else if (!prevState.canvasMounted && this.state.canvasMounted) {
			// Modal wurde geöffnet und Canvas mounted
			this.updateCanvasSize();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
		window.removeEventListener('resize', this.updateCanvasSize);
	}

	updateCanvasSize() {

		if (!this.canvasDiv()) {
			//console.log('!this.canvasDiv()');
			return;
		}

		const width = this.canvasDiv().offsetWidth;
		const height = this.canvasDiv().offsetHeight;
		if (this.state.cuWidth !== width) this.setState({ cuWidth: width });
		if (this.state.cuHeight !== height) this.setState({ cuHeight: height });

		// scrolling verhindern, wenn das canvas getouched wird...
		document.body.addEventListener('touchstart', e => {
			if (e.target === this.canvas()) {
				e.preventDefault();
			}
		}, { passive: false });
		document.body.addEventListener('touchend', e => {
			if (e.target === this.canvas()) {
				e.preventDefault();
			}
		}, { passive: false });
		document.body.addEventListener('touchmove', e => {
			if (e.target === this.canvas()) {
				e.preventDefault();
			}
		}, { passive: false });
		document.body.addEventListener('touchcancel', e => {
			if (e.target === this.canvas()) {
				e.preventDefault();
			}
		}, { passive: false });
	}

	canvasDiv() {
		if (this.canvasUnterschrift) return this.canvasUnterschrift.current;
		return null;
	}

	canvas() {
		if (!this.canvasDiv()) return null;
		return this.canvasDiv().childNodes[0];
	}

	setCanvasMounted(mounted) {
		this.setState({ canvasMounted: mounted });
	}

	onSaveClicked() {
		this.props.saveUnterschrift(this.canvas().toDataURL('image/png'));
		this.props.closeModal();
	}

	render() {
		const { classes } = this.props;

		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				open={this.props.open}
				fullScreen
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={this.props.closeModal} aria-label="Schließen"><ArrowBackIcon /></IconButton>
						<Typography variant="h6" className={classes.title}>Unterschrift</Typography>
					</Toolbar>
				</AppBar>

				{this.state.isFetching && <LinearProgress color="secondary" className="global_progress_bar" />}

				<DialogContent className={classes.root}>

					<div className={classes.unterschrift} ref={this.canvasUnterschrift}>
						<CanvasUnterschrift
							width={this.state.cuWidth}
							height={this.state.cuHeight}
							disabled={this.state.textfieldsDisabled}
							setCanvasMounted={this.setCanvasMounted}
						/>
					</div>

				</DialogContent>

				<DialogActions>
					<Button onClick={this.onSaveClicked} color="primary">Speichern</Button>
				</DialogActions>

			</Dialog>
		);
	}
}

FullscreenSignModal.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullscreenSignModal);
