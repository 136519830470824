import { INSERT_POSITION, SPLIT_POSITION } from '../constants/actionTypes';
import { loadState } from '../sessionStorage';

const persistedState = loadState();
const empty = {
	positionen: {
		items: {}
	}
};
const initialState = Object.assign({}, (persistedState || empty).positionen);

export const nested = (object, ...args) => args.reduce((prev, curr) => prev[curr] || [], object);

export default function positionen(state = initialState, action) {
	switch (action.type) {
		case INSERT_POSITION:
			return Object.assign({},
				state,
				{
					items: {
						...state.items,
						[action.item.id]: action.item
					}
				});
		case SPLIT_POSITION:
			return Object.assign({},
				state,
				{
					[action.id]: {
						...state[action.id],
						[action.index]: {
							count: action.count,
							index: action.index,
							status: action.status
						},
						[action.index + 1]:
							{
								count: +action.created +
									+nested(state, action.id, action.index + 1, 'count'), // add previous count if it exists
								index: action.index + 1,
								status: action.status
							}
					}
				});
		default:
			return state;
	}
}
