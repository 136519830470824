import Fetch from '../fetch';
import { formatDatum, formatPreis, writeNullToKeys, getBelegartFromCode, addDigits } from '../../functions';
import $ from '../../config';

class FetchBelegsimulation {

	constructor(props, signal, sendData) {
		this.props = props;
		this.signal = signal;
		this.sendData = sendData;
		this.path = {
			string: `verkauf/belege/simulation`,
			id: null
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		new Fetch(this.props).post(this.path, this.signal, 'Belegsimulation', this.sendData).then(json => {

			let beleg = json.result;

			if (!beleg) {
				reject('[Belegsimulation.js] Kein result vorhanden!');
				return;
			}

			getBelegartFromCode(this.props.db, beleg.belegart).then(belegart => {

				// Formatierungen bei Belegen
				beleg.belegdatum = formatDatum(beleg.belegdatum);
				beleg.liefertermin = formatDatum(beleg.liefertermin);
				beleg.nettoFaellig = formatDatum(beleg.nettoFaellig);
				if (beleg.mwstProzent1) beleg.mwstProzent1 = `${beleg.mwstProzent1} %`;
				if (beleg.mwstProzent2) beleg.mwstProzent2 = `${beleg.mwstProzent2} %`;
				if (beleg.mwstProzent3) beleg.mwstProzent3 = `${beleg.mwstProzent3} %`;
				beleg.mwstBetrag1 = formatPreis(beleg.mwstBetrag1);
				beleg.mwstBetrag2 = formatPreis(beleg.mwstBetrag2);
				beleg.mwstBetrag3 = formatPreis(beleg.mwstBetrag3);
				beleg.nachnahme = formatPreis(beleg.nachnahme);
				beleg.gewicht = `${beleg.gewicht} kg`;

				beleg.skontoBetrag = formatPreis(beleg.skontoBetrag);
				beleg.frachtkosten = formatPreis(beleg.frachtkosten);
				beleg.skontoFaellig = formatDatum(beleg.skontoFaellig);

				beleg.nachnahmeBrutto = formatPreis(beleg.nachnahmeBrutto);
				if (beleg.rabatt1) beleg.rabatt1 = beleg.rabatt1 ? addDigits(beleg.rabatt1, 1) : null;
				if (beleg.rabatt2) beleg.rabatt2 = beleg.rabatt2 ? addDigits(beleg.rabatt2, 1) : null;
				beleg.rabatt1Betrag = formatPreis(beleg.rabatt1Betrag, true);
				beleg.rabatt2Betrag = formatPreis(beleg.rabatt2Betrag, true);
				// Belegstatus-Text holen
				beleg.belegstatusCode = beleg.belegstatus;
				beleg.belegstatus = $.belegstatus[beleg.belegstatus];
				// Belegart-Text einfügen
				beleg.belegart = belegart;

				// Positionen-Formatierungen
				if (beleg.positionen) {
					for (let i = 0; i < beleg.positionen.length; i++) {
						const pos = beleg.positionen[i];
						beleg.positionen[i].einzelpreis = formatPreis(pos.einzelpreis, true);
						beleg.positionen[i].gesamtpreis = formatPreis(pos.gesamtpreis);
						beleg.positionen[i].endpreis = formatPreis(pos.endpreis);
						beleg.positionen[i].einzelpreisBrutto = formatPreis(pos.einzelpreisBrutto);
						beleg.positionen[i].gesamtpreisBrutto = formatPreis(pos.gesamtpreisBrutto);
						beleg.positionen[i].rabatt1 = pos.rabatt1 ? addDigits(pos.rabatt1, 1) : null;
						beleg.positionen[i].rabatt2 = pos.rabatt2 ? addDigits(pos.rabatt2, 1) : null;
						beleg.positionen[i].rabatt3 = pos.rabatt3 ? addDigits(pos.rabatt3, 1) : null;
						beleg.positionen[i].versicherung = formatPreis(pos.versicherung);
						beleg.positionen[i].versicherungBrutto = formatPreis(pos.versicherungBrutto);
						beleg.positionen[i].eilzuschlagNetto = formatPreis(pos.eilzuschlagNetto);
						beleg.positionen[i].eilzuschlagBrutto = formatPreis(pos.eilzuschlagBrutto);
						beleg.positionen[i].fixTermin = formatDatum(pos.fixTermin);
						beleg.positionen[i].gewicht = `${pos.gewicht} kg`;
						beleg.positionen[i].positionsArt = pos.positionsArt === 'T' ? 'T' : 'A';
					}
				}

				resolve(writeNullToKeys(beleg));
			}); //getBelegartFromCode()
		}).catch(err => reject(err));
	});
}

export default FetchBelegsimulation;
