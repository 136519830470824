import Items from '.';
import { formatDealsListe, writeNullToKeys } from '../../functions';

class FetchDeals extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.props = props;
		this.signal = signal;

		this.path = {
			string: props.listingPath,
			id: null
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Deals', this.path).then(json => {

			if (!json.path || !json.results || !json.results.deals) {
				resolve([]);
				return;
			}

			const { deals } = json.results;

			// wenn es keine Deals gibt
			if (deals.length === 0) {
				resolve([]);
				return;
			}

			formatDealsListe(this.props.db, deals).then(formatierteDeals => {
				const obj = {
					totalResultCount: json.results.totalResultCount,
					deals: writeNullToKeys(formatierteDeals)
				};
				resolve(obj)
			});
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Belege zu Adresse'
	}).catch(err => console.error(err));
}

export default FetchDeals;
