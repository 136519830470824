import Items from '.';
import FetchAktivitaetenZuDeal from './FetchAktivitaetenZuDeal';
import BelegeZuDeal from './BelegeZuDeal';
import FetchBeleg from './FetchBeleg';
import FetchDeal from './FetchDeal';
import { writeNullToKeys } from '../../functions';

class FetchDealDetails extends Items {
	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[Deal.js] id nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.dealId = id;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		const dealPromise = new FetchDeal(this.props, this.signal, this.dealId).fetch();
		const belPromise = new BelegeZuDeal(this.props, this.signal, this.dealId).fetch();
		const aktPromise = new FetchAktivitaetenZuDeal(this.props, this.signal, this.dealId).fetch();

		Promise.all([dealPromise, belPromise, aktPromise]).then(values => {
			const deal = values[0];
			const belegeJson = values[1];
			const aktivitaeten = values[2];

			// Belege holen zu Beleg-ID's
			const belegPromises = [];
			if (belegeJson.results && belegeJson.results.belegeZuDeal && belegeJson.results.belegeZuDeal.length > 0) {
				const belegeArr = belegeJson.results.belegeZuDeal;
				for (let i = 0; i < belegeArr.length; i++) {
					belegPromises.push(new FetchBeleg(this.props, this.signal, belegeArr[i].id).fetch());
				}
			}

			Promise.all(belegPromises).then(belege => {

				const { adresse } = deal;
				const { ansprechpartner } = deal;

				const dataDeal = {
					id: this.dealId,
					firma: adresse ? adresse.name1 : null,
					phase: deal.phase,
					ansprechpartner: ansprechpartner ? `${ansprechpartner.anrede} ${ansprechpartner.name}` : null,
					thema: deal.titel,
					gsumme: deal.investitionssummeGeplant,
					gdatum: deal.investitionsdatumGeplant,
					chance: deal.chance,
					phone: ansprechpartner ? ansprechpartner.telefon : null,
					mail: ansprechpartner ? ansprechpartner.email : null,
					web: adresse ? adresse.webseite : null,
					zustBenutzer: deal.zustaendigerBenutzer,
					aktivitaeten,
					belege
				};

				resolve(writeNullToKeys(dataDeal));
				return;
			}); //Promise.all(belegPromises)
		}); //Promise.all([dealPromise,
	});
}

export default FetchDealDetails;
