import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import TimerIcon from '@material-ui/icons/Timer';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeTelLink } from '../functions';
import store from '../redux-service';
import { acceptTask, completeTask, insertStatus, rejectTask, updateStatus } from '../redux-service/actions';
import { getAcceptedIds, getCompletedIds, getPendingIds } from '../redux-service/selectors/tasks';
import AcceptDeclineOnce from '../redux-service/containers/acceptDeclineOnce';
import IterableButton from '../redux-service/components/iterableButton';
import FilteredPositionList from '../redux-service/containers/filteredPositionList';
//region Styles
const StyledCard = withStyles({
	root: {
		margin: '8px',
		padding: 8
	}
})(Card);
const left = {
	item: true,
	xs: 6,
	sm: 4
};
const right = {
	item: true,
	xs: 6,
	sm: 8
};
const leftCenter = {
	...left,
	container: true,
	justify: 'center'
};
const rightCenter = {
	...right,
	container: true,
	justify: 'center'
};
//endregion

//@rs: #1
//region Rows
const Row = props => (
	<>
		<Grid {...props.left.grid}>
			<Typography variant="subtitle2">
				{props.left.title}
			</Typography>
		</Grid>
		<Grid {...props.right.grid}>
			<Typography variant="subtitle2">
				{props.right.title}
			</Typography>
		</Grid>
	</>
);

//endregion

const detailViewServiceauftrag = props => {
//region Kundendaten/Assetdaten
	const Kundendaten = () => {
		if (!props.dataItem) return;
		const serviceauftrag = props.dataItem.serviceauftrag.results.serviceauftragDetails;
		const { adresse } = props.dataItem;
		const dash = '—';
		return (
			<>
				<StyledCard>
					<Grid container spacing={1}>
						<Grid xs={12} item container justify="center"><Typography variant="h5" color="secondary">{adresse.anrede}{adresse.name1}</Typography></Grid>
						<Grid xs={12} item container justify="center">
							<Typography variant="subtitle2">
								{serviceauftrag.strasse}, {serviceauftrag.plz} {serviceauftrag.ort}
							</Typography>
						</Grid>
						<List>
							<ListItem>
								<Typography variant="h6">Adresse</Typography>
							</ListItem>
							{
								serviceauftrag.telefon1 &&
								<ListItem button divider component="a" href={serviceauftrag.telefon1 !== dash ? `tel:${makeTelLink(serviceauftrag.telefon1)}` : `#`}>
									<ListItemIcon>
										<PhoneIcon/>
									</ListItemIcon>
									<ListItemText primary={serviceauftrag.telefon1} secondary="Telefon"/>
								</ListItem>
							}
							{
								serviceauftrag.telefon2 &&
								<ListItem button divider component="a" href={serviceauftrag.telefon2 !== dash ? `tel:${makeTelLink(serviceauftrag.telefon2)}` : `#`}>
									<ListItemIcon>
										<PhoneIcon/>
									</ListItemIcon>
									<ListItemText primary={serviceauftrag.telefon2} secondary="Telefon"/>
								</ListItem>
							}
							{
								serviceauftrag.mobil &&
								<ListItem button divider component="a" href={serviceauftrag.mobil !== dash ? `tel:${makeTelLink(serviceauftrag.mobil)}` : `#`}>
									<ListItemIcon>
										<SmartphoneIcon/>
									</ListItemIcon>
									<ListItemText primary={serviceauftrag.mobil} secondary="Mobil"/>
								</ListItem>
							}
							{
								serviceauftrag.email &&
								<ListItem button divider component="a" href={serviceauftrag.email !== dash ? `mailto:${serviceauftrag.email}` : `#`}>
									<ListItemIcon>
										<EmailIcon/>
									</ListItemIcon>
									<ListItemText primary={serviceauftrag.email} secondary="E-Mail"/>
								</ListItem>
							}
						</List>
						<Grid item xs={12}>
							<ExpansionPanel>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
									<Typography variant="subtitle2">Details</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid container spacing={0}>
										<Grid {...left}>
											Abrechnungsart
										</Grid>
										<Grid {...right}>
											{serviceauftrag.abrechnungsartId}
										</Grid>
										<Grid {...left}>
											Adress-Id
										</Grid>
										<Grid {...right}>
											{serviceauftrag.adresseId}
										</Grid>
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</Grid>
					</Grid>
				</StyledCard>
			</>
		);
	};

	const Assetdaten = () => {
		if (!props.dataItem.asset) return (
			<StyledCard>
				<Typography>Keine Assetdaten vorhanden!</Typography>
			</StyledCard>
		);
		const { asset } = props.dataItem;
		return (
			<>
				<StyledCard>
					<CardHeader
						title={asset.bezeichnung}
					/>
					<Grid container spacing={1}>
						<Grid {...left}><Typography>ID</Typography></Grid>
						<Grid {...right}><Typography>{asset.id}</Typography></Grid>
						<Grid {...left}><Typography>Artikelnr.</Typography></Grid>
						<Grid {...right}><Typography>{asset.artikelId}</Typography></Grid>
						<Grid {...left}><Typography>Assetnr.</Typography></Grid>
						<Grid {...right}><Typography>{asset.assetnummer}</Typography></Grid>
						<Grid {...left}><Typography>Imei</Typography></Grid>
						<Grid {...right}><Typography>{asset.imei}</Typography></Grid>
						<Grid {...left}><Typography>Aktiv</Typography></Grid>
						<Grid {...right}><Typography>{asset.istAktiv}</Typography></Grid>
						<Grid {...left}><Typography>Seriennummer</Typography></Grid>
						<Grid {...right}><Typography>{asset.seriennummer}</Typography></Grid>
					</Grid>
				</StyledCard>
			</>
		);
	};
//endregion
	const Auftragsdaten = () => {
		// const { acceptedIds, completedIds, rejectedIds } = store.getState().tasks;
		// console.table({
		// 	acceptedIds,
		// 	completedIds,
		// 	rejectedIds
		// });
		if (!props.dataItem) return;
		const serviceauftrag = props.dataItem.serviceauftrag.results.serviceauftragDetails;
		const pending = getPendingIds(store.getState()).includes(serviceauftrag.id);
		const accepted = getAcceptedIds(store.getState()).includes(serviceauftrag.id);
		const uncompleted = !getCompletedIds(store.getState()).includes(serviceauftrag.id);
		const status = store.getState().status[serviceauftrag.id] ? store.getState().status[serviceauftrag.id].timestamps.length - 1 : 0;
		return (
			<>
				<StyledCard>
					<Grid container spacing={1}>
						<Grid item container xs={8} justify="space-evenly" alignItems="center">
							<Typography variant="h6">{serviceauftrag.name1}</Typography>
							<Typography variant="subtitle2">{serviceauftrag.ort}</Typography>
						</Grid>
						<Grid item xs={4} container justify="flex-end" alignItems="center">
							<TimerIcon/>
							<Typography variant="h6">{serviceauftrag.aufwandGeschaetzt}</Typography>
						</Grid>
						<Grid item xs={12}>
							<AcceptDeclineOnce
								id={serviceauftrag.id}
								updateUri={newId => props.dataItem.screenProps.writeUriAndSelectId(newId)}
								store={store}
								pending={pending}
								accepted={accepted}
								primary="Annehmen"
								donePrimary="Auftrag angenommen"
								secondary="Ablehnen"
								doneSecondary="Auftrag abgelehnt"
								accept={props => {
									props.store.dispatch(acceptTask(props.id));
									props.updateUri(props.id);
									store.dispatch(insertStatus(props.id, Date.now()));
								}}
								decline={props => {
									props.store.dispatch(rejectTask(props.id));

									const remaining = getPendingIds(props.store.getState());
									if (remaining.length) {
										//@rs: #1 Auf erstes aktive springen oder auf erstes neue?
										props.updateUri(remaining[0]);
								}

							}}
							/>
							{
								accepted && uncompleted &&
								<IterableButton
									states={['Fahre los', 'Fange an', 'Läuft wieder', 'Bin fertig']}
									status={status}
									callback={(currentStatus, states) => {
										store.dispatch(updateStatus(serviceauftrag.id, Date.now()));
										if (!~(currentStatus - states.length)) store.dispatch(completeTask(serviceauftrag.id));
									}}
								/>
							}
						</Grid>
					</Grid>
				</StyledCard>
				{serviceauftrag.positionen &&
				<FilteredPositionList ids={serviceauftrag.positionen.map(p => p.id)}/>
				}
			</>
		);
	};

	return [
		{
			ueberschrift: 'Kundendaten',
			Funktion: Kundendaten
		},
		{
			ueberschrift: 'Assetdaten',
			Funktion: Assetdaten
		},
		{
		ueberschrift: 'Auftragsdaten',
		Funktion: Auftragsdaten
	}];
};

export default detailViewServiceauftrag;
