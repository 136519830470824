import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import VarioAppBar from '../components/VarioAppBar';
import $ from '../config';
import NavigationDrawer from '../containers/NavigationDrawer';
import FilterDrawer from '../components/FilterDrawer';
import Auswertungen from './auswertungen';
import Dashboard from './dashboard';
import ListView from './listview';
import Notfound from './notfound';
import { getKuerzel, initComponent, checkOnline } from '../functions';
import Modal from '../containers/Modal';
import BelegNeu from './belegneu';
import FetchAdressen from '../classes/items/FetchAdressen';
import FetchSearchresultsGlobal from '../classes/items/FetchSearchresultsGlobal';
import FetchSuchergebnisDetails from '../classes/items/FetchSuchergebnisDetails';
import FetchDeals from '../classes/items/FetchDeals';
import FetchArtikels from '../classes/items/FetchArtikels';
import FetchDealDetails from '../classes/items/FetchDealDetails';
import FetchArtikelDetails from '../classes/items/FetchArtikelDetails';
import FetchBelegDetails from '../classes/items/FetchBelegDetails';
import FetchServiceauftragDetails from '../classes/items/FetchServiceauftragDetails';
import FetchEinstellungen from '../classes/items/FetchEinstellungen';
import FetchEinstellungDetails from '../classes/items/FetchEinstellungDetails';
import FetchOffenerPostenDetails from '../classes/items/FetchOffenerPostenDetails';
import FetchBelege from '../classes/items/FetchBelege';
import FetchServiceauftraege from '../classes/items/FetchServiceauftraege';
import FetchOffenePosten from '../classes/items/FetchOffenePosten';
import FetchAdresseDetails from '../classes/items/FetchAdresseDetails';
import ListViewDeals from '../containers/ListViewDeals';
import ListViewArtikel from '../containers/ListViewArtikel';
import ListViewAdressen from '../containers/ListViewAdressen';
import ListViewBelege from '../containers/ListViewBelege';
import ListViewServiceauftraege from '../containers/ListViewServiceauftraege';
import ListViewEinstellungen from '../containers/ListViewEinstellungen';
import ListViewOffenePosten from '../containers/ListViewOffenePosten';
import ListViewSuchergebnisse from '../containers/ListViewSuchergebnisse';
import detailViewAdresse from '../containers/detailViewAdresse';
import detailViewBeleg from '../containers/detailViewBeleg';
import detailViewServiceauftrag from '../containers/detailViewServiceauftrag';
import detailViewArtikel from '../containers/detailViewArtikel';
import detailViewDeal from '../containers/detailViewDeal';
import detailViewEinstellung from '../containers/detailViewEinstellung';
import detailViewOffenerPosten from '../containers/detailViewOffenerPosten';
import detailViewSuchergebnisse from '../containers/detailViewSuchergebnisse';
import Backdrop from '@material-ui/core/Backdrop';
import Camera from '../components/Camera';

class MainScreen extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this.timer = null;
		this._isMounted = false;
		this.bereichId = null;

		this.state = {
			drawerOpen: false,
			filterDrawerOpen: false,
			showSearch: false,
			hatDeals: false,
			hatServiceauftraege: false,
			ueberschrift: '',
			online: true,
			RESTserverOnline: true,
			listingPath: '',
			pageNumber: 1,
			listingMaxPage: 0,
			listingTotalResultsCount: null,
			displayIsSplit: null,
			adrIdForBelegOrAkt: null,
			aktEditierenOpen: false,
			isFetching: true,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		};

		this.config = {
			[$.adressenNode]: {
				ListViewContainer: ListViewAdressen,
				getDetailView: detailViewAdresse,
				FetchListClass: FetchAdressen,
				FetchDetailsClass: FetchAdresseDetails,
				itemsNode: $.adressenNode,
				itemsName: 'Adressen',
				basePath: 'stammdaten/adressen',
				showFilter: true,
				defaultFilters: {
					orderBy: {
						filterType: $.filterTypeField,
						value: 'adressnummer',
						order: $.orderAsc,
						type: $.typeSelect
					},
					istKunde: {
						filterType: $.filterTypeFilter,
						value: '',
						type: $.typeSelect
					},
					istLieferant: {
						filterType: $.filterTypeFilter,
						value: '',
						type: $.typeSelect
					},
					gesperrt: {
						filterType: $.filterTypeFilter,
						value: false,
						type: $.typeCheckbox
					}
				}
			},
			[$.artikelsNode]: {
				ListViewContainer: ListViewArtikel,
				getDetailView: detailViewArtikel,
				FetchListClass: FetchArtikels,
				FetchDetailsClass: FetchArtikelDetails,
				itemsNode: $.artikelsNode,
				itemsName: 'Artikel',
				basePath: 'stammdaten/artikel',
				showFilter: true,
				defaultFilters: {
					orderBy: {
						filterType: $.filterTypeField,
						value: 'artikelnummer',
						order: $.orderAsc,
						type: $.typeSelect
					},
					artikelart: {
						filterType: $.filterTypeFilter,
						value: [],
						type: $.typeCheckbox
					},
					gesperrt: {
						filterType: $.filterTypeFilter,
						value: false,
						type: $.typeCheckbox
					}
				}
			},
			[$.belegeNode]: {
				ListViewContainer: ListViewBelege,
				getDetailView: detailViewBeleg,
				FetchListClass: FetchBelege,
				FetchDetailsClass: FetchBelegDetails,
				itemsNode: $.belegeNode,
				itemsName: 'Kundenbelege',
				basePath: 'verkauf/belege',
				showFilter: true,
				defaultFilters: {
					orderBy: {
						filterType: $.filterTypeField,
						value: 'belegnummer',
						order: $.orderDesc,
						type: $.typeSelect
					},
					belegtyp: {
						filterType: $.filterTypeFilter,
						value: [],
						type: $.typeCheckbox
					},
					belegstatus: {
						filterType: $.filterTypeFilter,
						value: ''
					}
				}
			},
			[$.serviceNode]: {
				ListViewContainer: ListViewServiceauftraege,
				getDetailView: detailViewServiceauftrag,
				FetchListClass: FetchServiceauftraege,
				FetchDetailsClass: FetchServiceauftragDetails,
				itemsNode: $.serviceNode,
				itemsName: 'Serviceaufträge',
				basePath: 'service/auftraege',
				showFilter: true,
				defaultFilters: {
					orderBy: {
						filterType: $.filterTypeField,
						value: 'id',
						order: $.orderAsc,
						type: $.typeSelect
					}
				}
			},
			[$.dealsNode]: {
				ListViewContainer: ListViewDeals,
				getDetailView: detailViewDeal,
				FetchListClass: FetchDeals,
				FetchDetailsClass: FetchDealDetails,
				itemsNode: $.dealsNode,
				itemsName: 'Deals',
				basePath: 'crm/deals',
				showFilter: true,
				defaultFilters: {
					orderBy: {
						filterType: $.filterTypeField,
						value: 'investitionsdatumGeplant',
						order: $.orderAsc,
						type: $.typeSelect
					},
					zustaendigerBenutzer: {
						filterType: $.filterTypeFilter,
						value: getKuerzel()
					},
					phase: {
						filterType: $.filterTypeFilter,
						value: '',
						type: $.typeSelect
					},
					status: {
						filterType: $.filterTypeFilter,
						value: '',
						type: $.typeSelect
					}
				}
			},
			[$.offenepostenNode]: {
				ListViewContainer: ListViewOffenePosten,
				getDetailView: detailViewOffenerPosten,
				FetchListClass: FetchOffenePosten,
				FetchDetailsClass: FetchOffenerPostenDetails,
				itemsNode: $.offenepostenNode,
				itemsName: 'Offene Posten',
				basePath: 'finanzen/offeneposten',
				showFilter: true,
				defaultFilters: {
					orderBy: {
						filterType: $.filterTypeField,
						value: 'rechnungsdatum',
						order: $.orderAsc,
						type: $.typeSelect
					},
					opvArt: {
						filterType: $.filterTypeFilter,
						value: 'O',
						type: $.typeSelect
					},
					abgeschlossen: {
						filterType: $.filterTypeFilter,
						value: false,
						type: 'checkbox'
					},
					inKlaerung: {
						filterType: $.filterTypeFilter,
						value: false,
						type: 'checkbox'
					}
				}
			},
			[$.suchergebnisseNode]: {
				ListViewContainer: ListViewSuchergebnisse,
				getDetailView: detailViewSuchergebnisse,
				FetchListClass: FetchSearchresultsGlobal,
				FetchDetailsClass: FetchSuchergebnisDetails,
				itemsNode: $.suchergebnisseNode,
				itemsName: 'Suchergebnisse',
				showFilter: false
			},
			[$.einstellungenNode]: {
				ListViewContainer: ListViewEinstellungen,
				getDetailView: detailViewEinstellung,
				FetchListClass: FetchEinstellungen,
				FetchDetailsClass: FetchEinstellungDetails,
				itemsNode: $.einstellungenNode,
				itemsName: 'Einstellungen',
				showFilter: false
			}
		};

		//#region
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.toggleFilterDrawer = this.toggleFilterDrawer.bind(this);
		this.openSearch = this.openSearch.bind(this);
		this.closeSearch = this.closeSearch.bind(this);
		this.updateUeberschriftFromChild = this.updateUeberschriftFromChild.bind(this);
		this.online = this.online.bind(this);
		this.updateListingPath = this.updateListingPath.bind(this);
		this.updatePageNumber = this.updatePageNumber.bind(this);
		this.updateListingMaxPage = this.updateListingMaxPage.bind(this);
		this.updateListingTotalResultsCount = this.updateListingTotalResultsCount.bind(this);
		this.splitScreen = this.splitScreen.bind(this);
		this.mobileListe = this.mobileListe.bind(this);
		this.mobileDetailView = this.mobileDetailView.bind(this);
		this.updateSplitScreen = this.updateSplitScreen.bind(this);
		this.setAdrIdForBelegOrAkt = this.setAdrIdForBelegOrAkt.bind(this);
		this.getAdrIdForBelegOrAkt = this.getAdrIdForBelegOrAkt.bind(this);
		this.openAktEditierenModal = this.openAktEditierenModal.bind(this);
		this.closeAktEditierenModal = this.closeAktEditierenModal.bind(this);
		this.updateQueryString = this.updateQueryString.bind(this);
		this.setIsFetching = this.setIsFetching.bind(this);
		this.getIsFetching = this.getIsFetching.bind(this);
		//#endregion
	}

	//region Component Methods
	UNSAFE_componentWillMount() {
		initComponent(this.props, 'MainScreen.class');
		if (
			sessionStorage.getItem($.hatDealsName) || localStorage.getItem($.hatDealsName)
		) this.setState({ hatDeals: true });
		if (
			sessionStorage.getItem($.hatServiceauftraegeName) || localStorage.getItem($.hatServiceauftraegeName)
		) this.setState({ hatServiceauftraege: true });
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
		window.removeEventListener('online', this.online);
		window.removeEventListener('offline', this.online);
	}

	componentDidMount() {
		this._isMounted = true;
		// Offline/Online
		window.addEventListener('online', this.online);
		window.addEventListener('offline', this.online);
		const self = this;
		document.onkeydown = evt => {
			evt = evt || window.event;
			if (evt.keyCode === 27) {
				// Escape-Taste gedrückt
				if (self.state.showSearch) self.closeSearch();
			}
		};
		this.online();
	}

	componentDidUpdate(prevProps) {
		// Online-Check
		if (this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(() => this.online(), 10000);

		// Suche anzeigen/verstecken
		const locationChanged = this.props.location !== prevProps.location;
		if (locationChanged) this.setState({ showSearch: false });
	}

	splitScreen = () => window.innerWidth >= 960;
	mobileListe = () => !this.splitScreen() && !this.bereichId;
	mobileDetailView = () => !this.splitScreen() && !!this.bereichId;

	updateSplitScreen = () => {
		if (this._isMounted) this.setState({ displayIsSplit: this.splitScreen() });
	};

	online() {
		checkOnline(this.controller.signal).then(response => {
			if (this._isMounted && this.state.online !== response.online) this.setState({
				online: response.online,
				RESTserverOnline: response.RESTserverOnline
			});
		});
	}

	updateUeberschriftFromChild(ueberschrift) {
		this.setState({ ueberschrift });
	};

	updateListingPath(queryString) {
		this.setState({ listingPath: queryString });
	};

	updatePageNumber(page) {
		this.setState({ pageNumber: page });
	}

	updateListingMaxPage(page) {
		this.setState({ listingMaxPage: page });
	}

	updateListingTotalResultsCount(totalResultsCount) {
		this.setState({ listingTotalResultsCount: totalResultsCount });
	}

	toggleDrawer = () => open => {
		this.setState({ drawerOpen: !open });
	};

	toggleFilterDrawer = (open) => {
		this.setState({ filterDrawerOpen: open });
	};

	openSearch() {
		this.setState({ showSearch: true });
	}

	closeSearch() {
		this.setState({ showSearch: false });
	}

	setAdrIdForBelegOrAkt(adrIdForBelegOrAkt) {
		this.setState({ adrIdForBelegOrAkt });
	}

	getAdrIdForBelegOrAkt() {
		return this.state.adrIdForBelegOrAkt;
	}

	openAktEditierenModal() {
		this.setState({ aktEditierenOpen: true });
	}

	closeAktEditierenModal() {
		this.setState({ aktEditierenOpen: false });
	}

	setIsFetching(isFetching) {
		this.setState({ isFetching });
	}

	getIsFetching() {
		return this.state.isFetching;
	}

	updateQueryString(config, filter, filterOrder, fields, fieldsOrder) {
		const queryStringArr = [], filterArr = [];
		let str, val, debug = false;
		filter.forEach((obj, key) => {
			val = obj.value;
			/** TODO Filter QueryString
			 * Grundsätzlich soll bei gesperrt === true (Kästchen angehakt <=> gesperrte einblenden)
			 * das 'gesperrt=...' komplett aus dem querystring verschwinden, denn dann werden alle angezeigt,
			 * nicht gesperrte und gesperrte.
			 */
			if (key === 'gesperrt' && val === true) return;  // analog zu opvArt=alle
			if (key === 'opvArt' && val === 'alle') return;
			if (!Array.isArray(val) && val !== '') { /* Either string or number */
				str = (filterOrder.get(key) && filterOrder.get(key).value === $.orderDesc) ?
					`${key}?-${val}` :  /* Minus, falls Reihenfolge absteigend*/
					(obj.type === 'text' && val.length >= 3) ?
						`${key}?"${val.trim()}"` : /* Text in Quotes wrappen */
						`${key}=${val}`; /* : Ansonsten direkt einsetzen */
			} else if (Array.isArray(val)) {
				str = val
					.map(_val => (typeof _val === 'string') ? `${key}=${_val}` : null) /* Only string values */
					.filter(val => !!val) /* Filter all null or empty values */
					.join(',');
			}

			if (str) filterArr.push(str);
		});

		// Set, um doppelte Einträge zu verhindern
		if (filterArr.length) queryStringArr.push(`filter=${[...new Set(filterArr)].join(',')}`);

		// fields
		fields.forEach((obj, key) => {
			val = obj.value;
			if (val) {
				str = `${key}=${val}`;

				if (obj.type === 'text' && val.length >= 3 && key === this.q)
					str = `q="${val.trim()}"`;  /* "Text" */
				else if (fieldsOrder.get(key) && fieldsOrder.get(key).value === $.orderDesc)
					str = `${key}=-${val}`;  /* Minus for DESC */

				queryStringArr.push(str);
			}
		});

		str = `${config.basePath}?${queryStringArr.join('&')}`;
		if (debug) console.log(str);
		this.updateListingPath(str);
	};

	//endregion

	render() {
		return (
			<>
				<NavigationDrawer
					db={this.props.db}
					open={this.state.drawerOpen}
					toggleDrawer={this.toggleDrawer}
					screenProps={this.props}
					hatDeals={this.state.hatDeals}
					hatServiceauftraege={this.state.hatServiceauftraege}
				/>

				{this.config[this.props.match.params.bereich] && this.config[this.props.match.params.bereich].showFilter &&
				<FilterDrawer
					open={this.state.filterDrawerOpen}
					bereich={this.props.match.params.bereich}
					config={this.config[this.props.match.params.bereich] || null}
					pageNumber={this.state.pageNumber}
					listingTotalResultsCount={this.state.listingTotalResultsCount}
					updateQueryString={this.updateQueryString}
					updatePageNumber={this.updatePageNumber}
					updateListingMaxPage={this.updateListingMaxPage}
					toggleFilterDrawer={this.toggleFilterDrawer}
					screenProps={this.props}
				/>
				}

				<VarioAppBar
					openAktEditierenModal={this.openAktEditierenModal}
					getAdrIdForBelegOrAkt={this.getAdrIdForBelegOrAkt}
					bereich={this.state.ueberschrift}
					config={this.config[this.props.match.params.bereich] || null}
					db={this.props.db}
					online={this.state.online}
					RESTserverOnline={this.state.RESTserverOnline}
					toggleDrawer={this.toggleDrawer}
					toggleFilterDrawer={this.toggleFilterDrawer}
					showSearch={this.state.showSearch}
					openSearch={this.openSearch}
					closeSearch={this.closeSearch}
					splitScreen={this.splitScreen}
					mobileListe={this.mobileListe}
					mobileDetailView={this.mobileDetailView}
					showBelegneuAuswahl={this.showBelegneuAuswahl}
					updateListingPath={this.updateListingPath}
				/>

				<div className="wrapper">

					<Switch>
						{/* DASHBOARD */}
						<Route path="/app/dashboard" exact render={props => <Dashboard
							updateUeberschriftFromChild={this.updateUeberschriftFromChild}
							setIsFetching={this.setIsFetching}
							getIsFetching={this.getIsFetching}
							{...props}
							{...this.props} />}
						/>

						{/* AUSWERTUNGEN */}
						<Route path={`/app/${$.auswertungenNode}`} exact render={props => <Auswertungen
							updateUeberschriftFromChild={this.updateUeberschriftFromChild}
							setIsFetching={this.setIsFetching}
							getIsFetching={this.getIsFetching}
							{...props}
							{...this.props} />}
						/>

						{/* Komischerweise kann man die Routen nicht als Array.map() reinziehen... */}
						{/* ADRESSEN */}
						<Route path={`/app/${$.adressenNode}/:id/${$.ansprechpartnersNode}/:ansId`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									aktEditierenOpen={this.state.aktEditierenOpen}
									closeAktEditierenModal={this.closeAktEditierenModal}
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.adressenNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						<Route path={`/app/${$.adressenNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									aktEditierenOpen={this.state.aktEditierenOpen}
									closeAktEditierenModal={this.closeAktEditierenModal}
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.adressenNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						<Route path={`/app/${$.adressenNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									config={this.config[$.adressenNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						{/* ARTIKEL */}
						<Route path={`/app/${$.artikelsNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.artikelsNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						<Route path={`/app/${$.artikelsNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									config={this.config[$.artikelsNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						{/* BELEGE */}
						<Route path={`/app/${$.belegeNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.belegeNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>
						<Route path={`/app/${$.belegeNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									config={this.config[$.belegeNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props}
								/>
							);
						}}
						/>

						{/* DEALS */}
						<Route path={`/app/${$.dealsNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									closeAktEditierenModal={this.closeAktEditierenModal}
									aktEditierenOpen={this.state.aktEditierenOpen}
									getAdrIdForBelegOrAkt={this.getAdrIdForBelegOrAkt}
									setAdrIdForBelegOrAkt={this.setAdrIdForBelegOrAkt}
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.dealsNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props}
								/>
							);
						}}
						/>
						<Route path={`/app/${$.dealsNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									config={this.config[$.dealsNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props}
								/>
							);
						}}
						/>

						{/* SCANNER */}
						<Route path={`/app/${$.scannerNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<Camera onCode={console.log} onInit={console.log}/>
							);
						}}
						/>

						{/* SERVICEAUFTRÄGE */}
						<Route path={`/app/${$.serviceNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.serviceNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props}
								/>
							);
						}}
						/>
						<Route path={`/app/${$.serviceNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									config={this.config[$.serviceNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props}
								/>
							);
						}}
						/>

						{/* EINSTELLUNGEN */}
						<Route path={`/app/${$.einstellungenNode}/:id`} exact render={props =>
							<ListView
								updateUeberschriftFromChild={this.updateUeberschriftFromChild}
								id={parseInt(props.match.params.id)}
								config={this.config[$.einstellungenNode]}
								listingPath={this.state.listingPath}
								pageNumber={this.state.pageNumber}
								listingMaxPage={this.state.listingMaxPage}
								listingTotalResultsCount={this.state.listingTotalResultsCount}
								updatePageNumber={this.updatePageNumber}
								updateListingTotalResultsCount={this.updateListingTotalResultsCount}
								setIsFetching={this.setIsFetching}
								getIsFetching={this.getIsFetching}
								{...props}
								{...this.props}
							/>}
						/>
						<Route path={`/app/${$.einstellungenNode}`} exact render={props => <ListView
							updateUeberschriftFromChild={this.updateUeberschriftFromChild}
							config={this.config[$.einstellungenNode]}
							listingPath={this.state.listingPath}
							pageNumber={this.state.pageNumber}
							listingMaxPage={this.state.listingMaxPage}
							listingTotalResultsCount={this.state.listingTotalResultsCount}
							updatePageNumber={this.updatePageNumber}
							updateListingTotalResultsCount={this.updateListingTotalResultsCount}
							{...props}
							{...this.props} />}
						/>

						{/* NEUER BELEG */}
						<Route path={`/app/belegneu/:id/:belegartcode`} render={props => <BelegNeu
							updateUeberschriftFromChild={this.updateUeberschriftFromChild}
							belegartCode={props.match.params.belegartcode}
							id={parseInt(props.match.params.id)}
							listingPath={this.state.listingPath}
							pageNumber={this.state.pageNumber}
							listingMaxPage={this.state.listingMaxPage}
							listingTotalResultsCount={this.state.listingTotalResultsCount}
							updatePageNumber={this.updatePageNumber}
							updateListingTotalResultsCount={this.updateListingTotalResultsCount}
							splitScreen={this.splitScreen}
							mobileListe={this.mobileListe}
							mobileDetailView={this.mobileDetailView}
							updateSplitScreen={this.updateSplitScreen}
							setIsFetching={this.setIsFetching}
							getIsFetching={this.getIsFetching}
							{...props}
							{...this.props} />}
						/>

						{/* OFFENE POSTEN */}
						<Route path={`/app/${$.offenepostenNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									config={this.config[$.offenepostenNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>
						<Route path={`/app/${$.offenepostenNode}`} exact render={props => {
							this.bereichId = null;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									config={this.config[$.offenepostenNode]}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						{/* SUCHERGEBNISSE */}
						<Route path={`/app/${$.suchergebnisseNode}/${$.adressenNode}/:adrId/${$.ansprechpartnersNode}/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									adrId={props.match.params.adrId}
									config={this.config[$.suchergebnisseNode]}
									suchEingabe={this.state.suchEingabe}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						<Route path={`/app/${$.suchergebnisseNode}/:typ/:id`} exact render={props => {
							this.bereichId = props.match.params.id;
							return (
								<ListView
									updateUeberschriftFromChild={this.updateUeberschriftFromChild}
									id={parseInt(props.match.params.id)}
									typ={props.match.params.typ}
									config={this.config[$.suchergebnisseNode]}
									suchEingabe={this.state.suchEingabe}
									listingPath={this.state.listingPath}
									pageNumber={this.state.pageNumber}
									listingMaxPage={this.state.listingMaxPage}
									listingTotalResultsCount={this.state.listingTotalResultsCount}
									updatePageNumber={this.updatePageNumber}
									updateListingTotalResultsCount={this.updateListingTotalResultsCount}
									splitScreen={this.splitScreen}
									mobileListe={this.mobileListe}
									mobileDetailView={this.mobileDetailView}
									updateSplitScreen={this.updateSplitScreen}
									setIsFetching={this.setIsFetching}
									getIsFetching={this.getIsFetching}
									{...props}
									{...this.props} />
							);
						}}
						/>

						<Route path={`/app/${$.suchergebnisseNode}`} exact render={props => <ListView
							updateUeberschriftFromChild={this.updateUeberschriftFromChild}
							config={this.config[$.suchergebnisseNode]}
							suchEingabe={this.state.suchEingabe}
							listingPath={this.state.listingPath}
							pageNumber={this.state.pageNumber}
							listingMaxPage={this.state.listingMaxPage}
							listingTotalResultsCount={this.state.listingTotalResultsCount}
							updatePageNumber={this.updatePageNumber}
							updateListingTotalResultsCount={this.updateListingTotalResultsCount}
							splitScreen={this.splitScreen}
							mobileListe={this.mobileListe}
							mobileDetailView={this.mobileDetailView}
							updateSplitScreen={this.updateSplitScreen}
							setIsFetching={this.setIsFetching}
							getIsFetching={this.getIsFetching}
							{...props}
							{...this.props} />}
						/>

						<Route component={Notfound}/>

					</Switch>
				</div>

				<Modal
					open={this.state.emOpen}
					title={this.state.emTitle}
					text={this.state.emText}
					handleOk={() => this.setState({ emOpen: false })}
					cancelBtn={false}
					destroy={() => this.setState({ emOpen: false })}
				/>

				<Backdrop
					style={{ zIndex: 1100 }}
					open={this.state.isFetching}
				/>
			</>
		);
	};

};

MainScreen.propTypes = {
	db: PropTypes.object.isRequired
};

export default withRouter(MainScreen);
