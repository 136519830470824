import React from 'react';
import FilteredServiceList from '../redux-service/containers/filteredServiceList';

//@rs: Unterteilung in aktive und angenommene Aufträge?
const ListViewServiceauftraege = props => {
	return (
		<FilteredServiceList {...props}/>
	);
}

export default ListViewServiceauftraege;
