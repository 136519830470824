import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FetchAdresseEditieren from '../classes/items/FetchAdresseEditieren';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 218
	},
	formControlBeschreibung: {
		margin: theme.spacing(1),
		minWidth: '95%'
	}
});

class AdresseEditierenModal extends React.Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;

		this.state = {};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateState = this.updateState.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.updateState();
	}

	updateState() {
		const { name1, strasse, plz, ort, mobil, telefon1, telefon2, email, webseite, id } = { ...this.props.adresse };
		this.setState({
			name1,
			strasse,
			plz,
			ort,
			mobil,
			telefon1,
			telefon2,
			email,
			webseite,
			id
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};


	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			this.updateState();
		}
	}

	handleSubmit() {
		this.props.closeAdrEditieren();

		const sendData = {
			data: { ...this.state },
			protokollBemerkung: 'Adresse geändert.'
		};

		new FetchAdresseEditieren(this.props.screenprops, this.controller.signal, sendData, this.props.adresse.id).fetch().then(responseOk => {
			if (responseOk) this.props.setTabValueInUrl(0);
			else console.error(786377439);
		});

		this.setState({});
	}

	render() {
		const { classes } = this.props;
		const textFields = [
			{
				label: 'Name 1',
				value: this.state.name1,
				text: true,
				change: 'name1'
			},
			{
				label: 'Straße',
				value: this.state.strasse,
				text: true,
				change: 'strasse'
			},
			{
				label: 'Postleitzahl',
				value: this.state.plz,
				text: false,
				change: 'plz'
			},
			{
				label: 'Ort',
				value: this.state.ort,
				text: true,
				change: 'ort'
			},
			{
				label: 'Mobil',
				value: this.state.mobil,
				change: 'mobil',
				text: true
			},
			{
				label: 'Telefon',
				value: this.state.telefon1,
				change: 'telefon1',
				text: true
			},
			{
				label: 'Telefon',
				value: this.state.telefon2,
				change: 'telefon2',
				text: true
			},
			{
				label: 'E-Mail',
				value: this.state.email,
				change: 'email',
				text: true
			}, {
				label: 'Webseite',
				value: this.state.webseite,
				change: 'webseite',
				text: true
			}
		];

		return (
			<div>
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					open={this.props.open}
				>
					<DialogTitle>Adresse ändern</DialogTitle>
					<DialogContent>
						<form className={classes.container}>
							{
								textFields.map((item, index) =>
									<FormControl className={classes.formControl} key={index}>
										<TextField
											label={item.label}
											value={item.value}
											type={item.text ? 'text' : 'number'}
											onChange={this.handleChange(item.change)}
										/>
									</FormControl>
								)
							}
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.closeAdrEditieren} color="primary">
							Abbrechen
						</Button>
						<div style={{ width: '10px' }}></div>
						<Button onClick={this.handleSubmit} color="primary">
							Änderungen speichern
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

AdresseEditierenModal.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AdresseEditierenModal);
