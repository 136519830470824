import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const SplitPositionModal = (props) => {
	const gesamt = props.menge;
	const [neu, setNeu] = useState(1);
	return (
		<div>
			<Dialog open={props.open}>
				<DialogTitle>Position aufteilen</DialogTitle>
				<DialogContent>
					<form>
						<FormControl>
							<TextField
								label="Gesamtmenge"
								value={gesamt}
								type="number"
								disabled
							/>
						</FormControl>
						<FormControl>
							<TextField
								label="Übrig"
								value={gesamt - neu}
								type="number"
								disabled
							/>
						</FormControl>
						<FormControl>
							<TextField
								label="Neu"
								value={neu}
								onChange={evt => setNeu(evt.target.value < gesamt ? evt.target.value : gesamt - 1)}
							/>
						</FormControl>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => props.cancel()} color="primary">
						Abbrechen
					</Button>
					<Button onClick={() => neu > 0 && neu < gesamt && props.submit(neu)} color="primary">
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		</div>

	);
};

export default SplitPositionModal;
