import Items from '.';

class FetchArtikelBilder extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[FetchArtikelBilder.js] id nicht vorhanden`);
		this.path = {
			string: `stammdaten/artikel/${id}/bilder`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Artikel Bilder', this.path).then(json => {

			if (!json.path || !json.results || json.results.totalResultCount === 0) {
				resolve([]);
				return;
			}

			const bilder = json.results.artikelBilder;

			resolve(bilder);
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
	});
}

export default FetchArtikelBilder;
