import React, { Component } from 'react';
import $ from '../config';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { getCurrentFilename, getBenutzerId, objWithMaps2objWithObjects, isValidJSON, getBelegtyptextFromCode } from '../functions';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear'
import Grid from "@material-ui/core/Grid";
import FetchBenutzerParameter from '../classes/items/FetchBenutzerParameter';
import FetchBenutzerParameterSpeichern from '../classes/items/FetchBenutzerParameterSpeichern';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Divider } from '@material-ui/core';

const styles = theme => ({
	wrapper: {
		width: 300,
		padding: theme.spacing(1)
	},
	filterButton: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		borderRadius: 0
	}
});

class FilterDrawer extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.defaultLimit = 20;
		this.defaultOffset = 0;
		this.path = {
			string: `system/parameter/benutzer_${getBenutzerId()}_${this.props.bereich}Filter`,
			id: null
		};

		this.eingabeTimer = null;
		this.basePath = null;
		this.parameter = `${props.bereich}filter`;
		this.orderBy = 'orderBy';
		this.q = 'q';
		this.adresse = 'adresse';
		this.phase = 'phase';
		this.status = 'status';
		this.belegtyp = 'belegtyp';
		this.istKunde = 'istKunde';
		this.istLieferant = 'istLieferant';
		this.istKundeLieferant = 'istKundeLieferant';
		this.OP_art = 'opvArt';
		this.OP_status = 'OP_status';
		this.OP_O = 'O';
		this.OP_V = 'V';
		this.alle = 'alle';
		this.OP_offen = 'OP_offen';
		this.OP_inKlaerung = 'OP_inKlaerung';
		this.OP_offenUndInKlaerung = 'OP_offenUndInKlaerung';
		this.OP_abgeschlossen = 'OP_abgeschlossen';
		this.OPfilter_abgeschlossen = 'abgeschlossen';
		this.OPfilter_inKlaerung = 'inKlaerung';

		this.OP_alleOffeneEtc = 'OP_alleOffeneEtc';
		this.gesperrt = 'gesperrt';
		this.artikelart = 'artikelart';
		this.belegart = 'belegtyp';

		this.state = {
			query: {
				filter: new Map(),
				filterOrder: new Map(),
				fields: new Map(),
				fieldsOrder: new Map()
			},
			belegtypen: [],
			belegFilter: [
				true /* Angebot */,
				true /* Auftrag */,
				true /* Lieferschein */,
				true /* Rechnung */,
				true /* Lieferschein/Rechnung */,
				true /* Kassenquittung */,
				true /* Rücksendeschein (RMA) */,
				true /* Warenrücknahme */,
				true /* Kommissionierschein*/,
				true /* Gutschrift mit Warenrücknahme */,
				true /* Gutschrift ohne Warenrücknahme */,
				true /* Provisionsabrechnung */
			]
		};

		//#region
		this.setInitialFilter = this.setInitialFilter.bind(this);
		this.handleBelegFilterChange = this.handleBelegFilterChange.bind(this);
		this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
		this.handleResetValue = this.handleResetValue.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleTotalResultCountChange = this.handleTotalResultCountChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleFilterOrderChange = this.handleFilterOrderChange.bind(this);
		this.handleFieldOrderChange = this.handleFieldOrderChange.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.addFilterOrder = this.addFilterOrder.bind(this);
		this.addFieldOrder = this.addFieldOrder.bind(this);
		this.fetchBenutzerFilter = this.fetchBenutzerFilter.bind(this);
		this.fetchBenutzerFilterSpeichern = this.fetchBenutzerFilterSpeichern.bind(this);
		this.setFilterState = this.setFilterState.bind(this);
		this.fixErrorsInObj = this.fixErrorsInObj.bind(this);
		this.getBelegtypen = this.getBelegtypen.bind(this);
		this.handleMultipleFilterChange = this.handleMultipleFilterChange.bind(this);
		this.removeFilter = this.removeFilter.bind(this);
		this.addFilterInner = this.addFilterInner.bind(this);
		//#endregion
	}

	componentDidMount() {
		this._isMounted = true;
		this.getBelegtypen();
		this.handlePageChange(this.props.pageNumber);
		this.fetchBenutzerFilter();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.listingTotalResultsCount !== this.props.listingTotalResultsCount)
			this.handleTotalResultCountChange(this.props.listingTotalResultsCount);
		if (prevProps.pageNumber !== this.props.pageNumber) {
			this.handlePageChange(this.props.pageNumber);
			this.props.updateQueryString(this.props.config, this.state.query.filter, this.state.query.filterOrder, this.state.query.fields, this.state.query.fieldsOrder);
		}

		// console.log(this.state.query.filter);
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	async fetchBenutzerFilter() {
		const debug = false;
		try {
			const stateStr = await new FetchBenutzerParameter(this.props.screenProps, this.controller.signal, this.path).fetch();
			if (debug) console.log(stateStr);
			if (stateStr && isValidJSON(stateStr)) {

				if (debug) console.log(`setFilterState(stateStr =)`, JSON.parse(stateStr));
				this.setFilterState(stateStr);
			}
			else if (!isValidJSON(stateStr)) {
				console.warn('!isValidJSON(stateStr = ). stateStr=', stateStr);
				this.setInitialFilter();
			}
			else {
				console.warn('KEIN stateStr, setInitialFilter() aufgerufen. stateStr=', stateStr);
				this.setInitialFilter();
			}

		} catch (err) {
			console.error(`[${getCurrentFilename(__filename)}: fetchBenutzerFilter()] fail! Fehler=`, err);
			this.setInitialFilter();
		}
	}

	async fetchBenutzerFilterSpeichern() {
		const debug = false;

		// Maps des state in Objekte konvertieren, damit stringify funktioniert
		let objWithObjects = objWithMaps2objWithObjects(this.state.query);
		//console.log(3, objWithObjects)
		objWithObjects = this.fixErrorsInObj(objWithObjects);

		if (debug) console.log('objWithObjects', objWithObjects);

		const ret = await new FetchBenutzerParameterSpeichern(
			this.props.screenProps,
			this.controller.signal,
			this.path,
			JSON.stringify(objWithObjects)
		).fetch();
		return ret;
	}

	setFilterState(stateStr) {

		const debug = false;

		const objWithObjects = this.fixErrorsInObj(JSON.parse(stateStr));

		// Unterobjekte in Maps konverieren
		const obj2Map = _obj => {
			const map = new Map();
			for (const _key of Object.keys(_obj)) map.set(_key, _obj[_key]);
			return map;
		};

		const objWithMaps = {};
		for (const key in objWithObjects) {
			const innerObj = objWithObjects[key];
			objWithMaps[key] = obj2Map(innerObj);
		}

		if (debug) {
			console.log('objWithObjects', objWithObjects);
			console.log('objWithMaps', objWithMaps);
		}

		this.setState({ query: objWithMaps });
		this.props.updateQueryString(
			this.props.config,
			this.state.query.filter,
			this.state.query.filterOrder,
			this.state.query.fields,
			this.state.query.fieldsOrder
		);
	}

	fixErrorsInObj(obj) {
		/**
		 * Um Fehler durch Updates der App in Kombination mit gespeicherten Daten
		 * vom Server zu vermeiden
		 */
		const debug = false;
		if (debug) console.log('obj', obj);

		const newObj = {};

		for (const key in obj) {
			const value = obj[key];

			// in fields nur orderBy übernehmen
			if (key === 'fields') {
				// fields
				const fieldsObj = value;
				if (fieldsObj[this.orderBy]) {
					const newFieldsObj = { [this.orderBy]: fieldsObj[this.orderBy] };
					newObj.fields = newFieldsObj;
				}
			}

			// in filter nur die Bekannten aus defaultFilters übernehmen
			else if (key === 'filter') {
				// filter
				const filterObj = value;
				const availableFilters = Object.getOwnPropertyNames(this.props.config.defaultFilters);
				const newFilterObj = {};
				for (const filterKey in filterObj) {
					let val = filterObj[filterKey];
					if (filterKey === 'titel') console.warn('filterKey === titel'); // war hier mal im if
					if (availableFilters.includes(filterKey)) newFilterObj[filterKey] = val;
				}
				newObj.filter = newFilterObj;
			}

			// die gehören nicht rein, zumindest nicht im root-level
			else if (key === 'limit' || key === 'offset' || key === this.orderBy) { }

			// sonstige keys
			else newObj[key] = value;
		}

		if (debug) console.log('newObj', newObj);

		return newObj;
	}

	async setInitialFilter() {
		const { defaultFilters } = this.props.config;
		//console.log(defaultFilters)
		for (const key in defaultFilters) {
			const obj = defaultFilters[key];

			if (obj.filterType === $.filterTypeFilter) {
				this.addFilter(key, obj.value, obj.type);
				this.addFilterOrder(key, obj.order);
			} else if (obj.filterType === $.filterTypeField) {
				this.addField(key, obj.value, obj.type);
				this.addFieldOrder(key, obj.order);
			} else console.warn(`[${getCurrentFilename(__filename)}: setInitialFilter()] Ungültiger filterType in ${key}. filterType = `, obj.filterType);
		};

		await this.fetchBenutzerFilterSpeichern();
		this.props.updateQueryString(this.props.config, this.state.query.filter, this.state.query.filterOrder, this.state.query.fields, this.state.query.fieldsOrder);
	}

	async getBelegtypen() {
		const belegtypTextPromises = [];
		const belegtypen = $.angezeigteBelegtypen;
		for (let i = 0; i < belegtypen.length; i++) {
			const belegtyp = belegtypen[i];
			belegtypTextPromises.push(getBelegtyptextFromCode(this.props.screenProps.db, belegtyp));
		}
		const belegTypenText = await Promise.all(belegtypTextPromises);
		const belegtypenObjArr = belegTypenText.map((belegtypText, index) => ({
			belegtypCode: belegtypen[index],
			belegtypText
		}));
		this.setState({ belegtypen: belegtypenObjArr });
	}

	handleBelegFilterChange = index => {
		const { belegFilter } = this.state;
		belegFilter.splice(index, 1, !belegFilter[index]);
		this.setState({ belegFilter });
	};

	handleFilterSubmit = e => {
		e.preventDefault();
		if (e) this.props.updatePageNumber(1);
		this.props.toggleFilterDrawer(false);

		this.props.updateQueryString(this.props.config, this.state.query.filter, this.state.query.filterOrder, this.state.query.fields, this.state.query.fieldsOrder);
		this.fetchBenutzerFilterSpeichern();
	}

	handleResetValue(key) {

		// OP-Filter übersetzen
		if (key === this.OP_status) {
			this.addFilter(this.OPfilter_abgeschlossen, false, $.typeSelect);
			this.removeFilter(this.OPfilter_inKlaerung);
			return;
		}

		if (!this.props.config.defaultFilters || !this.props.config.defaultFilters[key]) return;
		const element = this.props.config.defaultFilters[key];
		if (element.filterType === $.filterTypeFilter) this.addFilter(key, element.value, element.type);
		else if (element.filterType === $.filterTypeField) this.addField(key, element.value, element.type);
		else console.warn(`[${getCurrentFilename(__filename)}: handleResetValue()] Ungültiger filterType in ${key}. filterType = `, element.filterType);
	}

	handlePageChange(page) {
		const offset = 20 * (page - 1);
		this.addField('offset', offset);
		this.addField('limit', this.defaultLimit);
	}

	handleTotalResultCountChange(totalResultsCount) {
		let listingMaxPage = Math.ceil(totalResultsCount / 20);
		if (listingMaxPage === 0) listingMaxPage = 1;
		this.props.updateListingMaxPage(listingMaxPage);
	}

	handleFieldChange = name => e => {
		//console.log(`handleFieldChange(name = ${name}, e.target.type = ${e.target.type}, e.target.value = ${e.target.value})`);
		const type = e.target.type ? e.target.type : null;
		let { value } = e.target;
		if (type === 'checkbox') value = e.target.checked;
		this.addField(name, value, type);
	};

	handleFieldOrderChange = name => e => {
		//console.log('handleFieldOrderChange()', name);
		const { value } = e.target;
		this.addFieldOrder(name, value);
	};

	handleMultipleFilterChange = name => e => {
		let { value } = e.target;

		//console.log(name, value);

		if (name === this.istKundeLieferant && value) {

			// den jeweils anderen löschen
			if (value === this.istKunde) this.removeFilter(this.istLieferant);
			if (value === this.istLieferant) this.removeFilter(this.istKunde);
			else {
				// alle
				this.removeFilter(this.istKunde);
				this.removeFilter(this.istLieferant);
			}

			// "normalen" Filterchange weitergeben; value ist "istKunde" oder "istLieferant"
			e.target.value = 'true';
			this.handleFilterChange(value)(e);
		}
		else if (name === this.OP_art) {

			// den jeweils anderen löschen
			if (value === this.OP_O) this.removeFilter(this.OP_V);
			else if (value === this.OP_V) this.removeFilter(this.OP_O);
			else if (!value) {
				this.removeFilter(this.OP_V);
				this.removeFilter(this.OP_O);
			}
			else console.warn(`[${getCurrentFilename(__filename)}: handleMultipleFilterChange()] => 1`);

			// "normalen" Filterchange weitergeben; value ist "istKunde" oder "istLieferant"
			e.target.value = 'true';
			this.handleFilterChange(value)(e);
		}
		else console.warn(`[${getCurrentFilename(__filename)}: handleMultipleFilterChange()] => 2`);
	};

	handleFilterChange = name => e => {
		const type = e.target.type ? e.target.type : null;
		let { value } = e.target;
		const { checked } = e.target;

		if (type === 'text') {
			if (this.eingabeTimer) clearTimeout(this.eingabeTimer);
			this.eingabeTimer = setTimeout(() => {
				this.addFilter(name, value, type);
			}, 300);
		}

		else if (type === $.typeCheckbox) {
			// gesperrt=true/false
			if (name === this.gesperrt) value = checked;
			// sonstige, z.B. artikelart=2
			this.addFilter(name, value, type, checked);
		}

		else this.addFilter(name, value, type);
	};

	handleFilterOrderChange = name => e => {
		//console.log('handleFilterOrderChange()', name);
		const { value } = e.target;
		this.addFilterOrder(name, value);
	}

	addField = (key, value, type) => {
		// console.log(`addField(key = ${key}, value = ${value}, type = ${type})`);
		const { query } = this.state;
		query.fields = query.fields || new Map();
		query.fields.set(key, {
			value,
			type
		});
		this.setState({ query });
	};

	addFieldOrder = (key, value) => {
		const { query } = this.state;
		query.fieldsOrder.set(key, { value: value || 'ASC' });
		this.setState({ query });
	};

	addFilter = (key, value, type, checked) => {
		const { query } = this.state;
		let val;

		if (key === this.alle) return;

		// OP-Status-Filter übersetzen
		if (key === this.OP_status) {
			if (value === this.OP_offenUndInKlaerung) {
				this.addFilterInner(this.OPfilter_abgeschlossen, false, $.typeSelect);
				this.addFilterInner(this.OPfilter_inKlaerung, true, $.typeSelect);
			}
			else if (value === this.OP_abgeschlossen) {
				this.addFilterInner(this.OPfilter_abgeschlossen, true, $.typeSelect);
				this.removeFilter(this.OPfilter_inKlaerung);
			}
			else if (value === this.OP_inKlaerung) {
				this.addFilterInner(this.OPfilter_inKlaerung, true, $.typeSelect);
				this.removeFilter(this.OPfilter_abgeschlossen);
			}
			else if (value === this.OP_offen) {
				this.addFilterInner(this.OPfilter_abgeschlossen, false, $.typeSelect);
				this.removeFilter(this.OPfilter_inKlaerung);
			}
			else console.warn(`[${getCurrentFilename(__filename)}: addFilter()] Komischer value`);
			return;
		}

		/* Array -> Checkbox Group */
		if (Array.isArray(this.props.config.defaultFilters[key].value)) {
			const existingFilter = query.filter.has(key) ? query.filter.get(key).value : null;
			const filterArray = Array.isArray(existingFilter) ? existingFilter : [];
			if (!filterArray.includes(value) && checked) filterArray.push(value); /* Add new values if checked */
			else if (filterArray.includes(value) && !checked) {
				filterArray.splice(filterArray.indexOf(value), 1); /* Remove existing values if unchecked */
			}
			val = filterArray;
		}
		else val = value;

		this.addFilterInner(key, val, type);
	};

	addFilterInner(key, value, type) {
		const { query } = this.state;
		query.filter.set(key, {
			value,
			type
		});
		this.setState({ query });
	}

	addFilterOrder = (key, value) => {
		const { query } = this.state;
		query.filterOrder.set(key, { value: value || 'ASC' });
		this.setState({ query });
	};

	removeFilter = key => {
		const { query } = this.state;
		query.filter.delete(key);
		this.setState({ query });
	};

	render() {
		const { classes } = this.props;
		const hasChanged = to => (belegfilter, belegtypen) => belegfilter
			.map((v, i) => v !== to ? belegtypen[i].belegtypCode : null)
			.filter(v => v)
			.map(value => ({
				target: {
					type: $.typeCheckbox,
					value,
					checked: to
				}
			}));
		const filterListJSX = () => {
			let jsx;

			if (!this.state.query.fields || !this.state.query.filter) return null;

			//#region filter/value
			// orderBy = ...
			const fieldsOrderBy = this.state.query.fields.get(this.orderBy);
			const sortField = fieldsOrderBy ? fieldsOrderBy.value : '';

			const fieldsOrderOrderBy = this.state.query.fieldsOrder.get(this.orderBy);
			const sortDir = fieldsOrderOrderBy ? fieldsOrderOrderBy.value : '';

			// filter = ...
			const { filter } = this.state.query;

			const filterStatus = filter.get(this.status);
			const statusValue = filterStatus ? filterStatus.value : '';

			const filterPhase = filter.get(this.phase);
			const phaseValue = filterPhase ? filterPhase.value : '';

			const filterBelegtyp = filter.get(this.belegtyp);
			const belegtypValue = filterBelegtyp ? filterBelegtyp.value : '';

			const istKundeLieferantValue = () => {
				const filterIstKunde = filter.get(this.istKunde);
				const filterIstLieferant = filter.get(this.istLieferant);

				if (filterIstKunde && filterIstKunde.value) return this.istKunde;
				else if (filterIstLieferant && filterIstLieferant.value) return this.istLieferant;
				else return 'alle';
			};

			const OP_art = () => {
				const filterOP_art = filter.get(this.OP_art);
				if (filterOP_art) return filterOP_art.value;
				return this.alle;
			};

			const OP_status = () => {
				const OPfilter_abgeschlossen = filter.has(this.OPfilter_abgeschlossen);
				const OPfilter_inKlaerung = filter.has(this.OPfilter_inKlaerung);

				//console.log(filter.get(this.OPfilter_abgeschlossen), filter.get(this.OPfilter_inKlaerung));

				if (OPfilter_abgeschlossen && OPfilter_inKlaerung && !filter.get(this.OPfilter_abgeschlossen).value && filter.get(this.OPfilter_inKlaerung).value)
					return this.OP_offenUndInKlaerung;

				else if (OPfilter_abgeschlossen) {
					if (filter.get(this.OPfilter_abgeschlossen).value) return this.OP_abgeschlossen;
					else return this.OP_offen;
				}

				else if (OPfilter_inKlaerung && filter.get(this.OPfilter_inKlaerung).value) return this.OP_inKlaerung;

				// beide nicht gesetzt: default "offen" zurückgeben
				else return this.OP_offen;
			};

			//console.log(OP_status());

			const filterGesperrt = filter.get(this.gesperrt);
			const gesperrtChecked = filterGesperrt ? !!filterGesperrt.value : false;

			let artikelartMitBestandsfuehrungChecked = false, artikelartOhneBestandsfuehrungChecked = false,
				artikelartIstDienstleistungChecked = false, artikelartIstBaugruppeChecked = false, artikelartIstVariantenhauptartikelChecked = false;
			const filterArtikelart = filter.get(this.artikelart);
			if (filterArtikelart && Array.isArray(filterArtikelart.value)) {
				const arten = filterArtikelart.value;
				artikelartMitBestandsfuehrungChecked = arten.includes('1');
				artikelartOhneBestandsfuehrungChecked = arten.includes('2');
				artikelartIstDienstleistungChecked = arten.includes('3');
				artikelartIstBaugruppeChecked = arten.includes('5');
				artikelartIstVariantenhauptartikelChecked = arten.includes('V');
			}
			//#endregion

			//#region Adressen
			if (this.props.bereich === $.adressenNode)
				jsx =
					<>
						<Typography variant="h6">Sortierung</Typography>
						<div style={{ height: '10px' }} />
						<Grid container>

							{/* Sortierung => Feldname */}
							<Grid item xs={6}>
								<Select
									value={sortField}
									onChange={this.handleFieldChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="adressnummer">Adressnr.</MenuItem>
									<MenuItem value="name1">Name 1</MenuItem>
									<MenuItem value="plz">PLZ</MenuItem>
									<MenuItem value="ort">Ort</MenuItem>
								</Select>
							</Grid>

							{/* Sortierung => aufsteigend/absteigend */}
							<Grid item xs={6}>
								<Select
									value={sortDir}
									onChange={this.handleFieldOrderChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="ASC">Aufsteigend</MenuItem>
									<MenuItem value="DESC">Absteigend</MenuItem>
								</Select>
							</Grid>
						</Grid>

						<div style={{ height: '30px' }} />
						<Typography variant="h6">Filter</Typography>
						<div style={{ height: '10px' }} />
						<FormControl fullWidth={true} margin="none">
							<InputLabel htmlFor={this.istKundeLieferant}>Kunden/Lieferanten</InputLabel>
							<Select
								value={istKundeLieferantValue()}
								onChange={this.handleMultipleFilterChange(this.istKundeLieferant)}
								name={this.istKundeLieferant}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={() => { this.handleResetValue(this.istKunde); this.handleResetValue(this.istLieferant) }}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								}
							>
								<MenuItem value={this.alle}>alle</MenuItem>
								<MenuItem value={this.istKunde}>nur Kunden</MenuItem>
								<MenuItem value={this.istLieferant}>nur Lieferanten</MenuItem>
							</Select>
						</FormControl>

						<div style={{ height: '30px' }} />
						<FormControlLabel
							control={
								<Checkbox
									checked={gesperrtChecked}
									value={this.gesperrt}
									onChange={this.handleFilterChange(this.gesperrt)}
								/>
							}
							label="gesperrte einblenden"
						/>
					</>;
			//#endregion
			//#region Artikel
			else if (this.props.bereich === $.artikelsNode)
				jsx =
					<>
						<Typography variant="h6">Sortierung</Typography>
						<div style={{ height: '10px' }} />
						{/* Sortierung => Feldname */}
						<Grid container>
							<Grid item xs={6}>
								<Select
									value={sortField}
									onChange={this.handleFieldChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="artikelnummer">Artikelnr.</MenuItem>
									<MenuItem value="bezeichnung">Bezeichnung</MenuItem>
									<MenuItem value="warengruppeId">Warengruppe</MenuItem>
									<MenuItem value="hersteller">Hersteller</MenuItem>
								</Select>
							</Grid>

							{/* Sortierung => aufsteigend/absteigend */}
							<Grid item xs={6}>
								<Select
									value={sortDir}
									onChange={this.handleFieldOrderChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="ASC">Aufsteigend</MenuItem>
									<MenuItem value="DESC">Absteigend</MenuItem>
								</Select>
							</Grid>

						</Grid>

						<div style={{ height: '30px' }} />
						<Typography variant="h6">Filter</Typography>
						<div style={{ height: '10px' }} />
						<FormControlLabel
							control={
								<Checkbox
									checked={artikelartMitBestandsfuehrungChecked}
									value="1"
									onChange={this.handleFilterChange(this.artikelart)}
								/>
							}
							label="mit Bestandsführung"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={artikelartOhneBestandsfuehrungChecked}
									value="2"
									onChange={this.handleFilterChange(this.artikelart)}
								/>
							}
							label="ohne Bestandsführung"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={artikelartIstDienstleistungChecked}
									value="3"
									onChange={this.handleFilterChange(this.artikelart)}
								/>
							}
							label="Dienstleistungen"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={artikelartIstBaugruppeChecked}
									value="5"
									onChange={this.handleFilterChange(this.artikelart)}
								/>
							}
							label="Baugruppen"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={artikelartIstVariantenhauptartikelChecked}
									value="V"
									onChange={this.handleFilterChange(this.artikelart)}
								/>
							}
							label="Variantenhauptartikel"
						/>

						<div style={{ height: '20px' }} />
						<Divider />
						<div style={{ height: '20px' }} />
						<FormControlLabel
							control={
								<Checkbox
									checked={gesperrtChecked}
									value={this.gesperrt}
									onChange={this.handleFilterChange(this.gesperrt)}
								/>
							}
							label="gesperrte einblenden"
						/>
					</>;
			//#endregion
			//#region Belege
			else if (this.props.bereich === $.belegeNode)
				jsx =
					<>
						<Typography variant="h6">Sortierung</Typography>
						<div style={{ height: '10px' }} />
						<Grid container>
							<Grid item xs={6}>
								<Select
									value={sortField}
									onChange={this.handleFieldChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="belegnummer">Belegnummer</MenuItem>
									<MenuItem value="belegdatum">Belegdatum</MenuItem>
									<MenuItem value="adresseId">Adressnummer</MenuItem>
								</Select>
							</Grid>
							<Grid item xs={6}>
								<Select
									value={sortDir}
									onChange={this.handleFieldOrderChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="ASC">Aufsteigend</MenuItem>
									<MenuItem value="DESC">Absteigend</MenuItem>
								</Select>
							</Grid>
						</Grid>

						<div style={{ height: '30px' }} />
						<Typography variant="h6">Filter</Typography>
						<div style={{ height: '10px' }} />
						<FormControl fullWidth={true} margin="none">
							<Button onClick={() => {
								const changed = hasChanged(true)(this.state.belegFilter, this.state.belegtypen); /* Synthetic Checkbox Objects */
								changed.forEach(checkbox => this.handleFilterChange(this.belegart)(checkbox));
								this.setState({ belegFilter: Array(12).fill(true) });
							}}>
								Alle
							</Button>
							<Button onClick={() => {
								const changed = hasChanged(false)(this.state.belegFilter, this.state.belegtypen); /* Synthetic Checkbox Objects */
								changed.forEach(checkbox => this.handleFilterChange(this.belegart)(checkbox));
								this.setState({ belegFilter: Array(12).fill(false) });
							}}>
								Keine
							</Button>
							{this.state.belegtypen.map((obj, index) =>
									<>
										<FormControlLabel
											key={index}
											control={
												<Checkbox
													checked={this.state.belegFilter[index]}
													value={String(obj.belegtypCode)}
													onChange={e => {
														this.handleBelegFilterChange(index);
														this.handleFilterChange(this.belegart)(e);
													}
													}
												/>
											}
											label={obj.belegtypText}
										/>
									</>
							)
							}
						</FormControl>
					</>;
			//#endregionB
			//#region Offene Posten
			else if (this.props.bereich === $.offenepostenNode)
				jsx =
					<>
						<Typography variant="h6">Sortierung</Typography>
						<div style={{ height: '10px' }} />

						<Grid container>
							<Grid item xs={6}>
								<Select
									value={sortField}
									onChange={this.handleFieldChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="id">ID</MenuItem>
									<MenuItem value="adressnummer">Adress-Nr.</MenuItem>
									<MenuItem value="lieferantennummer">Lieferanten-Nr.</MenuItem>
									<MenuItem value="kundennummer">Kunden-Nr.</MenuItem>
									<MenuItem value="rechnungstyp">Rechnungstyp</MenuItem>
									<MenuItem value="rechnungsart">Rechnungsart</MenuItem>
									<MenuItem value="rechnungsnummer">Rechnungs-Nr.</MenuItem>
									<MenuItem value="rechnungsdatum">Rechnungsdatum</MenuItem>
									<MenuItem value="naechsteFaelligkeit">Nächste Fälligkeit</MenuItem>
									<MenuItem value="nettoFaellig">Netto fällig</MenuItem>
									<MenuItem value="rechnungsBetrag">Rechnungsbetrag</MenuItem>
									<MenuItem value="offenerBetrag">Offener Betrag</MenuItem>
									<MenuItem value="bisherGezahlt">Bisher gezahlt</MenuItem>
									<MenuItem value="abgeschlossen">Abgeschlossen</MenuItem>
									<MenuItem value="mahnen">Mahnen</MenuItem>
									<MenuItem value="mahnstufe">Mahnstufe</MenuItem>
									<MenuItem value="inKlaerung">In Klärung</MenuItem>
								</Select>
							</Grid>
							<Grid item xs={6}>
								<Select
									value={sortDir}
									onChange={this.handleFieldOrderChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="ASC">Aufsteigend</MenuItem>
									<MenuItem value="DESC">Absteigend</MenuItem>
								</Select>
							</Grid>
						</Grid>

						<div style={{ height: '30px' }} />
						<Typography variant="h6">Filter</Typography>
						<div style={{ height: '10px' }} />
						<FormControl fullWidth={true} margin="none">
							<InputLabel htmlFor={this.OP_art}>Alle/OP/Verbindlichkeiten</InputLabel>
							<Select
								value={OP_art()}
								onChange={this.handleFilterChange(this.OP_art)}
								name={this.OP_art}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={() => this.handleResetValue(this.OP_art)}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								}
							>
								<MenuItem value={this.alle}>Alle</MenuItem>
								<MenuItem value={this.OP_O}>Nur Offene Posten</MenuItem>
								<MenuItem value={this.OP_V}>Nur Verbindlichkeiten</MenuItem>
							</Select>
						</FormControl>

						<div style={{ height: '10px' }} />
						<FormControl fullWidth={true} margin="none">
							<InputLabel htmlFor={this.OP_art}>Status</InputLabel>
							<Select
								value={OP_status()}
								onChange={this.handleFilterChange(this.OP_status)}
								name={this.OP_status}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={() => this.handleResetValue(this.OP_status)}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								}
							>
								<MenuItem value={this.OP_offen}>Offen</MenuItem>
								<MenuItem value={this.OP_inKlaerung}>In Klärung</MenuItem>
								<MenuItem value={this.OP_offenUndInKlaerung}>Offen und in Klärung</MenuItem>
								<MenuItem value={this.OP_abgeschlossen}>Abgeschlossen</MenuItem>
							</Select>
						</FormControl>
					</>;
			//#endregion
			//#region Deals
			else if (this.props.bereich === $.dealsNode)
				jsx =
					<>
						<Typography variant="h6">Sortierung</Typography>
						<div style={{ height: '10px' }} />

						<Grid container>
							<Grid item xs={7}>
								<Select
									value={sortField}
									onChange={this.handleFieldChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="id">ID</MenuItem>
									<MenuItem value="adresseId">Adresse</MenuItem>
									<MenuItem value="ansprechpartnerId">Ansprechpartner</MenuItem>
									<MenuItem value="titel">Titel</MenuItem>
									<MenuItem value="chance">Chance</MenuItem>
									<MenuItem value="typ">Typ</MenuItem>
									<MenuItem value="status">Status</MenuItem>
									<MenuItem value="phase">Phase</MenuItem>
									<MenuItem value="zustaendigerBenutzer">Zust. Benutzer</MenuItem>
									<MenuItem value="investitionsdatumGeplant">I.-Datum geplant</MenuItem>
									<MenuItem value="investitionssummeGeplant">I.-Summe geplant</MenuItem>
									<MenuItem value="investitionssummeErreicht">Inv. erreicht</MenuItem>
									<MenuItem value="angelegtDatum">Angelegt-Datum</MenuItem>
									<MenuItem value="geaendertDatum">Geändert-Datum</MenuItem>
								</Select>
							</Grid>
							<Grid item xs={5}>
								<Select
									value={sortDir}
									onChange={this.handleFieldOrderChange(this.orderBy)}
									inputProps={{ name: this.orderBy }}
								>
									<MenuItem value="ASC">Aufsteigend</MenuItem>
									<MenuItem value="DESC">Absteigend</MenuItem>
								</Select>
							</Grid>
						</Grid>

						<div style={{ height: '30px' }} />
						<Typography variant="h6">Filter</Typography>
						<div style={{ height: '10px' }} />
						<FormControl fullWidth={true} margin="none">
							<InputLabel htmlFor={this.phase}>Phase</InputLabel>
							<Select
								value={phaseValue}
								onChange={this.handleFilterChange(this.phase)}
								inputProps={{ name: this.phase }}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={() => this.handleResetValue(this.phase)}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								}
							>
								{Object.keys($.dealPhasen).map(key =>
									<MenuItem key={key} value={key}>{$.dealPhasen[key]}</MenuItem>
								)}
							</Select>
						</FormControl>

						<FormControl fullWidth={true} margin="normal">
							<InputLabel htmlFor={this.status}>Status</InputLabel>
							<Select
								value={statusValue}
								onChange={this.handleFilterChange(this.status)}
								inputProps={{ name: this.status }}
								endAdornment={
									<InputAdornment position="end">
										<IconButton onClick={() => this.handleResetValue(this.status)}>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								}
							>
								{Object.keys($.dealStatus).map(key =>
									<MenuItem key={key} value={key}>{$.dealStatus[key]}</MenuItem>
								)}
							</Select>
						</FormControl>
					</>;
			//#endregion
			//#region Service
			else if (this.props.bereich === $.serviceNode)
				jsx =
					<>
					</>;
			//#endregion

			return jsx;
		};

		return (
			<Drawer
				anchor="right"
				open={this.props.open}
				onClose={() => this.props.toggleFilterDrawer(false)}
			>
				<div className={classes.wrapper}>
					<form onSubmit={this.handleFilterSubmit}>

						{filterListJSX()}

						<Button type="submit" variant="contained" size="large" color="secondary" fullWidth={true} className={classes.filterButton}>
							Anwenden
						</Button>
					</form>
				</div>
			</Drawer>
		);
	};
}

export default withStyles(styles)(FilterDrawer);
