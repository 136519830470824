import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import { ArrowUpward, ArrowDownward, ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles({
    card: {
        minWidth: 160
    },
    title: {
        fontSize: 14
    }
});

export default function AuswertungsCard(props) {
    const classes = useStyles();
    const Icon = props.icon;
    const backgroundStyle = { backgroundColor: props.color };
    const TrendIcon = props.todaysValue > props.yesterdaysValue ? ArrowUpward : (props.todaysValue < props.yesterdaysValue ? ArrowDownward : ArrowForward);

    return (
        <>
            <Grid item={true} xs={12}>
                <Card className={classes.card} style={backgroundStyle} item={true}>
                    <CardContent>
                        <Grid container>
                            <Grid item={true} xs={8}>
                                <Typography color="textSecondary" gutterBottom>
                                    {
                                        props.titel
                                    } (heute)
                        </Typography>
                            </Grid>
                            <Grid item={true} xs={4} align="right">
                                <Icon />
                            </Grid>
                        </Grid>
                        <Typography variant="h1" align="right" color="primary">
                            {
                                props.todaysValue
                            }
                        </Typography>
                    </CardContent>

                    <Grid container direction="row" alignItems="center">
                        <Grid item={true}>
                            <TrendIcon />
                        </Grid>
                        <Grid item={true}>
                            <Typography color="textSecondary">
                                {
                                    props.yesterdaysValue
                                } (gestern)
                        </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
}
