import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '../../containers/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import DetailView from '../../components/DetailView';
import {
	getDbSuchergebnisse, getSuchergebnisTypFromUrl, pageLoadedByUrl, isDetailView,
	getIdFromUrl, getBereichFromUrl, istSuchergebnisFromUrl, getSuchergebnisAdrIdFromUrl
} from '../../functions';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
//import FileCopyIcon from '@material-ui/icons/FileCopy';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import CreateIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';
import AktivitaetEditierenModal from '../../components/AktivitaetEditierenModal';
import AdresseEditierenModal from '../../components/AdresseEditierenModal';
//import BelegtypAuswahlModal from '../../containers/BelegtypAuswahlModal';
import { withStyles } from '@material-ui/core/styles';
import $ from '../../config';

const styles = theme => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 1101,
		color: 'white'
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	listing: {
		position: 'relative',
		overflowY: 'scroll',
		height: 'calc(100vh - 115px)'
	},
	listingMobile: {
		position: 'relative',
		overflowY: 'scroll',
		height: 'calc(100vh - 108px)'
	},
	detail: {
		overflowY: 'scroll',
		height: 'calc(100vh - 66px)'
	},
	detailMobile: {
		overflowY: 'scroll',
		height: 'calc(100vh - 58px)'
	}
});

class ListView extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.itemsNode = props.config.itemsNode;
		this.itemsName = props.config.itemsName;
		this.classname = `${this.constructor.name}.class`;

		this.state = {
			items: [],
			selectedId: null,
			typ: null,
			isFetching: true,
			childIsFetching: true,
			ueberschrift: this.itemsName,
			suchItem: null,
			adrEditierenOpen: false,
			belegtypAuswahlOpen: false,
			dataItem: null,
			tabValue: 0,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		}

		//#region
		this.updatePage = this.updatePage.bind(this);
		this.getItems = this.getItems.bind(this);
		this.updateIsFetching = this.updateIsFetching.bind(this);
		this.getPropsId = this.getPropsId.bind(this);
		this.writeUriAndSelectId = this.writeUriAndSelectId.bind(this);
		this.updateChildIsFetching = this.updateChildIsFetching.bind(this);
		this.getDbSuche = this.getDbSuche.bind(this);
		this.setUeberschrift = this.setUeberschrift.bind(this);
		this.convertAndSortSuchergebnisse = this.convertAndSortSuchergebnisse.bind(this);
		this.sortArraySuchergebnisse = this.sortArraySuchergebnisse.bind(this);
		this.getTypeFromItem = this.getTypeFromItem.bind(this);
		this.detailsViewOnly = this.detailsViewOnly.bind(this);
		this.closeAdrEditierenModal = this.closeAdrEditierenModal.bind(this);
		this.openAdrEditierenModal = this.openAdrEditierenModal.bind(this);
		this.setTabValue = this.setTabValue.bind(this);
		this.updateUeberschriftFromChild = this.updateUeberschriftFromChild.bind(this);
		this.setDataItem = this.setDataItem.bind(this);
		this.setTabValueInUrl = this.setTabValueInUrl.bind(this);
		this.openBelegtypAuswahlModal = this.openBelegtypAuswahlModal.bind(this);
		this.closeBelegtypAuswahlModal = this.closeBelegtypAuswahlModal.bind(this);
		this.belegEditClick = this.belegEditClick.bind(this);
		//#endregion
	}

	componentDidMount() {
		this.setUeberschrift(this.itemsName);
		this._isMounted = true;
		window.addEventListener('resize', this.props.updateSplitScreen);
		this.setState({
			typ: this.props.typ,
			selectedId: this.getPropsId(),
			displayIsSplit: this.props.splitScreen()
		});
		// items können bei Suchergebnissen schon jetzt geholt werden, weil nicht auf die
		// Query (Filter) gewartet werden muss
		if (this.itemsNode === $.suchergebnisseNode) this.getItems();
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.id !== this.props.id && this.getPropsId()) {
			// wenn eine neue ID in den props war
			this.setState({ selectedId: this.getPropsId() });
		} else if (!this.getPropsId() && !this.props.splitScreen() && this.state.selectedId) {
			// selectedId löschen, wenn keine ID in der URI steht und man nur eine mobile Liste möchte
			this.setState({ selectedId: null });
		}

		// Filter
		if (prevProps.listingPath !== this.props.listingPath) {
			this.updateIsFetching(true);
			this.getItems();
		}

		// Überschrift
		let ueberschrift = this.itemsName;
		if (this.itemsNode === $.suchergebnisseNode) {
			this.getDbSuche().then(suchergebnisse => {
				const { suchbegriff } = suchergebnisse;
				if (suchergebnisse) ueberschrift = `Suchergebnisse für "${suchbegriff}"`;
				if (prevState.ueberschrift !== ueberschrift) this.setUeberschrift(ueberschrift);
			});
		} else if (prevState.ueberschrift !== ueberschrift) {
			this.setUeberschrift(ueberschrift);
		}

		// AdressId zur Verfügung stellen für AppBar, wenn wir bei Deals sind
		if (this.props.getAdrIdForBelegOrAkt
			&& Array.isArray(this.state.items)
			&& this.state.selectedId) {
			const { items } = this.state;
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item.id === this.state.selectedId && this.props.getAdrIdForBelegOrAkt() !== item.adresseId) {
					this.props.setAdrIdForBelegOrAkt(item.adresseId);
					break;
				}
			}
		}

		if (false) {
			console.log('')
			console.log('this.props.getIsFetching()', this.props.getIsFetching())
			console.log('this.state.isFetching', this.state.isFetching)
			console.log('this.props.mobileDetailView()', this.props.mobileDetailView())
			console.log('this.state.childIsFetching', this.state.childIsFetching)
			console.log('this.props.mobileListe()', this.props.mobileListe())
			console.log('this.props.mobileDetailView() && !this.state.childIsFetching', this.props.mobileDetailView() && !this.state.childIsFetching)
			console.log('this.props.mobileListe() && this.state.childIsFetching', this.props.mobileListe() && this.state.childIsFetching)
		}

		// Backdrop nach isFetching abstellen
		if (
			this.props.getIsFetching() /* Status von isFetching in der index: nur ändern, wenn true */
			&& !this.state.isFetching /* isFetching im ListView */
			&& (
				(this.props.mobileDetailView() && !this.state.childIsFetching) /* mobiles DetailView und kein isFetching im DetailView */
				|| (this.props.mobileListe() && this.state.childIsFetching) /* mobile Liste und isFetching im DetailView bleibt auf default: true */
				|| (!this.props.mobileListe() && !this.props.mobileDetailView() && !this.state.childIsFetching) /* DesktopView und DetailView ist fertig  */
			)
		) {
			this.props.setIsFetching(false);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
		window.removeEventListener('resize', this.props.updateSplitScreen);
	}

	updatePage(direction) {
		let page = this.props.pageNumber;

		if (direction === 'forward') page++;
		else if (direction === 'back') page--;
		else if (direction === 'first') page = 1;
		else if (direction === 'last') page = this.props.listingMaxPage;

		this.props.updatePageNumber(page);
	}

	async getDbSuche() {
		const suchergebnisse = await getDbSuchergebnisse(this.props.db);
		if (suchergebnisse && suchergebnisse.length) return suchergebnisse[0];
		return null;
	}

	setUeberschrift(ueberschrift) {
		this.setState({ ueberschrift });
		this.props.updateUeberschriftFromChild(ueberschrift);
	}

	updateUeberschriftFromChild(ueberschrift) {
		this.props.updateUeberschriftFromChild(ueberschrift);
	}

	getPropsId() {
		return this.props.id ? parseInt(this.props.id) : null;
	}

	setDataItem(dataItem) {
		this.setState({ dataItem });
	}

	setTabValueInUrl(tabValue) {
		const newPath = `${this.props.history.location.pathname}?tab=${tabValue}`;
		this.props.history.push(newPath);
		this.getItems();
	}

	// mit suchItem wird die Funktion nur in ListViewSuchergebnisse aufgerufen
	writeUriAndSelectId = (id = null, suchItem = null, tabValue = null) => {

		const debug = false;

		if (debug) {
			console.log('');
			console.log(`[${this.classname}] neu aufgerufen mit Argument-ID:`, id);
		}

		id = id || this.getPropsId() || getIdFromUrl(this.props.history.location.pathname);

		// firstItem entscheidet darüber, ob push oder replace gemacht wird in der URL
		// Wichtig für die Navigation mit Zurück-Button!
		const path = this.props.history.location.pathname;
		let
			firstItem = false, bereich = this.itemsNode, typ = null,
			inSuchergebnisse = bereich === $.suchergebnisseNode, newPath = `/app/${bereich}/${id}`;

		if (pageLoadedByUrl(this.props)) {
			// reload oder direkt durch URL aufgerufen

			if (debug) console.log(`[${this.classname}] reload oder direkt durch URL aufgerufen`);
			// Bereich und ID aus URL holen

			if (istSuchergebnisFromUrl(path)) {
				// wenn ein Suchergebnis...
				if (debug) console.log(`[${this.classname}] ist Suchergebnis`);
				inSuchergebnisse = true;
				bereich = $.suchergebnisseNode;
				typ = getSuchergebnisTypFromUrl(path);
			} else {
				// kein Suchergebnis
				if (debug) console.log(`[${this.classname}] ist KEIN Suchergebnis`);
				bereich = getBereichFromUrl(path);
			}
			if (!id) {
				id = getIdFromUrl(path);
				if (debug) console.log(`[${this.classname}] neue ID gesetzt`, id)
			}
		}

		if (!id) {
			if (debug) console.log(`[${this.classname}] keine id`);

			if (debug) console.log(`[${this.classname}] erstes Element anzeigen`);

			// keine ID in den props und kein reload: erstes Element anzeigen
			const _firstItem = this.state.items.length ? this.state.items[0] : null;
			if (_firstItem) {
				firstItem = true;
				id = _firstItem.id || null;
				if (debug) console.log(`[${this.classname}] neue ID gesetzt:`, id);
				typ = this.getTypeFromItem(_firstItem);
			} else {
				console.warn(`this.state.items =`, this.state.items);
				console.warn(`[${this.classname}] kein _firstItem`);
			}
		}

		if (!id) {
			if (debug) console.log(`[${this.classname}] currentItems ist ein Array mit Länge Null`);
			return;
		}

		if (debug) console.log(`[${this.classname}] newPath oben`, newPath)

		// Bei Suchergebnissen den Typ berücksichtigen
		if (inSuchergebnisse) {
			if (debug) {
				console.log('');
				console.log(`[${this.classname}] in Suchergebnisse`);
			}

			if (suchItem) {
				if (debug) console.log(`[${this.classname}] suchItem`);
				typ = this.getTypeFromItem(suchItem);
				if (typ === $.belegeNode) tabValue = 0;
				this.setState({ suchItem });
			} else typ = typ || this.getTypeFromItem(this.state.suchItem) || getSuchergebnisTypFromUrl(path) || $.adressenNode;

			newPath = `/app/${$.suchergebnisseNode}/${typ}/${id}`;

			if (typ === $.ansprechpartnersNode) {
				if (suchItem || this.state.suchItem) {
					if (debug) console.log(`[${this.classname}] 8473464343`);
					const se = suchItem ? suchItem : this.state.suchItem;
					newPath = `/app/${$.suchergebnisseNode}/${$.adressenNode}/${se.adresseId}/${$.ansprechpartnersNode}/${id}`;
				} else {
					const adrId = getSuchergebnisAdrIdFromUrl(this.props.history.location.pathname);
					newPath = `/app/${$.suchergebnisseNode}/${$.adressenNode}/${adrId}/${$.ansprechpartnersNode}/${id}`;
				}
			}
		}

		if (debug) console.log(`[${this.classname}] newPath unten`, newPath)

		if (path !== newPath) {
			if (debug) {
				console.log(`[${this.classname}] `);
				console.log(`[${this.classname}] path !== newPath, path:`, path);
				console.log(`[${this.classname}] path !== newPath, newPath:`, newPath);
				console.log(`[${this.classname}] `);
			}

			// ?... dran hängen
			newPath = newPath + this.props.history.location.search;

			if (firstItem) {
				if (debug) console.log(`[${this.classname}] firstItem, history.replace`);
				this.props.history.replace(newPath);
			} else {
				if (debug) console.log(`[${this.classname}] kein firstItem, history.push`);
				this.props.history.push(newPath);
			}
		} else if (debug) console.log(`[${this.classname}] gleicher path`, path);

		this.setState({
			selectedId: id,
			tabValue: tabValue || this.state.tabValue
		});

		if (debug) console.log(`[${this.classname}] tabValue gesetzt auf`, tabValue || this.state.tabValue);
	}

	getItems() {

		//console.log('getItems() aufgerufen');

		// Liste nur holen, wenn keine ausschließliche Detailansicht angezeigt werden soll
		if (isDetailView(this.props.location.search) || this.props.mobileDetailView()) this.setState({ items: [] });

		//console.log('getItems() tiefer...');

		const { FetchListClass } = this.props.config;
		new FetchListClass(this.props, this.controller.signal).fetch().then(obj => {

			this.updateIsFetching(false);
			let items;

			let _itemsNode = this.itemsNode;
			// Unterschiede API - WebApp
			// Die WebApp unterscheidet in der Namensgebung zwischen Singular und Plural.
			if (this.itemsNode === $.artikelsNode) _itemsNode = 'artikel';

			if (!obj || !obj.totalResultCount || !obj[_itemsNode]) {
				console.warn(`[${this.classname}] !obj || !obj[_itemsNode]`);
				items = [];
				this.props.updateListingTotalResultsCount(0);
			} else {
				items = obj[_itemsNode];
				this.props.updateListingTotalResultsCount(obj.totalResultCount);
			}

			if (!Array.isArray(items)) {
				// wir haben ein Objekt und kein Array, also Suchergebnisse
				items = this.convertAndSortSuchergebnisse(obj[_itemsNode]);
			}

			if (this._isMounted) this.setState({ items });

		}).catch(err => {
			if (this._isMounted) this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: err
			});
		});
	};

	getTypeFromItem(item) {
		if (!item) return null;
		if (item.name3) {
			// Adresse
			return $.adressenNode;
		} else if (item.belegdatum) {
			// Beleg
			return $.belegeNode;
		} else if (item.chance) {
			// Deal
			return null;
		} else if (item.artikelart) {
			// Artikel
			return null;
		} else if (item.opvArt) {
			// Offener Posten
			return null;
		} else if (item.auftragsnummer) {
			// Serviceauftrag
			return null;
		} else if (item.position && item.adresseId) {
			// Ansprechpartner
			return $.ansprechpartnersNode;
		}
		console.error('[ListView.class] Kein Typ gefunden. item =', item);
		return null;
	}

	convertAndSortSuchergebnisse(obj) {
		// Array aus Objekt
		const keys = Object.keys(obj), arrFromObj = [];
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			arrFromObj.push(...obj[key]);
		}
		return this.sortArraySuchergebnisse(arrFromObj);
	}

	sortArraySuchergebnisse(arr) {
		// sortieren
		const adressen = [], belege = [], ansprechpartner = [];
		let gefunden = false;
		for (let i = 0; i < arr.length; i++) {
			const item = arr[i];
			if (item.position) {
				// Ansprechpartner
				ansprechpartner.push(item);
				gefunden = true;
			}
			else if (item.name1) {
				// Adresse
				adressen.push(item);
				gefunden = true;
			}
			else if (item.belegdatum) {
				// Beleg
				belege.push(item);
				gefunden = true;
			}
		}
		// wenn nichts gefunden wurde, ursprüngliches Array zurückgeben
		if (!gefunden) return arr;

		return [...adressen, ...ansprechpartner, ...belege];
	}

	//TODO replace with 'global' detailsViewOnly
	detailsViewOnly() {
		if (isDetailView(this.props.location.search)) return true;
		return false;
	}

	openAdrEditierenModal() {
		this.setState({ adrEditierenOpen: true });
	}

	closeAdrEditierenModal() {
		this.setState({ adrEditierenOpen: false });
	}

	openBelegtypAuswahlModal() {
		this.setState({ belegtypAuswahlOpen: true });
	}

	closeBelegtypAuswahlModal() {
		this.setState({ belegtypAuswahlOpen: false });
	}

	updateIsFetching(isFetching) {
		if (this._isMounted) this.setState({ isFetching });
	}

	updateChildIsFetching(isFetching) {
		if (this._isMounted) this.setState({ childIsFetching: isFetching });
	}

	setTabValue(tabValue) {
		//console.log(tabValue)
		this.setState({ tabValue });
	}

	belegEditClick() {
		if (!this.state.dataItem) return false;
		const
			{ adresseId } = this.state.dataItem,
			belegId = this.state.dataItem.id,
			newPath = `/app/belegneu/${adresseId}/0100?belegId=${belegId}`;
		this.props.history.push(newPath);
	}

	render() {
		const { classes } = this.props;
		const { ListViewContainer } = this.props.config;

		const griditemProps = () => {
			const debug = false;
			if (this.detailsViewOnly()) {
				if (debug) console.log('griditemProps()', 'detailsViewOnly');
				return { xsListView: false, xsDetailView: 12 };
			}
			else if (this.props.splitScreen()) {
				if (debug) console.log('griditemProps()', 'splitScreen');
				return { xsListView: 4, xsDetailView: 8 };
			}
			else if (this.props.mobileListe()) {
				if (debug) console.log('griditemProps()', 'mobileListe');
				return { xsListView: 12, xsDetailView: false };
			}
			else if (this.props.mobileDetailView()) {
				if (debug) console.log('griditemProps()', 'mobileDetailView');
				return { xsListView: false, xsDetailView: 12 };
			}
		};

		const showAdrBearbeitenButton = () => {
			if (this.props.mobileListe() || !this.state.dataItem) return false;

			const typ = getSuchergebnisTypFromUrl(this.props.location.pathname);
			const { itemsNode } = this.props.config;
			const uebersichtTabValue = 0;
			if (
				(itemsNode === $.adressenNode && this.state.tabValue === uebersichtTabValue)
				|| (itemsNode === $.suchergebnisseNode && (typ === $.adressenNode || typ === $.ansprechpartnersNode) && this.state.tabValue === uebersichtTabValue)
			) return true;
			return false;
		}

		const showBelegEditButton = () => {
			if (this.props.mobileListe() || !this.state.dataItem) return false;

			const { itemsNode } = this.props.config;

			if (itemsNode === $.belegeNode && parseInt(this.state.dataItem.belegstatusCode) <= 2) return true;
			return false;
		}

		/* const showBelegUebernehmenButton = () => {
			if (this.props.mobileListe() || !this.state.dataItem) return false;
			const itemsNode = this.props.config.itemsNode;
			if (itemsNode === $.belegeNode) return true;
			return false;
		} */

		return (
			<>
				{(this.state.isFetching
					|| (this.state.childIsFetching && !this.props.mobileListe())) && <LinearProgress color="secondary" className="global_progress_bar" />}

				<Grid container className="split_screen">

					{/* Liste */}
					{!this.detailsViewOnly() && (this.props.splitScreen() || this.props.mobileListe()) &&
						<>
							{this.props.config.showFilter &&
								<>
									<Paper square={true} className="pagination">
										<Grid container alignItems="center">
											<Grid item xs={2}>
												<Button fullWidth={true} onClick={() => this.updatePage('first')} disabled={this.props.pageNumber === 1}>
													<FirstPageIcon />
												</Button>
											</Grid>

											<Grid item xs={2}>
												<Button fullWidth={true} onClick={() => this.updatePage('back')} disabled={this.props.pageNumber === 1}>
													<KeyboardArrowLeftIcon />
												</Button>
											</Grid>

											<Grid item xs={4}>
												<Typography align="center" variant="h6">{this.props.pageNumber} / {this.props.listingMaxPage}</Typography>
											</Grid>

											<Grid item xs={2}>
												<Button fullWidth={true} onClick={() => this.updatePage('forward')} disabled={this.props.pageNumber === this.props.listingMaxPage}>
													<KeyboardArrowRightIcon />
												</Button>
											</Grid>

											<Grid item xs={2}>
												<Button fullWidth={true} onClick={() => this.updatePage('last')} disabled={this.props.pageNumber === this.props.listingMaxPage}>
													<LastPageIcon />
												</Button>
											</Grid>
										</Grid>
									</Paper>
								</>
							}

							<Grid item xs={griditemProps()['xsListView']} classes={window.innerWidth >= 600 ? { root: classes.listing } : { root: classes.listingMobile }}>
								<ListViewContainer
									selectedId={this.state.selectedId}
									items={this.state.items}
									writeUriAndSelectId={this.writeUriAndSelectId}
								/>
							</Grid>
						</>
					}

					{/* Detailansicht */}
					{(this.props.splitScreen() || this.props.mobileDetailView()) &&
						<Grid item xs={griditemProps()['xsDetailView']} classes={window.innerWidth >= 600 ? { root: classes.detail } : { root: classes.detailMobile }}>
							<DetailView
								{...this.props}
								setDataItem={this.setDataItem}
								splitScreen={this.props.splitScreen}
								updateChildIsFetching={this.updateChildIsFetching}
								id={this.state.selectedId}
								items={this.state.items}
								getTypeFromItem={this.getTypeFromItem}
								displayIsSplit={this.props.splitScreen}
								tabValueFromListView={this.state.tabValue}
								setTabValue={this.setTabValue}
								updateUeberschriftFromChild={this.updateUeberschriftFromChild}
								writeUriAndSelectId={this.writeUriAndSelectId}
							/>

							<div style={{ height: '70px' }} />
						</Grid>
					}
				</Grid>

				<Modal
					open={this.state.emOpen}
					title={this.state.emTitle}
					text={this.state.emText}
					handleOk={() => this.setState({ emOpen: false })}
					cancelBtn={false}
					destroy={() => this.setState({ emOpen: false })}
				/>

				{/* Neue Aktivität */}
				<AktivitaetEditierenModal
					screenprops={this.props}
					open={this.props.aktEditierenOpen || false}
					adrIdForBelegOrAkt={this.props.getAdrIdForBelegOrAkt ? this.props.getAdrIdForBelegOrAkt() : null}
					closeAktEditieren={this.props.closeAktEditierenModal}
					setTabValueInUrl={this.setTabValueInUrl}
				/>

				{/* "Adresse bearbeiten" Button unten rechts */}
				{showAdrBearbeitenButton() &&
					<>
						<Tooltip title="Adresse bearbeiten" classes={{ tooltip: classes.lightTooltip }}>
							<Fab color="secondary" aria-label="Edit" className={classes.fab} onClick={this.openAdrEditierenModal}>
								<CreateIcon />
							</Fab>
						</Tooltip>

						<AdresseEditierenModal
							screenprops={this.props}
							adresse={this.state.dataItem.adresse}
							open={this.state.adrEditierenOpen}
							closeAdrEditieren={this.closeAdrEditierenModal}
							setTabValueInUrl={this.setTabValueInUrl}
						/>
					</>
				}

				{/* "Beleg übernehmen" Button unten rechts */}
				{/*
					<>
						<Tooltip title="Beleg übernehmen" classes={{ tooltip: classes.lightTooltip }}>
							<Fab color="secondary" aria-label="" className={classes.fab} onClick={this.openBelegtypAuswahlModal}>
								<FileCopyIcon />
							</Fab>
						</Tooltip>

						<BelegtypAuswahlModal
							belegId={this.state.selectedId}
							adresseId={this.state.dataItem.adresseId}
							open={this.state.belegtypAuswahlOpen}
							onClose={this.closeBelegtypAuswahlModal}
							closeBelegtypAuswahlModal={this.closeBelegtypAuswahlModal}
						/>
					</>
				*/}

				{/* "Beleg ändern" Button unten rechts */}
				{showBelegEditButton() &&
					<Tooltip title="Beleg ändern" classes={{ tooltip: classes.lightTooltip }}>
						<Fab color="secondary" aria-label="" className={classes.fab} onClick={this.belegEditClick}>
							<CreateIcon />
						</Fab>
					</Tooltip>
				}

				<Modal
					open={this.state.emOpen}
					title={this.state.emTitle}
					text={this.state.emText}
					handleOk={() => this.setState({ emOpen: false })}
					cancelBtn={false}
					destroy={() => this.setState({ emOpen: false })}
				/>
			</>
		);
	}
}

export default withStyles(styles)(ListView);
