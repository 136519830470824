import React from 'react';
import TabAktivitaeten from './TabAktivitaeten';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Banner from './Banner';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListViewBelege from './ListViewBelege';
import $ from '../config';
import { keineBlaVorhanden } from '../functions';
import CardAdresseKlein from './CardAdresseKlein';
import { withStyles } from '@material-ui/core';

const detailViewDeal = props => {

	const StyledCard = withStyles({
		root: {
			marginBottom: '8px'
		}
	})(Card);
	const StyledAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);
	const StyledListItemText = withStyles(theme => ({
		primary: {
			color: theme.palette.secondary.main,
			fontWeight: 'bold'
		}
	}))(ListItemText);

	const Uebersicht = () => {
		if (!props.dataItem) return null;
		const deal = props.dataItem;
		let adresseId;
		if (deal.belege.length || deal.aktivitaeten.length)
			adresseId = deal.belege.length > deal.aktivitaeten.length ? deal.belege[0].adresseId : deal.aktivitaeten[0].adresseId;

		const adressen = [{
			adresse: {
				id: adresseId,
				name1: deal.firma
			}
		}];

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(2354770283);
				return null;
			}
			const newPath = `/app/${$.adressenNode}/${id}?details`;
			props.dataItem.screenProps.history.push(newPath);
		};

		//console.log(props.dataItem);

		return (
			<>
				<CardAdresseKlein
					adressen={adressen}
					handleClick={handleClick}
					StyledCard={StyledCard}
				/>

				<StyledCard>
					<CardContent>
						<Typography variant="h5">
							{deal.firma}
						</Typography>
						<Grid container spacing={1} style={{ marginTop: 16 }}>
							<Grid item xs={4} sm={4}>
								Ansprechpartner
							</Grid>
							<Grid item xs={8} sm={8}>
								{deal.ansprechpartner}
							</Grid>
							<Grid item xs={4} sm={4}>
								Thema
							</Grid>
							<Grid item xs={8} sm={8}>
								{deal.thema}
							</Grid>
							<Grid item xs={4} sm={4}>
								Phase
							</Grid>
							<Grid item xs={8} sm={8}>
								{deal.phase}
							</Grid>
							<Grid item xs={4} sm={4}>
								Zuständiger Benutzer
							</Grid>
							<Grid item xs={8} sm={8}>
								{deal.zustBenutzer}
							</Grid>
							<Grid item xs={4} sm={4}>
								Geplantes Datum
							</Grid>
							<Grid item xs={8} sm={8}>
								{deal.gdatum}
							</Grid>
							<Grid item xs={4} sm={4}>
								Geplante Summe
							</Grid>
							<Grid item xs={8} sm={8}>
								{deal.gsumme}
							</Grid>
						</Grid>
					</CardContent>
				</StyledCard>
			</>
		);
	};

	const MyAktivitaeten = () => {
		if (!props.dataItem) return null;
		const { aktivitaeten } = props.dataItem;
		if (!aktivitaeten || !aktivitaeten.length || aktivitaeten === '—') return <Banner icon={<InfoOutlinedIcon />}>Keine Aktivitäten vorhanden</Banner>;
		return <TabAktivitaeten
			aktivitaeten={aktivitaeten}
			StyledCard={StyledCard}
			StyledAvatar={StyledAvatar}
			StyledListItemText={StyledListItemText}
		/>;
	};

	const Belege = () => {
		if (!props.dataItem
			|| !props.dataItem.belege
			|| !props.dataItem.belege.length
		) return keineBlaVorhanden('Belege', true);
		const { belege } = props.dataItem;

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(7352346570);
				return null;
			}
			const newPath = `/app/${$.belegeNode}/${id}?details`;
			props.dataItem.screenProps.history.push(newPath);
		};

		return <ListViewBelege items={belege} handleClick={handleClick} />
	}

	return [{
		ueberschrift: 'Übersicht',
		Funktion: Uebersicht
	}, {
		ueberschrift: 'Aktivitäten',
		Funktion: MyAktivitaeten
	}, {
		ueberschrift: 'Kundenbelege',
		Funktion: Belege
	}];
};

export default detailViewDeal;
