import { getKuerzel } from './functions';

export default {
	tabLager: 'lager', 																			// Tabelle muss genau so heißen wie Knoten vom Server
	tabLagerkreise: 'lagerkreise',  												// Tabelle muss genau so heißen wie Knoten vom Server
	tabBenutzer: 'benutzer', 																// Tabelle muss genau so heißen wie Knoten vom Server
	tabTechniker: 'techniker', 															// Tabelle muss genau so heißen wie Knoten vom Server
	tabBelegarten: 'belegarten', 														// Tabelle muss genau so heißen wie Knoten vom Server
	tabZahlungsarten: 'zahlungsarten', 											// Tabelle muss genau so heißen wie Knoten vom Server
	tabLieferbedingungen: 'lieferbedingungen', 							// Tabelle muss genau so heißen wie Knoten vom Server
	tabServiceAuftragsarten: 'serviceAuftragsarten', 				// Tabelle muss genau so heißen wie Knoten vom Server
	tabServiceAbrechnungsarten: 'serviceAbrechnungsarten', 	// Tabelle muss genau so heißen wie Knoten vom Server
	tabGespeichertListen: 'tabGespeichertListen',
	tabGPSPosition: 'tabGPSPosition',
	tabWetter: 'tabWetter',
	tabCache: 'tabCache',
	tabSuchergebnisse: 'tabSuchergebnisse',
	title: 'VARIOapp',
	get titleOnline() { return this.title; },
	get titleOnlineRESTOffline() { return this.title; },
	get titleOffline() { return this.title; },
	dbName: 'VARIO-PWA-DB-V02',
	bearerTokenName: 'bearerToken',
	benutzerIdName: 'benutzerId',
	benutzerKuerzelName: 'benutzerKuerzel',
	benutzernameName: 'benutzername',
	ansprechpartnerIdName: 'ansprechpartnerId',
	rennerPennerTitel: 'Renner & Penner',
	dreiJahresumsatzTitel: '3-Jahresumsatz',
	umsatzNachAuslandTitel: 'Umsatz nach Inland / EU / Ausland',
	umsatzTitel: 'Umsatz',
	warengruppenUmsatzTitel: 'Warengruppenumsatz nach Monat',
	gebHeute: 'heute!!!',
	gebMorgen: 'morgen',
	gebUebermorgen: 'übermorgen',
	logoutModusName: 'logoutModus',
	hatDealsName: 'hatDeals',
	hatServiceauftraegeName: 'hatServiceauftraege',
	ansprechpartnersNode: 'ansprechpartners',
	adressenNode: 'adressen',
	artikelsNode: 'artikels',
	belegeNode: 'belege',
	dealsNode: 'deals',
	serviceNode: 'serviceauftraege',
	scannerNode: 'scanner',
	einstellungenNode: 'einstellungen',
	offenepostenNode: 'offeneposten',
	auswertungenNode: 'auswertungen',
	suchergebnisseNode: 'suchergebnisse',
	filterTypeFilter: 'filter', /* in der API als ein Teil von filter=... */
	filterTypeField: 'field', /* Steuerfelder in der API als z.B. orderBy=... */
	typeSelect: 'select',
	typeCheckbox: 'checkbox',
	typeText: 'text',
	orderAsc: 'ASC',
	orderDesc: 'DESC',
	angezeigteBelegtypen: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '11', '12', '51'],
	get defaultFilters() {
		return {
			[this.adressenNode]: [
				{ key: 'istKunde', value: '' },
				{ key: 'istLieferant', value: '' },
				{ key: 'gesperrt', value: false }
			],
			[this.artikelsNode]: [],
			[this.belegeNode]: [
				{ key: 'belegtyp', value: '' }
			],
			[this.offenepostenNode]: [
				{ key: 'opvArt', value: 'O' }
			],
			[this.dealsNode]: [
				{ key: 'zustaendigerBenutzer', value: getKuerzel() },
				{ key: 'phase', value: '' },
				{ key: 'status', value: '' }
			]
		}
	},
	get defaultOrderBy() {
		return {
			[this.adressenNode]: 'adressnummer',
			[this.artikelsNode]: 'id',
			[this.belegeNode]: '-belegdatum',
			[this.offenepostenNode]: '-rechnungsdatum',
			[this.dealsNode]: 'investitionsdatumGeplant'
		}
	},
	get dbVersions() {
		return [{
			[this.tabLager]: '++pwaId, adresseId, &id, kurzbezeichnung, lager, lagerkreisId',
			[this.tabLagerkreise]: '++pwaId, ausgangLagerId, eingangLagerId, &id, lieferungAdresseId, name, rechnungAdresseId',
			[this.tabBenutzer]: '++pwaId, ansprechpartnerId, gesperrt, &id, kuerzel, name',
			[this.tabGespeichertListen]: 'id, gespeichertUm',
			[this.tabGPSPosition]: 'id, lat, lng, gespeichertUm',
			[this.tabWetter]: 'id, beschreibung, temperatur, ort, icon, gespeichertUm',
			[this.tabTechniker]: '++pwaId, &id, techniker, bezeichnung, gesperrt',
			[this.tabServiceAbrechnungsarten]: '++pwaId, &id, bezeichnung, abrechnung',
			[this.tabServiceAuftragsarten]: '++pwaId, &id, bezeichnung, istStandard',
			[this.tabCache]: '&path, results',
			[this.tabSuchergebnisse]: '&id, suchbegriff, results',
			[this.tabBelegarten]: '++pwaId, anzeige, &belegart, belegtyp, &id, istAktiv, istApiaktiv'
		}, {
			[this.tabZahlungsarten]: '++pwaId, &id, bezeichnung, lieferart, gesperrt',
			[this.tabLieferbedingungen]: '++pwaId, &id, bezeichnung, gesperrt, mahnen, nachnahme, nettotage, skonto, skontotage, zahlungsart'
		}];
	},
	get interneTabellen() {
		return [
			this.tabGespeichertListen,
			this.tabGPSPosition,
			this.tabWetter,
			this.tabCache,
			this.tabSuchergebnisse
		];
	},
	belegstatus: {
		1: 'gespeichert, nicht gebucht',
		2: 'gespeichert und gedruckt',
		3: 'gebucht',
		4: 'teilweise übernommen', // nur Aufträge!
		5: 'übernommen',
		6: 'aufgelöst', // nur Aufträge!
		8: 'übernommen, Lieferschein noch nicht gebucht' // nur Aufträge!
	},
	dealTypen: {
		1: 'Vertrieb',
		2: 'Marketing'
	},
	dealPhasen: {
		1: 'Erstkontakt',
		2: 'Follow-Up',
		3: 'In Verhandlung',
		4: 'Abgeschlossen'
	},
	dealStatus: {
		0: 'Offen',
		1: 'Gewonnen',
		2: 'Verloren'
	},
	aktArten: {
		0: 'Telefon',
		1: 'E-Mail',
		2: 'Sonstiges',
		3: 'Kommentar',
		4: 'Interner Kommentar',
		5: 'Softwaretest',
		6: 'Punkte',
		7: 'Programmierung',
		8: 'Besprechung',
		9: 'Akquise',
		10: 'Onlinevorführung',
		11: 'Fernwartung',
		12: 'Consulting',
		13: 'Design',
		14: 'Fahrzeit',
		15: 'Aufwandsschätzung',
		'-1': 'DEALS: Neuanlage',
		'-2': 'DEALS: BelegHinzufuegen',
		'-3': 'DEALS: Gewonnen',
		'-4': 'DEALS: Verloren',
		'-5': 'DEALS: Reaktiviert',
		'-6': 'DEALS: BelegEntfernen',
		'-7': 'DEALS: Zurückgesetzt'
	}
};
