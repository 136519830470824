import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { getDeutschesDatum, getBenutzername, aktArtenSelect, getBenutzerId, ansPartnerSelect } from '../functions';
import FetchAktivitaetEditieren from '../classes/items/FetchAktivitaetEditieren';
import FetchAnsprechpartnerZuAdresse from '../classes/items/FetchAnsprechpartnerZuAdresse';
import $ from '../config';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 218
	},
	formControlBeschreibung: {
		margin: theme.spacing(1),
		minWidth: '95%'
	}
});

class AktivitaetEditierenModal extends React.Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.initialState = {
			angelegt: getDeutschesDatum(true),
			benutzer: getBenutzername(),
			aktArtValue: '',
			ansPartnerValue: '',
			dauer: '',
			beschreibung: '',
			errorDauer: false,
			errorBeschreibung: false,
			errorAktArt: false,
			adrId: null,
			ansprechpartners: []
		};

		this.intervalId = null;

		this.state = this.initialState;

		this.handleChange = this.handleChange.bind(this);
		this.updateTime = this.updateTime.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.checkInputErrors = this.checkInputErrors.bind(this);
		this.closeAktEditieren = this.closeAktEditieren.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.intervalId = window.setInterval(this.updateTime, 1000);
	}

	componentDidUpdate(prevProps, prevState) {
		// Ansprechpartner holen
		const adrId = this.props.adrIdForBelegOrAkt || this.props.screenprops.id;
		if (adrId && (!this.state.adrId || prevState.adrId !== adrId)) {
			this.setState({ adrId });
			new FetchAnsprechpartnerZuAdresse(this.props.screenprops, this.controller.signal, adrId).fetch().then(ansprechpartners => {
				this.setState({
					ansprechpartners,
					ansPartnerValue: ansprechpartners && ansprechpartners.length ? ansprechpartners[0].id : ''
				});
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
		clearInterval(this.intervalId);
	}

	updateTime() {
		this.setState({ angelegt: getDeutschesDatum(true) });
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	checkInputErrors() {
		const dauer = parseInt(this.state.dauer);
		if (
			!this.state.aktArtValue
			|| !dauer
			|| !this.state.beschreibung
			|| dauer <= 0
		) {
			let errorAktArt = false, errorBeschreibung = false, errorDauer = false;
			if (!this.state.aktArtValue) errorAktArt = true;
			if (!dauer || dauer <= 0) errorDauer = true;
			if (!this.state.beschreibung) errorBeschreibung = true;
			this.setState({
				errorAktArt,
				errorDauer,
				errorBeschreibung
			});
			return true;
		}
		return false;
	}

	handleSubmit() {
		if (this.checkInputErrors()) return;

		const { itemsNode } = this.props.screenprops.config;

		const sendData = {
			data: {
				benutzerId: getBenutzerId(),
				art: parseInt(this.state.aktArtValue),
				dauer: parseInt(this.state.dauer),
				beschreibung: this.state.beschreibung,
				ansprechpartnerId: parseInt(this.state.ansPartnerValue) || 0
			}
		};

		if (itemsNode === $.adressenNode) sendData.data.adresseId = this.props.screenprops.id;
		else if (itemsNode === $.dealsNode) {
			sendData.data.adresseId = this.props.adrIdForBelegOrAkt;
			sendData.data.dealsId = this.props.screenprops.id;
		}

		new FetchAktivitaetEditieren(this.props.screenprops, this.controller.signal, sendData).post().then(tabValue => {
			if (tabValue) this.props.setTabValueInUrl(tabValue);
			else console.error(7863706439);
		});

		this.closeAktEditieren();
	}

	closeAktEditieren() {
		this.props.closeAktEditieren();
		this.setState(this.initialState);
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<Dialog
					disableBackdropClick
					disableEscapeKeyDown
					open={this.props.open}
				>
					<DialogTitle>Aktivität anlegen</DialogTitle>
					<DialogContent>
						<form className={classes.container}>
							<FormControl className={classes.formControl}>
								<TextField
									label="angelegt am"
									value={this.state.angelegt}
									disabled
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									label="Benutzer"
									value={this.state.benutzer}
									disabled
								/>
							</FormControl>
							<FormControl className={classes.formControl} error={this.state.errorAktArt}>
								{aktArtenSelect(this.state.aktArtValue, this.handleChange, 'aktArtValue')}
							</FormControl>
							<FormControl className={classes.formControl}>
								{ansPartnerSelect(this.state.ansPartnerValue, this.handleChange, 'ansPartnerValue', this.state.ansprechpartners)}
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									label="Dauer in Minuten"
									value={this.state.dauer}
									onChange={this.handleChange('dauer')}
									type="number"
									error={this.state.errorDauer}
								/>
							</FormControl>
							<FormControl className={classes.formControlBeschreibung}>
								<TextField
									label="Beschreibung"
									multiline
									value={this.state.beschreibung}
									onChange={this.handleChange('beschreibung')}
									type="text"
									error={this.state.errorBeschreibung}
								/>
							</FormControl>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeAktEditieren} color="primary">
							Abbrechen
						</Button>
						<div style={{ width: '10px' }}></div>
						<Button onClick={this.handleSubmit} color="primary">
							Aktivtät anlegen
            </Button>
					</DialogActions>
				</Dialog>
			</div >
		);
	}
}

AktivitaetEditierenModal.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AktivitaetEditierenModal);
