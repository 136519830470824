import React, { Component } from 'react';

class CanvasUnterschrift extends Component {

	constructor(props) {
		super(props);

		this.canvasUnterschrift = React.createRef();
		this.move = 'move';
		this.down = 'down';
		this.up = 'up';
		this.out = 'out';

		this.state = {
			isDrawing: false,
			lastX: 0,
			lastY: 0
		};

		this.draw = this.draw.bind(this);
		this.canvas = this.canvas.bind(this);
		this.ctx = this.ctx.bind(this);
		this.handleEvent = this.handleEvent.bind(this);
		this.initValues = this.initValues.bind(this);
		this.setSize = this.setSize.bind(this);
	}

	componentDidMount() {
		this.props.setCanvasMounted(true);
	}

	componentDidUpdate() {
		//console.log(this.state)
		if (!this.state.isDrawing) this.setSize();
	}

	componentWillUnmount() {
		this.props.setCanvasMounted(false);
	}

	canvas() {
		if (this.canvasUnterschrift) return this.canvasUnterschrift.current;
	}

	ctx() {
		return this.canvas().getContext('2d');
	}

	setSize() {
		if (this.canvas().width !== this.props.width || this.canvas().height !== this.props.height)
			this.initValues(this.props.width, this.props.height);
	}

	initValues(width, height) {
		if (width) this.canvas().width = width;
		if (height) this.canvas().height = height;
		const ctx = this.ctx();
		ctx.strokeStyle = '#0000AA';
		ctx.lineJoin = 'round';
		ctx.lineCap = 'round';
		ctx.lineWidth = 2;
	}

	draw(x, y) {
		const ctx = this.ctx();
		if (this.state.isDrawing) {
			ctx.beginPath();
			ctx.moveTo(this.state.lastX, this.state.lastY);
			ctx.lineTo(x, y);
			ctx.stroke();
			this.setState({
				lastX: x,
				lastY: y
			});
		}
	}

	handleEvent = name => e => {

		if (this.props.disabled) return;

		const event = e.nativeEvent;
		let x, y;

		if (event.touches && event.touches[0]) {
			// TouchEvents
			const touches = event.touches[0];
			const { offsetTop } = event.target;
			const { offsetLeft } = event.target;
			x = Math.round(touches.clientX - offsetLeft);
			y = Math.round(touches.clientY - offsetTop);
			//console.log('y', y)
		} else {
			// MouseEvents
			x = event.offsetX;
			y = event.offsetY;
		}

		if (x && y && name === this.down && !this.state.isDrawing) {
			this.setState({
				isDrawing: true,
				lastX: x,
				lastY: y
			});
		} else if (x && y && name === this.move) {
			this.draw(x, y);
		} else if (name === this.up && this.state.isDrawing) {
			this.setState({ isDrawing: false });
		} else if (name === this.out && this.state.isDrawing) {
			this.setState({ isDrawing: false });
		}
	}

	render() {
		return (
			<canvas
				ref={this.canvasUnterschrift}
				onMouseDown={this.handleEvent(this.down)}
				onMouseMove={this.handleEvent(this.move)}
				onMouseUp={this.handleEvent(this.up)}
				onMouseOut={this.handleEvent(this.out)}
				onTouchStart={this.handleEvent(this.down)}
				onTouchMove={this.handleEvent(this.move)}
				onTouchEnd={this.handleEvent(this.up)}
				onTouchCancel={this.handleEvent(this.out)}
				style={{
					border: '1px solid #aaa',
					backgroundColor: '#f3f3f3',
					borderRadius: '5px'
				}}
			/>
		)
	}
}

export default (CanvasUnterschrift);
