import React from "react";
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Hidden from '@material-ui/core/Hidden';
import PersonIcon from '@material-ui/icons/Person';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { withStyles } from '@material-ui/core';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import amber from '@material-ui/core/colors/amber';
//import { getCurrentFilename } from '../functions';

const ListViewSuchergebnisse = props => {

	if (!props.items.length) {
		//console.warn(`[${getCurrentFilename(__filename)}] Keine items vorhanden`);;
		return null;
	}
	let Icon, StyledAvatar;
	const OrangeAvatar = withStyles(theme => ({
		root: {
			backgroundColor: deepOrange[500],
			margin: theme.spacing(1)
		}
	}))(Avatar);
	const IndigoAvatar = withStyles(theme => ({
		root: {
			backgroundColor: indigo[500],
			margin: theme.spacing(1)
		}
	}))(Avatar);
	const AmberAvatar = withStyles(theme => ({
		root: {
			backgroundColor: amber[600],
			margin: theme.spacing(1)
		}
	}))(Avatar);
	const StyledListItemText = withStyles({
		primary: {
			textDecoration: 'line-through'
		}
	})(ListItemText);

	let MyListItemText;

	return (
		<List>
			{props.items.length ? props.items.map((suchergebnis, i) => {

				if (suchergebnis.position) {
					// Ansprechpartner
					Icon = PersonIcon;
					StyledAvatar = OrangeAvatar;
					MyListItemText = ListItemText;
				}
				else if (suchergebnis.name1) {
					// Adresse
					Icon = LocationCityIcon;
					StyledAvatar = IndigoAvatar;
					MyListItemText = suchergebnis.gesperrt === 'ja' ? StyledListItemText : ListItemText;
				}
				else if (suchergebnis.belegdatum) {
					// Beleg
					Icon = ReceiptIcon;
					StyledAvatar = AmberAvatar;
					MyListItemText = ListItemText;
				}
				else console.error('[ListViewSuchergebnisse] sehr komisch hier. suchergebnis = ', suchergebnis);

				const secondary = () => {
					if (suchergebnis.plz && suchergebnis.ort) return `${suchergebnis.plz} ${suchergebnis.ort}`;
					if (suchergebnis.belegdatum) return suchergebnis.belegdatum;
					return null;
				};

				return (
					<ListItem
						key={i}
						button
						divider={i + 1 !== props.items.length ? true : false}
						selected={props.selectedId === suchergebnis.id}
						onClick={() => props.writeUriAndSelectId(suchergebnis.id, suchergebnis)}
					>
						<StyledAvatar>
							<Icon />
						</StyledAvatar>

						<MyListItemText
							primary={suchergebnis.name1 || suchergebnis.belegart || suchergebnis.name}
							secondary={secondary()}
						/>

						<Hidden only={["xs", "md", "lg"]}>
							<ListItemSecondaryAction>
								<ListItemText primary={suchergebnis.betragNetto || null} />
							</ListItemSecondaryAction>
						</Hidden>
					</ListItem>
				)
			}
			) : null}
		</List>
	);
};

export default ListViewSuchergebnisse;