import Items from '.';
import { writeNullToKeys } from '../../functions';
import FetchArtikelStammdaten from './FetchArtikelStammdaten';
import FetchArtikelLieferBestaende from './FetchArtikelLieferBestaende';
import FetchArtikelBilder from './FetchArtikelBilder';
import FetchArtikelBild from './FetchArtikelBild';

class FetchArtikelDetails extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.id = id;
		if (!id || isNaN(id)) throw new TypeError(`[FetchArtikelDetails.js] id nicht vorhanden`);
		this.fetch = this.fetch.bind(this);
	}

	async fetch() {
		try {
			const promises = [
				new FetchArtikelStammdaten(this.props, this.signal, this.id).fetch(),
				new FetchArtikelLieferBestaende(this.props, this.signal, this.id).fetch(),
				new FetchArtikelBilder(this.props, this.signal, this.id).fetch()
			];
			const values = await Promise.all(promises);
			const [stammdaten, bestaende, bilder] = values;
			let obj = {
				stammdaten: writeNullToKeys(stammdaten),
				bestaende,
				bilder: []
			};

			if (bilder.length) {
				const bildPromises = [];
				for (let i = 0; i < bilder.length; i++)
					bildPromises.push(new FetchArtikelBild(this.props, this.signal, bilder[i].id).fetch());
				const bildValues = await Promise.all(bildPromises);
				obj.bilder = bildValues;
				return (obj);
			}

			// keine Bilder vorhanden
			return (obj);

		} catch (error) {
			console.log(57493540378);
			console.error(error);
		}
	};
}

export default FetchArtikelDetails;
