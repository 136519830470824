import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const ListViewEinstellungen = props => {

  return (<List>
    {props.items ? props.items.map((einstellung, i) => (<ListItem
      key={i}
      button
      divider={i + 1 !== props.items.length ? true : false}
      selected={props.selectedId === einstellung.id}
      onClick={() => props.writeUriAndSelectId(einstellung.id)}
    >
      <ListItemText
        primary={einstellung.title}
        secondary={`Platzhalter für Kurzansicht der Einstellung`}
      />
    </ListItem>)) : null}
  </List>);
};

export default ListViewEinstellungen;