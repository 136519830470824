import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import FetchArtikelDetails from '../classes/items/FetchArtikelDetails';
import detailViewArtikel from './detailViewArtikel';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Divider } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
	root: {
		padding: '16px 8px 8px 8px'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	appBar: {
		position: 'relative'
	}
});

class FullscreenPositionModal extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;

		this.state = {
			isFetching: true,
			artikel: null,
			detailViewContent: null
		};
		//#region
		this.fetchArtikelDetails = this.fetchArtikelDetails.bind(this);
		//#endregion
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.artId && !prevState.artikel) {
			if (!prevState.isFetching) this.setState({ isFetching: true });
			this.fetchArtikelDetails();
		}
		if (prevProps.open && !this.props.open) {
			// Modal wurde geschlossen
			this.setState({
				artikel: null,
				detailViewContent: null
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	async fetchArtikelDetails() {
		const { artId } = this.props;
		if (!artId) {
			console.warn('[FullscreenPositionModal] keine artId')
			this.setState({ isFetching: false });
			return;
		}
		const artikel = await new FetchArtikelDetails(this.props.screenprops, this.controller.signal, artId).fetch();
		this.setState({
			isFetching: false,
			artikel,
			detailViewContent: detailViewArtikel({ dataItem: artikel })
		});
	}

	render() {
		const
			{ classes } = this.props,
			stammdaten = this.state.artikel ? this.state.artikel.stammdaten : {};

		const detailView = () => {
			const { detailViewContent } = this.state;
			if (!detailViewContent) return;
			return detailViewContent[0].Funktion();
		}

		const pos = () => {
			const { simulation } = this.props;
			const { artikel } = this.state;
			if (!simulation || !artikel) return {};
			const { positionen } = simulation;
			const p = {};
			for (let i = 0; i < positionen.length; i++) {
				const __pos = positionen[i];
				if (__pos.artikelId === artikel.stammdaten.id) {
					p.positionsNr = __pos.position;
					p.menge = __pos.menge;
					p.rabatt1 = __pos.rabatt1;
					p.rabatt2 = __pos.rabatt2;
					p.gesamtpreis = __pos.gesamtpreis;
					return p;
				}
			}
		};

		const _pos = pos();

		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				open={this.props.open}
				fullScreen
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={this.props.closeModal} aria-label="Schließen"><ArrowBackIcon /></IconButton>
						<Typography variant="h6" className={classes.title}>{stammdaten.bezeichnung}</Typography>
					</Toolbar>
				</AppBar>

				{this.state.isFetching && <LinearProgress color="secondary" className="global_progress_bar" />}

				<DialogContent className={classes.root}>

					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Card>
								<CardHeader title="Info zu dieser Position" />

								<Divider />

								<CardContent>
									<Table>
										<TableBody>
											<TableRow hover>
												<TableCell component="th" variant="head" scope="row">Positionsnummer</TableCell>
												<TableCell align="right">{_pos.positionsNr}</TableCell>
											</TableRow>
											<TableRow hover>
												<TableCell component="th" variant="head" scope="row">Menge</TableCell>
												<TableCell align="right">{_pos.menge}</TableCell>
											</TableRow>
											<TableRow hover>
												<TableCell component="th" variant="head" scope="row">Rabatt 1</TableCell>
												<TableCell align="right">{_pos.rabatt1}</TableCell>
											</TableRow>
											<TableRow hover>
												<TableCell component="th" variant="head" scope="row">Rabatt 2</TableCell>
												<TableCell align="right">{_pos.rabatt2}</TableCell>
											</TableRow>
											<TableRow hover>
												<TableCell component="th" variant="head" scope="row">Gesamtpreis</TableCell>
												<TableCell align="right">{_pos.gesamtpreis}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Grid>
					</Grid>


					{detailView()}
				</DialogContent>
			</Dialog>
		);
	}
}

FullscreenPositionModal.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullscreenPositionModal);
