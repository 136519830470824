import Items from '.';
import { getCurrentFilename, semiCamelCase, writeNullToKeys } from '../../functions';
import FetchAssetDetails from './FetchAssetDetails';
import FetchAdresseDetails from './FetchAdresseDetails';
import store from '../../redux-service';
import { insertPosition } from '../../redux-service/actions';

class FetchServiceauftragDetails extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[${getCurrentFilename(__filename)}] belegId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.belegId = id;
		this.path = {
			string: `service/auftraege/${id}`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = async () => {
		const name = 'Serviceauftrag Details';
		const camelName = semiCamelCase(name);

		try {
			const json = await writeNullToKeys(super.fetchDbOrServerGet(name, this.path));
			const obj = {
				serviceauftrag: json.path && json.results && json.results[camelName] && json,
				asset: null,
				adresse: null
			};

			if (!json.path || !json.results || !json.results[camelName]) return [];
			if (json.results[camelName].assetId) {
				obj.asset = await new FetchAssetDetails(this.props, this.signal, json.results[camelName].assetId).fetch();
			}
			if (json.results[camelName].adresseId) {
				obj.adresse = (await new FetchAdresseDetails(this.props, this.signal, json.results[camelName].adresseId).fetch()).adresse;
			}
			const { positionen } = obj.serviceauftrag.results.serviceauftragDetails;
			positionen && positionen.forEach(position => {
				store.dispatch(insertPosition(position));
			});
			return obj;
		} catch(err) {
			console.error(err);
			console.log(943857823684638);
			return err;
		}
	};
}

export default FetchServiceauftragDetails;
