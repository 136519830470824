import React from 'react';
import Dexie from 'dexie';
import $ from '../config';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import parseRTF from 'rtf-parser';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Banner from '../containers/Banner';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Version from '../trash/version/index.txt';
import VersionDate from '../trash/version/date.txt';
import { MenuItem } from '@material-ui/core';

const dummyPromise = () => new Promise((resolve, reject) => resolve(null));

export const getBelegartFromCode = Dexie.async(function* (db, belegartCode) {
	try {
		const belegartenArr = yield db[$.tabBelegarten]
			.where('belegart')
			.equals(belegartCode.toString())
			.toArray();
		let text = '';
		if (belegartenArr[0]) text = belegartenArr[0].anzeige;
		return text;
	} catch (e) {
		console.log(e);
	}
});

export const getBelegtyptextFromCode = Dexie.async(function* (db, belegtyp) {
	try {
		const belegartenArr = yield db[$.tabBelegarten]
			.where('belegtyp')
			.equals(belegtyp.toString())
			.toArray();
		let text = '';
		if (belegartenArr[0]) text = belegartenArr[0].anzeige;
		return text;
	} catch (e) {
		console.log(e);
	}
});

export const makeTelLink = nummer => {
	if (!nummer) {
		console.warn('[makeTelLink()] keine Nummer')
		return '';
	}
	return nummer.toString().replace(/[\D\s]/g, ''); // Leerzeichen und nicht-Zahlen raus
}

export const makeWebsiteLink = str => {
	if (str.includes('http://') || str.includes('https://')) return str;
	return `http://${str}`;
};

export const routenPlanerZuAdresse = adresse => `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(adresse)}`;

export const makeId = () => {
	let text = '';
	const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	for (var i = 0; i < 20; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
};

export const getDbBelegarten = Dexie.async(function* (db) {
	try {
		const belegartenArr = yield db[$.tabBelegarten]
			.where('istApiaktiv')
			.equals(1)
			.toArray();
		return belegartenArr;
	} catch (e) {
		console.log(e);
	}
});

export const initComponent = (screenProps, vonWo) => {
	dbIsNotComplete(screenProps).then(dbNotComplete => {
		if (dbNotComplete) {
			console.warn(`[initComponent() => ${vonWo}] Datenbank nicht vollständig => logout() aufgerufen`);
			clearAll(screenProps).then(success => {
				logout(screenProps);
			});
		} else {
			if (window.console_InfoOutput) console.info('Datenbank vollständig.')
			pruefeEingeloggt(screenProps);
		}
	});
};

export async function formatAktivitaetenListe(db, aktivitaeten) {

	if (!aktivitaeten.length) return [];

	const promisesRTF = [], promisesMitarbeitername = [];
	let i;
	for (i = 0; i < aktivitaeten.length; i++) {
		promisesRTF.push(convertRTF(aktivitaeten[i].beschreibung));
		promisesMitarbeitername.push(getMitarbeiterNameVonId(db, aktivitaeten[i].benutzerId));
	}

	const RTFs = await Promise.all(promisesRTF);
	const mitarbeiterNamen = await Promise.all(promisesMitarbeitername);

	for (i = 0; i < aktivitaeten.length; i++) {
		aktivitaeten[i].art = $.aktArten[aktivitaeten[i].art];
		aktivitaeten[i].datum = formatDatum(aktivitaeten[i].datum);
		aktivitaeten[i].beschreibung = RTFs[i];
		aktivitaeten[i].benutzer = mitarbeiterNamen[i];
	}
	return aktivitaeten;
}

export const aktArtenSelect = (value, handleChange, changeArg) => {
	const options = [], vals = [], keyMap = new Map();

	Object.keys($.aktArten).forEach(key => {
		const aktArt = $.aktArten[key];
		if (aktArt.includes('DEALS')) return;
		// um zu sortieren: in Array klatschen und sich die keys merken
		keyMap.set(aktArt, key);
		vals.push(aktArt);
	});

	vals.sort();
	for (let k = 0; k < vals.length; k++) {
		const val = vals[k], _key = keyMap.get(val);
		options.push(<MenuItem key={k} value={_key}>{val}</MenuItem>);
	}

	return (
		<>
			<InputLabel htmlFor="aktArtenSelect">Art</InputLabel>
			<Select
				input={<Input id="aktArtenSelect" />}
				value={value}
				onChange={handleChange(changeArg)}
			>
				<MenuItem value="" />
				{options}
			</Select>
		</>
	);
};

export const ansPartnerSelect = (value, handleChange, changeArg, ansprechpartners) => {
	const options = [];

	for (let i = 0; i < ansprechpartners.length; i++) {
		const ans = ansprechpartners[i];
		let str = ans.anrede ? `${ans.anrede} ${ans.name}` : ans.name;
		if (str === '') continue;
		options.push(<MenuItem key={i} value={ans.id}>{str}</MenuItem>);
	}

	return (
		<>
			<InputLabel htmlFor="ansPartnerSelect">Ansprechpartner</InputLabel>
			<Select
				input={<Input id="ansPartnerSelect" />}
				value={value}
				onChange={handleChange(changeArg)}
			>
				{options}
			</Select>
		</>
	);
};

export async function formatBelegeListe(db, belege) {

	if (!belege || !belege.length) return [];

	const promises = [];
	let i;
	for (i = 0; i < belege.length; i++) promises.push(getBelegartFromCode(db, belege[i].belegart));

	const belegarten = await Promise.all(promises);

	for (i = 0; i < belege.length; i++) {
		belege[i].belegart = belegarten[i];
		belege[i].betragNetto = formatPreis(belege[i].betragNetto);
		belege[i].belegdatum = formatDatum(belege[i].belegdatum);
		belege[i].belegstatusCode = belege[i].belegstatus;
		belege[i].belegstatus = $.belegstatus[belege[i].belegstatus];
	}
	return belege;
}

export async function formatOPListe(db, offenePosten) {

	if (!offenePosten || !offenePosten.length) return [];

	const promises = [];
	let i;
	for (i = 0; i < offenePosten.length; i++) promises.push(getBelegartFromCode(db, offenePosten[i].rechnungsart));

	const belegarten = await Promise.all(promises);

	for (i = 0; i < offenePosten.length; i++) {
		offenePosten[i].rechnungsart = belegarten[i];
		offenePosten[i].tageUeberfaellig = getTageBis(offenePosten[i].nettoFaellig) * -1;
		offenePosten[i].rechnungsdatum = formatDatum(offenePosten[i].rechnungsdatum);
		offenePosten[i].nettoFaellig = formatDatum(offenePosten[i].nettoFaellig);
		offenePosten[i].offenerBetrag = formatPreis(offenePosten[i].offenerBetrag);
	}
	return offenePosten;
}

export async function formatDealsListe(db, deals) {

	if (!deals || !deals.length) return [];

	const benPromises = [], notizPromises = [];
	let i;
	for (i = 0; i < deals.length; i++) {
		benPromises.push(getMitarbeiterNameVonKuerzel(db, deals[i].zustaendigerBenutzer));
		if (deals[i].notiz) notizPromises.push(convertRTF(deals[i].notiz));
		else notizPromises.push(dummyPromise());
	}

	const benutzer = await Promise.all(benPromises);
	const notizen = await Promise.all(notizPromises);

	for (i = 0; i < deals.length; i++) {
		deals[i].zustaendigerBenutzer = benutzer[i];
		deals[i].notiz = notizen[i];
		deals[i].typ = $.dealTypen[deals[i].typ];
		deals[i].status_text = $.dealStatus[deals[i].status];
		deals[i].phase_text = $.dealPhasen[deals[i].phase];
		deals[i].investitionsdatumErreicht = formatDatum(deals[i].investitionsdatumErreicht);
		deals[i].investitionsdatumGeplant = formatDatum(deals[i].investitionsdatumGeplant);
		deals[i].investitionssummeErreicht = formatPreis(deals[i].investitionssummeErreicht);
		deals[i].investitionssummeGeplant = formatPreis(deals[i].investitionssummeGeplant);
		deals[i].angelegtDatum = formatDatum(deals[i].angelegtDatum);
	}
	return deals;
}

export const getSuchergebnisTypFromUrl = path => {
	if (path.match(new RegExp(`\\/${$.adressenNode}\\/\\d+$`))) return $.adressenNode;
	if (path.match(new RegExp(`\\/${$.adressenNode}\\/\\d+\\/${$.ansprechpartnersNode}\\/\\d+$`))) return $.ansprechpartnersNode;
	if (path.match(new RegExp(`\\/${$.belegeNode}\\/\\d+$`))) return $.belegeNode
	return null;
};

export const adressenOrDealsDetails = path => {

	let match = path.match(new RegExp(`\\/${$.adressenNode}\\/(\\d+)$`));

	if (match && match.length) {
		return {
			bereich: $.adressenNode,
			adrId: parseInt(match[1])
		};
	};

	match = path.match(new RegExp(`\\/${$.dealsNode}\\/(\\d+)$`));

	if (match) {
		return {
			bereich: $.dealsNode,
			adrId: null
		};
	};

	return null;
};

export const getDefaultFilters = itemsnode => {
	let strArr = [];
	const defaultFiltersArr = $.defaultFilters[itemsnode];
	for (let i = 0; i < defaultFiltersArr.length; i++) {
		const filter = defaultFiltersArr[i];
		if (filter.value !== '') strArr.push(`${filter.key}=${filter.value}`);
	}
	return strArr.join(',');
};

export const isDetailView = search => search.includes('details');

export const isAdrSearchView = search => search.includes('adrSearch');

export const getBelegartFromURI = search => {
	const match = search.match(/belegart=(\d+)$/);
	if (match && match.length) return match[1];
	return null;
};

export const semiCamelCase = itemsName => itemsName
	.split(' ')
	.map((item, idx) => (idx ? item.charAt(0).toUpperCase() : item.charAt(0).toLowerCase()) + item.substring(1))
	.join('')

export const getSuchergebnisAdrIdFromUrl = path => {
	const match = path.match(new RegExp(`\\/${$.adressenNode}\\/(\\d+)\\/${$.ansprechpartnersNode}\\/\\d+$`));
	return match ? match[1] : null;
};

export const istSuchergebnisFromUrl = path => path.includes(`/app/${$.suchergebnisseNode}`);

export const getVersion = () => `${Version.match(/v[^-]+/)}-${VersionDate}`;

export const getIdFromUrl = path => {
	const match = path.match(/\/(\d+)$/);
	return match ? match[1] : null;
};

export const containsInt = str => str.match(/\d+/);

export const getBereichFromUrl = path => {
	const match = path.match(/\/(\w+)\/\d+$/);
	return match ? match[1] : null;
};

export const pageLoadedByUrl = props => props.history.action === 'POP'

export const getCurrentFilename = filename => filename.toString().match(/([^\\]+)$/)[0]

export const tabInUrl = search => {
	const match = search.match(/tab=(\d+)/);
	return match ? parseInt(match[1]) : null;
};

export const uniqueHashCode = str =>
	str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);

export const getWriteFetchObj = (signal, method, sendData) =>
	({
		method,
		body: JSON.stringify(sendData),
		headers: {
			Authorization: `Bearer ${getBearerToken()}`,
			'Content-Type': 'application/json',
			signal
		}
	});

export const writeNullToKeys = obj => {
	if (!obj) return;
	if (obj instanceof Array) { 	// obj ist Array
		for (const [key, value] of obj.entries()) {
			obj[key] = writeNullToKeys(value);
		}
		return obj;
	} else {
		for (const [key, value] of Object.entries(obj)) {	// obj ist Objekt
			if (typeof value === 'boolean') { // value ist Boolean
				obj[key] = value ? 'ja' : 'nein';
			} else if (!(value instanceof Object)) { // value ist String
				obj[key] = (value === 0 || value) ? value : '—';
			} else {
				// value ist Objekt oder Array: sich selbst aufrufen mit Unterscheidung,
				// weil React-readonly-Objekte bei key === 'chance' dabei sind
				if (key !== 'chance') obj[key] = writeNullToKeys(value);
			}
		}
		return obj;
	}
};

const checkRESTserverOnline = signal => new Promise((resolve, reject) => {
	fetch(`${window.RESTserver}/vario-api/login`, {
		method: 'OPTIONS',
		signal
	})
		.then(response => {
			throw new Error(response.status);
		})
		.catch(error => {
			if (error.message === 'Failed to fetch') {
				resolve(false);
				return;
			}
			if (parseInt(error.message) === 200) {
				resolve(true);
			}
		});
});

export const setSettingsChildren = (stateSettings, settingName, value) => {
	const setting = {
		[settingName]: value
	};
	const newSettings = [];
	for (let i = 0; i < stateSettings.length; i++) {
		const existingSetting = stateSettings[i];
		if (!existingSetting.hasOwnProperty(settingName)) {
			// diese Einstellung gibt es noch nicht: alte hinzufügen!
			newSettings.push(existingSetting);
		}
	}
	newSettings.push(setting);
	return newSettings;
};

export const getSettingsChildren = (stateSettings, settingName) => {
	for (let i = 0; i < stateSettings.length; i++) {
		const setting = stateSettings[i];
		if (setting.hasOwnProperty(settingName))
			return setting[settingName];
	}
	return null;
};

export const grabArrFromObjectArray = (array, key) => {
	const innerArray = [];
	for (let i = 0; i < array.length; i++) {
		const element = array[i];
		innerArray.push(element[key]);
	}
	return innerArray;
};

export const checkOnline = signal => new Promise((resolve, reject) => {

	if (!navigator.onLine) {
		document.title = $.titleOffline;
		resolve({
			online: false,
			RESTserverOnline: false
		});
		return;
	}

	// wir sind online

	document.title = $.titleOnlineRESTOffline;

	checkRESTserverOnline(signal).then(RESTserverOnline => {
		if (RESTserverOnline) document.title = $.titleOnline;
		else document.title = $.titleOnlineRESTOffline;
		resolve({
			online: true,
			RESTserverOnline
		});
		return;
	});
});

const pruefeEingeloggt = screenProps => {
	/** Verhindern, dass logout() zweimal aufgerufen wird durch initComponent() */
	const logoutModus = sessionStorage.getItem($.logoutModusName);
	if (logoutModus) return;
	sessionStorage.setItem($.logoutModusName, true);
	/** Erst nur eine primitive Prüfung, ob Bearertoken ein String mit Länge > 0 ist.
	 *  Ein OPTIONS-Call auf /login würde mehr Zeit und Traffic kosten. Man kommt sowieso nicht weit
	 *  ohne Authentifizierung bei den REST-Calls. */
	const bearerToken = getBearerToken();
	if (typeof bearerToken !== 'string' || bearerToken === '') {
		console.info('[pruefeEingeloggt()] kein Bearertoken vorhanden => logout()')
		logout(screenProps);
	} else if (screenProps.history && screenProps.match.path === '/') {
		// umleiten zu Dashboard, wenn wir auf dem Loginscreen sind
		if (window.console_InfoOutput) console.info('[pruefeEingeloggt()] hat zum Dashboard umgeleitet.')
		screenProps.history.replace('/app/dashboard');
	}
};

export const convertRTF = rtf => new Promise((resolve, reject) => {
	rtf = rtf.replace(/\\ansicpg0/, ''); // Unsinn entfernen
	parseRTF.string(rtf, (err, doc) => {
		if (err) {
			//console.log('RTF: ', rtf)
			//console.error(err);
			//reject(err);
			resolve('Fehler im RTF.');
		} else if (doc && doc.content[0] && doc.content[0].content) {
			const { content } = doc.content[0];
			const strArr = [];
			for (let i = 0; i < content.length; i++) {
				const el = content[i];
				const str = el.value;
				const res = /([a-z0-9äöüß])/i.exec(str);
				if (res) strArr.push(str);
			}
			resolve(strArr.join(''));
		} else {
			resolve(rtf);
		}
	});
});

export const screenPropsOK = screenProps => {
	if (!screenProps) {
		console.error('[screenPropsOK()] Kein screenProps-Objekt!')
		return false;
	} else if (!screenProps.db) {
		console.error('[screenPropsOK()] Kein db-Objekt!')
		return false;
	} else if (!screenProps.history) {
		console.error('[screenPropsOK()] Kein history-Objekt!')
		return false;
	}
	return true;
};

export const logout = screenProps => {
	const loginSeite = '/';
	if (!screenPropsOK(screenProps)) return;
	clearCache(screenProps.db).then(cacheCleared => {
		if (screenProps.history) screenProps.history.replace(loginSeite);
		else document.location.href = loginSeite;
	});
};

export const deleteRowsFromCache = async (db, search) => {
	const primaryKeys = [], promises = [];
	await db[$.tabCache].each((row, cursor) => {
		const { primaryKey } = cursor;
		if (primaryKey.includes(search)) primaryKeys.push(primaryKey);
	});
	for (let i = 0; i < primaryKeys.length; i++) promises.push(db[$.tabCache].delete(primaryKeys[i]));
	await Promise.all(promises);
	return true;
};

export const clearCache = (db = null) => new Promise((resolve, reject) => {
	sessionStorage.clear();
	if (db) {
		db[$.tabCache].clear();
		resolve(true);
		if (window.console_InfoOutput) console.info('Cache geleert.');
		return;
	}
	console.error('Cache leeren schlug fehl. Kein db-Handle vorhanden.')
	resolve(false);
});

const clearAll = screenProps => new Promise((resolve, reject) => {
	if (!screenProps || !screenProps.db) {
		console.error('Keine screenProps oder kein db-Handle!')
		return;
	}
	const tablePromise = [];
	screenProps.db.tables.forEach(table => {
		tablePromise.push(table.clear());
	});
	Promise.all(tablePromise).then(() => {
		resolve(true);
		if (window.console_InfoOutput) console.log('Alle Tabellen geleert')
		return;
	});
	//console.info('Fehler beim Leeren der Tabellen.')
	resolve(false);
});

export const getBearerToken = () => sessionStorage.getItem($.bearerTokenName);

export const getBenutzername = () => sessionStorage.getItem($.benutzernameName);

export const getBenutzerId = () => parseInt(sessionStorage.getItem($.benutzerIdName));

export const getKuerzel = () => sessionStorage.getItem($.benutzerKuerzelName);

const dbIsNotComplete = screenProps => new Promise((resolve, reject) => {
	const { db } = screenProps;
	if (!db) {
		console.error('[dbIsNotComplete()] logout() aufgerufen, weil db-Objekt nicht vorhanden');
		logout(screenProps);
	}
	const tableCount = [], tabletable = [],
		excludedTables = [
			...$.interneTabellen,
			$.tabServiceAbrechnungsarten,
			$.tabServiceAuftragsarten,
			$.tabTechniker
		];
	// Diese Tabellen werden nicht auf null Zeilen geprüft
	db.tables.forEach(table => {
		tableCount.push(table.count());
	});
	Promise.all(tableCount).then(countArr => {
		let nullRows = false;
		db.tables.forEach((table, index) => {
			const numRows = countArr[index];
			if (!excludedTables.includes(table.name)) {
				tabletable.push({ Name: table.name, Zeilen: numRows });
				if (numRows === 0) nullRows = true;
			}
		});
		if (nullRows) {
			console.table(tabletable);
			resolve(true);
			return;
		}
		resolve(false);
	});
});

export const chance = (chance, classesObj) => {
	if (chance === 20)
		return (
			<>
				<StarIcon color="primary" />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
			</>
		);
	if (chance === 40)
		return (
			<>
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
			</>
		);
	if (chance === 60)
		return (
			<>
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
			</>
		);
	if (chance === 80)
		return (
			<>
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarBorderIcon color="primary" classes={{ root: classesObj }} />
			</>
		);
	if (chance === 100)
		return (
			<>
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarIcon color="primary" />
				<StarIcon color="primary" />
			</>
		);
};

export function formatPreis(preis, nurKomma = false) {
	if (!preis || preis === 'null') return '—';
	const preisStr = preis.toLocaleString('de-DE', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
	if (nurKomma) return preisStr;
	return `${preisStr} €`;
}

export function addDigits(num, digits) {
	if (!num || num === 'null') return '—';
	return num.toLocaleString('de-DE', {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits
	});
}

export const str2float = str => parseFloat(str.toString().replace('.', '').replace(',', '.'));

export const getDeutschesDatum = (mitZeit = false) => {
	const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	};
	if (mitZeit) {
		options.hour = '2-digit';
		options.minute = '2-digit';
		options.second = '2-digit';
	}
	return new Date().toLocaleString('de-DE', options);
};

export const getTageBis = dateStr => {
	let time = new Date(dateStr).getTime();
	if (isNaN(time)) time = new Date(deutsch2VarioDateString(dateStr)).getTime();
	return Math.round((time - new Date().getTime()) / 1000 / 86400);
};

export const deutsch2VarioDateString = dateStr => {
	dateStr = dateStr.toString();
	return `${dateStr.substr(6, 4)}-${dateStr.substr(3, 2)}-${dateStr.substr(0, 2)}T00:00:00.000+02:00`;
}

export const keineBlaVorhanden = (item, plural) =>
	<Banner icon={<InfoOutlinedIcon />}>Kein{plural && 'e'} {item} vorhanden</Banner>;

export const getEmptyPostFetchObj = signal => {
	return {
		signal,
		method: 'POST',
		headers: {
			Authorization: `Bearer ${getBearerToken()}`
		}
	};
};

const getSqlDatum = dateStr => new Date(dateStr).toISOString().slice(0, 10);

export const deutsch2js = dateStr => {
	if (dateStr === '—') return null;
	dateStr = dateStr.toString();
	const tag = dateStr.substring(0, 2);
	const monat = dateStr.substring(3, 5);
	const jahr = dateStr.substring(6);
	return new Date(`${jahr}-${monat}-${tag}`);
};

export const js2deutsch = date => {
	const options = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	};
	return new Date(date).toLocaleDateString('de-DE', options);
};

export const formatDatum = dateStr => {
	if (!dateStr) return null;
	const tag = dateStr.substring(8, 10);
	const monat = dateStr.substring(5, 7);
	const jahr = dateStr.substring(0, 4);
	const stunde = dateStr.substring(11, 13);
	const minute = dateStr.substring(14, 16);
	if (stunde === '00') return `${tag}.${monat}.${jahr}`;
	return `${tag}.${monat}.${jahr} ${stunde}:${minute}`;
};

export const getOPDatum = () => {
	let OPVon, OPBis;
	const jetzt = new Date();
	// heute eintragen
	OPBis = getSqlDatum(jetzt);
	const vorher = new Date();
	// "von" eintragen
	vorher.setDate(vorher.getDate() - 365);
	OPVon = getSqlDatum(vorher);
	return {
		OPVon,
		OPBis
	}
};

export const objWithMaps2objWithObjects = obj => {

	const map2Obj = map => {
		const _obj = {};
		for (let [k, v] of map) _obj[k] = v;
		return _obj;
	};

	const _objWithObjects = {};
	for (const key in obj) {
		const map = obj[key];
		_objWithObjects[key] = map2Obj(map);
	}

	//console.log(`[objWithMaps2objWithObjects()] obj=`, obj);
	//console.log(`[objWithMaps2objWithObjects()] _objWithObjects=`, _objWithObjects);

	return _objWithObjects;
};

export const isValidJSON = json => {
	try {
		JSON.parse(json);
	} catch (e) {
		return false;
	}
	return true;
}

export const getMitarbeiterNameVonKuerzel = Dexie.async(function* (db, kuerzel) {
	try {
		const benutzer = yield db[$.tabBenutzer]
			.where('kuerzel')
			.equals(kuerzel)
			.toArray();
		if (!benutzer[0]) return;
		return benutzer[0].name;
	} catch (e) {
		console.log(e);
	}
});

export const getMitarbeiterNameVonId = Dexie.async(function* (db, id) {
	try {
		const benutzer = yield db[$.tabBenutzer]
			.where('id')
			.equals(id)
			.toArray();
		if (!benutzer[0]) return;
		return benutzer[0].name;
	} catch (e) {
		console.log(e);
	}
});

export const getLieferbedingungen = Dexie.async(function* (db) {
	try {
		const lieferbedingungen = yield db[$.tabLieferbedingungen]
			.where('gesperrt')
			.equals(0)
			.toArray();
		const lieferarten = [];
		for (let i = 0; i < lieferbedingungen.length; i++) {
			const { lieferart } = lieferbedingungen[i];
			if (lieferart) lieferarten.push(lieferart);
		}
		return lieferarten.sort();
	} catch (e) {
		console.log(e);
	}
});

export const getZahlungsarten = Dexie.async(function* (db) {
	try {
		const zahlungsarten = yield db[$.tabZahlungsarten]
			.where('gesperrt')
			.equals(0)
			.toArray();
		const _zahlungsarten = [];
		for (let i = 0; i < zahlungsarten.length; i++) {
			const { zahlungsart } = zahlungsarten[i];
			if (zahlungsart) _zahlungsarten.push(zahlungsart);
		}
		return _zahlungsarten.sort();
	} catch (e) {
		console.log(e);
	}
});

export const getLagerkreis = Dexie.async(function* (db, id) {
	try {
		const lagerkreisArr = yield db[$.tabLagerkreise]
			.where('id')
			.equals(id)
			.toArray();
		return lagerkreisArr[0].name;
	} catch (e) {
		console.log(e);
	}
});

export const removeDuplicates = (arr) => {
	const s = new Set(arr);
	const it = s.values();
	return Array.from(it);
}

export const setDbSuchergebnisse = (db, suchbegriff, obj) => {
	Dexie.spawn(function* () {
		try {
			yield db[$.tabSuchergebnisse].put({
				id: 1,
				suchbegriff,
				results: obj
			});
		} catch (e) {
			console.error(e);
		}
	});
}

export const getDbSuchergebnisse = db => {
	return new Promise((resolve, reject) => {
		Dexie.spawn(function* () {
			try {
				const suchergebnisse = yield db[$.tabSuchergebnisse].toArray();
				resolve(suchergebnisse);
			} catch (e) {
				reject(e);
				console.error(e);
			}
		});
	});
}
