import Items from '.';
import { writeNullToKeys, semiCamelCase } from '../../functions';
import store from '../../redux-service';
import { insertTask } from '../../redux-service/actions';

class FetchServiceauftraege extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.path = {
			string: props.listingPath,
			id: null
		};
		this.fetch = this.fetch.bind(this);
	}

	fetch = async () => {

		const name = 'Serviceauftraege';
		const camelName = semiCamelCase(name);

		try {
			const json = await super.fetchDbOrServerGet(name, this.path, true);

			if (!json.path || !json.results || !json.results[camelName]) return [];

			const obj = {
				totalResultCount: json.results.totalResultCount,
				serviceauftraege: writeNullToKeys(json.results.serviceauftraege)
			};
			/**
			 * Für jeden gefetchten Serviceauftrag
			 */
			obj.serviceauftraege.forEach(serviceauftrag => {
				try {
					store.dispatch(insertTask(serviceauftrag));
				} catch(e) {
					console.log(serviceauftrag);
					console.error(e);
				}
			});
			return obj;

		} catch (error) {
			console.log(6436347);
			console.error(error);
			return error;
		}
	};
}

export default FetchServiceauftraege;
