import React from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardAdresseKlein from './CardAdresseKlein';
import $ from '../config';
import { keineBlaVorhanden } from '../functions';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import brown from '@material-ui/core/colors/brown';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const detailViewOffenerPosten = props => {

	const styles = theme => ({
		CardActionArea: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: '#eee',
			fontSize: '1.4rem',
			height: '100%',
			display: 'grid'
		},
		CardActionAreaHeader: {
			fontSize: '0.7rem',
			textTransform: 'uppercase'
		},
		card: {
			marginBottom: '8px'
		},
		avatar: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	});

	const StyledCard = withStyles({
		root: {
			marginBottom: '8px'
		}
	})(Card);

	const OffenerPosten = () => {
		const op = props.dataItem;
		if (!op) return null;

		const adressen = [{
			adresse: {
				id: op.adressnummer,
				name1: op.adresse.name1,
				strasse: op.adresse.strasse,
				plz: op.adresse.plz,
				ort: op.adresse.ort
			}
		}];

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(7354889283);
				return null;
			}
			const newPath = `/app/${$.adressenNode}/${id}?details`;
			props.dataItem.screenProps.history.push(newPath);
		};

		//console.log(op);

		const
			abgeschlossen = 'Abgeschlossen',
			status = op.abgeschlossen === 'ja' ? abgeschlossen : op.inKlaerung === 'ja' ? 'In Klärung' : 'Offen',
			opvArt = op.opvArt === 'O' ? 'Offener Posten' : 'Verbindlichkeit',
			left = { item: true, xs: 6, sm: 4 },
			right = { item: true, xs: 6, sm: 8 },
			gridProps = { item: true, xs: 12, sm: 6, md: 6, lg: 4 };

		const colorCard = (header, body, bgColor, classes) => (
			<Grid {...gridProps}>
				<Card style={{ height: '100%' }}>
					<CardActionArea style={{ backgroundColor: bgColor }} className={classes.CardActionArea}>
						<div className={classes.CardActionAreaHeader}>{header}</div>
						{body}
					</CardActionArea>
				</Card>
			</Grid>
		);

		const jsx = props => (
			<>
				<Card className={props.classes.card}>
					<CardContent>

						<Grid container spacing={1}>
							{colorCard('Art', opvArt, deepPurple[500], props.classes)}
							{colorCard('Rechnungsart', op.rechnungsart, brown[500], props.classes)}
							{colorCard('Rechnungsnummer', op.rechnungsnummer, blueGrey[500], props.classes)}
							{colorCard('Rechnungsdatum', op.rechnungsdatum, green[500], props.classes)}
							{colorCard('Offener Betrag', op.offenerBetrag, red[500], props.classes)}
							{colorCard('Status', status, purple[500], props.classes)}
						</Grid>

						<Grid container spacing={1} style={{ marginTop: '16px' }}>
							<Grid {...left}>Bisher gezahlt</Grid>
							<Grid {...right}>{op.bisherGezahlt}</Grid>
							<Grid {...left}>Bezahlt am</Grid>
							<Grid {...right}>{op.bezahltAm}</Grid>
							<Grid {...left}>Valuta</Grid>
							<Grid {...right}>{op.valuta}</Grid>
							<Grid {...left}>Nächste Fälligkeit</Grid>
							<Grid {...right}>{op.naechsteFaelligkeit}</Grid>
							<Grid {...left}>OP-Nr.</Grid>
							<Grid {...right}>{op.id}</Grid>
							<Grid {...left}>Bemerkung</Grid>
							<Grid {...right}>{op.bemerkung}</Grid>
							<Grid {...left}>Zahlungsart</Grid>
							<Grid {...right}>{op.zahlungsart}</Grid>
							<Grid {...left}>Verrechnungsart</Grid>
							<Grid {...right}>{op.verrechnungsart}</Grid>
						</Grid>

					</CardContent>
				</Card>

				<CardAdresseKlein
					adressen={adressen}
					handleClick={handleClick}
					StyledCard={StyledCard}
				/>

				<Card className={props.classes.card}>
					<CardContent>
						<Typography variant="h5">Mahnwesen</Typography>
						<Grid container spacing={1} style={{ marginTop: '16px' }}>
							<Grid {...left}>Mahnen</Grid>
							<Grid {...right}>{op.mahnen}</Grid>
							<Grid {...left}>Mahnstufe</Grid>
							<Grid {...right}>{op.mahnstufe}</Grid>
							<Grid {...left}>Mahnzinsen %</Grid>
							<Grid {...right}>{op.zinsenProzent}</Grid>
							<Grid {...left}>Mahnzinsen</Grid>
							<Grid {...right}>{op.zinsenBetrag}</Grid>
							<Grid {...left}>Mahngebühr</Grid>
							<Grid {...right}>{op.gebuehr}</Grid>
							<Grid {...left}>Nächste Fälligkeit</Grid>
							<Grid {...right}>{op.naechsteFaelligkeit}</Grid>
							<Grid {...left}>Nächste Mahnung</Grid>
							<Grid {...right}>{op.naechsteMahnung}</Grid>
						</Grid>

						<Table style={{ marginTop: '16px' }}>
							<TableHead>
								<TableRow>
									<TableCell>Mahnstufe</TableCell>
									<TableCell align="right">Datum</TableCell>
									<TableCell align="right">Mahnbetrag</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{op.mahnstufen.map((ms, i) =>
									<TableRow hover key={i}>
										<TableCell component="th" scope="row">{ms.mahnstufe}</TableCell>
										<TableCell align="right">{ms.datum}</TableCell>
										<TableCell align="right">{ms.betrag}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>

					</CardContent>
				</Card>

			</>
		);
		const Styled = withStyles(styles)(jsx);
		return <Styled />;
	};

	const Zahlungshistorie = () => {
		const op = props.dataItem;
		if (!op || !!op.zahlungen || !op.zahlungen.length) return keineBlaVorhanden('Zahlungen', true);

		return (
			<>
				{op.zahlungen.map((zahlung, index) => (
					<Card key={index}>
						<CardContent>
							<Typography gutterBottom variant="h5">
								{zahlung.zahlungsBetrag}
							</Typography>
							<Grid container spacing={1} style={{ marginTop: 16 }}>
								<Grid item xs={6} sm={6}>
									Datum
								</Grid>
								<Grid item xs={6} sm={6}>
									{zahlung.zahlungsDatum}
								</Grid>
								<Grid item xs={6} sm={6}>
									Zahlung per
								</Grid>
								<Grid item xs={6} sm={6}>
									{zahlung.zahlungPer}
								</Grid>
								<Grid item xs={6} sm={6}>
									Bemerkung
								</Grid>
								<Grid item xs={6} sm={6}>
									{zahlung.bemerkung}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				))}
			</>
		);
	};

	return [{
		ueberschrift: 'Offener Posten',
		Funktion: OffenerPosten
	}, {
		ueberschrift: 'Zahlungshistorie',
		Funktion: Zahlungshistorie
	}];
};

export default detailViewOffenerPosten;