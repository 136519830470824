import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	getBelegartFromCode, formatPreis, deutsch2js, str2float,
	js2deutsch, deutsch2VarioDateString, getLieferbedingungen, getZahlungsarten
} from '../../functions';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import FullscreenSearchModal from '../../components/FullscreenSearchModal';
import FullscreenSignModal from '../../components/FullscreenSignModal';
import FullscreenPositionModal from '../../containers/FullscreenPositionModal';
import FetchBelegsimulation from '../../classes/items/FetchBelegsimulation';
import IconButton from '@material-ui/core/IconButton';
import FetchBelegSpeichern from '../../classes/items/FetchBelegSpeichern';
import FetchBelegBuchen from '../../classes/items/FetchBelegBuchen';
import ModalBelegSpeichern from '../../containers/ModalBelegSpeichern';
import Modal from '../../containers/Modal';
import IconBuchen from '@material-ui/icons/Book';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import deLocale from 'date-fns/locale/de';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FetchBelegDetails from '../../classes/items/FetchBelegDetails';
import FetchBelegEdit from '../../classes/items/FetchBelegEdit';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: '68px', /** wegen Appbar  */
		[theme.breakpoints.down('xs')]: {
			marginTop: '58px' /** wegen kleinerer Appbar  */
		},
		height: 'calc(100vh - 68px)'
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 1101,
		color: 'white'
	},
	link: {
		cursor: 'pointer'
	},
	editButton: {
		margin: theme.spacing(1),
		position: 'absolute',
		right: '5px',
		top: '5px'
	},
	posPaper: {
		position: 'relative',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: '200px',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		}
	},
	textField: {
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: '100px'
	},
	verticalBottom: {
		marginTop: 'auto'
	},
	unit: {
		position: 'absolute',
		bottom: '4px',
		left: '110px'
	},
	posRelative: {
		position: 'relative'
	},
	textFieldFullWidth: {
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		width: '100%'
	},
	container: {
		padding: '0 16px 16px 16px'
	},
	unterschrift: {
		padding: '15px'
	},
	header: {
		padding: '8px 0 0 16px'
	},
	tableHead: {
		fontSize: theme.typography.pxToRem(16),
		paddingLeft: '8px',
		paddingRight: '8px'
	},
	tableCell: {
		fontSize: theme.typography.pxToRem(14),
		paddingLeft: '8px',
		paddingRight: 0
	}
});

class BelegNeu extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.changeTimer = null;
		this.changeTimerMillis = 800;
		this.dash = '—';
		this.classname = 'BelegNeu';
		this.bereiche = {
			adresse: 'Adresse',
			belegdaten: 'Belegdaten',
			rechnungsadresse: 'Rechnungsadresse',
			lieferadresse: 'Lieferadresse',
			belegfuss: 'Belegfuß',
			position: 'Position'
		};

		//#region
		this.adr = [
			['anrede', 'Anrede'],
			['name1', 'Name 1'],
			['name2', 'Name 2'],
			['strasse', 'Straße'],
			['lkz', 'LKZ'],
			['plz', 'PLZ'],
			['ort', 'Ort']
		];

		this.belDaten = [
			['belNr', 'Beleg-Nr.'],
			['adrNr', 'Adress-Nr.'],
			['kundenNr', 'Kunden-Nr.'],
			['belDatum', 'Belegdatum'],
			['liefertermin', 'Liefertermin'],
			['lieferterminBemerkung', 'Lieferterminbemerkung']
		];

		this.r_adr = [
			['r_anrede', 'Anrede'],
			['r_name1', 'Name 1'],
			['r_name2', 'Name 2'],
			['r_strasse', 'Straße'],
			['r_lkz', 'LKZ'],
			['r_plz', 'PLZ'],
			['r_ort', 'Ort']
		];

		this.l_adr = [
			['l_anrede', 'Anrede'],
			['l_name1', 'Name 1'],
			['l_name2', 'Name 2'],
			['l_strasse', 'Straße'],
			['l_lkz', 'LKZ'],
			['l_plz', 'PLZ'],
			['l_ort', 'Ort']
		];

		this.fuss = [
			['gewicht', 'Gewicht'],
			['anzPakete', 'Anzahl Pakete'],

			['trenner', ''],

			['lieferbedingung', 'Lieferbedingung'],
			['zahlungsart', 'Zahlungsart'],

			['trenner', ''],

			['valuta', 'Valuta'],
			['waehrung', 'Währung'],
			['benutzer', 'Benutzer'],
			['betragNetto', 'Betrag Netto'],

			['rabattKumuliert', 'Rabatt kumuliert'],
			['frachtkostenKumuliert', 'Frachtkosten kumuliert'],
			['zwischensumme', 'Zwischensumme'],

			['betragNetto', 'Betrag netto'],

			['trenner', ''],

			['rabatt1Text', 'Rabatt 1 Text'],
			['rabatt1', 'Rabatt 1'],
			['rabatt1Betrag', 'Rabatt 1 Betrag'],

			['trenner', ''],

			['rabatt2Text', 'Rabatt 2 Text'],
			['rabatt2', 'Rabatt 2'],
			['rabatt2Betrag', 'Rabatt 2 Betrag'],

			['trenner', ''],

			['frachtkosten', 'Versand netto'],
			['nachnahme', 'Nachnahme netto'],
			['eilzuschlagNetto', 'Eilzuschlag netto'],
			['versicherung', 'Versicherung netto'],

			['trenner', ''],

			['mwstBetrag1', 'MwSt-Betrag 1'],
			['mwstBetrag2', 'MwSt-Betrag 2'],
			['mwstBetrag3', 'MwSt-Betrag 3'],

			['trenner', ''],

			['gesamtBrutto', 'Gesamtbetrag brutto'],

			['trenner', ''],

			['skonto', 'Skonto']
		];
		//#endregion

		this.state = {
			unterschriftImage: null,
			zAdrData: {
				rechnungsadresseId: null,
				lieferadresseId: null
			},

			// Belegdaten
			adrNr: '',
			belDatum: '',
			liefertermin: '',
			lieferterminBemerkung: '',
			lieferbedingung: '',
			zahlungsart: '',
			rabatt1Text: '',
			rabatt1: '',
			rabatt1Betrag: '',
			rabatt2Text: '',
			rabatt2: '',
			rabatt2Betrag: '',
			lieferbedingungen: [],
			zahlungsarten: [],

			// Positionen
			positionenVorschlaege: [],

			// Steuerung
			tabValue: 0,
			belegArtText: '',
			belegartCode: '',
			adrId: null,
			sucheOpen: false,
			belegId: null,
			belegId2Fetch: null,
			belegEditId: null,
			textfieldsDisabled: false,
			searchHeader: '',
			isFetching: true,
			signModalOpen: false,
			posModalOpen: false,
			posModalArtId: null,
			belegnummerGespeichert: null,
			belegnummerEditMode: null,
			positionsIdsEditMode: [],
			positionsIdsToDeleteEditMode: [],
			simulation: null,

			// SpeichernModal
			mOpen: false,
			mTitle: '',
			mText: '',

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		};

		//#region
		this.handleTabChange = this.handleTabChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handlePosChange = this.handlePosChange.bind(this);
		this.openSuche = this.openSuche.bind(this);
		this.saveBeleg = this.saveBeleg.bind(this);
		this.belegSimulation = this.belegSimulation.bind(this);
		this.addArtikel = this.addArtikel.bind(this);
		this.addTextPosition = this.addTextPosition.bind(this);
		this.handleTextPosChange = this.handleTextPosChange.bind(this);
		this.deletePosition = this.deletePosition.bind(this);
		this.getSendData = this.getSendData.bind(this);
		this.handleBuchen = this.handleBuchen.bind(this);
		this.closeSuche = this.closeSuche.bind(this);
		this.changeAdresse = this.changeAdresse.bind(this);
		this.openSignModal = this.openSignModal.bind(this);
		this.closeSignModal = this.closeSignModal.bind(this);
		this.openPosModal = this.openPosModal.bind(this);
		this.closePosModal = this.closePosModal.bind(this);
		this.saveUnterschrift = this.saveUnterschrift.bind(this);
		this.handleTextChange = this.handleTextChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.editBeleg = this.editBeleg.bind(this);
		this.prevStateVorschlaegeWithoutPosition = this.prevStateVorschlaegeWithoutPosition.bind(this);
		this.TabContainer = this.TabContainer.bind(this);
		//#endregion
	}

	componentDidMount() {
		getZahlungsarten(this.props.db).then(zahlungsarten => {
			this.setState({ zahlungsarten });
		});
		getLieferbedingungen(this.props.db).then(lieferbedingungen => {
			this.setState({ lieferbedingungen });
		});

		this._isMounted = true;
		window.scrollTo(0, 0);

		// ggf. belegId aus URL holen
		let belegEditId;
		const { search } = this.props.location;
		if (search.includes('belegId')) {
			const match = search.match(/belegId=(\d+)/);
			if (match && match.length) {
				[belegEditId] = match.slice(1);
				this.setState({
					belegId2Fetch: belegEditId,
					belegEditId,
					belegId: belegEditId
				});
			}
		}

		const adrId = parseInt(this.props.id);
		const { belegartCode } = this.props;
		if (belegartCode && adrId) this.setState({ belegartCode }, () => this.belegSimulation(adrId));
		else console.error(`[${this.classname}] componentDidMount() if (belegartCode && adrId)`);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.getIsFetching() && !this.state.isFetching) this.props.setIsFetching(false);

		const { belegartCode } = this.props;
		if (belegartCode !== prevState.belegartCode) {
			getBelegartFromCode(this.props.db, belegartCode).then(belegArtText => {
				let text = `Neuanlage: ${belegArtText}`;
				if (this.state.belegEditId) text = `${belegArtText} bearbeiten`;
				this.props.updateUeberschriftFromChild(text);
				this.setState({ belegArtText });
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	TabContainer(props) {

		const styles = {
			height: 'calc(100% - 48px)',
			overflowY: 'scroll',
			padding: 8 * 1
		};

		return <Typography component="div" style={styles}>
			{props.children}
		</Typography>;
	}

	prevStateVorschlaegeWithoutPosition(prevState) {
		const prevVorschlaege = prevState.positionenVorschlaege;
		let ret = false;
		for (let i = 0; i < prevVorschlaege.length; i++) {
			const prevVorschlag = prevVorschlaege[i];
			if (!prevVorschlag.position) {
				ret = true;
				break;
			}
		}
		return ret;
	}

	handleTabChange = (event, tabValue) => {
		this.setState({ tabValue });
	};

	deletePosition = pos => {
		const { positionenVorschlaege, positionsIdsToDeleteEditMode, positionsIdsEditMode } = this.state;
		for (const [index, vorschlag] of positionenVorschlaege.entries()) {
			if (vorschlag.position === pos.position || !vorschlag.position) {
				let idToDelete;
				if (vorschlag.id && vorschlag.id !== this.dash) idToDelete = parseInt(vorschlag.id);
				positionenVorschlaege.splice(index, 1);
				positionsIdsToDeleteEditMode.push(idToDelete);
				if (positionsIdsEditMode[index]) positionsIdsEditMode.splice(index, 1);
				break;
			}
		}

		this.setState({
			positionenVorschlaege,
			positionsIdsToDeleteEditMode,
			positionsIdsEditMode
		});
		this.belegSimulation();
	};

	handleTextPosChange = pos => event => {
		const { positionenVorschlaege } = this.state;

		for (const [i, vorschlag] of positionenVorschlaege.entries()) {
			if (vorschlag.position === pos.position || !vorschlag.position) {
				positionenVorschlaege[i].position = pos.position;
				positionenVorschlaege[i].text = event.target.value;
				if (this.state.positionsIdsEditMode[i] && this.state.positionsIdsEditMode[i] !== this.dash)
					positionenVorschlaege[i].id = this.state.positionsIdsEditMode[i];
			}
		}
		this.setState({ positionenVorschlaege });
		this.belegSimulationDelayed();
	};

	handlePosChange = (pos, key) => event => {
		const eingabe = event.target.value;
		if (key === 'menge' && eingabe <= 0 && eingabe !== '') {
			this.deletePosition(pos);
			return;
		}
		const { positionenVorschlaege } = this.state;

		for (const [i, vorschlag] of positionenVorschlaege.entries()) {
			if (vorschlag.position === pos.position || vorschlag.artikelId === pos.artikelId) {
				positionenVorschlaege[i][key] = eingabe;
				positionenVorschlaege[i].position = pos.position;
				if (this.state.positionsIdsEditMode[i] && this.state.positionsIdsEditMode[i] !== this.dash)
					positionenVorschlaege[i].id = this.state.positionsIdsEditMode[i];
			}
		}
		this.setState({ positionenVorschlaege });
		if (eingabe !== '') this.belegSimulationDelayed();
	};

	openSuche(header) {
		this.setState({
			sucheOpen: true,
			searchHeader: header
		});
	}

	closeSuche() {
		this.setState({ sucheOpen: false });
	}

	saveUnterschrift(unterschriftImage) {
		this.setState({ unterschriftImage });
	}

	async saveBeleg() {

		this.setState({ textfieldsDisabled: true });
		const { adrId } = this.state;

		if (!adrId) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Etwas ging schief [saveBeleg(): no adrId]',
				isFetching: false
			});
			return;
		}

		this.setState({ isFetching: true });

		let obj = {};
		try {
			const sendData = await this.getSendData(adrId);
			obj = await new FetchBelegSpeichern(
				this.props,
				this.controller.signal,
				sendData,
				this.state.unterschriftImage
			).myFetch();
		} catch(error) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Etwas ging schief [saveBeleg(): FetchBelegSpeichern()]',
				isFetching: false
			});
			return;
		}

		this.setState({
			belegId: obj.belegId,
			belegnummerGespeichert: obj.belegnummer,
			mOpen: true,
			mTitle: 'Erfolg',
			mText: `${obj.kommentar} Möchten Sie den Beleg buchen?`,
			isFetching: false
		});
	}

	async editBeleg() {

		this.setState({ textfieldsDisabled: true });
		const { adrId, belegEditId, belegnummerEditMode } = this.state;

		if (!adrId || !belegEditId || !belegnummerEditMode) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Etwas ging schief [editBeleg()] !adrId || !belegEditId || !belegnummerEditMode',
				isFetching: false
			});
			return;
		}

		this.setState({ isFetching: true });

		let obj = {};
		try {
			const sendData = await this.getSendData(adrId, true);
			obj = await new FetchBelegEdit(
				this.props,
				this.controller.signal,
				sendData,
				this.state.unterschriftImage,
				belegEditId,
				belegnummerEditMode
			).myFetch();
		} catch(error) {
			console.error(error);
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: '[editBeleg()] Etwas ging schief: FetchBelegEdit()',
				isFetching: false
			});
			return;
		}

		this.setState({
			mOpen: true,
			mTitle: 'Erfolg',
			mText: obj.kommentar,
			isFetching: false
		});
	}

	async handleBuchen() {
		this.setState({ mOpen: false });

		const { belegId } = this.state;
		if (!belegId) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Etwas ging schief [handleBuchen(): no belegId]',
				isFetching: false
			});
			return;
		}

		this.setState({ isFetching: true });

		try {
			await new FetchBelegBuchen(this.props, this.controller.signal, belegId, this.state.adrNr).myFetch();
		} catch(error) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Etwas ging schief [handleBuchen(): FetchBelegBuchen()]',
				isFetching: false
			});
			return;
		}

		this.setState({
			emOpen: true,
			emTitle: 'Erfolg',
			emText: `Beleg mit der Nummer ${this.state.belegnummerGespeichert} erfolgreich gebucht.`,
			isFetching: false,
			belegId: null
		});
	}

	addTextPosition(text) {
		const { positionenVorschlaege } = this.state;
		positionenVorschlaege.push({
			bezeichnung: text,
			positionsArt: 'T'
		});
		this.setState({ positionenVorschlaege });
		this.belegSimulation();
	}

	async addArtikel(artikelId, pos = null) {
		const { positionenVorschlaege } = this.state;

		// nachsehen, ob Artikel schon drin ist
		let istSchonDrin = false;
		for (const vorschlag of positionenVorschlaege) {
			if (vorschlag.artikelId === artikelId) {
				istSchonDrin = true;
				break;
			}
		}

		if (!istSchonDrin) {
			if (pos) positionenVorschlaege.push(pos);
			else
				positionenVorschlaege.push({
					artikelId,
					menge: 1,
					positionsArt: 'A'
				});
			this.setState({ positionenVorschlaege });
			this.belegSimulation();
			return true;
		}
	}

	changeAdresse(bereich, adr) {

		const { zAdrData } = this.state;

		if (bereich === this.bereiche.adresse) {
			this.props.history.replace(`/app/belegneu/${adr.id}/${this.state.belegartCode}`);
			this.belegSimulation(adr.id);
		} else if (bereich === this.bereiche.rechnungsadresse) {
			if (adr.adresse && !adr.adresse.defaultAdresse && adr.adresse.adresseId) {
				// Zusatzadresse gewählt
				zAdrData.rechnungsadresseId = adr.adresse.id;
				this.setState({ zAdrData });
				this.belegSimulation(adr.adresseId, zAdrData);
			} else if (adr.uebernommenId) {
				zAdrData.rechnungsadresseId = adr.uebernommenId;
				this.setState({ zAdrData });
			}
		} else if (bereich === this.bereiche.lieferadresse) {
			if (adr.adresse && !adr.adresse.defaultAdresse && adr.adresse.adresseId) {
				// Zusatzadresse gewählt
				zAdrData.lieferadresseId = adr.adresse.id;
				this.setState({ zAdrData });
				this.belegSimulation(adr.adresseId);
			} else if (adr.uebernommenId) {
				zAdrData.lieferadresseId = adr.uebernommenId;
				this.setState({ zAdrData });
			}
		}
	}

	async getSendData(adrId, forEdit = false) {

		const { belegId2Fetch } = this.state;
		let beleg;
		if (belegId2Fetch) {
			let newState;
			try {
				beleg = await new FetchBelegDetails(this.props, this.controller.signal, belegId2Fetch).fetch();
				this.changeAdresse(this.bereiche.rechnungsadresse, { uebernommenId: beleg.rechnungsadresse.id }, false);
				this.changeAdresse(this.bereiche.lieferadresse, { uebernommenId: beleg.lieferadresse.id }, false);
				newState = {
					belDatum: beleg.belegdatum,
					liefertermin: beleg.liefertermin,
					lieferterminBemerkung: beleg.lieferterminBemerkung,
					lieferbedingung: beleg.lieferbedingung,
					zahlungsart: beleg.zahlungsart,
					rabatt1: beleg.rabatt1,
					rabatt1Betrag: beleg.rabatt1Betrag,
					rabatt1Text: beleg.rabatt1Text,
					rabatt2: beleg.rabatt2,
					rabatt2Betrag: beleg.rabatt2Betrag,
					rabatt2Text: beleg.rabatt2Text,
					belegnummerEditMode: beleg.belegnummer,
					belegId2Fetch: null
				};
				if (beleg.positionen && beleg.positionen.length) {
					const { positionen } = beleg;
					newState.positionenVorschlaege = positionen;
					const ids = [];
					for (let k = 0; k < positionen.length; k++) {
						const position = positionen[k];
						if (position.id) ids.push(position.id);
					}
					newState.positionsIdsEditMode = ids;
				}
				this.setState(newState);
			} catch(error) {
				console.error(`[${this.classname}] new FetchBelegDetails()`, error);
				return;
			}

			this.props.history.replace(`/app/belegneu/${this.state.adrId}/${this.state.belegartCode}`);
		}

		const sendData = {
			data: {
				adresseId: adrId
			}
		};

		if (!forEdit) {
			// Beleg anlegen
			sendData.lagerkreisId = 1;
			sendData.lagerId = 1;
			sendData.data.belegart = this.state.belegartCode;
		} else {
			// Beleg ändern: zu löschende Positionen-IDs einfügen
			let arr = [];
			const { positionsIdsToDeleteEditMode } = this.state;
			if (positionsIdsToDeleteEditMode.length) arr = [...positionsIdsToDeleteEditMode];
			sendData.positionenLoeschen = arr;
		}

		//console.log(beleg);
		//#region
		const
			{
				positionenVorschlaege,
				positionsIdsEditMode,
				belDatum, liefertermin,
				zAdrData,
				lieferterminBemerkung,
				rabatt1Text,
				rabatt1,
				rabatt1Betrag,
				rabatt2Text,
				rabatt2,
				rabatt2Betrag,
				zahlungsart,
				lieferbedingung
			} = this.state;

		if (belDatum && belDatum !== this.dash)
			sendData.data.belegdatum = deutsch2VarioDateString(belDatum);

		if (liefertermin && liefertermin !== this.dash)
			sendData.data.liefertermin = deutsch2VarioDateString(liefertermin);

		if (lieferterminBemerkung && lieferterminBemerkung !== this.dash)
			sendData.data.lieferterminBemerkung = lieferterminBemerkung;

		if (rabatt1Text && rabatt1Text !== this.dash) sendData.data.rabatt1Text = rabatt1Text;
		if (rabatt1 && rabatt1 !== this.dash) sendData.data.rabatt1 = str2float(rabatt1);
		if (rabatt1Betrag && rabatt1Betrag !== this.dash) sendData.data.rabatt1Betrag = str2float(rabatt1Betrag);
		if (rabatt2Text && rabatt2Text !== this.dash) sendData.data.rabatt2Text = rabatt2Text;
		if (rabatt2 && rabatt2 !== this.dash) sendData.data.rabatt2 = str2float(rabatt2);
		if (rabatt2Betrag && rabatt2Betrag !== this.dash) sendData.data.rabatt2Betrag = str2float(rabatt2Betrag);

		if (zAdrData.rechnungsadresse)
			sendData.data.rechnungsadresse = { zusatzadressId: zAdrData.rechnungsadresseId };

		if (zAdrData.lieferadresse)
			sendData.data.lieferadresse = { zusatzadressId: zAdrData.lieferadresseId };

		if (zahlungsart) sendData.data.zahlungsart = zahlungsart;
		if (lieferbedingung) sendData.data.lieferbedingung = lieferbedingung;

		if (positionenVorschlaege.length > 0) {
			const positionenSendData = [];
			for (let i = 0; i < positionenVorschlaege.length; i++) {

				const vorschlag = positionenVorschlaege[i];

				if (vorschlag.positionsArt !== 'T') {

					const obj = {
						artikelId: parseInt(vorschlag.artikelId),
						menge: parseInt(vorschlag.menge)
					};

					if (vorschlag.einzelpreis && vorschlag.einzelpreis !== this.dash) obj.einzelpreis = str2float(vorschlag.einzelpreis);
					if (vorschlag.rabatt1 && vorschlag.rabatt1 !== this.dash) obj.rabatt1 = str2float(vorschlag.rabatt1);
					if (vorschlag.rabatt2 && vorschlag.rabatt2 !== this.dash) obj.rabatt2 = str2float(vorschlag.rabatt2);
					if (vorschlag.id && vorschlag.id !== this.dash) obj.id = parseInt(vorschlag.id);
					else if (positionsIdsEditMode[i] && positionsIdsEditMode[i] !== this.dash) obj.id = parseInt(positionsIdsEditMode[i]);

					positionenSendData.push(obj);

				} else {
					// Text

					const textObj = {
						positionsArt: 'T',
						bezeichnung: vorschlag.bezeichnung
					};

					if (vorschlag.id && vorschlag.id !== this.dash) textObj.id = parseInt(vorschlag.id);

					positionenSendData.push(textObj);
				}
			}
			sendData.data.positionen = positionenSendData;
			console.log(positionenSendData);
		}

		return sendData;
		//#endregion
	}

	insertIds = positionen => {
		if (!positionen || !positionen.length) return null;
		const { positionsIdsEditMode } = this.state;
		if (!positionsIdsEditMode.length) return positionen;

		for (let i = 0; i < positionen.length; i++) {
			const { id } = positionen[i];
			if (id && id === this.dash && positionsIdsEditMode[i]) positionen[i].id = positionsIdsEditMode[i];
			else if (id && id === this.dash && !positionsIdsEditMode[i])
				console.log(`[insertIds()] id && id === this.dash && !positionsIdsEditMode[i], positionsIdsEditMode =`, positionsIdsEditMode);
		}
		return positionen;
	};

	async belegSimulation(adrId = this.state.adrId) {
		this.setState({
			adrId,
			isFetching: true
		});

		setTimeout(() => {
			this.closeSuche();
		}, 50);

		let sendData, sim;
		try {
			sendData = await this.getSendData(adrId);
			sim = await new FetchBelegsimulation(this.props, this.controller.signal, sendData).fetch();
		} catch(error) {
			console.error(`[${this.classname}] getSendData(), new FetchBelegsimulation()`, error);
			return;
		}

		if (!this._isMounted || !sim) {
			console.warn(`[${this.classname}] !this._isMounted || !sim`);
			return;
		}

		this.setState({
			positionenVorschlaege: this.insertIds(sim.positionen) || [],
			simulation: sim,
			isFetching: false,

			// Belegkopf - Adresse
			anrede: sim.hauptadresse.anrede,
			name1: sim.hauptadresse.name1,
			name2: sim.hauptadresse.name2,
			strasse: sim.hauptadresse.strasse,
			lkz: sim.hauptadresse.lkz,
			plz: sim.hauptadresse.plz,
			ort: sim.hauptadresse.ort,

			// Belegkopf - Belegdaten
			belNr: this.state.belegnummerEditMode || sim.belegnummer,
			adrNr: sim.adresseId,
			kundenNr: sim.kundennummer,
			belDatum: sim.belegdatum,
			liefertermin: sim.liefertermin,
			lieferterminBemerkung: sim.lieferterminBemerkung,

			// Belegkopf - Rechnungsadresse
			r_anrede: sim.rechnungsadresse.anrede,
			r_name1: sim.rechnungsadresse.name1,
			r_name2: sim.rechnungsadresse.name2,
			r_strasse: sim.rechnungsadresse.strasse,
			r_lkz: sim.rechnungsadresse.lkz,
			r_plz: sim.rechnungsadresse.plz,
			r_ort: sim.rechnungsadresse.ort,

			// Belegkopf - Lieferadresse
			l_anrede: sim.lieferadresse.anrede,
			l_name1: sim.lieferadresse.name1,
			l_name2: sim.lieferadresse.name2,
			l_strasse: sim.lieferadresse.strasse,
			l_lkz: sim.lieferadresse.lkz,
			l_plz: sim.lieferadresse.plz,
			l_ort: sim.lieferadresse.ort,

			// Belegfuß
			gewicht: sim.gewicht,
			anzPakete: sim.anzahlPakete,
			zahlungsart: sim.zahlungsart,
			waehrung: sim.waehrung,
			skontoBetrag: sim.skontoBetrag,
			skonto: sim.skonto,
			skontoFaellig: sim.skontoFaellig,
			skontoTage: sim.skontoTage,
			frachtkosten: sim.frachtkosten,
			nachnahme: sim.nachnahme,
			eilzuschlagNetto: sim.eilzuschlagNetto,
			versicherung: sim.versicherung,
			rabatt1Text: sim.rabatt1Text,
			rabatt1Betrag: sim.rabatt1Betrag,
			rabatt1: sim.rabatt1,
			rabatt2Text: sim.rabatt2Text,
			rabatt2Betrag: sim.rabatt2Betrag,
			rabatt2: sim.rabatt2,
			lieferbedingung: sim.lieferbedingung,
			lieferungFreigegeben: sim.freigabe,
			vorkasse: sim.vorkasse,
			valuta: sim.valuta,
			mwstSatz1: sim.mwstProzent1,
			mwstSatz2: sim.mwstProzent2,
			mwstSatz3: sim.mwstProzent3,
			mwstBetrag1: sim.mwstBetrag1,
			mwstBetrag2: sim.mwstBetrag2,
			mwstBetrag3: sim.mwstBetrag3,
			betragNetto: formatPreis(sim.betragNetto),
			gesamtBrutto: formatPreis(sim.betragBrutto)
		});
	}

	handleDateChange = name => date => {
		this.setState({ [name]: js2deutsch(date) });
	};

	handleTextChange = name => event => {
		this.setState({ [name]: event.target.value });
		this.belegSimulationDelayed();
	};

	openSignModal() {
		this.setState({ signModalOpen: true });
	}

	closeSignModal() {
		this.setState({ signModalOpen: false });
	}

	openPosModal(artikelId) {
		this.setState({
			posModalArtId: artikelId,
			posModalOpen: true
		});
	}

	closePosModal() {
		this.setState({
			posModalArtId: null,
			posModalOpen: false
		});
	}

	belegSimulationDelayed = () => {
		if (this.changeTimer) clearTimeout(this.changeTimer);
		this.changeTimer = setTimeout(() => {
			this.belegSimulation();
		}, this.changeTimerMillis);
	};

	handleSelectChange = name => e => {
		this.setState({ [name]: e.target.value });
		this.belegSimulationDelayed();
	};

	render() {

		const { classes } = this.props;

		const positionen = () => {
			const jsx = [];
			if (!this.state.simulation
				|| !this.state.simulation.positionen
				|| !this.state.positionenVorschlaege
				|| !this.state.positionenVorschlaege.length) return null;
			const vorschlaege = this.state.positionenVorschlaege;
			const { positionen } = this.state.simulation;

			positionen.forEach((pos, i) => {

				const
					left = {
						item: true,
						xs: 5,
						md: 3
					},
					right = {
						item: true,
						xs: 7,
						md: 9
					},
					vorschlag = vorschlaege[i] || {
						menge: 0,
						text: ''
					};
				if (!vorschlaege[i]) console.log('578205732');

				//console.log(pos, vorschlag)

				if (pos.positionsArt === 'T') {
					// Textposition
					jsx.push(
						<Paper elevation={1} key={i} className={classes.posPaper}>
							<Grid container spacing={1}>
								<Grid {...left} className={classes.verticalBottom}>Position</Grid><Grid {...right}>{pos.position}</Grid>
								<Grid {...left} className={classes.verticalBottom}>Art</Grid><Grid {...right}>Text</Grid>
								<Grid {...left} className={classes.verticalBottom}>Text</Grid>
								<Grid {...right}>
									<TextField
										disabled={this.state.textfieldsDisabled}
										className={classes.textFieldFullWidth}
										value={vorschlag.bezeichnung && vorschlag.bezeichnung !== this.dash ? vorschlag.bezeichnung : ''}
										onChange={this.handleTextPosChange(pos)}
										type="text"
									/>
								</Grid>
							</Grid>
							{!this.state.textfieldsDisabled &&
							<IconButton aria-label="Löschen" onClick={() => this.deletePosition(pos)} className={classes.editButton}>
								<DeleteIcon color="primary"/>
							</IconButton>
							}
						</Paper>
					);
				} else {
					// Artikel
					jsx.push(
						<Paper elevation={1} key={i} className={classes.posPaper}>
							<Grid container spacing={1}>
								<Grid {...left} className={classes.verticalBottom}>Position</Grid><Grid {...right}>{pos.position}</Grid>
								<Grid {...left} className={classes.verticalBottom}>Art</Grid><Grid {...right}>Artikel</Grid>
								<Grid {...left} className={classes.verticalBottom}>Menge</Grid>
								<Grid {...right}>
									<TextField
										disabled={this.state.textfieldsDisabled}
										className={classes.textField}
										value={vorschlag.menge || ''}
										onChange={this.handlePosChange(pos, 'menge')}
										type="number"
										label="Menge"
									/>
								</Grid>
								<div style={{
									height: '8px',
									width: '100%'
								}}/>
								<Grid {...left} className={classes.verticalBottom}>Mengeneinheit</Grid><Grid {...right}>{pos.mengeneinheit}</Grid>
								<Grid {...left} className={classes.verticalBottom}>ArtNr.</Grid>
								<Grid {...right}>
									<Link onClick={() => this.openPosModal(pos.artikelId)} className={classes.link}>{pos.artikelnummer || ''}</Link>
								</Grid>
								<Grid {...left} className={classes.verticalBottom}>Bezeichnung</Grid><Grid {...right}>{pos.bezeichnung}</Grid>
								<Grid {...left} className={classes.verticalBottom}>Einzelpreis</Grid>
								<Grid {...right} className={classes.posRelative}>
									<TextField
										disabled={this.state.textfieldsDisabled}
										className={classes.textField}
										value={vorschlag.einzelpreis && vorschlag.einzelpreis !== this.dash ? vorschlag.einzelpreis : ''}
										onChange={this.handlePosChange(pos, 'einzelpreis')}
										type="text"
										label="Einzelpreis"
									/>
									<div className={classes.unit}>€</div>
								</Grid>
								<Grid {...left} className={classes.verticalBottom}>Rabatt 1</Grid>
								<Grid {...right} className={classes.posRelative}>
									<TextField
										disabled={this.state.textfieldsDisabled}
										className={classes.textField}
										value={vorschlag.rabatt1 && vorschlag.rabatt1 !== this.dash ? vorschlag.rabatt1 : ''}
										onChange={this.handlePosChange(pos, 'rabatt1')}
										type="text"
										label="Rabatt 1"
									/>
									<div className={classes.unit}>%</div>
								</Grid>
								<Grid {...left} className={classes.verticalBottom}>Rabatt 2</Grid>
								<Grid {...right} className={classes.posRelative}>
									<TextField
										disabled={this.state.textfieldsDisabled}
										className={classes.textField}
										value={vorschlag.rabatt2 && vorschlag.rabatt2 !== this.dash ? vorschlag.rabatt2 : ''}
										onChange={this.handlePosChange(pos, 'rabatt2')}
										type="text"
										label="Rabatt 2"
									/>
									<div className={classes.unit}>%</div>
								</Grid>
								<div style={{
									height: '16px',
									width: '100%'
								}}/>
								<Grid {...left} className={classes.verticalBottom}><b>Gesamtpreis Position</b></Grid>
								<Grid {...right}><b>{pos.gesamtpreis}</b></Grid>
							</Grid>
							{!this.state.textfieldsDisabled &&
							<IconButton aria-label="Löschen" onClick={() => this.deletePosition(pos)} className={classes.editButton}>
								<DeleteIcon color="primary"/>
							</IconButton>
							}
						</Paper>
					);
				}
			});

			return jsx;
		};

		const fab = () => {
			const addIcon = (
				<Tooltip title="Neue Position" classes={{ tooltip: classes.lightTooltip }}>
					<Fab color="secondary" className={classes.fab} onClick={() => this.openSuche(this.bereiche.position)}>
						<AddIcon/>
					</Fab>
				</Tooltip>
			);
			const saveIcon = (
				<Tooltip title="Beleg speichern" classes={{ tooltip: classes.lightTooltip }}>
					<Fab color="secondary" className={classes.fab} onClick={this.state.belegEditId ? this.editBeleg : this.saveBeleg}>
						<SaveIcon/>
					</Fab>
				</Tooltip>
			);
			const buchenIcon = (
				<Tooltip title="Beleg buchen" classes={{ tooltip: classes.lightTooltip }}>
					<Fab color="secondary" className={classes.fab} onClick={this.handleBuchen}>
						<IconBuchen/>
					</Fab>
				</Tooltip>
			);

			if (!this.state.textfieldsDisabled) {
				if (this.state.tabValue === 1) {
					let keinePositionLeer = true;
					const { positionenVorschlaege } = this.state;
					for (const vorschlag of positionenVorschlaege) {
						if (vorschlag.menge === '') {
							keinePositionLeer = false;
							break;
						}
					}
					if (keinePositionLeer) return addIcon;
				} else if (this.state.tabValue === 2) return saveIcon;
			} else if (this.state.belegId) return buchenIcon;
		};

		const paper = (arr, header) => {

			let _label, _value;
			const
				jsx = [],
				left = {
					item: true,
					xs: 7,
					sm: 6,
					md: 3
				},
				right = {
					item: true,
					xs: 5,
					sm: 6,
					md: 9
				},
				fullwidth = {
					item: true,
					xs: 12
				};

			arr.forEach((el, i) => {

				const [key, label] = el;

				if (key === 'belDatum' || key === 'liefertermin') {
					const deutschesDatum = this.state[key];
					if (!deutschesDatum) return;

					jsx.push(
						<DatePicker
							key={i}
							disabled={this.state.textfieldsDisabled}
							id={key}
							label={label}
							className={classes.textField}
							value={deutsch2js(deutschesDatum)}
							onChange={this.handleDateChange(key)}
							animateYearScrolling
							autoOk
							format="dd.MM.yyyy"
						/>
					);
				} else if (key === 'lieferterminBemerkung') {
					jsx.push(
						<TextField
							style={{
								width: '100%',
								marginTop: '8px'
							}}
							key={i}
							className={classes.textFieldFullWidth}
							disabled={this.state.textfieldsDisabled}
							value={this.state.lieferterminBemerkung !== this.dash ? this.state.lieferterminBemerkung : ''}
							onChange={this.handleTextChange(key)}
							label={label}
						/>
					);
				} else if (key === 'trenner') {
					jsx.push(
						<React.Fragment key={i}>
							<Grid {...fullwidth}><Divider style={{ margin: '8px 0' }}/></Grid>
						</React.Fragment>
					);
				} else if (key.includes('mwstBetrag')) {
					const
						nummer = key.slice(-1),
						betragKey = `mwstBetrag${nummer}`,
						satzKey = `mwstSatz${nummer}`,
						satzText = this.state[betragKey] !== this.dash ? `(${this.state[satzKey]})` : '';
					jsx.push(
						<React.Fragment key={i}>
							<Grid {...left} className={classes.verticalBottom}>{label}:</Grid>
							<Grid {...right}>{this.state[betragKey]} {satzText}</Grid>
						</React.Fragment>
					);
				} else if (key.includes('rabatt') && key !== 'rabattKumuliert') {
					// Rabatt 1 und Rabatt 2
					const _class = key.includes('Text') ? classes.textFieldFullWidth : classes.textField;
					const _label = key.includes('Text') ? label : '';
					jsx.push(
						<React.Fragment key={i}>
							<Grid {...left} className={classes.verticalBottom}>{label}:</Grid>
							<Grid {...right} className={classes.posRelative}>
								<TextField
									disabled={this.state.textfieldsDisabled}
									className={_class}
									value={this.state[key] !== this.dash ? this.state[key] : ''}
									onChange={this.handleTextChange(key)}
									type="text"
									label={_label}
								/>
								{/* Einheit dran hängen */}
								{(key === 'rabatt1' || key === 'rabatt2') && <div className={classes.unit}>%</div>}
								{key.includes('Betrag') && <div className={classes.unit}>€</div>}
							</Grid>
						</React.Fragment>
					);
				} else if (key === 'skonto') {
					const
						prozentString = this.state.skonto !== this.dash ? `(${this.state.skonto} %)` : '',
						faelligString = this.state.skontoFaellig !== this.dash ? `${this.state.skontoFaellig} (${this.state.skontoTage} Tage)` : '';
					jsx.push(
						<React.Fragment key={i}>
							<Grid {...left} className={classes.verticalBottom}>Skonto:</Grid>
							<Grid {...right}>{this.state.skontoBetrag} {prozentString}</Grid>
							{faelligString &&
							<>
								<Grid {...left} className={classes.verticalBottom}>Skonto fällig:</Grid>
								<Grid {...right}>{faelligString}</Grid>
							</>
							}
						</React.Fragment>
					);
				} else if (key === 'lieferbedingung' || key === 'zahlungsart') {
					const values = () => {
						const _jsx = [];
						let _values = this.state.lieferbedingungen;
						if (key === 'zahlungsart') _values = this.state.zahlungsarten;
						for (let j = 0; j < _values.length; j++)
							_jsx.push(<MenuItem key={j} value={_values[j]}>{_values[j]}</MenuItem>);
						return _jsx;
					};
					const value = this.state[key] !== this.dash ? this.state[key] : '';
					jsx.push(
						<React.Fragment key={i}>
							<FormControl className={classes.formControl}>
								<InputLabel htmlFor={`key${i}`}>{label}</InputLabel>
								<Select
									disabled={this.state.textfieldsDisabled}
									value={value}
									onChange={this.handleSelectChange(key)}
									inputProps={{
										name: key,
										id: `key${i}`
									}}
								>
									{values()}
								</Select>
							</FormControl>
						</React.Fragment>
					);
				} else {
					_label = label;
					_value = this.state[key];
					// ggf. fett schreiben
					if (key === 'gesamtBrutto' || key === 'zwischensumme') {
						_label = <b>{label}</b>;
						_value = <b>{_value}</b>;
					}
					jsx.push(
						<React.Fragment key={i}>
							<Grid {...left} className={classes.verticalBottom}>{_label}:</Grid>
							<Grid {...right}>{_value}</Grid>
						</React.Fragment>
					);
				}
			});

			return (
				<Paper elevation={1} style={{
					position: 'relative',
					marginBottom: '8px'
				}}>
					<Typography variant="h6" className={classes.header}>{header}</Typography>
					{header !== this.bereiche.belegfuss && header !== this.bereiche.belegdaten &&
					<Button
						variant="contained"
						color="secondary"
						className={classes.editButton}
						onClick={() => this.openSuche(header)}
						disabled={this.state.textfieldsDisabled}
					>
						ändern
					</Button>
					}
					<Grid container spacing={1} style={{ padding: '8px 16px 16px 16px' }}>{jsx}</Grid>
				</Paper>
			);
		};

		const defaultAdresse = {
			defaultAdresse: true,
			id: this.state.adrId,
			anrede: this.state.anrede,
			name1: this.state.name1,
			name2: this.state.name2,
			strasse: this.state.strasse,
			lkz: this.state.lkz,
			plz: this.state.plz,
			ort: this.state.ort
		};

		const { TabContainer } = this;

		return (
			<>
				{this.state.isFetching && <LinearProgress color="secondary" className="global_progress_bar"/>}

				<div className={classes.root}>
					<AppBar position="relative" color="default">
						<Tabs
							value={this.state.tabValue}
							onChange={this.handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							centered
							variant="fullWidth"
						>
							<Tab label="Belegkopf"/>
							<Tab label="Positionen"/>
							<Tab label="Belegfuß"/>
						</Tabs>
					</AppBar>

					{/* Belegkopf */}
					{this.state.tabValue === 0 &&
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
						<TabContainer>
							<form noValidate autoComplete="off">
								{paper(this.adr, this.bereiche.adresse)}
								{paper(this.belDaten, this.bereiche.belegdaten)}
								{paper(this.r_adr, this.bereiche.rechnungsadresse)}
								{paper(this.l_adr, this.bereiche.lieferadresse)}
							</form>

							<div style={{ marginBottom: '30px' }}/>
						</TabContainer>
					</MuiPickersUtilsProvider>
					}

					{/* Positionen */}
					{this.state.tabValue === 1 &&
					<TabContainer>
						<form noValidate autoComplete="off">
							{positionen()}
						</form>

						<div style={{ marginBottom: '70px' }}/>
					</TabContainer>
					}

					{/* Belegfuß */}
					{this.state.tabValue === 2 &&
					<TabContainer>
						<form noValidate autoComplete="off">
							{paper(this.fuss, 'Belegfuß')}
						</form>

						<Paper elevation={1} className={classes.formBlock}>
							<Typography variant="h6" className={classes.header}>Unterschrift</Typography>

							<Button
								style={{ margin: '16px' }}
								variant="contained"
								color="secondary"
								onClick={this.openSignModal}
								disabled={this.state.textfieldsDisabled}
							>
								hinzufügen
							</Button>

							{this.state.unterschriftImage &&
							<img
								src={this.state.unterschriftImage}
								alt="Unterschrift"
								style={{ width: '90%' }}
							/>
							}
						</Paper>

						<div style={{ marginBottom: '100px' }}/>
					</TabContainer>
					}

					{/* FAB Button unten rechts */}
					{fab()}

					{/* Popup für Adress-/ArtikelSuche */}
					<FullscreenSearchModal
						defaultAdresse={defaultAdresse}
						header={this.state.searchHeader}
						bereiche={this.bereiche}
						screenprops={this.props}
						open={this.state.sucheOpen}
						closeModal={this.closeSuche}
						addArtikel={this.addArtikel}
						changeAdresse={this.changeAdresse}
						addTextPosition={this.addTextPosition}
					/>

					{/* Popup für Unterschrift */}
					<FullscreenSignModal
						open={this.state.signModalOpen}
						closeModal={this.closeSignModal}
						saveUnterschrift={this.saveUnterschrift}
					/>

					{/* Popup für Position */}
					<FullscreenPositionModal
						simulation={this.state.simulation}
						open={this.state.posModalOpen}
						closeModal={this.closePosModal}
						artId={this.state.posModalArtId}
						screenprops={this.props}
					/>

					{/* Popup für Bestätigung speichern */}
					<ModalBelegSpeichern
						open={this.state.mOpen}
						title={this.state.mTitle}
						text={this.state.mText}
						handleCancel={() => this.setState({ mOpen: false })}
						handleBuchen={() => this.handleBuchen()}
						cancelBtn={false}
						destroy={() => this.setState({ mOpen: false })}
					/>

					{/* Popup für Bestätigung von buchen oder Fehler */}
					<Modal
						open={this.state.emOpen}
						title={this.state.emTitle}
						text={this.state.emText}
						handleOk={() => this.setState({ emOpen: false })}
						cancelBtn={false}
						destroy={() => this.setState({ emOpen: false })}
					/>
				</div>
			</>
		);
	}
};

BelegNeu.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(BelegNeu);
