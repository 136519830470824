import Items from '.';
import { formatPreis, semiCamelCase } from '../../functions';

class FetchUmsatzZuAdresse extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		this.path = {
			string: `auswertungen/sql`,
			id
		}
		this.sendData = {
			sql: `
					select
						sum(reb.betragnetto * reb.bewertungsfaktor) as betrag
					from
						reb
				 	where
						 reb.adr_id = ${id}
					and
						datediff(day, reb.datum, current_date) <= 365
		`};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		const name = 'Gesamtzumsatz zu Adresse';
		super.fetchDbOrServerPost(name, this.path, this.sendData).then(json => {
			const umsatz = json.results[semiCamelCase(name)][0].betrag;

			resolve(formatPreis( umsatz));
		}).catch(err => reject(err));
	}).catch(err => console.error(err))
}

export default FetchUmsatzZuAdresse;
