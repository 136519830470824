import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ModalBelegSpeichern = props => {

	return (
		<Dialog
			open={props.open}
			onBackdropClick={props.destroy}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Erfolg</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleCancel} color="primary">Nein</Button>
				<Button onClick={props.handleBuchen} color="primary" autoFocus>Ja</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ModalBelegSpeichern;