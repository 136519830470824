import React from "react";
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
const ListViewOffenePosten = props => {

	if (!props.items) return null;

	const StyledTypography = withStyles({
		root: {
			display: 'flex',
			justifyContent: 'space-between'
		}
	})(Typography);

	const StyledAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);

	const handleClick = id => {
		if (isNaN(id)) {
			console.warn(7856885283);
			return null;
		}
		if (props.writeUriAndSelectId) return props.writeUriAndSelectId(id);
		else if (props.handleClick) return props.handleClick(id);
		else console.warn('[ListViewOffenePosten.class] kein Clickhandler in den props');
	};

	//console.log(props.items)

	return (
		<List style={props.listStyle}>
			{props.items.map((offenerPosten, i) => {

				return (
					<ListItem
						key={i}
						button
						divider={i + 1 !== props.items.length ? true : false}
						selected={props.selectedId === offenerPosten.id}
						onClick={() => handleClick(offenerPosten.id)}
					>
						<StyledAvatar><ErrorOutlineIcon /></StyledAvatar>

						<ListItemText
							disableTypography
							primary={
								<StyledTypography
									component="p"
									variant="body1"
								>
									<span>{offenerPosten.tageUeberfaellig} Tage überfällig</span>
									<span>{offenerPosten.offenerBetrag}</span>
								</StyledTypography>
							}
							secondary={
								<Grid container>
									<Grid item xs={4}>{offenerPosten.adresse}</Grid>
									<Grid item xs={8} align="right">Bisher gezahlt: {offenerPosten.bisherGezahlt}€</Grid>
								</Grid>
							}
						/>
					</ListItem>
				)
			})}
		</List>
	);
};

export default ListViewOffenePosten;
