import Items from '.';
import { semiCamelCase, writeNullToKeys } from '../../functions';

class FetchSearchresultsScope extends Items {

	constructor(props, signal, path, config) {
		super(props, signal);
		this.path = path;
		this.config = config;
		this.fetch = this.fetch.bind(this);
	}

	async fetch() {
		const name = this.config.itemsName;
		const camelName = semiCamelCase(name);
		const json = await super.fetchDbOrServerGet(name, this.path);
		return {
			totalResultCount: json.results.totalResultCount,
			results: json.results[camelName] ? writeNullToKeys(json.results[camelName]) : [],
			itemsNode: this.config.itemsNode
		};
	};
}

export default FetchSearchresultsScope;