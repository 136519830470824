import Fetch from '../fetch';
import FetchAktivitaetenZuAdresse from './FetchAktivitaetenZuAdresse';
import FetchAktivitaetenZuDeal from './FetchAktivitaetenZuDeal';

class FetchAktivitaetEditieren {

	constructor(props, signal, sendData) {
		this.path = {
			string: `crm/aktivitaeten`,
			id: null
		};
		this.sendData = sendData;
		this.props = props;
		this.signal = signal;
		this.post = this.post.bind(this);
	}

	// Aktivität anlegen
	post = () => new Promise((resolve, reject) => {
		new Fetch(this.props).post(this.path, this.signal, 'Aktivitaet anlegen', this.sendData).then(() => {
			// Request für den Cache erneuern
			// TODO deleteRowsFromCache() nutzen!!!
			if (!this.sendData.data.dealsId) {
				// Aktivitäten zu Adresse
				new FetchAktivitaetenZuAdresse(this.props, this.signal, this.sendData.data.adresseId, true).fetch().then(() => {
					resolve(3); // tab 3
				});
			} else {
				// Aktivitäten zu Deal
				new FetchAktivitaetenZuDeal(this.props, this.signal, this.sendData.data.dealsId, true).fetch().then(() => {
					resolve(1); // tab 1
				});
			}
		}).catch(err => {
			console.error(err);
			console.error(8905427520570);
			reject(false);
			return;
		});
	});
}

export default FetchAktivitaetEditieren;
