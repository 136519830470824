export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const REJECT_TASK = 'REJECT_TASK';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const INSERT_TASK = 'INSERT_TASK';
export const ACCEPT_TASK = 'ACCEPT_TASK';
export const INSERT_STATUS = 'INSERT_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CHECK_ITEM = 'CHECK_ITEM';
export const INSERT_POSITION = 'INSERT_POSITION';
export const SPLIT_POSITION = 'SPLIT_POSITION';
export const CLEAR_POSITION = 'CLEAR_POSITION';
