import { getBearerToken, logout, screenPropsOK, getWriteFetchObj, getCurrentFilename } from '../../functions';

class Fetch {

	constructor(screenProps) {
		this.bearerToken = getBearerToken();
		this.screenProps = screenProps;
		this.get = this.get.bind(this);
		this.post = this.post.bind(this);
		this.patch = this.patch.bind(this);
		this.stdAuthObj = this.stdAuthObj.bind(this);
	}

	stdAuthObj(signal) {
		return {
			headers: {
				Authorization: `Bearer ${this.bearerToken}`,
				signal
			}
		};
	};

	get = (path, signal, itemsName) => new Promise((resolve, reject) => {

		const errIntro = `[${getCurrentFilename(__filename)} => get()]`;

		if (!screenPropsOK(this.screenProps)) {
			console.error(`${errIntro} ScreenProps nicht OK.`);
			return;
		}

		if (!this.bearerToken) {
			console.error(`${errIntro} Kein Bearertoken vorhanden! => logout() aufgerufen`);
			logout(this.screenProps);
			return;
		}

		if (!path || !path.string) {
			console.error(`${errIntro} Kein path oder path.string vorhanden!`);
			return;
		}

		if (!signal) {
			console.error(`${errIntro} Kein signal vorhanden!`);
			return;
		}

		if (!itemsName) {
			console.error(`${errIntro} Kein itemsName vorhanden!`);
			return;
		}

		if (!navigator.onLine) {
			reject('Sie sind OFFLINE. Keine Daten verfügbar.');
			return;
		}

		let url = `${window.RESTserver}/vario-api/${path.string}`;
		let err = `Keine ${itemsName} vorhanden.`;

		if (path.id) err = `${itemsName} mit der ID ${path.id} ist nicht vorhanden.`;

		fetch(url, this.stdAuthObj(signal))
			.then(response => {
				const json = response.json();
				if (response.ok) return json;
				// Fehler: Promise-Objekt aus json ziehen
				return json.then(Promise.reject.bind(Promise));
			})
			.then(json => resolve(json))
			.catch(error => reject(`${err} [${error.errorMessage}.]`));
	});

	post = (path, signal, itemsName, sendData) => new Promise((resolve, reject) => {

		const errIntro = `[${getCurrentFilename(__filename)} => post()]`;

		if (!screenPropsOK(this.screenProps)) {
			console.error(`${errIntro} ScreenProps nicht OK.`);
			return;
		}

		if (!this.bearerToken) {
			console.error(`${errIntro} Kein Bearertoken vorhanden! => logout() aufgerufen`);
			logout(this.screenProps);
			return;
		}

		if (!path || !path.string) {
			console.error(`${errIntro} Kein path oder path.string vorhanden!`);
			return;
		}

		if (!signal) {
			console.error(`${errIntro} Kein signal vorhanden!`);
			return;
		}

		if (!itemsName) {
			console.error(`${errIntro} Kein itemsName vorhanden!`);
			return;
		}

		if (!navigator.onLine) {
			reject('Sie sind OFFLINE. Keine Daten verfügbar.');
			return;
		}

		let url = `${window.RESTserver}/vario-api/${path.string}`;
		let err = `Keine ${itemsName} vorhanden.`;

		if (path.id) err = `${itemsName} mit der ID ${path.id} ist nicht vorhanden.`;

		fetch(url, getWriteFetchObj(signal, 'POST', sendData))
			.then(response => {
				// nur Bestätigung, dass schreiben/ändern erfolgreich war:
				if (response.status >= 201 && response.status <= 204) return true;

				// wir erhalten einen JSON als Ergebnis:
				const json = response.json();
				if (response.ok) return json;

				// Fehler: Promise-Objekt aus json ziehen
				return json.then(Promise.reject.bind(Promise));
			})
			.then(json => resolve(json))
			.catch(error => reject(`${err} [${error.errorMessage}.]`));
	});

	patch = (path, signal, itemsName, sendData) => new Promise((resolve, reject) => {

		const errIntro = `[${getCurrentFilename(__filename)} => patch()]`;

		if (!screenPropsOK(this.screenProps)) {
			console.error(`${errIntro} ScreenProps nicht OK.`);
			return;
		}

		if (!this.bearerToken) {
			console.error(`${errIntro} Kein Bearertoken vorhanden! => logout() aufgerufen`);
			logout(this.screenProps);
			return;
		}

		if (!path || !path.string) {
			console.error(`${errIntro} Kein path oder path.string vorhanden!`);
			return;
		}

		if (!signal) {
			console.error(`${errIntro} Kein signal vorhanden!`);
			return;
		}

		if (!itemsName) {
			console.error(`${errIntro} Kein itemsName vorhanden!`);
			return;
		}

		if (!navigator.onLine) {
			reject('Sie sind OFFLINE. Keine Daten verfügbar.');
			return;
		}

		let url = `${window.RESTserver}/vario-api/${path.string}`;
		let err = `Keine ${itemsName} vorhanden.`;

		if (path.id) err = `${itemsName} mit der ID ${path.id} ist nicht vorhanden.`;

		fetch(url, getWriteFetchObj(signal, 'PATCH', sendData))
			.then(response => {
				// nur Bestätigung, dass schreiben/ändern erfolgreich war:
				if (response.status >= 201 && response.status <= 204) return true;

				// wir erhalten einen JSON als Ergebnis:
				const json = response.json();
				if (response.ok) return json;

				// Fehler: Promise-Objekt aus json ziehen
				return json.then(Promise.reject.bind(Promise));
			})
			.then(json => resolve(json))
			.catch(error => reject(`${err} [${error.errorMessage}.]`));
	});
}

export default Fetch;
