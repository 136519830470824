import Items from '.';

class StatistikUmsatzNachAusland extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.path = {
			string: `auswertungen/sql`,
			id: null
		}
		const jahr = new Date().getUTCFullYear();
		this.sendData = {
			sql: `
				select case
					when lkz.zuordnung = 2 then 'AUSLAND'
					when lkz.zuordnung = 1 then 'EU'
					when lkz.zuordnung = 0 then 'INLAND'
					else 'XX'
				end as
					umsatzgebiet
					, sum(buc.endpreis * buc.bewertungsfaktor * -1)
				as
					summe
				from
					buc
				join
					bek
				on
					bek.belegnr = buc.belegnr
				join
					bek_adr
				on
					bek_adr.bek_id = bek.id
				and
					bek_adr.adr_typ = 2
				join
					lkz
				on
					lkz.id = bek_adr.lkz_id
				where
					buc.belegtyp in ('04', '05', '06', '11', '12')
				and
					buc.aus_beb = 'N'
				and
					(buc.jahr = '${jahr - 1}' or buc.jahr = '${jahr}')
				group by
					1`
		};
		this.signal = signal;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerPost('Umsatz nach Land', this.path, this.sendData).then(json => {

			const results = json.results.umsatzNachLand;
			const dataProvider = [];
			for (let i = 0; i < results.length; i++) {
				const result = results[i];
				dataProvider.push({
					title: result.umsatzgebiet,
					value: result.summe
				});
			}
			const obj = {
				dataProvider
			};
			resolve(obj);
		}).catch(err => reject(err));
	});
}

export default StatistikUmsatzNachAusland;
