import Items from '.';
import { writeNullToKeys } from '../../functions';

class FetchArtikels extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.path = {
			string: props.listingPath,
			id: null
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Artikel', this.path).then(json => {

			if (!json.path || !json.results || !json.results.artikel) {
				resolve([]);
				return;
			}

			let artikels = json.results.artikel;

			const obj = {
				totalResultCount: json.results.totalResultCount,
				artikel: writeNullToKeys(artikels)
			};
			resolve(obj);

		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Artikel'
	});
}

export default FetchArtikels;