import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
	root: {
		flexGrow: 1,
		margin: '1.5em',
		marginTop: '84px' /** wegen Appbar  */
	},
	title: {
		overflow: 'initial',
		whiteSpace: 'normal',
		fontSize: '2.75rem',
		lineHeight: '3.5rem'
	}
});

const NotFound = props => {

	const { classes } = props;

	return (
		<div className={classes.root}>
			<Typography className={classes.title} variant="h2" noWrap>
				Die angeforderte Seite wurde nicht gefunden.
      </Typography>
		</div>
	);
}

NotFound.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);