import React from "react";
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const ListViewArtikel = props => {

	if (!props.items) return null;

	const StyledAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);

	const handleClick = id => {
		if (isNaN(id)) return null;
		if (props.writeUriAndSelectId) return props.writeUriAndSelectId(id);
		else if (props.handleClick) return props.handleClick(id);
		else console.warn('[ListViewArtikel.class] kein Clickhandler in den props');
	};

	//console.log(props.items)
	let lineThrough;

	return (
		<List style={props.listStyle}>
			{props.items.map((artikel, i) => {
				lineThrough = artikel.gesperrt === 'ja' ? { textDecoration: 'line-through' } : null;
				return (
					<ListItem
						key={i}
						button
						divider={i + 1 !== props.items.length ? true : false}
						selected={props.selectedId === artikel.id}
						onClick={() => handleClick(artikel.id)}
					>
						<StyledAvatar><ImageIcon /></StyledAvatar>

						<ListItemText
							disableTypography
							primary={
								<Typography
									noWrap
									variant="body1"
									style={lineThrough}
								>
									{artikel.bezeichnung}
								</Typography>
							}
							secondary={
								<>
									<Typography
										component="p"
										variant="body2"
										color="textSecondary"
										noWrap
									>
										{artikel.artikelnummer}
									</Typography>
								</>
							}
						/>
					</ListItem>
				)
			})}
		</List>
	);
};

export default ListViewArtikel;