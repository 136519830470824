import Fetch from '../fetch';

class FetchBenutzerParameterSpeichern {
	constructor(props, signal, path, objStr) {
		this.props = props;
		this.signal = signal;
		this.path = path;
		this.sendData = {
			data: {
				wert: objStr
			}
		};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		new Fetch(this.props).post(this.path, this.signal, 'Benutzer Parameter speichern', this.sendData).then(json => {
			resolve(json);
		}).catch(err => {
			console.error(err);
			console.error(456434627674);
		});
	});
}

export default FetchBenutzerParameterSpeichern;