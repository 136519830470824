import { connect } from 'react-redux';
import ServiceList from '../components/serviceList';
import { getVisibleTasks } from '../selectors/tasks';

const mapStateToProps = (state, ownProps) => ({
	filteredItems: getVisibleTasks(state),// Tasks vom Server "reinpumpen", in Zukunft auch rejectedTasks)),
	rejectedIds: state.tasks.rejectedIds,
	acceptedIds: state.tasks.acceptedIds
});
// const mapDispatchToProps
const FilteredServiceList = connect(
	mapStateToProps
)(ServiceList);

export default FilteredServiceList;
