import FetchAdresseDetails from './FetchAdresseDetails';
import FetchBelegDetails from './FetchBelegDetails';
import { getCurrentFilename } from '../../functions';
import $ from '../../config';

class FetchSuchergebnisDetails {

	constructor(props, signal, id, typ, adrId = null) {
		if (!id || isNaN(id)) throw new TypeError(`[${getCurrentFilename(__filename)}] id nicht vorhanden`);
		else if (typ === $.ansprechpartnersNode && (!adrId || isNaN(adrId))) throw new TypeError(`[${getCurrentFilename(__filename)}] adrId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.id = id;
		this.adrId = adrId;
		this.typ = typ;
		this.fetch = this.fetch.bind(this);
	}

	async fetch() {
		if (!this.typ) return null;
		try {
			if (this.typ === $.adressenNode) {
				return await new FetchAdresseDetails(this.props, this.signal, this.id, this.typ).fetch();
			} else if (this.typ === $.belegeNode) {
				return await new FetchBelegDetails(this.props, this.signal, this.id).fetch();
			} else if (this.typ === $.ansprechpartnersNode) {
				return await new FetchAdresseDetails(this.props, this.signal, this.adrId).fetch();
			}
		} catch (e) {
			console.error(e);
		}
	}
}

export default FetchSuchergebnisDetails;