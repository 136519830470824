import Items from '.';
import { getCurrentFilename, formatAktivitaetenListe } from '../../functions';

class FetchAktivitaetenZuAdresse extends Items {

	constructor(props, signal, adresseId, forceRefetch = false) {
		super(props, signal);
		this.props = props;
		this.forceRefetch = forceRefetch;
		this.signal = signal;
		if (!adresseId || isNaN(adresseId)) throw new TypeError(`[${getCurrentFilename(__filename)}] adresseId nicht vorhanden`);
		this.path = {
			string: `stammdaten/adressen/${adresseId}/aktivitaeten?orderby=-datum`,
			id: adresseId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Aktivitaeten zu Adresse', this.path, this.forceRefetch).then(json => {

			if (!json.path || !json.results || !json.results.aktivitaetenZuAdresse) {
				resolve([]);
				return;
			}

			const aktZuAdresse = json.results.aktivitaetenZuAdresse;

			formatAktivitaetenListe(this.props.db, aktZuAdresse).then(formatierteAktivitaeten => {
				resolve(formatierteAktivitaeten);
			});
		}).catch(err => {
			reject(err);
			return;
		});
	});
}

export default FetchAktivitaetenZuAdresse;