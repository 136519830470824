import React from 'react';
import { connect } from 'react-redux';
import { getVisiblePositionen } from '../selectors/positionen';
import Position from '../components/position';

const mapStateToProps = (state, ownProps) => ({
	positionen: getVisiblePositionen(state.positionen, ownProps.ids)
});

const PositionList = props => (
	<>
		{
			props.positionen.map((position, key) => (
				<Position key={key} {...position} status={position.status || 0}/>
			))
		}
	</>
);

const FilteredPositionList = connect(
	mapStateToProps
)(PositionList);

export default FilteredPositionList;
