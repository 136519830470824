import React, { useState, useEffect } from 'react';
import AuswertungsCard from './AuswertungsCard';
import { ShoppingCart, EuroSymbol, Redeem, AssignmentInd } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

function Auswertungsgruppe() {
	const cardsArray = [
			/*		{
						titel: 'Aufträge',
						todaysValue: 123,
						yesterdaysValue: 50,
						icon: ShoppingCart,
						color: /!*'orange'*!/ 'white'
					},
					{
						titel: 'Umsatz',
						todaysValue: 456,
						yesterdaysValue: 123,
						icon: EuroSymbol,
						color: /!*'#8FBC8F'*!/ 'white'
					},
					{
						titel: 'Pakete',
						todaysValue: 463,
						yesterdaysValue: 473,
						icon: Redeem,
						color: /!*'#00CED1'*!/ 'white'
					},
					{
						titel: 'Neukunden',
						todaysValue: 321,
						yesterdaysValue: 321,
						icon: AssignmentInd,
						color: /!*'#DCDCDC'*!/ 'white'
					}*/
		],
		cardsPromise = Promise.resolve(cardsArray),
		[cardsData, setCardsData] = useState([]),
		[done, setDone] = useState(false);

	useEffect(() => {
		if (done) return;

		async function fetchCardsData() {
			const cardsData = await cardsPromise;
			setCardsData(cardsData);
			setDone(true);
		}

		fetchCardsData();
	}, [cardsPromise, done]);

	const _item_card = {
            item: true,
            xs: 12,
            sm: 6,
            md: 3
        };

    return (
        <>
					{
						cardsData.map((cardsItem, index) =>
							<Grid {..._item_card} container key={index}>
								<AuswertungsCard
									{...cardsItem}
								/>
							</Grid>)
					}
        </>
    );
};

export default Auswertungsgruppe;
