import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import { makeTelLink } from '../functions';
import { withStyles } from '@material-ui/core';

const TabAnsprechpartner = props => {

	let { ansprechpartners } = props;
	if (!ansprechpartners) return null;

	const dash = '—';
	const StyledListItemText = withStyles(theme => ({
		primary: {
			color: theme.palette.secondary.main,
			fontWeight: 'bold'
		}
	}))(ListItemText);

	const list = (ansprechpartner, index) => (
		<List component="nav" key={index}>
			<ListItem divider>
				<ListItemIcon>
					<PersonIcon />
				</ListItemIcon>
				<StyledListItemText
					primary={`${ansprechpartner.anrede !== dash ? ansprechpartner.anrede : ''} ${ansprechpartner.name}`}
					secondary="Name"
				/>
			</ListItem>
			<ListItem button divider component="a" href={ansprechpartner.telefon !== dash ? `tel:${makeTelLink(ansprechpartner.telefon)}` : `#`}>
				<ListItemIcon>
					<PhoneIcon />
				</ListItemIcon>
				<ListItemText primary={ansprechpartner.telefon} secondary="Telefon" />
			</ListItem>
			<ListItem button divider component="a" href={ansprechpartner.mobil !== dash ? `tel:${makeTelLink(ansprechpartner.mobil)}` : `#`}>
				<ListItemIcon>
					<SmartPhoneIcon />
				</ListItemIcon>
				<ListItemText primary={ansprechpartner.mobil} secondary="Mobil" />
			</ListItem>
			<ListItem button divider component="a" href={ansprechpartner.email !== dash ? `mailto:${ansprechpartner.email}` : `#`}>
				<ListItemIcon>
					<EmailIcon />
				</ListItemIcon>
				<ListItemText primary={ansprechpartner.email} secondary="E-Mail" />
			</ListItem>
			<ListItem button>
				<ListItemIcon>
					<PeopleIcon />
				</ListItemIcon>
				<ListItemText primary={ansprechpartner.position} secondary="Position" />
			</ListItem>
		</List>
	);

	const jsx = [];
	const { StyledCard } = props;
	ansprechpartners.forEach((ansprechpartner, index) => {
		if (props.noCard) jsx.push(list(ansprechpartner, index));
		else jsx.push(<StyledCard key={index}>{list(ansprechpartner, 0)}</StyledCard>);
	});
	return jsx;
};

export default TabAnsprechpartner;
