const getDeutschesDatumMonatJahrJs = datumString => {
  return new Date(datumString).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long'
  });
};

const formatierePreisOhneCents = preis => {
  if (preis === null || preis === 'null') return '';
  return preis.toLocaleString('de-DE', {
    maximumFractionDigits: 0
  });
};

export const zeigeChartUmsatz = (chartDataUmsatz, DOMId) => {
  return window.AmCharts.makeChart(DOMId, {
    type: 'stock',
    theme: 'none',
    language: 'de',
    thousandsSeparator: '.',
    decimalSeparator: ',',

    // Daten
    dataSets: [{
      color: '#84b933',
      title: 'Umsatz',
      fieldMappings: [{
        fromField: 'summe',
        toField: 'summe'
      }],
      dataProvider: chartDataUmsatz,
      categoryField: 'date'
    }],
    valueAxesSettings: {
      inside: false,
      unit: ' €'
    },
    // Graph
    panels: [{
      showCategoryAxis: true,
      valueAxes: [{
        id: 'umsatzV',
        dashLength: 5,
        unit: ' €'
      }],
      stockGraphs: [{
        id: 'umsatzG',
        valueField: 'summe',
        type: 'column',
        balloonFunction(item, graph) {
          const datum = getDeutschesDatumMonatJahrJs(item.category);
          const summe = formatierePreisOhneCents(item.values.value);
          return `Umsatz im ${datum}:<br><strong>${summe} €</strong>`;
        },
        fillAlphas: 1
      }]
    }],
    panelsSettings: {
      thousandsSeparator: '.',
      startDuration: 2,
      marginLeft: 70
    },

    // Beschriftung x-Achse
    categoryAxesSettings: {
      minPeriod: 'MM'
    },

    // Wertanzeige bei hover
    chartCursorSettings: {
      valueBalloonsEnabled: true,
      graphBulletSize: 2,
      cursorColor: '#A82E68',
      categoryBalloonFunction(datum) {
        return getDeutschesDatumMonatJahrJs(datum);
      }
    },

    // Leiste oben
    chartScrollbarSettings: {
      graph: 'umsatzG',
      usePeriod: 'MM',
      position: 'top',
      height: 60
    },
    periodSelector: {
      inputFieldsEnabled: false,
      position: 'top',
      periods: [{
        period: 'MM',
        count: 3,
        label: '3 Monate'
      }, {
        period: 'MM',
        count: 12,
        selected: true,
        label: '1 Jahr'
      }, {
        period: 'MM',
        count: 36,
        label: '3 Jahre'
      }, {
        period: 'MAX',
        label: 'gesamt'
      }]
    }
  });
};

export const zeigeVorjahresvergleich = (chartDataUmsatz, DOMId) => {
  const year = new Date().getFullYear();
  const filteredDataUmsatz = chartDataUmsatz.filter(el => el.date.getFullYear() >= year - 2);
  const month = function (i) {
    const months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
    return months[i];
  };
  const transform = new Array(12).fill().map((el, idx) => ({ month: month(idx) }));
  filteredDataUmsatz.forEach(el => {
    const diff = year - el.date.getFullYear();
    const graph = diff === 0 ? 'neu' : diff === 1 ? 'letztes' : 'vorletztes';
    transform[el.date.getMonth()][graph] = el.summe;
  });
  return window.AmCharts.makeChart(DOMId, {
    type: 'serial',
    theme: 'none',
    categoryField: 'month',
    startDuration: 1,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    categoryAxis: {
      gridPosition: 'start',
      position: 'left'
    },
    trendLines: [],
    graphs: [{
      balloonText: `${year}: [[value]] €`,
      fillAlphas: 0.8,
      id: 'AmGraph-3',
      lineAlpha: 0.2,
      title: 'Aktuelles Jahr',
      type: 'column',
      valueField: 'neu'
    }, {
      balloonText: `${year - 1}: [[value]] €`,
      fillAlphas: 0.8,
      id: 'AmGraph-2',
      lineAlpha: 0.2,
      title: 'Letztes Jahr',
      type: 'column',
      valueField: 'letztes'
    }, {
      balloonText: `${year - 2}: [[value]] €`,
      fillAlphas: 0.8,
      id: 'AmGraph-1',
      lineAlpha: 0.2,
      title: 'Vorletztes Jahr',
      type: 'column',
      valueField: 'vorletztes'
    }],
    guides: [],
    valueAxes: [{
      id: 'ValueAxis-1',
      position: 'bottom',
      axisAlpha: 0,
      unit: ' €'
    }],
    allLabels: [],
    balloon: {},
    titles: [],
    dataProvider: transform
  });
};

export const zeigeChartRennerPenner = (data, DOMId) => {
  return window.AmCharts.makeChart(DOMId, {
    type: 'serial',
    theme: 'light',
    language: 'de',
    rotate: true,
    thousandsSeparator: '.',
    decimalSeparator: ',',

    dataProvider: data,

    colors: ['#84b933'],

    valueAxes: [{
      gridColor: '#FFFFFF',
      gridAlpha: 0,
      dashLength: 0,
      unit: ' €'
    }],

    gridAboveGraphs: true,
    startDuration: 2,

    graphs: [{
      balloonFunction(item, graph) {
        const name1 = item.category;
        const umsatz = formatierePreisOhneCents(item.values.value);
        return `${name1}<br>Umsatz <strong>${umsatz} €</strong>`;
      },
      labelFunction(item, graph) {
        return '';
      },
      labelText: ' ',
      fillAlphas: 1,
      lineAlpha: 0.2,
      type: 'column',
      valueField: 'umsatz'
    }],

    categoryField: 'name1',

    balloon: {
      fixedPosition: false
    },

    categoryAxis: {
      tickPosition: 'start',
      tickLength: 5,
      gridAlpha: 0
    },

    chartCursor: {
      cursorAlpha: 0,
      cursorColor: '#A82E68'
    }
  });
};

export const zeigeChartWarengruppenUmsatz = (data, DOMId) => {
  return window.AmCharts.makeChart(DOMId, {
    type: 'serial',
    theme: 'light',
    language: 'de',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    dataProvider: data.dataProvider,
    graphs: data.graphs,
    categoryField: 'monat',
    categoryAxis: {
      gridPosition: 'start',
      axisAlpha: 1,
      gridAlpha: 0,
      position: 'left'
    },
    valueAxes: [{
      stackType: 'regular',
      axisAlpha: 0.3,
      gridAlpha: 0,
      unit: ' €'
    }]
  });
};

export const zeigeUmsatzNachAusland = (data, DOMId) => {
  return window.AmCharts.makeChart(DOMId, {
    type: 'pie',
    theme: 'light',
    language: 'de',
    thousandsSeparator: '.',
    decimalSeparator: ',',
    dataProvider: data.dataProvider,
    titleField: 'title',
    valueField: 'value',
    labelRadius: 5,
    radius: '42%',
    innerRadius: '50%',
    labelText: '[[title]]',
    balloonText: '[[title]]: [[percents]]% [[value]] €',
    balloon: {
      fixedPosition: false
    }
  });
};
