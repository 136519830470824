import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

const IconAvatar = props => {

	const bgColor = () => {
		if (props.color === 'default')
			return '#2e2e2e';
		if (props.color === 'gruen')
			return '#84b933';
		if (props.color === 'rot')
			return '#a82e68';
		if (props.color === 'gelb')
			return '#f5bd00';
	};

	const bgStyle = {
		backgroundColor: bgColor(),
		margin: 10,
		color: '#fafafa'
	};

	return (
		<Avatar style={bgStyle}>
			{props.children}
		</Avatar>
	);
}

IconAvatar.propTypes = {
	color: PropTypes.string.isRequired
};

export default IconAvatar;