import React from 'react';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import IconAvatar from '../../containers/IconAvatar';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
const avatarStyles = theme => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(1)
	}
});
const SplitButton = props => (
	<Button onClick={() => props.callback()}>
		<IconAvatar color="gruen">
			<CallSplitIcon/>
		</IconAvatar>
		<Typography variant="subtitle2">Aufteilen</Typography>
	</Button>
);

export default withStyles(avatarStyles)(SplitButton);
