import Dexie from 'dexie';
import Fetch from '../fetch';
import { uniqueHashCode, semiCamelCase } from '../../functions';
import $ from '../../config';

class Items {

	constructor(props, signal) {
		if (new.target === Items) throw new TypeError('[Items.js] Abstrakte Klassen können nicht direkt instanziiert werden!');
		if (!props) throw new TypeError(`[Items.js] props nicht vorhanden`);
		if (!props.db) throw new TypeError(`[Items.js] db nicht vorhanden`);
		if (!signal) throw new TypeError(`[Items.js] signal nicht vorhanden`);
		this.screenProps = props;
		this.db = props.db;
		this.signal = signal;
		this.fetchDbOrServerGet = this.fetchDbOrServerGet.bind(this);
		this.fetchDbOrServerPost = this.fetchDbOrServerPost.bind(this);
	}

	fetchDbOrServerGet(itemsName, path, forceRefetch = false) {
		const self = this; // wegen Dexie.spawn
		const itemsNameCamel = semiCamelCase(itemsName);

		return new Promise((resolve, reject) => {
			let resultObj;
			Dexie.spawn(function* () {
				try {
					const cachedItems = yield self.db[$.tabCache].get(path.string);

					if (cachedItems && !forceRefetch) {
						// es gibt gecachte Sachen, also diese nehmen!
						resultObj = {
							path: path.string,
							results: {
								totalResultCount: cachedItems.results.totalResultCount,
								[itemsNameCamel]: cachedItems.results[itemsNameCamel]
							}
						};
						resolve(resultObj);
						return;
					} else {
						// keine gecachte Sachen da oder forceRefetch, also welche vom Server holen!
						new Fetch(self.screenProps).get(path, self.signal, itemsName).then(json => {
							Dexie.spawn(function* () {
								try {

									let daten, { totalResultCount } = json;
									if (typeof totalResultCount === 'undefined') totalResultCount = 1;

									if (json.daten && json.daten.length) {
										// Statistik-Call
										daten = json.daten[0].datensaetze;
									} else {
										// nicht-Statistik-Call
										daten = json.result;
									}
									if (!daten) daten = null;

									resultObj = {
										path: path.string,
										results: {
											totalResultCount,
											[itemsNameCamel]: daten
										}
									};

									if (forceRefetch) yield self.db[$.tabCache].update(path.string, resultObj);
									else yield self.db[$.tabCache].put(resultObj);

									resolve(resultObj);
									return;
								} catch (err) {
									reject(err);
									return;
								}
							});
						})
							.catch(err => {
								reject(err);
								return;
							});
					}
				} catch (err) {
					reject(err);
					return;
				}
			});
		});
	}

	fetchDbOrServerPost(itemsName, path, sendData, cache = true) {
		const self = this; // wegen Dexie.spawn
		const itemsNameCamel = semiCamelCase(itemsName);
		const pathPlusHash = `${path.string}?${uniqueHashCode(JSON.stringify(sendData))}`;

		return new Promise((resolve, reject) => {
			let resultObj;
			Dexie.spawn(function* () {
				try {
					const cachedItems = yield self.db[$.tabCache].get(pathPlusHash);
					if (cache && cachedItems) {
						// es gibt gecachte Sachen, also diese nehmen!
						resultObj = {
							path: pathPlusHash,
							results: {
								totalResultCount: cachedItems.results.totalResultCount,
								[itemsNameCamel]: cachedItems.results[itemsNameCamel]
							}
						};
						resolve(resultObj);
						return;
					} else {
						// keine gecachte Sachen da, also welche vom Server holen!
						new Fetch(self.screenProps).post(path, self.signal, itemsName, sendData).then(json => {
							Dexie.spawn(function* () {
								try {
									let daten = json.result, { totalResultCount } = json;
									if (typeof totalResultCount === 'undefined') totalResultCount = 1;
									resultObj = {
										path: pathPlusHash,
										results: {
											body: sendData,
											totalResultCount,
											[itemsNameCamel]: daten
										}
									};
									yield self.db[$.tabCache].put(resultObj);
									resolve(resultObj);
									return;
								} catch (err) {
									reject(err);
									return;
								}
							});
						})
							.catch(err => {
								reject(err);
								return;
							});
					}
				} catch (err) {
					reject(err);
					return;
				}
			});
		});
	}
}

export default Items;
