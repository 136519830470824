import Items from '.';
import { writeNullToKeys, semiCamelCase } from '../../functions';

class FetchAdressen extends Items {
	constructor(props, signal) {
		super(props, signal);
		this.path = {
			string: props.listingPath,
			id: null
		};
		this.itemsName = props.config.itemsName;
		this.camelName = semiCamelCase(this.itemsName);
		this.fetch = this.fetch.bind(this);
	}

	async fetch() {
		const json = await super.fetchDbOrServerGet(this.itemsName, this.path);
		return {
			totalResultCount: json.results.totalResultCount,
			[this.camelName]: writeNullToKeys(json.results[this.camelName])
		};
	};
}

export default FetchAdressen;