import Items from '.';
import { formatDatum, writeNullToKeys, formatPreis, getMitarbeiterNameVonKuerzel, chance, convertRTF } from '../../functions';
import $ from '../../config';
import FetchAnsprechpartnerZuAdresse from './FetchAnsprechpartnerZuAdresse';
import FetchAdresse from './FetchAdresse';

class FetchDeal extends Items {

	constructor(props, signal, dealId) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		if (!dealId || isNaN(dealId)) throw new TypeError(`[Deal.js] dealId nicht vorhanden`);
		this.path = {
			string: `crm/deals/${dealId}`,
			id: dealId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Deal', this.path).then(json => {

			if (!json.path || !json.results || !json.results.deal) {
				reject('[Deal.js] !json.path || !json.results || !json.results.deal');
				return;
			}

			let { deal } = json.results, ansPromise = null, notizPromise = null, adrPromise = null;

			deal.typ = $.dealTypen[deal.typ];
			deal.status_text = $.dealStatus[deal.status];
			deal.phase_text = $.dealPhasen[deal.phase];
			deal.investitionsdatumErreicht = formatDatum(deal.investitionsdatumErreicht);
			deal.investitionsdatumGeplant = formatDatum(deal.investitionsdatumGeplant);
			deal.investitionssummeErreicht = formatPreis( deal.investitionssummeErreicht);
			deal.investitionssummeGeplant = formatPreis( deal.investitionssummeGeplant);
			deal.chance = chance(deal.chance);
			const benPromise = getMitarbeiterNameVonKuerzel(this.props.db, deal.zustaendigerBenutzer);
			if (deal.ansprechpartnerId) ansPromise = new FetchAnsprechpartnerZuAdresse(this.props, this.signal, deal.ansprechpartnerId).fetch();
			if (deal.notiz) notizPromise = convertRTF(deal.notiz);
			adrPromise = new FetchAdresse(this.props, this.signal, deal.adresseId).fetch();

			Promise.all([benPromise, adrPromise, ansPromise, notizPromise]).then(values => {

				for (let k = 0; k < values.length; k++) {
					const value = values[k];
					if (typeof value === 'string') {
						if (benPromise && k === 0) {
							// zuständiger Benutzer
							// Benutzername zusätzlich zur ID
							deal.zustaendigerBenutzer = value;
						} else if (notizPromise && k > 0) {
							// Notiz von RTF konvertieren
							deal.notiz = value;
						}
					} else if (value instanceof Object) {
						if (adrPromise && value.adressnummer) {
							// Adresse ergänzen
							const adr = value;
							deal.adresse = {
								anrede: adr.anrede,
								name1: adr.name1,
								name2: adr.name2,
								email: adr.email,
								telefon: adr.telefon1,
								mobil: adr.mobil,
								strasse: adr.strasse,
								plz: adr.plz,
								ort: adr.ort,
								webseite: adr.webseite
							};
						} else if (ansPromise && value.path && value.path.includes('ansprechpartner') && value.results.ansprechpartner) {
							// Anprechpartner ergänzen
							const ans = value.results.ansprechpartner;
							deal.ansprechpartner = {
								anrede: ans.anrede,
								name: ans.name,
								email: ans.email,
								telefon: ans.telefon,
								mobil: ans.mobil
							};
						}
					}
				}

				deal = writeNullToKeys(deal);
				resolve(deal);
			}); //Promise.all
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
	});
}

export default FetchDeal;
