import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Modal from '../../containers/Modal';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { initComponent, checkOnline, getVersion } from '../../functions'
import Dexie from 'dexie';
import $ from '../../config';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import varioLogo from '../../assets/images/global/vario-logo.svg';

const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
	},
	footer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	progress: {
		top: 68
	},
	progress_placeholder: {
		top: 68,
		height: 4
	},
	logo: {
		marginTop: theme.spacing(1),
		width: 170
	}
});

class LoginScreen extends Component {
	//#region
	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this.timer = null;
		this._isMounted = false;
		this.basePath = `/vario-api/stammdaten`;
		this.progressbarStep = 13;

		this.state = {
			login: '',
			passwort: '',
			zeigePasswort: false,
			RESTserverOnline: false,
			RESTserverUndefined: true,
			angemeldetBleiben: false,
			loggingIn: false,

			/** Progress Modal */
			pmOpen: false,
			pmProgressCompleted: 0,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: '',
			emHandleOk: () => this.setState({ emOpen: false }),
			emDestroy: () => this.setState({ emOpen: false }),
			emCancelBtn: false,

			/** Sonstiges */
			fetchObj: {}
		};

		this.pruefeLogin = this.pruefeLogin.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
		this.handleClickZeigePasswort = this.handleClickZeigePasswort.bind(this);
		this.onLoginSubmit = this.onLoginSubmit.bind(this);
		this.showErrorModal = this.showErrorModal.bind(this);
		this.pmDestroy = this.pmDestroy.bind(this);
		this.online = this.online.bind(this);
	}

	UNSAFE_componentWillMount() {
		document.title = this.titleOnline;
		initComponent(this.props, 'LoginScreen.class');
	}

	componentDidMount() {
		this._isMounted = true;
		window.addEventListener('online', this.online);
		window.addEventListener('offline', this.online);
		this.online();
	}

	componentDidUpdate() {
		if (this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(() => this.online(), 10000);
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
		window.removeEventListener('online', this.online);
		window.removeEventListener('offline', this.online);
	}

	online() {
		checkOnline(this.controller.signal).then(response => {
			if (this._isMounted)
				this.setState({
					RESTserverUndefined: false,
					RESTserverOnline: response.RESTserverOnline
				});
		});
	}

	pruefeLogin() {

		if (!navigator.onLine) {
			this.setState({
				RESTserverUndefined: false,
				RESTserverOnline: false,
				loggingIn: false,
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Sie sind OFFLINE. Keine Daten verfügbar.'
			});
			return;
		}

		const loginEingegeben = this.state.login.trim().toUpperCase();
		const passwortEingegeben = this.state.passwort.trim();
		const firstLoginToken = btoa(`${loginEingegeben}:${passwortEingegeben}`);

		if (!loginEingegeben || !passwortEingegeben) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Bitte füllen Sie die Felder aus!',
				loggingIn: false
			});
			return;
		}

		// login prüfen...
		this.setState({ pmOpen: true, loggingIn: true, pmProgressCompleted: this.state.pmProgressCompleted + this.progressbarStep });

		const headers = {
			headers: {
				Authorization: `Basic ${firstLoginToken}`,
				signal: this.controller.signal
			}
		};
		fetch(`${window.RESTserver}/vario-api/login`, headers)
			.then(response => {
				if (response.ok) return response.json();
				throw response;
			})
			.then(data => {

				if (data.token) {

					// login prüfen fertig
					const bearerToken = data.token;
					sessionStorage.removeItem($.logoutModusName);
					sessionStorage.setItem($.benutzerKuerzelName, loginEingegeben);
					sessionStorage.setItem($.bearerTokenName, bearerToken);
					if (this.state.angemeldetBleiben) {
						localStorage.setItem($.benutzerKuerzelName, loginEingegeben);
						localStorage.setItem($.bearerTokenName, bearerToken);
					}
					this.setState({
						pmProgressCompleted: this.state.pmProgressCompleted + this.progressbarStep,
						fetchObj: {
							headers: {
								Authorization: `Bearer ${bearerToken}`,
								signal: this.controller.signal
							}
						}
					});

					const basisdatenPath = '/vario-api/webapp/basisdaten';
					fetch(`${window.RESTserver}${basisdatenPath}`, this.state.fetchObj)
						.then(response => {
							const json = response.json();
							if (response.ok) return json;

							// Fehler: Promise-Objekt aus json ziehen
							return json.then(Promise.reject.bind(Promise));
						})
						.then(json => {

							this.setState({ pmProgressCompleted: this.state.pmProgressCompleted + this.progressbarStep });

							const arraysFromServer = new Map();
							for (const tabellenName in json) {
								const array = json[tabellenName];
								arraysFromServer.set(tabellenName, array);
							}

							// Daten des eingeloggten Benutzers holen
							const benutzerArr = json.benutzer;
							if (!benutzerArr.length) console.error('[LoginScreen.class]: !benutzerArr.length');
							for (let i = 0; i < benutzerArr.length; i++) {
								const benutzerObj = benutzerArr[i];
								const kuerzel = benutzerObj.kuerzel.toUpperCase();
								if (kuerzel === loginEingegeben) {
									const benutzerId = benutzerObj.id;
									const benutzerName = benutzerObj.name;
									sessionStorage.setItem($.benutzerIdName, benutzerId);
									sessionStorage.setItem($.benutzernameName, benutzerName);
									if (this.state.angemeldetBleiben) {
										localStorage.setItem($.benutzerIdName, benutzerId);
										localStorage.setItem($.benutzernameName, benutzerName);
									}
									break;
								}
							}

							//#region
							const self = this;
							const setDbData = (tabelleName, array) => {
								return new Promise((resolve, reject) => {

									const convertBool2IntInArr = dataArr => {
										for (let i = 0; i < dataArr.length; i++) {
											const item = dataArr[i];
											for (let prop in item) {
												if (item[prop] === true) item[prop] = 1;
												else if (item[prop] === false) item[prop] = 0;
											}
										}
										return dataArr;
									};

									Dexie.spawn(function* () {
										try {
											if (!self.props.db[tabelleName]) return;
											yield self.props.db[tabelleName].clear();
											array = convertBool2IntInArr(array);
											yield self.props.db[tabelleName]
												.bulkPut(array)
												.catch(Dexie.BulkError, e => console.log(e));
											resolve(tabelleName);
										} catch (e) {
											console.log(e);
										}
									});
								});
							}

							const promises = [];

							const dealsPing = fetch(`${window.RESTserver}/vario-api/crm/deals?limit=1&filter=istArchiviert=false`, this.state.fetchObj)
								.then(response => {
									this.setState({ pmProgressCompleted: this.state.pmProgressCompleted + this.progressbarStep });
									const json = response.json();
									if (response.ok) return json;

									// Fehler: Promise-Objekt aus json ziehen
									return json.then(Promise.reject.bind(Promise));
								})
								.then(json => json)
								.catch(err => {
									console.log(err);
									console.log(62738420);
								});

							promises.push(dealsPing);

							const serviceauftraegePing = fetch(`${window.RESTserver}/vario-api/service/auftraege?limit=1`, this.state.fetchObj)
								.then(response => {
									this.setState({ pmProgressCompleted: this.state.pmProgressCompleted + this.progressbarStep });
									const json = response.json();
									if (response.ok) return json;

									// Fehler: Promise-Objekt aus json ziehen
									return json.then(Promise.reject.bind(Promise));
								})
								.then(json => json)
								.catch(err => {
									console.log(err);
									console.log(62738420);
								});

							promises.push(serviceauftraegePing);
							//#endregion

							for (let i = 0; i < $.dbVersions.length; i++) {
								const dbVersion = $.dbVersions[i];
								for (let tabelleName in dbVersion) {

									// Interne Tabellen haben keinen Request und damit keinen JSON
									if ($.interneTabellen.includes(tabelleName)) continue;

									const _array = arraysFromServer.get(tabelleName);
									if (Array.isArray(_array)) promises.push(setDbData(tabelleName, _array));
								}
							}

							Promise
								.all(promises)
								.then(values => {

									// values[0] ist Deals-json
									if (values[0].totalResultCount > 0) {
										sessionStorage.setItem($.hatDealsName, true);
										if (this.state.angemeldetBleiben) localStorage.setItem($.hatDealsName, true);
									}

									// values[1] ist Serviceauftraege-json
									if (values[1].totalResultCount > 0) {
										sessionStorage.setItem($.hatServiceauftraegeName, true);
										if (this.state.angemeldetBleiben) localStorage.setItem($.hatServiceauftraegeName, true);
									}

									this.setState({ pmProgressCompleted: this.state.pmProgressCompleted + this.progressbarStep });

									Dexie.spawn(function* () {
										try {
											yield self.props.db[$.tabGespeichertListen].put({
												id: 1,
												gespeichertUm: Math.floor(new Date().getTime() / 1000 / 3600)
											});
											self.setState({ pmProgressCompleted: self.state.pmProgressCompleted + self.progressbarStep });
											setTimeout(() => {
												self.props.history.replace('/app/dashboard');
											}, 500);
										} catch (e) {
											console.log(e);
										}
									});

								})
								.catch(err => {
									console.error(err);
									console.error(753476030);
								});

						})
						.catch(error => {
							this.showErrorModal(`Request "webapp/basisdaten": ${error.errorMessage}`);
							console.error(error);
							console.log(65393520);
						});

				}
			})
			.catch(error => {
				this.showErrorModal('Zugangsdaten nicht korrekt');
				console.log(error);
			});
	}

	handleChange = prop => e => this.setState({ [prop]: e.target.value });

	handleChangeCheckbox = prop => e => this.setState({ [prop]: e.target.checked });

	handleClickZeigePasswort() {
		this.setState(state => ({ zeigePasswort: !state.zeigePasswort }))
	}

	onLoginSubmit(e) {
		this.pruefeLogin();
		e.preventDefault();
	}

	showErrorModal(text) {
		this.pmDestroy();
		this.setState({
			emOpen: true,
			emTitle: 'Fehler',
			emText: text,
			loggingIn: false
		});
	}

	pmDestroy() {
		this.setState({ pmOpen: false });
		/** Requests abbrechen */
		this.controller.abort();
	}
	//#endregion

	render() {

		const { classes } = this.props;

		const modal = (
			<Modal
				open={this.state.emOpen}
				title={this.state.emTitle}
				text={this.state.emText}
				handleOk={this.state.emHandleOk}
				cancelBtn={this.state.emCancelBtn}
				destroy={this.state.emDestroy}
			/>
		);

		const versionStr = `${new Date().getFullYear()} Vario Software-Entwicklungs AG`;

		return (
			<main className={classes.main}>
				{modal}

				{this.state.pmOpen ?
					<LinearProgress color="secondary" variant="determinate" className={classes.progress} value={this.state.pmProgressCompleted} />
					: <div className={classes.progress_placeholder} />}

				<Paper className={classes.paper}>

					<div><img src={varioLogo} alt="VARIO Logo" className={classes.logo} /><span style={{ fontSize: '2.0rem' }}>app</span></div>

					<Typography variant="h5" style={{ marginTop: '5px' }}>
						Anmeldung
					</Typography>

					<form
						className={classes.form}
						action="#"
						onSubmit={e => { this.onLoginSubmit(e) }}>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="loginInput">Benutzername</InputLabel>

							<Input
								id="loginInput"
								autoComplete="username"
								value={this.state.login}
								onChange={this.handleChange('login')}
								disabled={this.state.loggingIn}
							/>
						</FormControl>

						<FormControl margin="normal" required fullWidth>
							<InputLabel htmlFor="passwortInput">Passwort</InputLabel>

							<Input
								id="passwortInput"
								autoComplete="current-password"
								type={this.state.zeigePasswort ? 'text' : 'password'}
								value={this.state.passwort}
								onChange={this.handleChange('passwort')}
								disabled={this.state.loggingIn}
								endAdornment={
									<InputAdornment position="end">
										<IconButton aria-label="Passwort anzeigen" onClick={this.handleClickZeigePasswort}>
											{this.state.zeigePasswort ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>

						<div style={{ height: '10px' }} />

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							disabled={!this.state.RESTserverOnline || this.state.loggingIn}
						>
							{this.state.RESTserverOnline ? 'ANMELDEN' : 'Der REST-Server ist offline'}
						</Button>
					</form>
				</Paper>

				<div style={{ height: '10px' }} />
				<Typography className={classes.footer} variant="caption">&copy; {versionStr}</Typography>
				<Typography className={classes.footer} variant="caption">{getVersion()}</Typography>
			</main>
		);
	};
}

//#region
LoginScreen.propTypes = {
	classes: PropTypes.object.isRequired
};

//LoginScreen.contextType = React.context.DbContext;


export default withStyles(styles)(LoginScreen);
//#endregion
