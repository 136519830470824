import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const ListViewBelege = props => {

	if (!props.items) return null;
	//console.log(props.items)

	const StyledAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);

	const handleClick = id => {
		if (isNaN(id)) {
			console.warn(56754235283);
			return null;
		}
		if (props.writeUriAndSelectId) return props.writeUriAndSelectId(id);
		else if (props.handleClick) return props.handleClick(id);
		else console.warn('[ListViewBelege.class] kein Clickhandler in den props');
	};

	const StyledTypography = withStyles({
		root: {
			display: 'flex',
			justifyContent: 'space-between'
		}
	})(Typography);

	return (
		<List style={props.listStyle}>
			{props.items.map((beleg, i) =>
				<ListItem
					key={i}
					button
					divider={i + 1 !== props.items.length ? true : false}
					selected={props.selectedId === beleg.id}
					onClick={() => handleClick(beleg.id)}
				>
					<StyledAvatar><ReceiptIcon /></StyledAvatar>

					<ListItemText
						disableTypography
						primary={
							<Typography
								noWrap
								variant="body1"
							>
								{`${beleg.belegart} ${beleg.belegnummer}`}
							</Typography>
						}
						secondary={
							<>
								<Typography
									component="p"
									variant="body2"
									color="textSecondary"
									noWrap
								>
									{beleg.adresse}
								</Typography>
								<StyledTypography
									component="p"
									variant="body2"
									color="textSecondary"
								>
									<span>{`Betrag: ${beleg.betragNetto}`}</span>
									<span>{beleg.belegdatum}</span>
								</StyledTypography>
							</>
						}
					/>
				</ListItem>
			)}
		</List>
	);
};

export default ListViewBelege;