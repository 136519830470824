import Items from '.';
import { getCurrentFilename, formatDealsListe } from '../../functions';

class FetchDealsZuAdresse extends Items {

	constructor(props, signal, adresseId) {
		super(props, signal);
		if (!adresseId || isNaN(adresseId)) throw new TypeError(`[${getCurrentFilename(__filename)}] adresseId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.path = {
			string: `stammdaten/adressen/${adresseId}/deals`,
			id: adresseId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Deals zu Adresse', this.path).then(json => {

			if (!json.path || !json.results || !json.results.dealsZuAdresse) {
				resolve([]);
				return;
			}

			const deals = json.results.dealsZuAdresse;

			// wenn es keine Deals gibt
			if (deals.length === 0) {
				resolve([]);
				return;
			}

			formatDealsListe(this.props.db, deals).then(formatierteDeals => {
				resolve(formatierteDeals);
			});
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Belege zu Adresse'
	}).catch(err => console.error(err));
}

export default FetchDealsZuAdresse;