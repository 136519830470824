import React, { useState } from 'react';
import SplitController from './SplitController';
import IterableAcceptDecline from './iterableAcceptDecline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import store from '../index';
import { checkItem, removeItem } from '../actions';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';

const StyledCard = withStyles({
	root: {
		margin: '8px',
		padding: 8
	}
})(Card);

const Position = (props) => {
	const [status, updateStatus] = useState(props.status);
	let mostRecent = status > props.status ? status : props.status;
	return (
		<StyledCard>
			<Grid container spacing={1}>
				<Grid xs={10} item container justify="center"><Typography variant="h6" color="secondary">{props.menge}x {props.artikelbezeichnung}</Typography></Grid>
				<Grid item xs={2}>
					{
						props.menge > 1 &&
						<SplitController {...props} status={mostRecent}/>
					}
				</Grid>
				{props.langtext &&
				<Grid xs={12} item>
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content">
							<Typography variant="subtitle2">Beschreibung</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<Typography>
								{props.langtext}
							</Typography>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</Grid>
				}
				<Grid item xs={12}>
					<IterableAcceptDecline
						status={status}
						acceptStates={['ja1', 'ja2', 'ja3']}
						rejectStates={['ne1', 'ne2', 'ne3']}
						callback={(current, accept) => {
							updateStatus(mostRecent + 1);
							const ref = props.index || props.id;
							//TODO "special" submit actions, "exit stations"
							if (!accept) {
								store.dispatch(removeItem(props.id, mostRecent, ref));
							} else {
								store.dispatch(checkItem(props.id, mostRecent, ref));
							}
						}}
						controlled={mostRecent}
					/>
				</Grid>
			</Grid>
		</StyledCard>
	);
};

export default Position;
