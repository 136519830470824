import Items from '.';
import DealsZuAdresse from './FetchDealsZuAdresse';
import FetchBelegeZuAdresse from './FetchBelegeZuAdresse';
import FetchAktivitaetenZuAdresse from './FetchAktivitaetenZuAdresse';
import { writeNullToKeys, getCurrentFilename } from '../../functions';
import FetchAdresse from './FetchAdresse';
import FetchAnsprechpartnerZuAdresse from "./FetchAnsprechpartnerZuAdresse";
import FetchOffenePostenUebersichtAdresse from './FetchOffenePostenUebersichtAdresse';
import FetchUmsatzZuAdresse from './FetchUmsatzZuAdresse';
import FetchZusatzadressenZuAdresse from './FetchZusatzadressenZuAdresse';
import $ from '../../config';

class FetchAdresseDetails extends Items {

	constructor(props, signal, adresseId, itemNode) {
		super(props, signal);
		if (!adresseId || isNaN(adresseId)) throw new TypeError(`[${getCurrentFilename(__filename)}] adresseId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.adresseId = adresseId;
		this.itemNode = itemNode;
		this.path = {
			string: `stammdaten/adressen/${adresseId}`,
			id: adresseId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {

		if (this.itemNode === $.ansprechpartnersNode) {
			resolve({})
			return;
		}

		let adresse, deals, belege, aktivitaeten, ansprechpartner, offenePostenUebersicht, umsatz, zusatzadressen;
		const promises = [
			new FetchAdresse(this.props, this.signal, this.adresseId).fetch(),
			new DealsZuAdresse(this.props, this.signal, this.adresseId).fetch(),
			new FetchBelegeZuAdresse(this.props, this.signal, this.adresseId).fetch(),
			new FetchAktivitaetenZuAdresse(this.props, this.signal, this.adresseId).fetch(),
			new FetchAnsprechpartnerZuAdresse(this.props, this.signal, this.adresseId).fetch(),
			new FetchOffenePostenUebersichtAdresse(this.props, this.signal, this.adresseId).fetch(),
			new FetchUmsatzZuAdresse(this.props, this.signal, this.adresseId).fetch(),
			new FetchZusatzadressenZuAdresse(this.props, this.signal, this.adresseId).fetch()
		];

		Promise.all(promises).then(json => {

			[adresse, deals, belege, aktivitaeten, ansprechpartner, offenePostenUebersicht, umsatz, zusatzadressen] = json;

			const obj = {
				adresse: adresse ? adresse : null,
				deals: deals ? deals : null,
				belege: belege ? belege : null,
				aktivitaeten: aktivitaeten ? aktivitaeten : null,
				ansprechpartner: ansprechpartner ? ansprechpartner : null,
				offenePostenUebersicht: offenePostenUebersicht ? offenePostenUebersicht : null,
				umsatz: umsatz ? umsatz : null,
				zusatzadressen: zusatzadressen ? zusatzadressen : null
			};

			resolve(writeNullToKeys(obj));
		}).catch(err => {
			reject(err);
			console.warn(78350205621);
		}); //Promise.all(promises)
	}).catch(err => {
		console.warn(7023926935623);
		console.error(err);
	});
}

export default FetchAdresseDetails;