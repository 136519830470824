import Items from '.';
import { getCurrentFilename } from '../../functions';

class FetchUserEinstellung extends Items {

  constructor (props, signal, id, userId) {
    super(props, signal);
    if (!id || isNaN(id)) throw new TypeError(`[${getCurrentFilename(__filename)}] id nicht vorhanden`);
    this.path = {
      string: `einstellungen/${userId}/${id}`,
			id,
			userId
    };
    this.fetch = this.fetch.bind(this);
  }

  fetch = () => new Promise((resolve, reject) => {
    super.fetchDbOrServerGet('Einstellung', this.path).then(json => {
      if (!json.path || !json.results || !json.results.einstellung){
        reject('[Einstellung.js] !json.path || !json.results || !json.results.einstellung');
        return;
      }
			let { einstellung } = json.results;
      resolve(einstellung);
    }).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
  });
}

export default FetchUserEinstellung;
