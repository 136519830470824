import Items from '.';

class BelegeZuDeal extends Items {

	constructor(props, signal, dealId) {
		super(props, signal);
		if (!dealId || isNaN(dealId)) throw new TypeError(`[BelegeZuDeal.js] dealId nicht vorhanden`);
		this.itemsName = 'Belege zu Deal';
		this.path = {
			string: `crm/deals/${dealId}/belege`,
			id: dealId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => super.fetchDbOrServerGet(this.itemsName, this.path);
}

export default BelegeZuDeal;
