import AcceptDecline from '../components/acceptDecline';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const AcceptDeclineOnce = props => {
	if (!props.pending) {
		return (<Typography align="center" variant="h4" color="primary">{props.accepted ? props.donePrimary : props.doneSecondary}</Typography>
		);
	}
	return (
		<AcceptDecline {...props}/>
	);
};
export default AcceptDeclineOnce;
