import Items from '.';
import { formatPreis } from '../../functions';

class FetchArtikelStammdaten extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[FetchArtikelStammdaten.js] id nicht vorhanden`);
		this.path = {
			string: `stammdaten/artikel/${id}`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Artikel Stammdaten', this.path).then(json => {

			if (!json.path || !json.results || !json.results.artikelStammdaten) {
				reject('[FetchArtikelStammdaten.js] !json.path || !json.results || !json.results.artikelStammdaten');
				return;
			}

			let artikel = json.results.artikelStammdaten;

			artikel.vkNettoZahl = artikel.vkNetto;

			artikel.vkBrutto = formatPreis( artikel.vkBrutto);
			artikel.vkNetto = formatPreis( artikel.vkNetto);
			artikel.gewicht = `${artikel.gewicht || 0} kg`;

			resolve(artikel);
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
	});
}

export default FetchArtikelStammdaten;
