import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../containers/Modal';
import '../../css/global.css';
import FetchOffenePostenUebersicht from '../../classes/items/FetchOffenePostenUebersicht'
import StatistikUmsatz from '../../classes/items/StatistikUmsatz';
import StatistikWarengruppenUmsatz from '../../classes/items/StatistikWarengruppenUmsatz';
import * as Amcharts from '../../functions/Amcharts';
import { grabArrFromObjectArray } from '../../functions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Divider } from '@material-ui/core';
import Banner from '../../containers/Banner';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import $ from '../../config';
import SortIcon from '@material-ui/icons/Sort';
import BarChartIcon from '@material-ui/icons/BarChart';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PieChartIcon from '@material-ui/icons/PieChart';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Auswertungsgruppe from '../../containers/auswertungen/Auswertungsgruppe';


const styles = theme => ({
	root: {
		padding: theme.spacing(1)
	},
	tableroot: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto'
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 1101,
		color: 'white'
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	avatarBlue: {
		backgroundColor: blue[500],
		margin: theme.spacing(1)
	},
	avatarOrange: {
		backgroundColor: orange[500],
		margin: theme.spacing(1)
	},
	avatarUmsatz: {
		backgroundColor: red[500],
		margin: theme.spacing(1)
	},
	fullheight: {
		height: '100%'
	},
	title: {
		fontSize: 20
	},
	iconButtonOP: {
		padding: '6px'
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	}
});

class Dashboard extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.charts = [];
		this.data = [{
			fun: Amcharts.zeigeChartUmsatz,
			ueberschrift: $.umsatzTitel,
			promise: new StatistikUmsatz(this.props, this.controller.signal).fetch()
		}, {
			fun: Amcharts.zeigeVorjahresvergleich,
			ueberschrift: $.dreiJahresumsatzTitel,
			promise: new StatistikUmsatz(this.props, this.controller.signal).fetch()
		}, {
			fun: Amcharts.zeigeChartWarengruppenUmsatz,
			ueberschrift: $.warengruppenUmsatzTitel,
			promise: new StatistikWarengruppenUmsatz(this.props, this.controller.signal).fetch()
		}];

		this.state = {
			charts: [],
			offenePosten: null,
			isFetchingOP: true,
			isFetchingCharts: true,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		}

		this.cancelRequests = this.cancelRequests.bind(this);
		this.printCharts = this.printCharts.bind(this);
		this.getOffenePostenData = this.getOffenePostenData.bind(this);
		this.updateButtonsize = this.updateButtonsize.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.updateUeberschriftFromChild(`Dashboard`);
		this.printCharts();
		this.getOffenePostenData();
		window.addEventListener('resize', this.updateButtonsize);
	}

	componentDidUpdate() {
		if (this.props.getIsFetching() && !this.state.isFetchingCharts && !this.state.isFetchingOP)
			this.props.setIsFetching(false);
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	cancelRequests() {
		this.controller.abort();
	}

	printCharts() {
		const getDOMId = i => `DashboardCharts${i}`;
		this.setState({ isFetchingCharts: true });

		Promise.all(grabArrFromObjectArray(this.data, 'promise')).then(chartDatas => {

			const charts = [], newChartDatas = [], newThisData = [];
			let i;

			for (i = 0; i < chartDatas.length; i++) {
				const chartData = chartDatas[i];
				if (chartData === {} || (Array.isArray(chartData) && !chartData.length)) continue;
				newChartDatas.push(chartData);
				newThisData.push(this.data[i]);
			}

			// index umsortieren
			for (i = 0; i < newChartDatas.length; i++) {
				charts.push({
					data: newChartDatas[i],
					DOMId: getDOMId(i),
					ueberschrift: newThisData[i].ueberschrift
				});
			}

			if (this._isMounted) {
				this.setState({
					charts,
					isFetchingCharts: false
				}, () => {
					for (i = 0; i < newChartDatas.length; i++) newThisData[i].fun(newChartDatas[i], getDOMId(i));
				});
			}
		});
	}

	getOffenePostenData() {
		this.setState({ isFetchingOP: true });
		new FetchOffenePostenUebersicht(this.props, this.controller.signal).fetch().then(offenePosten => {
			if (!offenePosten || offenePosten.length) return null;
			if (this._isMounted) this.setState({
				offenePosten,
				isFetchingOP: false
			});
		});
	}

	updateButtonsize() {
		// stößt render() erneut an
		this.forceUpdate();
	}

	render() {
		const { classes } = this.props;

		const modal = (
			<Modal
				open={this.state.emOpen}
				title={this.state.emTitle}
				text={this.state.emText}
				handleOk={() => this.setState({ emOpen: false })}
				cancelBtn={false}
				destroy={() => this.setState({ emOpen: false })}
			/>
		);

		const getAvatar = title => {
			if (title === $.rennerPennerTitel) return <SortIcon className={classes.icon} />;
			if (title === $.umsatzTitel) return <InsertChartIcon className={classes.icon} />;
			if (title === $.dreiJahresumsatzTitel) return <InsertChartOutlinedIcon className={classes.icon} />;
			if (title === $.warengruppenUmsatzTitel) return <BarChartIcon className={classes.icon} />;
			if (title === $.umsatzNachAuslandTitel) return <PieChartIcon className={classes.icon} />;
		};

		const
			_item = {
				item: true,
				xs: 12,
				md: 6
			},
			b = { margin: '4px' },
			StyledItem = withStyles({ item: { width: '100%' } })(Grid);

		let MyItem = Grid;
		if (window.innerWidth < 720) {
			b.width = '100%';
			MyItem = StyledItem;
		}
		
		return (
			<>
				{(this.state.isFetchingCharts || this.state.isFetchingOP) && <LinearProgress color="secondary" className="global_progress_bar" />}

				<div className={classes.root}>

					<Grid container spacing={1}>

						<Grid item={true} xs={12}>
							<Card>
								<CardContent>
									<Grid container justify="space-between">
										<MyItem item={true}>
											<Button style={b} variant="contained" color="secondary" href={`/app/${$.adressenNode}?adrSearch&belegart=0100`}>
												<NoteAddIcon className={classes.leftIcon} />
												Angebot erfassen
                      </Button>
										</MyItem>
										<MyItem item={true}>
											<Button style={b} variant="contained" color="secondary" href={`/app/${$.adressenNode}?adrSearch&belegart=0200`}>
												<ReceiptIcon className={classes.leftIcon} />
												Auftrag erfassen
                      </Button>
										</MyItem>
										<MyItem item={true}>
											<Button style={b} variant="contained" color="secondary" href="/app/auswertungen">
												<TrendingUpIcon className={classes.leftIcon} />
												alle Auswertungen
                      </Button>
										</MyItem>
									</Grid>
								</CardContent>
							</Card>
						</Grid>

						{/* alle Cards außer Offene Posten */}
						{this.state.charts.map((chart, index) =>

							<Grid key={index} {..._item}>
								<Card className={classes.fullheight}>

									<CardHeader
										avatar={getAvatar(chart.ueberschrift)}
										title={chart.ueberschrift}
										classes={{ title: classes.title }}
									/>

									<Divider />

									<CardContent>
										<div id={chart.DOMId} className="chartdiv"></div>
									</CardContent>

								</Card>
							</Grid>
						)}

						{/* Offene Posten */}
						<Grid  {..._item}>
							<Card className={classes.fullheight}>
								<CardHeader
									title="Offene Posten"
									avatar={<ErrorOutlineIcon className={classes.icon} />}
									classes={{ title: classes.title }}
									action={
										<Link href="/app/offeneposten">
											<IconButton className={classes.iconButtonOP}>
												<InfoIcon color="primary" />
											</IconButton>
										</Link>
									}
								/>

								<Divider />

								<CardContent className={classes.tableroot}>
									{this.state.offenePosten ?
										<>
											<Table className={classes.table}>
												<TableBody>
													<TableRow hover>
														<TableCell>Mahnstufe 0</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe0_Anzahl} Stück</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe0_Betrag}</TableCell>
													</TableRow>
													<TableRow hover>
														<TableCell>Mahnstufe 1</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe1_Anzahl} Stück</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe1_Betrag}</TableCell>
													</TableRow>
													<TableRow hover>
														<TableCell>Mahnstufe 2</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe2_Anzahl} Stück</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe2_Betrag}</TableCell>
													</TableRow>
													<TableRow hover>
														<TableCell>Mahnstufe 3</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe3_Anzahl} Stück</TableCell>
														<TableCell align="right">{this.state.offenePosten.stufe3_Betrag}</TableCell>
													</TableRow>
													<TableRow hover>
														<TableCell component="th" variant="head">Gesamt</TableCell>
														<TableCell component="th" variant="head" align="right">{this.state.offenePosten.offen_Anzahl} Stück</TableCell>
														<TableCell component="th" variant="head" align="right">{this.state.offenePosten.offen_Betrag}</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</>
										:
										<Banner icon={<InfoOutlinedIcon />}>Derzeit nicht verfügbar</Banner>
									}
								</CardContent>
							</Card>
						</Grid>

						{modal}
						{
							<Grid container className={classes.root} spacing={1}>
								<Auswertungsgruppe />
							</Grid>
						}

					</Grid> {/* container */}
				</div>
				{/* classes.root */}

			</>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	db: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
