import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { logout } from '../functions';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import FeedbackIcon from '@material-ui/icons/Feedback';
import GavelIcon from '@material-ui/icons/Gavel';
import DashboardIcon from '@material-ui/icons/Dashboard';
//import MailIcon from '@material-ui/icons/Mail';
import InfoIcon from '@material-ui/icons/Info';
import BusinessIcon from '@material-ui/icons/Business';
import CopyrightIcon from '@material-ui/icons/Copyright';
import RadioIcon from '@material-ui/icons/Radio';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import OffenePostenIcon from '@material-ui/icons/ErrorOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BuildIcon from '@material-ui/icons/Build';
import AccountIcon from '@material-ui/icons/AccountCircle';
import bgImage from '../assets/images/global/vario-gebaeude-dark.jpg';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import varioLogo from '../assets/images/global/vario-logo.svg';
import $ from '../config';
import { getVersion } from '../functions';
import FetchLizenz from '../classes/items/FetchLizenz';

const styles = {
	drawerRoot: {
		maxWidth: '280px'
	},
	copyrightRoot: {
		marginTop: 0
	},
	copyrightPrimary: {
		fontSize: '0.9rem'
	},
	headerBild: {
		background: `url(${bgImage}) no-repeat center`,
		backgroundSize: 'cover',
		marginTop: '-21px',
		marginBottom: '-20px'
	},
	navigation: {
		marginTop: 16
	},
	logoWebApp: {
		paddingBottom: 0,
		paddingTop: '25px'
	},
	textOben: {
		color: '#fafafa'
	},
	iconOben: {
		color: '#fafafa'
	},
	ohneMinwidth: {
		minWidth: '35px'
	},
	subheader: {
		fontSize: '0.7rem'
	}
};

const CSSvarioLogo = {
	height: '24px',
	marginBottom: '-2px'
};

const NavigationDrawer = props => {
	const { classes } = props;
	const [lizenzNehmer, setLizenznehmer] = useState('<kein Lizenznehmer>');
	const [done, setDone] = useState(false);
	useEffect(() => {
		if (done) return;

		async function fetchLizenzNehmer() {
			const lizenz = await new FetchLizenz(props.screenProps, new AbortController().signal).fetch();
			setDone(true);
			setLizenznehmer(lizenz);
		}

		fetchLizenzNehmer();
	}, [done, props.screenProps]);
	const versionStr1 = `${new Date().getFullYear()} Vario Software-Entwicklungs AG`;

	const _logout = () => {
		console.log('[NavigationDrawer.js] logout() aufgerufen durch Klick auf LOGOUT');
		localStorage.clear(); // sämtliche Daten löschen!
		logout(props.screenProps);
	};

	const MenuList = () => (
		<div className={classes.list}>
			<List classes={{ root: classes.headerBild }}>
				<ListItem classes={{ root: classes.logoWebApp }} key="webApp">
					<ListItemText classes={{ primary: classes.textOben }}>
						<img src={varioLogo} alt="VARIO Logo" style={CSSvarioLogo} /><span style={{ fontSize: '1.3rem' }}>app</span>
					</ListItemText>
				</ListItem>
				<ListItem key="Konto">
					<ListItemIcon classes={{ root: classes.ohneMinwidth }}><AccountIcon classes={{ root: classes.iconOben }} /></ListItemIcon>
					<ListItemText classes={{ primary: classes.textOben }}>{sessionStorage.getItem($.benutzernameName)}</ListItemText>
				</ListItem>
			</List>

			<Divider />

			<List classes={{ root: classes.navigation }}>
				<ListItem button component="a" href={`/app/dashboard`} key="Dashboard">
					<ListItemIcon><DashboardIcon /></ListItemIcon>
					<ListItemText primary="Dashboard" />
				</ListItem>
				<ListItem button component="a" href={`/app/${$.adressenNode}`} key="Adressen">
					<ListItemIcon><PeopleIcon /></ListItemIcon>
					<ListItemText primary="Adressen" />
				</ListItem>
				<ListItem button component="a" href={`/app/${$.artikelsNode}`} key="Artikel">
					<ListItemIcon><RadioIcon /></ListItemIcon>
					<ListItemText primary="Artikel" />
				</ListItem>
				<ListItem button component="a" href={`/app/${$.belegeNode}`} key="Kundenbelege">
					<ListItemIcon><MonetizationOnIcon /></ListItemIcon>
					<ListItemText primary="Kundenbelege" />
				</ListItem>
				<ListItem button component="a" href={`/app/${$.offenepostenNode}`} key="Offene Posten">
					<ListItemIcon><OffenePostenIcon /></ListItemIcon>
					<ListItemText primary="Offene Posten" />
				</ListItem>
				{props.hatDeals &&
					<ListItem button component="a" href={`/app/${$.dealsNode}`} key="Deals">
						<ListItemIcon><CardGiftcardIcon /></ListItemIcon>
						<ListItemText primary="Deals" />
					</ListItem>
				}
				{props.hatServiceauftraege &&
					<ListItem button component="a" href={`/app/${$.serviceNode}`} key="Serviceaufträge">
						<ListItemIcon><BuildIcon /></ListItemIcon>
						<ListItemText primary="Serviceaufträge" />
					</ListItem>
				}
				<ListItem button component="a" href={`/app/${$.auswertungenNode}`} key="Auswertungen">
					<ListItemIcon><TrendingUpIcon /></ListItemIcon>
					<ListItemText primary="Auswertungen" />
				</ListItem>
				<ListItem button component="a" href={`/app/${$.scannerNode}`} key="Scanner">
					<ListItemIcon><PhotoCameraIcon/></ListItemIcon>
					<ListItemText primary="Scanner"/>
				</ListItem>
			</List>

			<Divider />

			<List>
				<ListItem key="Informationen">
					<ListItemText primary="INFORMATION" classes={{ primary: classes.subheader }} />
				</ListItem>
				<ListItem button component="a" target="_blank" href="https://www.vario-software.de/datenschutz/" key="Datenschutz">
					<ListItemIcon><LockIcon /></ListItemIcon>
					<ListItemText primary="Datenschutz" />
				</ListItem>
				<ListItem button component="a" target="_blank" href="https://www.vario-software.de/impressum/" key="Impressum">
					<ListItemIcon><InfoIcon /></ListItemIcon>
					<ListItemText primary="Impressum" />
				</ListItem>
				<ListItem component="a" target="_blank" href="https://www.vario-software.de/agb/" button key="AGB">
					<ListItemIcon><GavelIcon /></ListItemIcon>
					<ListItemText primary="AGB" />
				</ListItem>
			</List>

			<Divider />

			<List>
				<ListItem button key="Logout" onClick={_logout}>
					<ListItemIcon><ExitToAppIcon /></ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>

			<Divider />

			<List>
				<ListItem key="Copyright" alignItems="flex-start">
					<ListItemIcon><CopyrightIcon /></ListItemIcon>
					<ListItemText
						primary={versionStr1}
						secondary={getVersion()}
						classes={{ root: classes.copyrightRoot, primary: classes.copyrightPrimary }}
					/>
				</ListItem>
			</List>

			<Divider/>

			<List>
				<ListItem key="Lizenznehmer" alignItems="flex-start">
					<ListItemIcon><BusinessIcon/></ListItemIcon>
					<ListItemText
						primary={String(lizenzNehmer)}
						classes={{
							root: classes.copyrightRoot,
							primary: classes.copyrightPrimary
						}}
					/>
				</ListItem>
			</List>
		</div>
	);

	return (
		<>
			<Drawer
				classes={{ paper: classes.drawerRoot }}
				open={props.open}
				onClose={props.toggleDrawer(false)}
			>
				<MenuList />
			</Drawer>
		</>
	);

}

NavigationDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	hatDeals: PropTypes.bool.isRequired
};

export default withStyles(styles)(NavigationDrawer);
