import { ACCEPT_TASK, COMPLETE_TASK, INSERT_TASK, REJECT_TASK } from '../constants/actionTypes';
import { loadState } from '../sessionStorage';

const persistedState = loadState();
const initialState = Object.assign({},
	{
		items: {},
		completedIds: [],
		rejectedIds: [],
		acceptedIds: []
	},
	persistedState
);


export default function tasks(state = initialState, action) {
	switch (action.type) {
		case ACCEPT_TASK:
			return Object.assign({},
				state,
				{
					acceptedIds: [...state.acceptedIds, action.id]
				});
		case REJECT_TASK:
			return Object.assign({},
				state,
				{
					rejectedIds: [...state.rejectedIds, action.id]
				}
			);
		case COMPLETE_TASK:
			return Object.assign({},
				state,
				{
					completedIds: [...state.completedIds, action.id]
				}
			);
		case INSERT_TASK:
			return Object.assign({},
				state,
				{
					items: Object.assign({},
						state.items,
						{ [action.item.id]: action.item }
					)
				}
			);
		default:
			return state;
	}
}
