import Items from '.';
import { writeNullToKeys } from '../../functions';

class FetchEinstellungen extends Items {
  constructor (props, signal) {
    super(props, signal);
    this.itemsName = 'Einstellungen';
    this.path = {
      string: props.listingPath,
      id: null
    };
    this.fetch = this.fetch.bind(this);
  }

  fetch = () => new Promise((resolve, reject) => {
    super.fetchDbOrServerGet(this.itemsName, this.path).then(json => {
      const obj = {
        totalResultCount: json.results.totalResultCount,
        einstellungen: writeNullToKeys(json.results.einstellungen)
      };
      resolve(obj);
    }).catch(err => reject(err));
  });
}

export default FetchEinstellungen;