import Items from '.';
import { getCurrentFilename } from '../../functions';

class FetchAnsprechpartnerZuAdresse extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[${getCurrentFilename(__filename)}] id nicht vorhanden`);
		this.itemsName = 'Ansprechpartner zu Adresse';
		this.path = {
			string: `stammdaten/ansprechpartner?filter=adresseId=${id}`,
			id
		};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Ansprechpartner zu Adresse', this.path).then(json => {

			if (!json.path || !json.results || !json.results.ansprechpartnerZuAdresse) {
				resolve([]);
				return;
			}

			const ansprechpartner = json.results.ansprechpartnerZuAdresse;

			// wenn es keine Ansprechpartner gibt
			if (ansprechpartner.length === 0) {
				resolve([]);
				return;
			}

			resolve(ansprechpartner);

		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Belege zu Adresse'
	}).catch(err => console.error(err));
}

export default FetchAnsprechpartnerZuAdresse;
