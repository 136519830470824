import Items from '.';
import { writeNullToKeys, getBelegartFromCode, formatDatum, formatPreis, semiCamelCase, getCurrentFilename } from '../../functions';
import FetchAdresse from './FetchAdresse';
import FetchOPZahlungen from './FetchOPZahlungen';

class FetchOffenerPostenDetails extends Items {

	constructor(props, signal, OPId) {
		super(props, signal);
		if (!OPId || isNaN(OPId)) throw new TypeError(`[${getCurrentFilename(__filename)}] OPId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.OPId = OPId;
		this.path = {
			string: `finanzen/offeneposten/${OPId}`,
			id: OPId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = async () => {
		try {
			const name = 'Offener Posten Details';
			const camelName = semiCamelCase(name);

			const json = await super.fetchDbOrServerGet(name, this.path);
			if (!json.path || !json.results || !json.results[camelName])
				return (`[${getCurrentFilename(__filename)}] !json.path || !json.results || !json.results.${camelName}`);

			const op = json.results[camelName];
			const values = await Promise.all([
				new FetchAdresse(this.props, this.signal, op.adressnummer).fetch(),
				getBelegartFromCode(this.props.db, op.rechnungsart),
				new FetchOPZahlungen(this.props, this.signal, op.id).fetch()
			]);

			[op.adresse, op.rechnungsart, op.zahlungen] = values;
			op.rechnungsdatum = formatDatum(op.rechnungsdatum);
			op.nettoFaellig = formatDatum(op.nettoFaellig);
			op.naechsteFaelligkeit = formatDatum(op.naechsteFaelligkeit);
			op.bezahltAm = formatDatum(op.bezahltAm);
			op.naechsteMahnung = formatDatum(op.naechsteMahnung);
			op.rechnungsBetrag = formatPreis(op.rechnungsBetrag);
			op.bisherGezahlt = formatPreis(op.bisherGezahlt);
			op.offenerBetrag = formatPreis(op.offenerBetrag);
			op.gebuehr = formatPreis(op.gebuehr);
			op.zinsenBetrag = formatPreis(op.zinsenBetrag);
			op.mahnstufen = [
				{ mahnstufe: 1, datum: formatDatum(op.mahnung1Datum), betrag: formatPreis(op.mahnung1Betrag) },
				{ mahnstufe: 2, datum: formatDatum(op.mahnung2Datum), betrag: formatPreis(op.mahnung2Betrag) },
				{ mahnstufe: 3, datum: formatDatum(op.mahnung3Datum), betrag: formatPreis(op.mahnung3Betrag) },
				{ mahnstufe: 4, datum: formatDatum(op.mahnung4Datum), betrag: formatPreis(op.mahnung4Betrag) },
				{ mahnstufe: 5, datum: formatDatum(op.mahnung5Datum), betrag: formatPreis(op.mahnung5Betrag) }
			];

			return writeNullToKeys(op);
		} catch (error) {
			console.log(7846367943);
			console.error(error);
		}
	}
}

export default FetchOffenerPostenDetails;
