import Items from '.';
import { writeNullToKeys, getDefaultFilters } from '../../functions';
import $ from '../../config';

class FetchAdressenSuche extends Items {

	constructor(props, signal, eingabe) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.path = {
      string: `stammdaten/adressen?q="${eingabe}"&limit=50&orderby=${$.defaultOrderBy[$.adressenNode]}&filter=${getDefaultFilters($.adressenNode)}`,
			id: null
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('AdressenSuche', this.path).then(json => {

			if (!json.path || !json.results || !json.results.adressenSuche) {
				resolve([]);
				return;
			}

			let { adressenSuche } = json.results;
			resolve(writeNullToKeys(adressenSuche));

		}).catch(err => reject(err)); //super.fetchDbOrServerGet('AdressSuche'
	});
}

export default FetchAdressenSuche;
