import Items from '.';

class StatistikRenner extends Items {

	constructor(props, signal, year) {
		super(props, signal);
		this.setzeRennerDatum(year);
		const anzahlKundenRenner = 10;
		this.path = {
			string: `auswertungen/rennerpenner/daten?Top=${anzahlKundenRenner}&DatumVon=${this.rennerVon}&DatumBis=${this.rennerBis}`,
			id: null
		};
		this.fetch = this.fetch.bind(this);
		this.getSqlDatum = this.getSqlDatum.bind(this);
		this.setzeRennerDatum = this.setzeRennerDatum.bind(this);
	}

	getSqlDatum = dateObj => {
		return new Date(dateObj).toISOString().slice(0, 10);
	};

	setzeRennerDatum = year => {
		const einunddreissigesterDezember = new Date(year, 11, 31);
		this.rennerBis = this.getSqlDatum(einunddreissigesterDezember);
		const ersterJanuar = new Date(year, 0, 1);
		this.rennerVon = this.getSqlDatum(ersterJanuar);
	};

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Rennerstatistik', this.path).then(json => {

			if (!json.path || !json.results || !json.results.rennerstatistik) {
				resolve([]);
				return;
			}

			const statistik = json.results.rennerstatistik;
			const chartData = [];
			let j = 1;
			for (let i = 0; i < statistik.length; i++) {
				const datensatz = statistik[i];
				chartData.push({
					name1: `${j++}: ${datensatz.name1}`,
					umsatz: datensatz.umsatz
				});
			}

			resolve(chartData);

		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Artikel'
	});
}

export default StatistikRenner;