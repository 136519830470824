import Items from '.';
import { formatPreis, formatDatum } from '../../functions';
import { getCurrentFilename } from '../../functions';

class FetchAdresse extends Items {

	constructor(props, signal, id, forceRefetch = false) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[${getCurrentFilename(__filename)}] id nicht vorhanden`);
		this.path = {
			string: `stammdaten/adressen/${id}`,
			id
		}
		this.forceRefetch = forceRefetch;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Adresse', this.path, this.forceRefetch).then(json => {

			if (!json.path || !json.results || !json.results.adresse) {
				resolve([]);
				return;
			}

			const { adresse } = json.results;

			adresse.kreditlimit = formatPreis( adresse.kreditlimit);
			adresse.opvBetrag = formatPreis( adresse.opvBetrag);
			adresse.erstkontakt = formatDatum(adresse.erstkontakt);

			resolve(adresse);
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
	});
}

export default FetchAdresse;
