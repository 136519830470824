import { createSelector } from 'reselect';
import { SHOW_COMPLETED, SHOW_ACTIVE, SHOW_ACCEPTED } from '../constants/taskFilters';

const getVisibilityFilter = state => state.visibilityFilter;
const getTasks = state => state.tasks;
const getAccepted = tasks => tasks.acceptedIds || [];
const getCompleted = tasks => tasks.completedIds || [];
const getRejected = tasks => tasks.rejectedIds || [];
const getInactive = state => getRejected(state).concat(getCompleted(state));
const getAnswered = state => getAccepted(state).concat(getRejected(state));

export const getVisibleTasks = createSelector(
	[getVisibilityFilter, getTasks],
	(visibilityFilter, tasks) => {
		switch (visibilityFilter) {
			case SHOW_COMPLETED:
				return Object.values(tasks.items).filter(taskItem => getCompleted(tasks).includes(taskItem.id));
			case SHOW_ACTIVE:
				return Object.values(tasks.items).filter(taskItem => !getInactive(tasks).includes(taskItem.id));
			case SHOW_ACCEPTED:
				return Object.values(tasks.items).filter(taskItem => getAccepted(tasks).includes(taskItem.id));
			default:
				throw new Error('Unknown filter: ' + visibilityFilter);
		}
	}
);

export const getPendingIds = createSelector(
	[getTasks],
	tasks => Object.values(tasks.items).filter(taskItem => !getAnswered(tasks).includes(taskItem.id)).map(task => task.id)
);
export const getRejectedIds = createSelector(
	[getTasks],
	tasks => Object.values(tasks.items).filter(taskItem => getRejected(tasks).includes(taskItem.id)).map(task => task.id)
);
export const getAcceptedIds = createSelector(
	[getTasks],
	tasks => Object.keys(getVisibleTasks({
		visibilityFilter: SHOW_ACCEPTED,
		tasks
	}))
);
export const getCompletedIds = createSelector(
	[getTasks],
	tasks => Object.keys(getVisibleTasks({
		visibilityFilter: SHOW_COMPLETED,
		tasks
	}))
);
