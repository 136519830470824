import Items from '.';
import FetchBeleg from './FetchBeleg';

class FetchBelegDetails extends Items {

	constructor(props, signal, belegId) {
		super(props, signal);
		if (!belegId || isNaN(belegId)) throw new TypeError(`[FetchBelegDetails.js] belegId nicht vorhanden`);
		this.props = props;
		this.signal = signal;
		this.belegId = belegId;
		this.path = {
			string: `verkauf/belege/${belegId}`,
			id: belegId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		new FetchBeleg(this.props, this.signal, this.belegId).fetch().then(beleg => {
			// TODO rausnehmen, wenn API Positionenlimit hat
			if (beleg.positionen) beleg.positionen = beleg.positionen.slice(0, 100);
			resolve(beleg);
		}).catch(err => {
			reject(err);
			console.error(err);
			console.log(9002575435);
		}); // new Beleg
	}).catch(err => {
		console.error(err);
		console.log(943857823684638);
	}); //fetch()
}

export default FetchBelegDetails;
