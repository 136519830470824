import Items from '.';
import { getCurrentFilename, formatBelegeListe } from '../../functions';

class FetchBelegeZuAdresse extends Items {

	constructor(props, signal, adresseId, forceRefetch = false) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.forceRefetch = forceRefetch;
		if (!adresseId || isNaN(adresseId)) throw new TypeError(`[${getCurrentFilename(__filename)}] adresseId nicht vorhanden`);
		this.path = {
			string: `verkauf/belege?filter=adresseId=${adresseId}&orderby=-belegdatum`,
			id: adresseId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Belege zu Adresse', this.path, this.forceRefetch).then(json => {

			if (!json.path || !json.results || !json.results.belegeZuAdresse) {
				resolve([]);
				return;
			}

			const belege = json.results.belegeZuAdresse;

			formatBelegeListe(this.props.db, belege).then(formatierteBelege => {
				resolve(formatierteBelege);
			});
		}).catch(err => {
			console.log(3409327035);
			reject(err);
		}); //super.fetchDbOrServerGet('Belege zu Adresse'
	}).catch(err => {
		console.log(8680542375);
		console.error(err);
	});
}

export default FetchBelegeZuAdresse;
