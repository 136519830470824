import Items from '.';
import { removeDuplicates } from '../../functions';
import { nested } from '../../redux-service/reducers/positionen';

class FetchLizenz extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.path = {
			string: `auswertungen/sql`,
			id: null
		};
		this.sendData = {
			sql: `
				select name1
					from sp_current_lic_id
  				join lic on sp_current_lic_id.lic_id = lic.id
				`
		};
		this.signal = signal;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerPost('Lizenz Nehmer', this.path, this.sendData).then(json => {
			const result = nested(json, 'results', 'lizenzNehmer', '0', 'name1');
			resolve(result);
		}).catch(err => reject(err));
	});
}

export default FetchLizenz;
