import { combineReducers } from 'redux';
import tasks from './tasks';
import visibilityFilter from './visibilityFilter';
import status from './status';
import positionen from './positionen';

const rootReducer = combineReducers({
	tasks,
	visibilityFilter,
	status,
	positionen
});

export default rootReducer;

