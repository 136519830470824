import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import InfoIcon from '@material-ui/icons/Info';
import LinearProgress from '@material-ui/core/LinearProgress';
import $ from '../config';
import ListViewArtikel from '../containers/ListViewArtikel';
import ListViewBelege from '../containers/ListViewBelege';
import ListViewOffenePosten from '../containers/ListViewOffenePosten';
import ListViewDeals from '../containers/ListViewDeals';
import ListViewAdressen from '../containers/ListViewAdressen';
import { isAdrSearchView, getBelegartFromURI } from '../functions';

const styles = theme => ({
	card: {
		position: 'absolute',
		top: window.innerWidth >= 600 ? 64 : 56,
		left: 8,
		right: 8,
		zIndex: 1501,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0
	},
	progressbar: {
		zIndex: 1502,
		position: 'absolute',
		top: window.innerWidth >= 600 ? 64 : 56,
		left: 8,
		right: 8
	}
});

const SuchergebnisseDropdown = props => {
	const { classes } = props;
	let active = false, adressen = [], ansPartner = [], artikels = [], belege = [], offeneposten = [], deals = [];
	//console.log('searchResults', props.searchResults)

	for (let i = 0; i < props.searchResults.length; i++) {
		const obj = props.searchResults[i];

		if (obj.key === $.adressenNode) adressen = obj.value.results;
		else if (obj.key === $.ansprechpartnersNode) ansPartner = obj.value.results;
		else if (obj.key === $.artikelsNode) artikels = obj.value.results;
		else if (obj.key === $.belegeNode) belege = obj.value.results;
		else if (obj.key === $.offenepostenNode) offeneposten = obj.value.results;
		else if (obj.key === $.dealsNode) deals = obj.value.results;

		active = active || obj.value.active;
	}

	const results = () => {

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(7854235283);
				return null;
			}
			let newPath = `/app/${props.screenProps.config.itemsNode}/${id}`;
			const { search } = props.screenProps.location;
			if (isAdrSearchView(search)) newPath = `/app/belegneu/${id}/${getBelegartFromURI(search)}`;
			props.screenProps.history.push(newPath);
		};

		const listStyle = { paddingBottom: 0, paddingTop: 0 };

		return (
			<>
				<ListViewAdressen items={adressen} ansPartner={ansPartner} handleClick={handleClick} listStyle={listStyle} globalSearch={props.globalSearch} />
				<ListViewArtikel items={artikels} handleClick={handleClick} listStyle={listStyle} />
				<ListViewBelege items={belege} handleClick={handleClick} listStyle={listStyle} />
				<ListViewOffenePosten items={offeneposten} handleClick={handleClick} listStyle={listStyle} />
				<ListViewDeals items={deals} handleClick={handleClick} listStyle={listStyle} />
			</>
		);
	};

	const noResults = (
		<List>
			<ListItem>
				<InfoIcon color="secondary" /><ListItemText primary="Keine Ergebnisse" style={{ paddingLeft: '10px' }} />
			</ListItem>
		</List>
	);

	return (
		<>
			{active && <LinearProgress color="secondary" className={classes.progressbar} />}

			<Card className={classes.card}>
				{props.noResults ? noResults : results()}
			</Card>
		</>
	);

}

SuchergebnisseDropdown.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SuchergebnisseDropdown);
