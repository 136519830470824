import Items from '.';
import { writeNullToKeys } from '../../functions';

class FetchArtikelSuche extends Items {

	constructor(props, signal, eingabe) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.path = {
			string: `stammdaten/artikel?q="${eingabe}"&orderby=bezeichnung&limit=50&filter=gesperrt=false&fields=id,artikelnummer,bezeichnung`,
			id: null
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('ArtikelSuche', this.path).then(json => {

			if (!json.path || !json.results || !json.results.artikelSuche) {
				resolve([]);
				return;
			}

			let artikel = json.results.artikelSuche;
			resolve(writeNullToKeys(artikel));

		}).catch(err => reject(err)); //super.fetchDbOrServerGet('ArtikelSuche'
	});
}

export default FetchArtikelSuche;