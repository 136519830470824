import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FetchArtikelSuche from '../classes/items/FetchArtikelSuche';
import FetchAdressenSuche from '../classes/items/FetchAdressenSuche';
import FetchZusatzadressenZuAdresse from '../classes/items/FetchZusatzadressenZuAdresse';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListViewAdressen from '../containers/ListViewAdressen';

function renderInputComponent(inputProps) {

	const { classes, inputRef = () => { }, ref, ...other } = inputProps;

	return (
		<TextField
			fullWidth
			autoFocus
			InputProps={{
				inputRef: node => {
					ref(node);
					inputRef(node);
				},
				classes: {
					input: classes.input
				}
			}}
			{...other}
		/>
	);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {

	const { label } = suggestion;
	const firstIndex = label.toLowerCase().indexOf(query.toLowerCase());
	const lastIndex = firstIndex + query.length;
	const matches = [[firstIndex, lastIndex]];
	const parts = parse(label, matches);

	const styles = {
		height: '56px',
		width: '100%',
		position: 'relative'
	};
	const stylesInner = {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '100%',
		margin: 0,
		position: 'absolute',
		top: '50%',
		msTransform: 'translateY(-50%)',
		transform: 'translateY(-50%)'
	};

	return (
		<MenuItem selected={isHighlighted} component="div">
			<div style={styles}>
				<div style={stylesInner}>
					{parts.map((part, i) => <span key={i} style={{ fontWeight: part.highlight ? 900 : 400 }}>{part.text}</span>)}
				</div>
			</div>
		</MenuItem>
	);
}

function getSuggestionValue(suggestion) {
	return suggestion.label;
}

function shouldRenderSuggestions(value) {
	return value.trim().length > 2;
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
	root: {
		height: 250,
		flexGrow: 1,
		paddingTop: '24px'
	},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	button: {
		marginLeft: '2px'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	appBar: {
		position: 'relative'
	}
});

class FullscreenSearchModal extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.suchEingabeTimer = null;

		this.state = {
			textposition: '',
			suchEingabe: '',
			suggestions: [],
			isFetching: false,
			zAdressen: []
		};
		//#region
		this.handleChange = this.handleChange.bind(this);
		this.handleTextPosChange = this.handleTextPosChange.bind(this);
		this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
		this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
		this.getSuggestions = this.getSuggestions.bind(this);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
		this.onSaveClicked = this.onSaveClicked.bind(this);
		this.getZusatzAdressen = this.getZusatzAdressen.bind(this);
		this.handleZAdressenClick = this.handleZAdressenClick.bind(this);
		//#endregion
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.open && !this.props.open) {
			// Modal wurde geschlossen
			this.setState({
				suchEingabe: '',
				textposition: '',
				zAdressen: []
			});
		}
		else if (!prevProps.open && this.props.open) {
			// Modal wurde geöffnet
			// ggf. Zusatzadressen direkt anzeigen
			if (this.props.header === this.props.bereiche.lieferadresse || this.props.header === this.props.bereiche.rechnungsadresse)
				this.getZusatzAdressen();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.controller.abort();
	}

	getSuggestions = eingabe => new Promise((resolve, reject) => {

		let count = 0, eingabeWert;
		if (eingabe !== null) {
			eingabeWert = deburr(eingabe.trim()).toLowerCase();
			if (eingabeWert.length < 3) {
				resolve([]);
				return;
			}
		}

		this.setState({ isFetching: true });
		if (this.suchEingabeTimer) clearTimeout(this.suchEingabeTimer);
		this.suchEingabeTimer = setTimeout(() => {

			// Position suchen
			if (this.props.header === this.props.bereiche.position) {
				new FetchArtikelSuche(this.props.screenprops, this.controller.signal, eingabeWert).fetch().then(artikels => {
					this.setState({ isFetching: false });
					const suggestions = [];
					for (let i = 0; i < artikels.length; i++) {
						const { id } = artikels[i];
						const { bezeichnung } = artikels[i];
						const { artikelnummer } = artikels[i];
						suggestions.push({
							id,
							label: `${bezeichnung} (${artikelnummer})`
						});
					}
					const _suggestions = suggestions.filter(suggestion => {
						const keep = count < 20 && suggestion.label.toLowerCase().includes(eingabeWert);
						if (keep) count += 1;
						return keep;
					});
					resolve(_suggestions);
				}).catch((err) => {
					console.log(7582053721);
					console.error(err);
					reject(err);
				});
			}

			// andere Hauptadresse suchen
			else if (this.props.header === this.props.bereiche.adresse) {
				new FetchAdressenSuche(this.props.screenprops, this.controller.signal, eingabeWert).fetch().then(adressen => {
					this.setState({ isFetching: false });
					const suggestions = [];
					for (let i = 0; i < adressen.length; i++) {
						const adr = adressen[i];
						suggestions.push({
							id: adr.id,
							adresse: adr,
							label: `${adr.name1}, ${adr.plz} ${adr.ort}`
						});
					}
					const _suggestions = suggestions.filter(suggestion => {
						const keep = count < 20 && suggestion.label.toLowerCase().includes(eingabeWert);
						if (keep) count += 1;
						return keep;
					});
					resolve(_suggestions);
				}).catch((err) => {
					console.log(7523545372);
					console.error(err);
					reject(err);
				});
			}

		}, 300);
	});

	getZusatzAdressen() {
		let art;
		if (this.props.header === this.props.bereiche.rechnungsadresse) art = 'R';
		else if (this.props.header === this.props.bereiche.lieferadresse) art = 'L';
		else return;
		new FetchZusatzadressenZuAdresse(
			this.props.screenprops,
			this.controller.signal,
			this.props.screenprops.id,
			art
		)
			.fetch()
			.then(zAdressen => {
				this.setState({
					isFetching: false,
					zAdressen
				});
			})
			.catch((err) => {
				console.log(7563463672);
				console.error(err);
			});
	};

	handleSuggestionsFetchRequested = ({ value }) => {
		this.getSuggestions(value).then(value => {
			if (this._isMounted) this.setState({ suggestions: value });
		});
	};

	handleSuggestionsClearRequested = () => {
		//this.setState({ suggestions: [] });
	};

	onSuggestionSelected(event, { suggestion }) {
		if (this.props.header === this.props.bereiche.position) this.props.addArtikel(suggestion.id);
		else this.props.changeAdresse(this.props.header, suggestion);
		this.props.closeModal();
	}

	onSaveClicked() {
		this.props.addTextPosition(this.state.textposition);
		this.props.closeModal();
	}

	handleChange = name => (event, { newValue }) => {
		this.setState({ [name]: newValue });
	};

	handleTextPosChange = () => event => {
		this.setState({ textposition: event.target.value });
	};

	handleZAdressenClick(id, adresse) {
		if (isNaN(id)) {
			console.warn(7847898283);
			return null;
		}
		const adr = { adresse };
		this.props.changeAdresse(this.props.header, adr);
		this.props.closeModal();
	}

	render() {
		const { classes } = this.props;

		const autosuggestProps = {
			renderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			getSuggestionValue,
			renderSuggestion,
			shouldRenderSuggestions,
			onSuggestionSelected: this.onSuggestionSelected
		};
		const listStyle = { paddingBottom: 0, paddingTop: 0 };

		// Zusatzadressen (mit default Adresse) nur anzeigen, wenn auf Liefer- oder Rechnungsadresse
		const zAdressenItems =
			this.props.header === this.props.bereiche.lieferadresse || this.props.header === this.props.bereiche.rechnungsadresse
				? [this.props.defaultAdresse, ...this.state.zAdressen]
				: [];

		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				open={this.props.open}
				fullScreen
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={this.props.closeModal} aria-label="Schließen"><ArrowBackIcon /></IconButton>
						<Typography variant="h6" className={classes.title}>{this.props.header} suchen</Typography>
					</Toolbar>
				</AppBar>

				{this.state.isFetching && <LinearProgress color="secondary" className="global_progress_bar" />}

				<DialogContent className={classes.root}>

					{this.props.header !== this.props.bereiche.rechnungsadresse && this.props.header !== this.props.bereiche.lieferadresse &&
						<Autosuggest
							{...autosuggestProps}
							inputProps={{
								classes,
								placeholder: `${this.props.header} suchen...`,
								value: this.state.suchEingabe,
								onChange: this.handleChange('suchEingabe')
							}}
							theme={{
								container: classes.container,
								suggestionsContainerOpen: classes.suggestionsContainerOpen,
								suggestionsList: classes.suggestionsList,
								suggestion: classes.suggestion
							}}
							renderSuggestionsContainer={options => <Paper {...options.containerProps} square>{options.children}</Paper>}
						/>
					}

					{this.props.header === this.props.bereiche.position &&
						<>
							<div style={{ height: '20px' }} />
							<Typography variant="body1">oder</Typography>
							<div style={{ height: '20px' }} />
							<TextField
								placeholder="Textposition einfügen"
								className={classes.textField}
								value={this.state.textposition}
								onChange={this.handleTextPosChange()}
								type="text"
								fullWidth
							/>
						</>
					}

					<ListViewAdressen
						items={zAdressenItems}
						handleClick={this.handleZAdressenClick}
						listStyle={listStyle}
					/>

				</DialogContent>

				<DialogActions>
					{this.state.textposition.length > 0 &&
						<Button onClick={this.onSaveClicked} color="primary">Speichern</Button>
					}
				</DialogActions>

			</Dialog>
		);
	}
}

FullscreenSearchModal.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullscreenSearchModal);
