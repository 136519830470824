import Items from '.';
import { semiCamelCase } from '../../functions';

class FetchArtikelBild extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[FetchArtikelBild.js] id nicht vorhanden`);
		this.path = {
			string: `stammdaten/bilder/${id}`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	async fetch() {
		const name = 'Artikel Bild';
		const camelName = semiCamelCase(name);
		try {
			const json = await super.fetchDbOrServerGet(name, this.path);
			if (!json.path || !json.results || !json.results[camelName])
				return ('[FetchArtikelBild.js] !json.path || !json.results || !json.results.artikelBild');
			const bild = json.results[camelName];
			return (bild);
		} catch (error) {
			console.log(8960235720);
			return (error);
		}
	};
}

export default FetchArtikelBild;
