import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

const styles = () => {

};

const TabAktivitaeten = props => {

	const { aktivitaeten } = props;

	//const { classes } = props;

	const StyledListItemText = withStyles(theme => ({
		primary: {
			color: theme.palette.secondary.main,
			fontWeight: 'bold'
		},
		secondary: {
			color: theme.palette.primary.main,
			fontWeight: 'bold'
		}
	}))(ListItemText);

	const jsx = [], { StyledCard } = props, { StyledAvatar } = props;
	const left = { item: true, xs: 4, sm: 2 };
	const right = { item: true, xs: 8, sm: 10 };
	const v = { variant: 'subtitle2' };
	console.log(aktivitaeten)
	for (let i = 0; i < aktivitaeten.length; i++) {
		const akt = aktivitaeten[i];

		jsx.push(
			<StyledCard key={i}>
				<List>{/* List/ListItem nur wegen Kompatibiltät zu anderen Listen wegen padding */}
					<ListItem>
						<Grid container spacing={1}>
							<Grid {...left}><StyledAvatar><ContactPhoneIcon /></StyledAvatar></Grid>
							<Grid {...right}><StyledListItemText primary={akt.datum} secondary={akt.art} /></Grid>
							<Grid {...left}><Typography {...v}>Benutzer</Typography></Grid>
							<Grid {...right}><Typography {...v}>{akt.benutzer}</Typography></Grid>
							<Grid {...left}><Typography {...v}>Dauer</Typography></Grid>
							<Grid {...right}><Typography {...v}>{akt.dauer} Min.</Typography></Grid>
							<Grid {...left}><Typography {...v}>Beschreibung</Typography></Grid>
							<Grid {...right}><Typography {...v}>{akt.beschreibung}</Typography></Grid>
						</Grid>
					</ListItem>
				</List>
			</StyledCard>
		);
	}

	return jsx;
}

export default withStyles(styles)(TabAktivitaeten);
