import detailViewAdresse from './detailViewAdresse';
import detailViewBeleg from './detailViewBeleg';

const detailViewSuchergebnisse = props => {

	if (!props.dataItem) return null;

	//console.log('detailViewSuchergebnisse()', props.dataItem);

	if (props.dataItem.adresse && props.dataItem.aktivitaeten) {
		// Adresse
		return detailViewAdresse(props);
	} else if (props.dataItem.belegnummer) {
		// Beleg
		return detailViewBeleg(props);
	} else {
		// Ansprechpartner
		return detailViewAdresse(props);
	}
};

export default detailViewSuchergebnisse;