import { createStore } from 'redux';
import rootReducer from '../redux-service/reducers';
import { saveState } from './sessionStorage';

const store = createStore(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
	saveState({
		items: {},
		acceptedIds: store.getState().tasks.acceptedIds || [],
		rejectedIds: store.getState().tasks.rejectedIds || [],
		completedIds: store.getState().tasks.completedIds || [],
		status: store.getState().status || {},
		positionen: store.getState().positionen
	});
});

export default store;
