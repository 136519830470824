import Items from '.';
import { writeNullToKeys, setDbSuchergebnisse, getDbSuchergebnisse, formatBelegeListe, getDefaultFilters, semiCamelCase } from '../../functions';
import $ from '../../config';

class FetchSearchresultsGlobal extends Items {

	constructor(props, signal, eingabe) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.eingabe = eingabe;
		this.pathAnsprechpartner = null;
		this.pathAdressen = null;
		this.pathBelege = null;
		this.fetch = this.fetch.bind(this);
		this.setPaths = this.setPaths.bind(this);
	}

	setPaths = suchbegriff => {
		const limit = 20;

		this.pathAnsprechpartner = {
			string: `stammdaten/ansprechpartner?q="${suchbegriff}"&limit=${limit}&orderby=name`,
			id: null
		};
		this.pathAdressen = {
			string: `stammdaten/adressen?q="${suchbegriff}"&limit=${limit}&orderby=${$.defaultOrderBy[$.adressenNode]}&filter=${getDefaultFilters($.adressenNode)}`,
			id: null
		};
		this.pathBelege = {
			string: `verkauf/belege?q="${suchbegriff}"&limit=${limit}&orderby=-belegdatum`,
			id: null
		};
	};

	async fetch() {
		try {
			if (this.eingabe) {
				// wir wurden von VarioAppBar.js aufgerufen MIT Argument "eingabe"
				this.setPaths(this.eingabe);
			} else {
				// wir wurden von ListView.js aufgerufen OHNE Argument "eingabe"
				const suchergebnisse = await getDbSuchergebnisse(this.props.db);
				this.eingabe = suchergebnisse[0].suchbegriff
				this.setPaths(this.eingabe);
			}

			const nameAnsprechpartner = 'Globale Suche Ansprechpartner';
			const nameAdressen = 'Globale Suche Adressen';
			const nameBelege = 'Globale Suche Belege';

			const promises = [
				super.fetchDbOrServerGet(nameAnsprechpartner, this.pathAnsprechpartner),
				super.fetchDbOrServerGet(nameAdressen, this.pathAdressen),
				super.fetchDbOrServerGet(nameBelege, this.pathBelege)
			];

			const alleJsons = await Promise.all(promises);

			const totalResultCount =
				alleJsons[0].results.totalResultCount +
				alleJsons[1].results.totalResultCount +
				alleJsons[2].results.totalResultCount;

			const
				ansprechpartners = alleJsons[0].results ? alleJsons[0].results[semiCamelCase(nameAnsprechpartner)] : [],
				adressen = alleJsons[1].results ? alleJsons[1].results[semiCamelCase(nameAdressen)] : [],
				belege = alleJsons[2].results ? alleJsons[2].results[semiCamelCase(nameBelege)] : [];

			const formatierteBelege = await formatBelegeListe(this.props.db, belege);

			const data = {
				ansprechpartner: ansprechpartners && ansprechpartners.length ? ansprechpartners.filter(ansprechpartner => ansprechpartner !== undefined) : [],
				adressen: adressen && adressen.length ? adressen.filter(adresse => adresse !== undefined) : [],
				belege: formatierteBelege && formatierteBelege.length ? formatierteBelege.filter(beleg => beleg !== undefined) : []
			};
			const obj = {
				totalResultCount,
				suchergebnisse: writeNullToKeys(data)
			};
			setDbSuchergebnisse(this.props.db, this.eingabe, obj);
			return obj;
		} catch (e) {
			console.error(e);
			console.error(637846370527);
			return e;
		}
	}
}

export default FetchSearchresultsGlobal;
