import Items from '.';
import { getCurrentFilename, semiCamelCase } from '../../functions';

class FetchZusatzadressenZuAdresse extends Items {

	constructor(props, signal, adresseId, art = null) {
		super(props, signal);
		if (!adresseId || isNaN(adresseId)) throw new TypeError(`[${getCurrentFilename(__filename)}] adresseId nicht vorhanden`);
		this.props = props;
		this.art = art;
		this.signal = signal;
		let artFilter = '';
		if (art) artFilter = `?filter=art="${art}"`;
		this.path = {
			string: `stammdaten/adressen/${adresseId}/zusatzadressen${artFilter}`,
			id: adresseId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = async () => {
		const name = 'Zusatzadressen zu Adresse';
		const camelName = semiCamelCase(name);
		const json = await super.fetchDbOrServerGet(name, this.path);
		if (!json.path || !json.results || !json.results[camelName]) return [];
		const zusatzadressen = json.results[camelName];
		return zusatzadressen;
	}
}

export default FetchZusatzadressenZuAdresse;