import React, { useState } from "react";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Banner from './Banner';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import defaultArtikelBild from '../trash/defaultArtikelBild';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { keineBlaVorhanden } from '../functions';

const imgStyles = {
	width: 'auto',
	margin: '0 auto',
	objectFit: 'contain',
	maxHeight: '300px'
};

const detailViewArtikel = props => {

	const Uebersicht = () => {
		const dataArtikel = props.dataItem;
		if (!dataArtikel) return null;
		const { stammdaten } = dataArtikel, { bilder } = dataArtikel, { bestaende } = dataArtikel;

		// Bestände über alle Lagerkreise aufsummieren
		let bestandGesamt = 0;
		for (let i = 0; i < bestaende.length; i++) bestandGesamt += bestaende[i].bestand;

		let b64imgData = defaultArtikelBild;
		if (bilder.length) b64imgData = bilder[0].gfx;

		//console.log(dataArtikel)

		return <Grid container spacing={1}>
			<Grid item xs={12}>
				<Card>

					<CardActionArea>
						<CardMedia
							style={imgStyles}
							component="img"
							image={`data:image/jpg;base64,${b64imgData}`}
							alt={stammdaten.bezeichnung}
						/>
					</CardActionArea>

					<CardContent>
						<Typography variant="h5">{stammdaten.bezeichnung}</Typography>
					</CardContent>

				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader title="Info zu diesem Artikel" />

					<Divider />

					<CardContent>
						<Table>
							<TableBody>
								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Artikelnummer</TableCell>
									<TableCell align="right">{stammdaten.artikelnummer}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Verfügbarer Bestand</TableCell>
									<TableCell align="right">{bestandGesamt}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">rabattierfähig</TableCell>
									<TableCell align="right">{stammdaten.istRabattierfaehig}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">skontierbar</TableCell>
									<TableCell align="right">{stammdaten.istSkontofaehig}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Hersteller</TableCell>
									<TableCell align="right">{stammdaten.hersteller}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">VK Netto</TableCell>
									<TableCell align="right">{stammdaten.vkNetto}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">VK Brutto</TableCell>
									<TableCell align="right">{stammdaten.vkBrutto}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Gewicht</TableCell>
									<TableCell align="right">{stammdaten.gewicht}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Maße (H x B x T)</TableCell>
									<TableCell align="right">{`${stammdaten.laenge} x ${stammdaten.breite} x ${stammdaten.hoehe} cm`}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Mengeneinheit</TableCell>
									<TableCell align="right">{stammdaten.mengeneinheit}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Preiseinheit</TableCell>
									<TableCell align="right">{stammdaten.preiseinheit}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">Verpackungseinheit</TableCell>
									<TableCell align="right">{stammdaten.verpackungseinheit}</TableCell>
								</TableRow>

								<TableRow hover>
									<TableCell component="th" variant="head" scope="row">gesperrt</TableCell>
									<TableCell align="right">{stammdaten.gesperrt}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader
						title="Beschreibung"
					/>

					<Divider />

					<CardContent>
						{stammdaten.artikeltext}
					</CardContent>
				</Card>
			</Grid>
		</Grid>;
	};

	const Preisgruppen = () => {
		const artikel = props.dataItem;
		if (!artikel) return null;
		return <Banner icon={<InfoIcon />}>Derzeit nicht verfügbar</Banner>;
	};

	const Bestaende = () => {
		const [bestandIndex, setBestandIndex] = useState(0);
		const artikel = props.dataItem;
		if (!artikel) return null;
		let dataBestaendeJSX;

		const [ersterLagerkreis] = artikel.bestaende.sort((a, b) => a.id - b.id).slice(0, 1);
		const weitereLagerkreise = artikel.bestaende.slice(1);

		if (artikel.bestaende && artikel.bestaende.length) {

			const lagerkreisBestand = artikel.bestaende[bestandIndex || 0];

			dataBestaendeJSX = (
				<>
					<Table>
						<TableBody>
							<TableRow hover>
								<TableCell component="th" variant="head" scope="row">Bestand</TableCell>
								<TableCell align="right">{lagerkreisBestand.bestand}</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell component="th" variant="head" scope="row">Bestellt</TableCell>
								<TableCell align="right">{lagerkreisBestand.bestellt}</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell component="th" variant="head" scope="row">Blockiert</TableCell>
								<TableCell align="right">{lagerkreisBestand.blockiert}</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell component="th" variant="head" scope="row">In Kommissionierung</TableCell>
								<TableCell align="right">{lagerkreisBestand.inKommissionierung}</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell component="th" variant="head" scope="row">In Produktion</TableCell>
								<TableCell align="right">{lagerkreisBestand.inProduktion}</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell component="th" variant="head" scope="row">In Umlagerung</TableCell>
								<TableCell align="right">{lagerkreisBestand.inUmlagerung}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</>
			);
		}

		return (
			<>
				<FormControl style={{
					width: '240px',
					marginTop: '8px',
					marginBottom: '16px',
					marginLeft: '32px'
				}}>
					<Select
						value={bestandIndex}
						name="bestandIndex"
						onChange={event => setBestandIndex(event.target.value)}
					>
						<MenuItem value={0}>{`Lagerkreis ${ersterLagerkreis.lagerkreisName}`}</MenuItem>

						{weitereLagerkreise.length ? weitereLagerkreise.map((lagerkreis, index) =>
							<MenuItem key={index} value={index + 1}>{`Lagerkreis ${lagerkreis.lagerkreisName}`}</MenuItem>
						) : null}
					</Select>
				</FormControl>

				<Card>
					<CardContent>
						{dataBestaendeJSX}
					</CardContent>
				</Card>
			</>
		);
	};

	const Bilder = () => {
		const artikel = props.dataItem;
		if (!artikel || !artikel.bilder || !artikel.bilder.length) return keineBlaVorhanden('Bilder', true);
		const { bilder } = artikel, jsx = [];

		for (let i = 0; i < bilder.length; i++) {
			let b64imgData = bilder[i].gfx;

			jsx.push(
				<Card key={i} style={{ marginBottom: '8px' }}>
					<CardActionArea>
						<CardMedia
							style={imgStyles}
							component="img"
							image={`data:image/jpg;base64,${b64imgData}`}
							alt={`Produktbild ${i + 1}`}
						/>
					</CardActionArea>
				</Card>
			);
		}
		return jsx;
	};

	return [{
		ueberschrift: 'Übersicht',
		Funktion: Uebersicht
	}, {
		ueberschrift: 'Preisgruppen',
		Funktion: Preisgruppen
	}, {
		ueberschrift: 'Bestände',
		Funktion: Bestaende
	}, {
		ueberschrift: 'Bilder',
		Funktion: Bilder
	}];
}

export default detailViewArtikel;
