import { makeTelLink, makeWebsiteLink, routenPlanerZuAdresse, keineBlaVorhanden } from '../functions';
import List from '@material-ui/core/List/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import PhoneIcon from '@material-ui/icons/Phone';
import WebIcon from '@material-ui/icons/Web';
import EmailIcon from '@material-ui/icons/Email';
import MapIcon from '@material-ui/icons/Map';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { withStyles, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import GroupIcon from '@material-ui/icons/Group';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TabAnsprechpartner from './TabAnsprechpartner';

const AdresseTabUebersicht = props => {

	const _adresse = props.adresse || props;
	const { ansprechpartners } = props;

	const dash = '—';
	const adresse = {
		anrede: _adresse.anrede !== dash ? _adresse.anrede : '',
		name1: _adresse.name1 || dash,
		strasse: _adresse.strasse || dash,
		plz: _adresse.plz || dash,
		ort: _adresse.ort || dash,
		gesperrt: _adresse.gesperrt || 'nein',
		mobil: _adresse.mobil || dash,
		telefon1: _adresse.telefon1 || dash,
		telefon2: _adresse.telefon2 || dash,
		webseite: _adresse.webseite || dash,
		email: _adresse.email || dash,
		adressnummer: _adresse.adressnummer || dash,
		kundennummer: _adresse.kundennummer || dash,
		lieferantennummer: _adresse.lieferantennummer || dash
	};

	const openTab = tabIndex => {
		props.screenProps.setTabValue(tabIndex);
	};

	const StyledIcon = withStyles(theme => ({
		root: {
			color: theme.palette.secondary.main
		}
	}))(GroupIcon);

	const { StyledCard } = props, { StyledAvatar } = props, { StyledListItemText } = props;
	const left = { item: true, xs: 4, sm: 2 };
	const right = { item: true, xs: 8, sm: 10 };
	const v = { variant: 'subtitle2' };

	return (
		<>
			{/* Kopf */}
			<StyledCard>
				<List>{/* List/ListItem nur wegen Kompatibiltät zu anderen Listen wegen padding */}
					<ListItem>
						<Grid container spacing={1}>
							<Grid {...left}><StyledAvatar><PeopleIcon /></StyledAvatar></Grid>
							<Grid {...right}><StyledListItemText primary={`${adresse.anrede} ${adresse.name1}`} /></Grid>
							<Grid {...left}></Grid>
							<Grid {...right}>
								<Typography {...v}>
									{adresse.strasse}<br />
									{adresse.plz} {adresse.ort}
								</Typography>
							</Grid>
							<Grid {...left}><Typography {...v}>Adressnr.</Typography></Grid>
							<Grid {...right}><Typography {...v}>{adresse.adressnummer}</Typography></Grid>
							<Grid {...left}><Typography {...v}>Kundennr.</Typography></Grid>
							<Grid {...right}><Typography {...v}>{adresse.kundennummer}</Typography></Grid>
							<Grid {...left}><Typography {...v}>Lieferantennr.</Typography></Grid>
							<Grid {...right}><Typography {...v}>{adresse.lieferantennummer}</Typography></Grid>
						</Grid>
					</ListItem>
				</List>
			</StyledCard>

			{/* Kontakt */}
			<StyledCard>
				<CardHeader title="Kontakt" />

				<Divider />

				<List>

					{
						adresse.gesperrt === 'ja' &&
						<ListItem button divider>
							<ListItemIcon>
								<NotInterestedIcon color="error" />
							</ListItemIcon>
							<ListItemText primary="Adresse gesperrt" secondary="Status" />
						</ListItem>
					}

					<ListItem button divider component="a" href={adresse.telefon1 !== dash ? `tel:${makeTelLink(adresse.telefon1)}` : `#`}>
						<ListItemIcon>
							<PhoneIcon />
						</ListItemIcon>
						<ListItemText primary={adresse.telefon1} secondary="Telefon" />
					</ListItem>

					<ListItem button divider component="a" href={adresse.telefon2 !== dash ? `tel:${makeTelLink(adresse.telefon2)}` : `#`}>
						<ListItemIcon>
							<PhoneIcon />
						</ListItemIcon>
						<ListItemText primary={adresse.telefon2} secondary="Telefon" />
					</ListItem>

					<ListItem button divider component="a" href={adresse.mobil !== dash ? `tel:${makeTelLink(adresse.mobil)}` : `#`}>
						<ListItemIcon>
							<SmartphoneIcon />
						</ListItemIcon>
						<ListItemText primary={adresse.mobil} secondary="Mobil" />
					</ListItem>

					<ListItem button divider component="a" href={adresse.email !== dash ? `mailto:${adresse.email}` : `#`}>
						<ListItemIcon>
							<EmailIcon />
						</ListItemIcon>
						<ListItemText primary={adresse.email} secondary="E-Mail" />
					</ListItem>

					<ListItem button divider component="a" target={adresse.webseite !== dash ? '_blank' : '_self'} href={adresse.webseite !== dash ? makeWebsiteLink(adresse.webseite) : `#`}>
						<ListItemIcon>
							<WebIcon />
						</ListItemIcon>
						<ListItemText primary={adresse.webseite} secondary="Website" />
					</ListItem>

					<ListItem button component="a" target="_blank" href={routenPlanerZuAdresse(`${adresse.strasse}, ${adresse.plz} ${adresse.ort}`)}>
						<ListItemIcon>
							<MapIcon />
						</ListItemIcon>
						<ListItemText primary={adresse.name1} secondary="Navigation" />
					</ListItem>
				</List>
			</StyledCard>

			{/* 1. Ansprechpartner */}
			<StyledCard>
				<CardHeader
					title="1. Ansprechpartner"
					action={ansprechpartners.length ?
						<Link onClick={() => openTab(1)}>
							<IconButton>
								<StyledIcon />
							</IconButton>
						</Link>
						: null
					}
				/>

				<Divider />

				{ansprechpartners.length ?
					<TabAnsprechpartner ansprechpartners={[ansprechpartners[0]]} noCard />
					: keineBlaVorhanden('Ansprechpartner', false)}

			</StyledCard>

			{/* Auswertungen */}
			<StyledCard>
				<CardHeader
					title="Auswertungen"
					action={
						<IconButton>
							<ErrorOutlineIcon />
						</IconButton>
					}
				/>

				<Divider />

				<CardContent>
					<Table>
						<TableBody>
							<TableRow hover>
								<TableCell>Anzahl offener Posten</TableCell>
								<TableCell align="right">{props.offenePostenUebersicht.anzahl}</TableCell>
							</TableRow>

							<TableRow hover>
								<TableCell>Rechnungsbetrag</TableCell>
								<TableCell align="right">{props.offenePostenUebersicht.rechnungsbetrag}</TableCell>
							</TableRow>

							<TableRow hover>
								<TableCell>Bisher gezahlt</TableCell>
								<TableCell align="right">{props.offenePostenUebersicht.bishergezahlt}</TableCell>
							</TableRow>

							<TableRow hover>
								<TableCell>Offener Betrag</TableCell>
								<TableCell align="right">{props.offenePostenUebersicht.offenerbetrag}</TableCell>
							</TableRow>

							<TableRow hover>
								<TableCell>Gesamtumsatz letzte 12 Monate</TableCell>
								<TableCell align="right">{props.umsatz}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</CardContent>
			</StyledCard>
		</>
	);
};

export default AdresseTabUebersicht;
