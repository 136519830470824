import * as types from '../constants/actionTypes';

export const setVisibilityFilter = filter => ({
	type: types.SET_VISIBILITY_FILTER,
	filter
});
export const rejectTask = id => ({
	type: types.REJECT_TASK,
	id
});
export const completeTask = id => ({
	type: types.COMPLETE_TASK,
	id
});
export const insertTask = item => ({
	type: types.INSERT_TASK,
	item
});
export const acceptTask = id => ({
	type: types.ACCEPT_TASK,
	id
});
export const updateStatus = (id, timestamp) => ({
	type: types.UPDATE_STATUS,
	id,
	timestamp
});
export const insertStatus = (id, timestamp) => ({
	type: types.INSERT_STATUS,
	id,
	timestamp
});
export const removeItem = (id, status, reference) => ({
	type: types.REMOVE_ITEM,
	id,
	status,
	reference
});
export const checkItem = (id, status, reference) => ({
	type: types.CHECK_ITEM,
	id,
	status,
	reference
});
export const insertPosition = item => ({
	type: types.INSERT_POSITION,
	item
});
export const splitPosition = (id, created, count, index = 1, status = 0) => ({
	type: types.SPLIT_POSITION,
	id,
	created,
	count,
	index,
	status
});
