import React from 'react';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';

const IterableButton = props => {
	const [currentStatus, updateStatus] = useState(props.status);
	const { states } = props;
	const increment = value => value + 1;

	const modifier = props.modifier || increment;
	const color = props.color || 'primary';

	if (currentStatus < props.states.length) {
		return (
			<Button fullWidth={true} size="large" color={color} onClick={() => {
				props.callback && props.callback(currentStatus, states);
				updateStatus(modifier(currentStatus));
			}
			}>{props.before} <Typography variant="h6">{states[props.controlled || currentStatus]}</Typography> {props.after}</Button>
		);
	} else {
		return <></>;
	}
};
export default IterableButton;
