import React from 'react';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const AcceptDecline = props => {
	return (
		<CardActions>
			<Button fullWidth={true} size="large" color="primary" onClick={() => props.accept(props)}>
				<Typography variant="h6">{props.primary}</Typography>
				<CheckIcon fontSize="large"/>
			</Button>
			<Button fullWidth={true} size="large" color="secondary" onClick={() => props.decline(props)}>
				<CloseIcon fontSize="large"/>
				<Typography variant="h6">{props.secondary}</Typography>
			</Button>
		</CardActions>
	);
};
export default AcceptDecline;
