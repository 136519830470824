import Items from '.';
import { formatPreis, formatDatum } from '../../functions';

class FetchOPZahlungen extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		if (!id || isNaN(id)) throw new TypeError(`[FetchOPZahlungen.js] id nicht vorhanden`);
		this.path = {
			string: `finanzen/offeneposten/${id}/zahlungen`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Offene Posten Zahlungen', this.path).then(json => {

			if (json.results.totalResultCount === 0) {
				resolve(null);
				return;
			}

			if (!json.path || !json.results || !json.results.offenePostenZahlungen) {
				reject('[FetchOPZahlungen.js] !json.path || !json.results || !json.results.offenePostenZahlungen');
				return;
			}

			const zahlungen = json.results.offenePostenZahlungen;

			for (let i = 0; i < zahlungen.length; i++) {
				zahlungen[i].zahlungsBetrag = formatPreis( zahlungen[i].zahlungsBetrag);
				zahlungen[i].zahlungsDatum = formatDatum(zahlungen[i].zahlungsDatum);
			}

			resolve(zahlungen);
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
	});
}

export default FetchOPZahlungen;
