import { getEmptyPostFetchObj, deleteRowsFromCache } from '../../functions';

class FetchBelegBuchen {

	constructor(props, signal, belegId, adresseId) {
		this.props = props;
		this.belegId = belegId;
		this.adresseId = adresseId;
		this.signal = signal;
	}

	myFetch = async () => {
		const url = `${window.RESTserver}/vario-api/verkauf/belege/${this.belegId}/buchen`;
		let response;
		try {
			response = await fetch(url, getEmptyPostFetchObj(this.signal));
		} catch (error) {
			console.error(error);
			return error.errorMessage;
		}
		if (!response.ok) return 'Es gab ein Problem beim Buchen. (!response.ok)';
		if (response.status !== 200) return 'Es gab ein Problem beim Buchen (response.status !== 200).';
		// Request für den Cache erneuern
		await deleteRowsFromCache(this.props.db, 'belege');
		return 'Beleg gebucht!';
	}
}

export default FetchBelegBuchen;
