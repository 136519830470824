import Items from '.';
import $ from '../../config';

class StatistikGeburtstagskalender extends Items {
	constructor(props, signal) {
		super(props, signal);
		this.listCount = 8;
		this.path = {
			string: `auswertungen/geburtstage/daten`,
			id: null
		};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Geburtstagstatistik', this.path).then(json => {

			let result = json.results.geburtstagstatistik;

			const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
				msPerDay = 86400000;
			result.sort((a, b) => Date.parse(a.geburtstag) - Date.parse(b.geburtstag));
			const startDate = new Date();
			result = result.filter(el => Date.parse(el.geburtstag) >= startDate - msPerDay)
				.map(el => ({
					name: el.briefname1,
					datum: el.geburtstag
						.slice(0, 10)
						.split('-')
						.slice(1)
						.map(el => parseInt(el))
						.reverse()
						.map((el, idx) => !idx ? `${el}.` : months[el - 1])
						.join(' '),
					daysLeft: (e => e ? e >> 1 ? e - 2 ? e : $.gebUebermorgen : $.gebMorgen : $.gebHeute)(
						Math.ceil((Date.parse(el.geburtstag) - startDate) / msPerDay)),
					adresseId: el.adresseid,
					ansprechpartnerId: el.ansprechpartnerid
				})).slice(0, this.listCount);
			resolve(result);
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Artikel';
	});
}

export default StatistikGeburtstagskalender;
