import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EmailIcon from '@material-ui/icons/Email';
import PeopleIcon from '@material-ui/icons/People';
import MapIcon from '@material-ui/icons/Map';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { routenPlanerZuAdresse } from '../functions';
import IconButton from '@material-ui/core/IconButton';

const CardZusatzadressen = props => {

	const zusatzadressen = props.items;
	if (!zusatzadressen) return null;

	const StyledListItemText = withStyles(theme => ({
		primary: {
			color: theme.palette.secondary.main,
			fontWeight: 'bold'
		}
	}))(ListItemText);
	const StyledCard = withStyles({
		root: {
			margin: '4px'
		}
	})(Card);
	const StyledAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);
	const left = { item: true, xs: 4, sm: 2 };
	const right = { item: true, xs: 8, sm: 10 };
	const v = { variant: 'subtitle2' };
	const dash = '—';

	return zusatzadressen.map((adresse, index) => (
		<StyledCard key={index}>
			<List>
				<ListItem>
					<Grid container spacing={1}>
						<Grid {...left}><StyledAvatar><PeopleIcon /></StyledAvatar></Grid>
						<Grid {...right}>
							<Grid container spacing={1}>
								<Grid item xs={10}><StyledListItemText primary={adresse.anrede.length > 1 ? `${adresse.anrede} ${adresse.name1}` : adresse.name1} /></Grid>
								<Grid item xs={2} style={{ textAlign: 'right' }}>
									<Link href={`/app/adressen/${adresse.adresseId}?details`}>
										<IconButton><PeopleIcon color="primary" /></IconButton>
									</Link>
								</Grid>
							</Grid>
						</Grid>

						<Grid {...left}></Grid>
						<Grid {...right}>
							<Typography {...v}>
								{adresse.strasse}<br />
								{adresse.plz} {adresse.ort}
							</Typography>
						</Grid>
					</Grid>
				</ListItem>
			</List>

			<div style={{ height: '10px' }} />

			<List>
				<ListItem button divider component="a" href={adresse.email !== dash ? `mailto:${adresse.email}` : `#`}>
					<ListItemIcon>
						<EmailIcon />
					</ListItemIcon>
					<ListItemText primary={adresse.email} secondary="E-Mail" />
				</ListItem>

				<ListItem button component="a" target="_blank" href={routenPlanerZuAdresse(`${adresse.strasse}, ${adresse.plz} ${adresse.ort}`)}>
					<ListItemIcon>
						<MapIcon />
					</ListItemIcon>
					<ListItemText primary={adresse.name1} secondary="Navigation" />
				</ListItem>
			</List>
		</StyledCard>
	));
};

export default CardZusatzadressen;