import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoginScreen from './screens/login';
import Notfound from './screens/notfound';
import 'typeface-roboto';
import './css/global.css';
import MainScreen from './screens';
import $ from './config';
import Dexie from 'dexie';
import { Provider } from 'react-redux';
import store from './redux-service';

// THEME
const varioTheme = createMuiTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		text: {
			primary: '#2e2e2e', /** hellschwarz */
			secondary: '#777' /** grau */
		},
		primary: {
			main: '#84b933', /** grün */
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#a82e68' /** Brombeere */
		},
		error: {
			main: '#ff0000' /** rot */
		}
	}
});

if (window.indexedDB) {
	// Indexed DB
	const db = new Dexie($.dbName);
	// Tabellen
	for (let i = 0; i < $.dbVersions.length; i++) {
		const version = $.dbVersions[i];
		const vNummer = i + 1;
		db.version(vNummer).stores(version);
	}
	db.open().then(function (db) {
		if (window.console_InfoOutput) console.info('index.js neu geladen.');
		if (window.console_DbOutput) {
			console.log('Gefundene Datenbank: ' + db.name);
			console.log('Datenbank-Version: ' + db.verno);
			const tabletable = [], tableCount = [];
			db.tables.forEach(table => tableCount.push(table.count()));
			Promise.all(tableCount).then(countArr => {
				db.tables.forEach((table, index) => tabletable.push({ Name: table.name, Zeilen: countArr[index] }));
				console.table(tabletable);
			});
		}

		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<MuiThemeProvider theme={varioTheme}>
						<CssBaseline/>
						<Switch>
							<Route path="/" exact render={props => <LoginScreen db={db} {...props} />}/>
							<Route path="/app/:bereich" render={props => <MainScreen db={db} {...props} />}/>
							<Route component={Notfound}/>
						</Switch>
					</MuiThemeProvider>
				</Router>
			</Provider>,
			document.getElementById('root'));

	}).catch('NoSuchDatabaseError', function (e) {
		alert('Datenbank nicht gefunden. ', e);
	}).catch(function (e) {
		Dexie.delete($.dbName);
		window.location.href = '/';
		console.error('Dexie-Fehler: ', e);
	});

} else {
	alert('Ihr Browser unterstützt keine indexedDB.');
}

serviceWorker.register();
