import Items from '.';
import { formatPreis } from '../../functions';

class FetchOffenePostenUebersicht extends Items {
	constructor(props, signal) {
		super(props, signal);
		this.path = {
			string: `auswertungen/sql`,
			id: null
		}
		this.sendData = {
			sql: `
				select
					sum(opv.offenerbetrag) AS OFFEN_BETRAG,
					count(opv.id) AS OFFEN_ANZAHL,
					sum(iif(((coalesce(nullif(opv.mahnstufe, ''), '0') = '0') and (coalesce(opv.inklaerung, 'N') <> 'J')), opv.offenerbetrag, 0)) AS STUFE0_BETRAG,
					sum(iif(((coalesce(nullif(opv.mahnstufe, ''), '0') = '0') and (coalesce(opv.inklaerung, 'N') <> 'J')), 1, 0)) AS STUFE0_ANZAHL,
					sum(iif(((coalesce(opv.mahnstufe, '0') = '1') and (coalesce(opv.inklaerung, 'N') <> 'J')), opv.offenerbetrag, 0)) AS STUFE1_BETRAG,
					sum(iif(((coalesce(opv.mahnstufe, '0') = '1') and (coalesce(opv.inklaerung, 'N') <> 'J')), 1, 0)) AS STUFE1_ANZAHL,
					sum(iif(((coalesce(opv.mahnstufe, '0') = '2') and (coalesce(opv.inklaerung, 'N') <> 'J')), opv.offenerbetrag, 0)) AS STUFE2_BETRAG,
					sum(iif(((coalesce(opv.mahnstufe, '0') = '2') and (coalesce(opv.inklaerung, 'N') <> 'J')), 1, 0)) AS STUFE2_ANZAHL,
					sum(iif(((coalesce(opv.mahnstufe, '0') = '3') and (coalesce(opv.inklaerung, 'N') <> 'J')), opv.offenerbetrag, 0)) AS STUFE3_BETRAG,
					sum(iif(((coalesce(opv.mahnstufe, '0') = '3') and (coalesce(opv.inklaerung, 'N') <> 'J')), 1, 0)) AS STUFE3_ANZAHL,
					sum(iif(((coalesce(opv.mahnstufe, '0') >= '4') and (coalesce(opv.inklaerung, 'N') <> 'J')), opv.offenerbetrag, 0)) AS STUFE4_BETRAG,
					sum(iif(((coalesce(opv.mahnstufe, '0') >= '4') and (coalesce(opv.inklaerung, 'N') <> 'J')), 1, 0)) AS STUFE4_ANZAHL,
					sum(iif((coalesce(opv.inklaerung, 'N') = 'J'), opv.offenerbetrag, 0)) AS KLAERUNG_BETRAG,
					sum(iif((coalesce(opv.inklaerung, 'N') = 'J'), 1, 0)) AS KLAERUNG_ANZAHL
				from opv
				join adr on opv.adressnr = adr.adressnr
				where coalesce(opv.abgeschlossen, 'N') = 'N'
				and opv.opvart = 'O'
		`};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerPost('Offene Posten Uebersicht', this.path, this.sendData).then(json => {
			let op = json.results.offenePostenUebersicht[0];

			op.offen_Betrag = formatPreis( op.offen_Betrag - op.klaerung_Betrag);
			op.offen_Anzahl = op.offen_Anzahl - op.klaerung_Anzahl;
			op.klaerung_Betrag = formatPreis( op.klaerung_Betrag);
			op.stufe0_Betrag = formatPreis( op.stufe0_Betrag);
			op.stufe1_Betrag = formatPreis( op.stufe1_Betrag);
			op.stufe2_Betrag = formatPreis( op.stufe2_Betrag);
			op.stufe3_Betrag = formatPreis( op.stufe3_Betrag);

			resolve(op);
		}).catch(err => reject(err));
	}).catch(err => console.error(err))
}

export default FetchOffenePostenUebersicht;