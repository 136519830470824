import Items from '.';
import { writeNullToKeys, formatBelegeListe, semiCamelCase } from '../../functions';

class FetchBelege extends Items {
	constructor(props, signal) {
		super(props, signal);
		this.props = props;
		this.signal = signal;
		this.path = {
			string: props.listingPath,
			id: null
		};
		this.fetch = this.fetch.bind(this);
	}

	async fetch() {
		const name = this.props.config.itemsName;
		const camelName = semiCamelCase(name);
		const json = await super.fetchDbOrServerGet(name, this.path);
		let belege = json.results[camelName];
		const formatierteBelege = await formatBelegeListe(this.props.db, belege);
		return {
			totalResultCount: json.results.totalResultCount,
			belege: writeNullToKeys(formatierteBelege)
		};
	};
}

export default FetchBelege;