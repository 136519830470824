import React, { useState } from 'react';
import SplitButton from './splitButton';
import SplitPositionModal from './SplitPositionModal';
import store from '../index';
import { splitPosition } from '../actions';

const SplitController = (props) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<SplitButton callback={() => setOpen(!open)}/>
			<SplitPositionModal
				menge={props.menge}
				open={open}
				submit={value => {
					setOpen(!open);
					store.dispatch(splitPosition(props.id, value, props.menge - value, props.index, props.status));
				}}
				cancel={() => setOpen(!open)}
			/>
		</>
	);
};

export default SplitController;
