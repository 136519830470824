import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCommentIcon from '@material-ui/icons/AddComment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import SuchergebnisseDropdown from '../containers/SuchergebnisseDropdown';
import Modal from '../containers/Modal';
import Fetch from '../classes/fetch';
import FetchSearchresultsGlobal from '../classes/items/FetchSearchresultsGlobal';
import FetchSearchresultsScope from '../classes/items/FetchSearchresultsScope';
import Backdrop from '@material-ui/core/Backdrop';
import SortIcon from '@material-ui/icons/Sort';
import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
	adressenOrDealsDetails, getDefaultFilters, formatBelegeListe,
	formatOPListe, formatDealsListe, isDetailView, isAdrSearchView, containsInt
} from '../functions';
import Chip from '@material-ui/core/Chip';
import $ from '../config';

const weiss = '#fafafa';

const styles = theme => ({
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	icons: {
		color: 'white'
	},
	grow: {
		flexGrow: 1
	},
	menuButton: {
		color: weiss,
		marginLeft: -12,
		marginRight: 20
	},
	marginright: {
		marginRight: '10px'
	},
	sectionDesktop: {
		color: weiss,
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		}
	},
	sectionMobile: {
		color: weiss,
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},
	title: {
		color: weiss
	},
	arrowBackIcon: {
		margin: '0 10px',
		borderRadius: '50%'
	},
	eingabefeld: {
		flexGrow: 100
	},
	onlineIcon: {
		color: '#a4f237',
		border: '2px solid #fafafa',
		borderRadius: '50%'
	},
	onlineRESToffIcon: {
		color: 'yellow',
		border: '2px solid #fafafa',
		borderRadius: '50%'
	},
	offlineIcon: {
		color: '#cc4141',
		border: '2px solid #fafafa',
		borderRadius: '50%'
	},
	searchInput: {
		position: 'fixed',
		display: 'flex',
		left: 8,
		top: 8,
		right: 8,
		color: '#2e2e2e',
		backgroundColor: '#ffffff',
		zIndex: 1501,
		height: window.innerWidth >= 600 ? 56 : 48,
		flexWrap: 'nowrap',
		alignItems: 'center',
		borderTopLeftRadius: '3px',
		borderTopRightRadius: '3px'
	},
	backdrop: {
		zIndex: 1500
	},
	chip: {
		margin: theme.spacing(0.5)
	}
});

class VarioAppBar extends Component {

	//#region
	constructor(props) {
		super(props);

		this.fetch = new Fetch(props);
		this.controller = new AbortController();
		this._isMounted = false;
		this.limit = 7;
		this.limitScope = 2 * this.limit;
		// hier bei initSearchresults muss als key ein Bekannter drin stehen, damit
		// im Dropdown "active" gefunden wird
		this.initSearchresults = [
			{ key: $.adressenNode, value: { results: [], active: false } }
		]

		this.state = {
			menuAnchorEl: null,
			eingabe: '',
			noResults: false,
			searchResults: this.initSearchresults,
			bereich: '',
			chips: [],
			closeSearchClicked: false,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		};

		this.suchEingabeTimer = null;

		this.eingabe = React.createRef();

		//#region
		this.handleClick = this.handleClick.bind(this);
		this.handleMenuClose = this.handleMenuClose.bind(this);
		this.handleMenuOpen = this.handleMenuOpen.bind(this);
		this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
		this.emptySearch = this.emptySearch.bind(this);
		this.focusSearchinput = this.focusSearchinput.bind(this);
		this.search = this.search.bind(this);
		this.searchNow = this.searchNow.bind(this);
		this.closeSearch = this.closeSearch.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.handleAktClick = this.handleAktClick.bind(this);
		this.handleDeleteChip = this.handleDeleteChip.bind(this);
		this.doScopeSearch = this.doScopeSearch.bind(this);
		this.setActive = this.setActive.bind(this);
		this.initSearch = this.initSearch.bind(this);
		this.initChips = this.initChips.bind(this);
		this.handleCloseClick = this.handleCloseClick.bind(this);
		//#endregion
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleEnter);
		this._isMounted = false;
		this.controller.abort();
	}

	componentDidUpdate(prevProps) {
		this.focusSearchinput();
		if (prevProps.searchterm !== this.props.searchterm) {
			this.setState({ eingabe: this.props.searchterm });
			this.searchInner(this.props.searchterm);
		}

		if (
			!prevProps.showSearch
			&& isAdrSearchView(this.props.location.search)
			&& !this.state.closeSearchClicked
			&& (
				(!this.props.mobileListe() && containsInt(this.props.location.pathname))
				|| this.props.mobileListe()
			)
		)
			this.initSearch();
	}

	handleClick = event => {
		this.setState({ menuAnchorEl: null });
	};

	handleAktClick = event => {
		this.setState({ menuAnchorEl: null });
		this.props.openAktEditierenModal();
	};

	handleEnter = isGlobalSearch => event => {
		// Enter abfangen
		if (event.keyCode !== 13) return;
		event.preventDefault();
		if (isGlobalSearch) window.location.href = '/app/suchergebnisse'; // geht irgendwie nicht mit Router
		else {
			// Click emulieren, damit componentDidUpdate (s.o.) keine Auswirkungen mehr hat
			this.setState({ closeSearchClicked: true });
			this.props.closeSearch();
			this.emptySearch();
		}
	}

	handleMenuClose() {
		this.handleMobileMenuClose();
	}

	closeSearch() {
		this.emptySearch();
		this.props.closeSearch();
		// ?adrSearch in der URL darf jetzt keine Auswirkungen mehr haben
		this.setState({ closeSearchClicked: true });
	}

	initSearch() {
		this.props.openSearch();
		this.emptySearch();
	}

	handleMenuOpen = e => {
		this.setState({ menuAnchorEl: e.currentTarget });
	};

	handleMobileMenuClose() {
		this.setState({ menuAnchorEl: null });
	}

	focusSearchinput() {
		if (this.eingabe && this.eingabe.current) {
			this.eingabe.current.focus();
		}
	}

	initChips() {
		const { config } = this.props;
		if (!config || config.itemsNode === $.suchergebnisseNode || config.itemsNode === $.serviceNode) return [];
		return [{ key: 0, label: config.itemsName }];
	}

	emptySearch() {
		this.setState({
			eingabe: '',
			noResults: false,
			searchResults: this.initSearchresults,
			chips: this.initChips()
		});
		this.focusSearchinput();
	}

	search = e => {
		let eingabe = e.target.value;
		this.setState({ eingabe });
		eingabe = eingabe.trim();
		if (eingabe.length < 3) {
			this.setState({
				noResults: false,
				searchResults: this.initSearchresults
			});
			return;
		}

		if (this.suchEingabeTimer) clearTimeout(this.suchEingabeTimer);
		this.suchEingabeTimer = setTimeout(() => {
			this.searchNow(eingabe);
		}, 300);
	};

	setActive() {
		let newResults = [];
		const { searchResults } = this.state;
		if (!searchResults.length) newResults = this.initSearchresults;
		for (let i = 0; i < searchResults.length; i++) {
			const resObj = searchResults[i];
			newResults.push({
				key: resObj.key,
				value: { results: resObj.value.results, active: true }
			});
		}

		this.setState({ searchResults: newResults });
	}

	searchNow(eingabe) {
		if (!eingabe) return;
		this.setActive();
		window.removeEventListener('keydown', this.handleEnter);

		// bei Volltextsuche ohne Einschränkung
		if (!this.state.chips.length) this.doGlobalSearch(eingabe);
		// bei Suche im Bereich
		else this.doScopeSearch(eingabe);
	}

	async doGlobalSearch(eingabe) {
		const obj = await new FetchSearchresultsGlobal(this.props, this.controller.signal, eingabe).fetch();
		const { suchergebnisse } = obj;

		// Fehler (catch-Zweig von FetchSearchresultsGlobal)
		if (this._isMounted && !suchergebnisse) {
			this.setState({
				noResults: true,
				searchResults: this.initSearchresults
			});
			return;
		}

		let noResults = false;
		// nur erste n Ergebnisse anzeigen im dropdown
		const ansprechpartner = suchergebnisse.ansprechpartner.slice(0, this.limit);
		const belege = suchergebnisse.belege.slice(0, this.limit);
		const adressen = suchergebnisse.adressen.slice(0, this.limit);

		if (!adressen.length && !ansprechpartner.length && !belege.length) noResults = true;
		else window.addEventListener('keydown', this.handleEnter(true));

		if (this._isMounted) {
			const newResults = [
				{ key: $.adressenNode, value: { results: adressen, active: false } },
				{ key: $.ansprechpartnersNode, value: { results: ansprechpartner, active: false } }
			];
			this.setState({
				noResults,
				searchResults: newResults
			});
		}
	}

	async doScopeSearch(eingabe) {
		const
			{ itemsNode } = this.props.config, defaultFilters = getDefaultFilters(itemsNode),
			filters = [], orderBy = `orderBy=${$.defaultOrderBy[itemsNode]}`, elements = [];
		let q = `q="${eingabe}"`;
		if (defaultFilters) filters.push(defaultFilters);
		// Suche in filter packen
		if (itemsNode === $.offenepostenNode) {
			filters.push(`adresse?"${eingabe}"`);
			q = '';
		} else if (itemsNode === $.dealsNode) {
			filters.push(`titel?"${eingabe}"`);
			q = '';
		}
		elements.push(orderBy);
		if (filters.length) elements.push(`filter=${filters.join(',')}`);
		if (q) elements.push(q);
		const str = `${this.props.config.basePath}?${elements.join('&')}`;
		//console.log(str)
		const path = {
			string: str,
			id: null
		};

		const obj = await new FetchSearchresultsScope(this.props, this.controller.signal, path, this.props.config).fetch();

		this.props.updateListingPath(str);

		let
			noResults = obj.totalResultCount === 0,
			results = obj.results && obj.results.length ? obj.results.slice(0, this.limitScope) : [];

		// Belege formatieren
		if (itemsNode === $.belegeNode) await formatBelegeListe(this.props.db, results);
		// offene Posten formatieren
		else if (itemsNode === $.offenepostenNode) await formatOPListe(this.props.db, results);
		// Deals formatieren
		else if (itemsNode === $.dealsNode) await formatDealsListe(this.props.db, results);

		if (!noResults) window.addEventListener('keydown', this.handleEnter(false));

		if (this._isMounted) {
			const newResults = [{ key: itemsNode,
				value: {
					results,
					active: false
				}
			}];
			this.setState({
				noResults,
				searchResults: newResults
			});
		}
	}

	handleDeleteChip = data => () => {
		const { chips } = this.state;
		const chipToDelete = chips.indexOf(data);
		chips.splice(chipToDelete, 1);
		this.setState({ chips });
		// kein Chip mehr da: Globale Suche starten (wenn keine Eingabe vorhanden, wird einfach das Chip weggenommen)
		if (!chips.length) this.searchNow(this.state.eingabe);
	};

	handleCloseClick() {
		this.props.history.push(`/app/dashboard`);
	}
	//#endregion

	render() {

		const { menuAnchorEl } = this.state;
		const { classes } = this.props;

		const adrOrDealsObj = adressenOrDealsDetails(this.props.location.pathname);

		const menuContent = () => {

			let adrId = null;
			let adrIdForBelegOrAkt = this.props.getAdrIdForBelegOrAkt();
			if (adrIdForBelegOrAkt || adrOrDealsObj) adrId = adrIdForBelegOrAkt || adrOrDealsObj.adrId;

			// TODO: code ist hartcodiert, damit es erst mal schnell drin ist

			let _menuContent;
			if (!adrId) {
				_menuContent = [{
					title: 'Angebot',
					icon: <NoteAddIcon />,
					link: `/app/${$.adressenNode}?adrSearch&belegart=0100`
				}, {
					title: 'Auftrag',
					icon: <ReceiptIcon />,
					link: `/app/${$.adressenNode}?adrSearch&belegart=0200`
				}];
			} else {
				_menuContent = [{
					title: 'Angebot',
					icon: <NoteAddIcon />,
					link: `/app/belegneu/${adrId}/0100`
				}, {
					title: 'Auftrag',
					icon: <ReceiptIcon />,
					link: `/app/belegneu/${adrId}/0200`
				}];
			}

			const menu = [
				_menuContent.map((el, index) =>
					<MenuItem key={index} button component="a" href={el.link} onClick={this.handleClick}>
						<ListItemIcon><IconButton color="inherit">{el.icon}</IconButton></ListItemIcon>
						<ListItemText classes={{ root: classes.marginright }} primary={el.title} />
					</MenuItem>
				)
			];

			if (adrId) menu.push(
				<MenuItem key={9965439} button component="a" onClick={this.handleAktClick}>
					<ListItemIcon><IconButton color="inherit"><AddCommentIcon /></IconButton></ListItemIcon>
					<ListItemText classes={{ root: classes.marginright }} primary="Aktivität" />
				</MenuItem>
			);

			return menu;
		};

		// per CSS einblenden, damit this.eingabe = React.createRef() funktioniert
		const sucheEingabeFeld =
			<div style={{ display: this.props.showSearch ? 'block' : 'none' }}>
				<div className={classes.searchInput}>

					<ArrowBackIcon
						className="iconBtn"
						classes={{ root: classes.arrowBackIcon }}
						onClick={this.closeSearch}
					/>

					{this.state.chips.map(data => (
						<Chip
							key={data.key}
							label={data.label}
							onDelete={this.handleDeleteChip(data)}
							className={classes.chip}
						/>
					))
					}

					<InputBase
						value={this.state.eingabe}
						onChange={this.search}
						inputRef={this.eingabe}
						className={classes.eingabefeld}
						placeholder={this.state.chips.length ? 'Freier Filter...' : 'Suche...'}
					/>

				</div>

				<Backdrop
					className={classes.backdrop}
					open={this.props.showSearch}
					onClick={this.closeSearch}
				/>

				<SuchergebnisseDropdown
					globalSearch={!this.state.chips.length}
					screenProps={this.props}
					noResults={this.state.noResults}
					searchResults={this.state.searchResults}
				/>
			</div>;

		return (
			<>
				<AppBar position="fixed" style={{ zIndex: 1101 }}>
					<Toolbar>

						{/* Schließen Icon in Belegneu */}
						{this.props.match.params.bereich === 'belegneu' &&
							<>
								<IconButton edge="start" color="inherit" onClick={this.handleCloseClick} aria-label="Schließen"><CloseIcon /></IconButton>
								<div style={{ width: '16px' }} />
							</>
						}

						{/* NavigationDrawer */}
						{this.props.match.params.bereich !== 'belegneu' &&
							<>
								<IconButton onClick={() => this.props.toggleDrawer()(false)} className={classes.menuButton}><MenuIcon /></IconButton>
								<div className={classes.grow} />
							</>
						}

						{/* Text in der Mitte */}
						<Typography className={classes.title} variant="h6" noWrap>{this.props.bereich}</Typography>
						<div className={classes.grow} />

						{/* In Belegneu Suche und Neuanlage ausblenden */}
						{this.props.match.params.bereich !== 'belegneu' &&
							<>
								{/* Suche */}
								<Tooltip title="Suche" classes={{ tooltip: classes.lightTooltip }}>
									<IconButton classes={{ root: classes.icons }} onClick={this.initSearch}>
										<SearchIcon />
									</IconButton>
								</Tooltip>

								<Tooltip title="Neuanlage..." classes={{ tooltip: classes.lightTooltip }}>
									<IconButton aria-haspopup="true" onClick={this.handleMenuOpen} color="inherit">
										<AddIcon />
									</IconButton>
								</Tooltip>

								<Menu
									anchorEl={menuAnchorEl}
									anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
									transformOrigin={{ vertical: 'top', horizontal: 'right' }}
									open={Boolean(menuAnchorEl)}
									onClose={this.handleMobileMenuClose}
								>
									{menuContent()}
								</Menu>
							</>
						}

						{/* FilterDrawer */}
						{this.props.config
							&& this.props.config.showFilter
							&& (this.props.splitScreen() || this.props.mobileListe())
							&& !isDetailView(this.props.location.search) &&
							<Tooltip title="Sortierung/Filter" classes={{ tooltip: classes.lightTooltip }}>
								<IconButton classes={{ root: classes.icons }} onClick={() => this.props.toggleFilterDrawer(true)}>
									<SortIcon />
								</IconButton>
							</Tooltip>
						}

					</Toolbar>
				</AppBar>

				<Modal
					open={this.state.emOpen}
					title={this.state.emTitle}
					text={this.state.emText}
					handleOk={() => this.setState({ emOpen: false })}
					cancelBtn={false}
					destroy={() => this.setState({ emOpen: false })}
				/>

				{sucheEingabeFeld}
			</>
		); // return
	}; // render()
}

VarioAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
	bereich: PropTypes.string.isRequired,
	db: PropTypes.object.isRequired,
	online: PropTypes.bool.isRequired,
	RESTserverOnline: PropTypes.bool.isRequired,
	toggleDrawer: PropTypes.func.isRequired,
	showSearch: PropTypes.bool.isRequired,
	closeSearch: PropTypes.func.isRequired,
	searchterm: PropTypes.string
};

export default compose(
	withRouter,
	withStyles(styles)
)(VarioAppBar);
