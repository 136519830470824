import React from 'react';

const detailViewEinstellung = props => {

	const Uebersicht = () => {
		if (!props.dataItem) return;
		const { einstellung } = props.dataItem;
		//const element = props.dataItem.element;
		if (!einstellung) return;
		return (<>
			{/* Austoben, ggfs. spezifische Items */}
		</>);
	};

	return [{
		ueberschrift: 'Übersicht',
		Funktion: Uebersicht
	}];
};

export default detailViewEinstellung;
