import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../containers/Modal';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import CachedIcon from '@material-ui/icons/Cached';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getSuchergebnisTypFromUrl } from '../functions';
import RootRef from '@material-ui/core/RootRef';
import { getCurrentFilename, tabInUrl, getSuchergebnisAdrIdFromUrl } from '../functions';
import $ from '../config';

const styles = theme => ({
	redAvatar: {
	},
	greenAvatar: {
	},
	yellowAvatar: {
	},
	root: {
		flexGrow: 1,
		width: '100%'
	},
	fab: {
		position: 'fixed',
		top: theme.spacing(16),
		right: theme.spacing(2),
		zIndex: 1101,
		color: 'white'
	},
	lightTooltip: {
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	fabOben: {
	},
	listing: {
	},
	listingMobile: {
	},
	detail: {},
	detailMobile: {}
});

class DetailView extends Component {

	constructor(props) {
		super(props);

		this.controller = new AbortController();
		this._isMounted = false;
		this.tabsRef = React.createRef();
		this.tabs = [];
		this.filename = getCurrentFilename(__filename);

		this.state = {
			tabValue: 0,
			displayIsSplit: window.innerWidth < 960 || this.props.displayIsSplit ? false : true,
			isFetching: false,
			tabContents: null,
			ueberschrift: this.props.config.itemsName,
			dataItem: null,

			/** Error Modal */
			emOpen: false,
			emTitle: '',
			emText: ''
		};

		this.getItemDetails = this.getItemDetails.bind(this);
		this.updateSplitScreen = this.updateSplitScreen.bind(this);
		this.handleTabChange = this.handleTabChange.bind(this);
		this.getTypeFromItems = this.getTypeFromItems.bind(this);
	}

	//#region
	componentDidMount() {
		window.addEventListener('resize', this.updateSplitScreen);
		this._isMounted = true;
		this.getItemDetails();
	}

	componentDidUpdate(prevProps, prevState) {
		const currentItems = this.props.items;
		const debug = false;
		const { tabValueFromListView } = this.props;
		if (!currentItems.length) {
			if (debug) console.log(`[${this.filename}] currentItems ist ein Array mit Länge Null`, currentItems);
			return;
		}
		//console.log(this.props)

		// wenn sich der tabValue von ListView geändert hat
		if (debug) {
			console.log(`[${this.filename}] tabValueFromListView`, tabValueFromListView);
			console.log(`[${this.filename}] this.state.tabValue`, this.state.tabValue);
		}
		if (tabValueFromListView && !isNaN(tabValueFromListView) && tabValueFromListView !== this.state.tabValue) {
			if (debug) console.log(`[${this.filename}] tabValueFromListView drin`, tabValueFromListView);
			this.setTabValue(tabValueFromListView);
		}

		// wenn sich die ID geändert hat
		if (this.props.id && prevProps.id !== this.props.id) {

			if (debug) {
				console.log('');
				console.log(`[${this.filename}] ID geändert`, this.props.id);
			}

			// bei Suchergebnissen erst Typ des ersten Elements bestimmen
			if (this.props.match.params.bereich === $.suchergebnisseNode) {
				this.getItemDetails(this.getTypeFromItems(currentItems));
				if (debug) console.log(`[${this.filename}] oben getItemDetails(this.getTypeFromItems(currentItems))`);
				//else console.info(`[${this.filename}] adresseId nicht vorhanden`);
			} else {
				this.getItemDetails();
				if (debug) console.log(`[${this.filename}] Mitte getItemDetails()`);
			}

		} else if (prevProps.items !== currentItems) {

			// wenn sich die items geändert haben (z.B. reload, items kommen später als die ID)
			if (debug) {
				console.log(`[${this.filename}] prevProps.items !== currentItems`);
				//console.log(`[${this.filename}] prevProps.items`, prevProps.items);
				//console.log(`[${this.filename}] currentItems`, currentItems);
			}

			let idFirstItem = this.props.id || null;
			if (!idFirstItem && currentItems.length) idFirstItem = currentItems[0].id;
			if (!idFirstItem && debug) console.log(`[${this.filename}] idFirstItem ist null`);
			if (debug) console.log(`[${this.filename}] writeUriAndSelectId(${idFirstItem})`, idFirstItem);

			this.props.writeUriAndSelectId(idFirstItem, null, tabValueFromListView);

			// das Ändern der URL dauert etwas...
			setTimeout(() => {
				if (debug) {
					console.log(`[${this.filename}] unten getItemDetails()`);
					console.log(`[${this.filename}] this.getTypeFromItems(currentItems) = `, this.getTypeFromItems(currentItems));
				}
				this.getItemDetails(this.getTypeFromItems(currentItems));
			}, 100);
		}

		// Überschrift
		let ueberschrift = this.props.config.itemsName;
		// Bei Adressen den name1 in die Überschrift schreiben
		if (this.state.dataItem && this.state.dataItem.adresse) ueberschrift = this.state.dataItem.adresse.name1;
		// Bei Deals das thema oder doch lieber firma?
		if (this.state.dataItem && this.state.dataItem.thema) ueberschrift = this.state.dataItem.thema;
		// Bei Artikeln die stammdaten.bezeichnung
		if (this.state.dataItem && this.state.dataItem.stammdaten) ueberschrift = this.state.dataItem.stammdaten.bezeichnung;
		// Bei Belegen
		if (this.state.dataItem && this.state.dataItem.belegart) ueberschrift = `${this.state.dataItem.belegart} an ${this.state.dataItem.hauptadresse.name1}`;

		if (prevState.ueberschrift !== ueberschrift) {
			this.setState({ ueberschrift });
			this.props.updateUeberschriftFromChild(ueberschrift);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener("resize", this.updateSplitScreen);
	}

	getTypeFromItems(items) {
		//if (this.props.typ) return this.props.typ;
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (item.id === this.props.id) return this.props.getTypeFromItem(this.props.items[i]);
		}
	}

	updateSplitScreen = () => {
		this.setState({ displayIsSplit: window.innerWidth < 960 || this.props.displayIsSplit ? false : true });
	};

	handleTabChange = (event, tabValue) => {
		this.setTabValue(tabValue);
	};

	setTabValue(tabValue) {

		//console.log(`[${this.filename}] setTabValue()`, tabValue);

		// wenn es noch keine Tabs gibt, warten!
		if (!this.tabs.length) {
			setTimeout(() => {
				this.setTabValue(tabValue);
			}, 100);
			return;
		}

		this.setState({ tabValue });
		this.props.setTabValue(tabValue);
	}

	getItemDetails(typ = null) {
		let id;
		const debug = false;
		if (this.props.id) id = this.props.id;
		if (!id) return;
		if (isNaN(id)) {
			this.setState({
				emOpen: true,
				emTitle: 'Fehler',
				emText: 'Der Link funktioniert nicht :('
			});
			return;
		}
		if (this.props.updateChildIsFetching) this.props.updateChildIsFetching(true); // wurde von items/index.js aufgerufen
		else this.setState({ isFetching: true }); // standalone
		const { FetchDetailsClass } = this.props.config;

		let adrId = null;
		if (typ === $.ansprechpartnersNode) {
			// aufgerufen durch Klick in Liste
			if (this.props.adrId) adrId = this.props.adrId;
		}

		typ = typ || getSuchergebnisTypFromUrl(this.props.location.pathname);
		// falls der alte tabValue noch > 0 ist und dieser value bei den nun kommenden Tabs nicht aufgerufen werden kann
		if (typ === $.belegeNode) this.setTabValue(0);

		// crash bei reload: keine adrId da
		if (this.props.config.itemsNode === $.suchergebnisseNode && typ !== $.belegeNode && !adrId) {
			adrId = getSuchergebnisAdrIdFromUrl(this.props.location.pathname);
		}
		new FetchDetailsClass(this.props, this.controller.signal, id, typ, adrId).fetch().then(dataItem => {
			if (this.props.updateChildIsFetching) this.props.updateChildIsFetching(false);
			const { getDetailView } = this.props.config;

			dataItem.screenProps = this.props;

			if (this._isMounted) {
				this.setState({
					dataItem,
					isFetching: false,
					tabContents: getDetailView({ dataItem })
				});

				this.props.setDataItem(dataItem);

				if (this.props.splitScreen()) {
					const _tabInUrl = tabInUrl(this.props.location.search);
					if (_tabInUrl) {
						this.setTabValue(_tabInUrl);
					} else if (typ === $.ansprechpartnersNode) {
						this.setTabValue(1);
						if (debug) console.log(`[${this.filename}] tabValue gesetzt auf 4`);
					} else {
						this.setTabValue(0);
						if (debug) console.log(`[${this.filename}] tabValue gesetzt auf 0`);
					}
				}
			}

		}).catch(err => {
			if (this._isMounted)
				this.setState({
					emOpen: true,
					emTitle: 'Fehler',
					emText: err
				});
		});
	}

	render() {

		this.tabs = [];
		const ueberschriften = [];

		(() => {
			const { tabContents } = this.state;
			if (!tabContents) return;

			for (let i = 0; i < tabContents.length; i++) {
				const { ueberschrift, Funktion } = tabContents[i];
				ueberschriften.push(<Tab key={i} label={ueberschrift} />);
				this.tabs.push(
					<div key={i}>
						{this.state.tabValue === i &&
							<Typography component="div" style={{ padding: '8px 4px 0 4px' }}>
								<Funktion />
							</Typography>
						}
					</div>
				);
			}
		})()
		const { classes } = this.props;
		return (
			<>
				{this.state.isFetching && <LinearProgress color="secondary" className="global_progress_bar" />}

				{/*<Tooltip title="Neu laden" classes={{ tooltip: classes.lightTooltip }}>*/}
				{/*	<Fab color="secondary" className={classes.fab} onClick={() => window.location.reload()}>*/}
				{/*		<AutorenewIcon/>*/}
				{/*	</Fab>*/}
				{/*</Tooltip>*/}
				<AppBar position="sticky" color="default">
					<RootRef rootRef={this.tabsRef}>
						<Tabs
							value={this.state.tabValue}
							onChange={this.handleTabChange}
							indicatorColor="primary"
							textColor="primary"
							variant="scrollable"
							scrollButtons="on"
						>
							{ueberschriften}
						</Tabs>
					</RootRef>
				</AppBar>

				{this.tabs}

				<Modal
					open={this.state.emOpen}
					title={this.state.emTitle}
					text={this.state.emText}
					handleOk={() => this.setState({ emOpen: false })}
					cancelBtn={false}
					destroy={() => this.setState({ emOpen: false })}
				/>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(DetailView);
