import Items from '.';
import { getLagerkreis } from '../../functions';

class FetchArtikelLieferBestaende extends Items {

	constructor(props, signal, id) {
		super(props, signal);
		this.props = props;
		if (!id || isNaN(id)) throw new TypeError(`[FetchArtikelLieferBestaende.js] id nicht vorhanden`);
		this.path = {
			string: `stammdaten/artikel/${id}/lieferbestaende`,
			id
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Artikel Lieferbestaende', this.path).then(json => {

			if (!json.path || !json.results || json.results.totalResultCount === 0) {
				resolve([]);
				return;
			}

			let bestaende = json.results.artikelLieferbestaende;
			const promises = [];
			for (let i = 0; i < bestaende.length; i++) {
				const bestand = bestaende[i];
				promises.push(getLagerkreis(this.props.db, bestand.lagerkreisId));
			}

			Promise.all(promises).then(lagerkreisNamen => {
				for (let j = 0; j < bestaende.length; j++) {
					bestaende[j].lagerkreisName = lagerkreisNamen[j];
				}
				resolve(bestaende);
			}).catch(err => reject(err));
		}).catch(err => reject(err)); //super.fetchDbOrServerGet('Aktivitaet'
	});
}

export default FetchArtikelLieferBestaende;
