import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
		width: '100%'
	},
	modal: {
		zIndex: 1600
	}
});

const Modal = props => {

	const { classes } = props;
	const CancelButton = () => (
		props.cancelBtn ?
			<Button onClick={props.handleCancel} color="primary">
				Abbrechen
			</Button> :
			null
	);

	return (
		<div className={classes.root}>
			<Dialog
				className={classes.modal}
				open={props.open}
				onBackdropClick={props.destroy}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CancelButton />
					<Button onClick={props.handleOk} color="primary" autoFocus>OK</Button>
				</DialogActions>
			</Dialog>
		</div>
	);

}

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	handleOk: PropTypes.func.isRequired,
	cancelBtn: PropTypes.bool.isRequired,
	destroy: PropTypes.func.isRequired
};

export default withStyles(styles)(Modal);