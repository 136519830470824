import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Banner from './Banner';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import $ from '../config';
import CardAdresseKlein from './CardAdresseKlein';

const detailViewBeleg = props => {

	const StyledCard = withStyles({
		root: {
			marginBottom: '8px'
		}
	})(Card);

	const Belegkopf = () => {
		const beleg = props.dataItem;
		if (!beleg || !beleg.hauptadresse) return null;

		let adressen = [
			{ adresse: beleg.hauptadresse, name: 'Hauptadresse' },
			{ adresse: beleg.rechnungsadresse, name: 'Rechnungsadresse' },
			{ adresse: beleg.lieferadresse, name: 'Lieferadresse' }
		];
		// die originalen IDs sind unbrauchbar
		adressen[0].adresse.id = adressen[1].adresse.id = adressen[2].adresse.id = beleg.adresseId;

		const left = { item: true, xs: 5, sm: 4 };
		const right = { item: true, xs: 7, sm: 8 };

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(78559365283);
				return null;
			}
			const newPath = `/app/${$.adressenNode}/${id}?details`;
			props.dataItem.screenProps.history.push(newPath);
		};

		return (
			<>
				<StyledCard>
					<List>{/* List/ListItem nur wegen Kompatibiltät zu anderen Listen wegen padding */}
						<ListItem>
							<Typography variant="h5">{beleg.belegart} vom {beleg.belegdatum} an {beleg.hauptadresse.name1}</Typography>
						</ListItem>
						<ListItem>
							<Grid container spacing={1} style={{ marginTop: 16 }}>
								<Grid {...left}>Belegnummer</Grid>
								<Grid {...right}>{beleg.belegnummer}</Grid>
								<Grid {...left}>Status</Grid>
								<Grid {...right}>{beleg.belegstatus}</Grid>
								<Grid {...left}>Netto</Grid>
								<Grid {...right}>{beleg.betragNetto}</Grid>
								<Grid {...left}>Brutto</Grid>
								<Grid {...right}>{beleg.betragBrutto}</Grid>
								<Grid {...left}>Zahlungsart</Grid>
								<Grid {...right}>{beleg.zahlungsart}</Grid>
								<Grid {...left}>Lieferbedingung</Grid>
								<Grid {...right}>{beleg.lieferbedingung}</Grid>
								<Grid {...left}>Lieferbem.</Grid>
								<Grid {...right}>{beleg.lieferterminBemerkung}</Grid>
								<Grid {...left}>bestellt von</Grid>
								<Grid {...right}>{beleg.bestelltVon}</Grid>
								<Grid {...left}>Lieferdatum</Grid>
								<Grid {...right}>{beleg.liefertermin}</Grid>
							</Grid>
						</ListItem>
					</List>
				</StyledCard>

				<CardAdresseKlein
					adressen={adressen}
					handleClick={handleClick}
					StyledCard={StyledCard}
				/>
			</>
		);
	};

	const Belegpositionen = () => {
		const beleg = props.dataItem;
		if (!beleg) return null;
		const { positionen } = beleg, jsx = [];
		if (!positionen) return <Banner icon={<InfoIcon />}>Keine Positionen vorhanden</Banner>;

		let artikelUrl;
		for (let i = 0; i < positionen.length; i++) {
			const artikel = positionen[i];
			artikelUrl = '#';

			if (artikel.positionsArt === 'Artikel' && !isNaN(artikel.artikelId)) artikelUrl = `/app/${$.artikelsNode}/${artikel.artikelId}?details`;

			const StyledAvatar = withStyles(theme => ({
				root: {
					backgroundColor: theme.palette.primary.main,
					marginRight: theme.spacing(1),
					marginLeft: 0,
					fontSize: '0.9rem'
				}
			}))(Avatar);
			const StyledTypography = withStyles({
				root: {
					display: 'flex',
					justifyContent: 'space-between'
				}
			})(Typography);

			//console.log(beleg)

			jsx.push(
				<List key={i}>
					<ListItem button divider={i + 1 !== positionen.length ? true : false} component="a" href={artikelUrl}>

						<StyledAvatar>{artikel.position}</StyledAvatar>

						<ListItemText
							disableTypography
							primary={
								<Typography
									noWrap
									variant="body1"
								>
									{`${artikel.menge} ${artikel.mengeneinheit} ${artikel.bezeichnung}`}
								</Typography>
							}
							secondary={
								<>
									<Typography
										component="p"
										variant="body2"
										color="primary"
									>
										{artikel.artikelnummer}
									</Typography>
									<StyledTypography
										component="p"
										variant="body1"
										color="textSecondary"
									>
										<span>{`EP: ${artikel.einzelpreis}${artikel.mengeneinheit !== '—' ? ` / ${artikel.mengeneinheit}` : ''}`}</span>
										<span>Gesamt {artikel.gesamtpreis}</span>
									</StyledTypography>
								</>
							}
						/>

					</ListItem>
				</List>
			);
		}
		return jsx;
	};

	const Belegfuss = () => {
		const beleg = props.dataItem;
		if (!beleg) return null;

		const itemPropsLinks = { item: true, xs: 5, sm: 4 };
		const itemPropsRechts = { item: true, xs: 7, sm: 8 };

		//console.log(beleg);

		return (
			<Paper style={{ padding: 24 }}>
				<Typography variant="h5">
					{beleg.belegart} vom {beleg.belegdatum} an {beleg.hauptadresse.name1}
				</Typography>
				<Grid container spacing={1} style={{ marginTop: 16 }}>
					<Grid {...itemPropsLinks}>Betrag netto</Grid>
					<Grid {...itemPropsRechts}>{beleg.betragNetto}</Grid>
					<Grid {...itemPropsLinks}>Rabatt 1 Betrag</Grid>
					<Grid {...itemPropsRechts}>{beleg.rabatt1Betrag}</Grid>

					<Grid {...itemPropsLinks}><div style={{ height: '50px' }} /></Grid>
					<Grid {...itemPropsRechts}></Grid>

					<Grid {...itemPropsLinks}>Versand Netto</Grid>
					<Grid {...itemPropsRechts}>{beleg.frachtkosten}</Grid>
					<Grid {...itemPropsLinks}>Nachnahme Netto</Grid>
					<Grid {...itemPropsRechts}>{beleg.nachnahme}</Grid>
					<Grid {...itemPropsLinks}>Eilzuschlag Netto</Grid>
					<Grid {...itemPropsRechts}>{beleg.eilzuschlagNetto}</Grid>
					<Grid {...itemPropsLinks}>Versicherung Netto</Grid>
					<Grid {...itemPropsRechts}>{beleg.versicherung}</Grid>

					<Grid {...itemPropsLinks}><div style={{ height: '50px' }} /></Grid>
					<Grid {...itemPropsRechts}></Grid>

					<Grid {...itemPropsLinks}>Zwischensumme</Grid>
					{/*//TODO: Betrag Netto 1 bekomme ich nicht*/}
					<Grid {...itemPropsRechts}>{beleg.betragNetto1}</Grid>
					<Grid {...itemPropsLinks}>MwSt-Betrag 1</Grid>
					<Grid {...itemPropsRechts}>{beleg.mwstBetrag1}</Grid>
					<Grid {...itemPropsLinks}>MwSt-Betrag 2</Grid>
					<Grid {...itemPropsRechts}>{beleg.mwstBetrag2}</Grid>
					<Grid {...itemPropsLinks}>MwSt-Betrag 3</Grid>
					<Grid {...itemPropsRechts}>{beleg.mwstBetrag3}</Grid>

					<Grid {...itemPropsLinks}>
						<div style={{ height: '50px' }}/>
					</Grid>
					<Grid {...itemPropsRechts}></Grid>

					<Grid {...itemPropsLinks}>Gesamtbetrag Brutto</Grid>
					<Grid {...itemPropsRechts}>{beleg.betragBrutto}</Grid>
					<Grid {...itemPropsLinks}>Rohertrag %/€</Grid>
					<Grid {...itemPropsRechts}>Keine Ahnung ???</Grid>

				</Grid>
			</Paper>
		);
	};

	return [{
		ueberschrift: 'Belegkopf',
		Funktion: Belegkopf
	}, {
		ueberschrift: 'Positionen',
		Funktion: Belegpositionen
	}, {
		ueberschrift: 'Belegfuß',
		Funktion: Belegfuss
	}];
};

export default detailViewBeleg;
