import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const avatarStyles = theme => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		marginRight: theme.spacing(1)
	}
});
const StyledAvatar = withStyles(avatarStyles)(Avatar);

const ServiceList = props => {
	return (
		<List>
			{props.filteredItems ? props.filteredItems.map((serviceauftrag, i) => (
				<ListItem
					key={i}
					button
					divider={!!~(i - props.filteredItems.length)}
					selected={props.selectedId === serviceauftrag.id}
					onClick={() => props.writeUriAndSelectId(serviceauftrag.id)}
				>
					<StyledAvatar>
						<Typography variant="subtitle2">#{serviceauftrag.id}</Typography>
					</StyledAvatar>

					<ListItemText
						primary={`${serviceauftrag.name1}`}
						secondary={`${serviceauftrag.ort}`}
					/>

					<Hidden only={['xs', 'md', 'lg']}>
						<ListItemSecondaryAction>
							<ListItemText primary={`${serviceauftrag.aufwandGeschaetzt} Min.`}/>
						</ListItemSecondaryAction>
					</Hidden>
				</ListItem>
			)) : null}
		</List>
	);
};

export default ServiceList;
