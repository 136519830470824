import Items from '.';
import { formatAktivitaetenListe, getCurrentFilename } from '../../functions';

class FetchAktivitaetenZuDeal extends Items {

	constructor(props, signal, dealId, forceRefetch = false) {
		super(props, signal);
		this.props = props;
		this.forceRefetch = forceRefetch;
		this.signal = signal;
		if (!dealId || isNaN(dealId)) throw new TypeError(`[${getCurrentFilename(__filename)}] dealId nicht vorhanden`);
		this.itemsName = 'Aktivitaeten zu Deal';
		this.path = {
			string: `crm/deals/${dealId}/aktivitaeten?orderby=-datum`,
			id: dealId
		}
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Aktivitaeten zu Deal', this.path, this.forceRefetch).then(aktJson => {

			if (aktJson.results && aktJson.results.aktivitaetenZuDeal) {
				let { aktivitaetenZuDeal } = aktJson.results;
				formatAktivitaetenListe(this.props.db, aktivitaetenZuDeal).then(formatierteAktivitaeten => {
					resolve(formatierteAktivitaeten);
				});
			} else {
				resolve([]);
			}
		}); //super.fetchDbOrServerGet('Aktivitaeten zu Deal'
	}).catch(err => console.error(err));
}

export default FetchAktivitaetenZuDeal;
