import React from 'react';
import TabAktivitaeten from './TabAktivitaeten';
import AdresseTabUebersicht from './AdresseTabUebersicht';
import TabAnsprechpartner from './TabAnsprechpartner';
import CardZusatzadressen from './CardZusatzadressen';
import { keineBlaVorhanden } from '../functions';
import ListViewBelege from './ListViewBelege';
import ListViewDeals from './ListViewDeals';
import $ from '../config';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';

const detailViewAdresse = props => {

	const StyledCard = withStyles({
		root: {
			marginBottom: '8px'
		}
	})(Card);
	const StyledAvatar = withStyles(theme => ({
		root: {
			backgroundColor: theme.palette.primary.main,
			marginRight: theme.spacing(1)
		}
	}))(Avatar);
	const StyledListItemText = withStyles(theme => ({
		primary: {
			color: theme.palette.secondary.main,
			fontWeight: 'bold'
		}
	}))(ListItemText);

	const Uebersicht = () => {
		if (!props.dataItem) return;
		const { adresse } = props.dataItem;

		if (!adresse) return;
		return <AdresseTabUebersicht
			StyledCard={StyledCard}
			StyledAvatar={StyledAvatar}
			StyledListItemText={StyledListItemText}
			adresse={adresse}
			offenePostenUebersicht={props.dataItem.offenePostenUebersicht}
			ansprechpartners={props.dataItem.ansprechpartner}
			screenProps={props.dataItem.screenProps}
			umsatz={props.dataItem.umsatz}
		/>;
	};

	const MyAnsprechpartner = () => {
		if (!props.dataItem) return;
		const ansprechpartners = props.dataItem.ansprechpartner;
		if (!ansprechpartners || !ansprechpartners.length) return keineBlaVorhanden('Ansprechpartner', false);

		return <TabAnsprechpartner StyledCard={StyledCard} ansprechpartners={ansprechpartners} />
	};

	const Deals = () => {
		if (!props.dataItem) return null;
		const { deals } = props.dataItem;
		if (!deals || deals === '—' || !deals.length) return keineBlaVorhanden('Deals', true);

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(78542365283);
				return null;
			}
			const newPath = `/app/${$.dealsNode}/${id}?details`;
			props.dataItem.screenProps.history.push(newPath);
		};

		return <ListViewDeals items={deals} handleClick={handleClick} />;
	};

	const MyAktivitaeten = () => {
		if (!props.dataItem) return;
		const { aktivitaeten } = props.dataItem;
		if (!aktivitaeten || !aktivitaeten.length || aktivitaeten === '—') return keineBlaVorhanden('Aktivitäten', true);

		return <TabAktivitaeten
			aktivitaeten={aktivitaeten}
			StyledCard={StyledCard}
			StyledAvatar={StyledAvatar}
			StyledListItemText={StyledListItemText}
		/>;
	};

	const Belege = () => {
		if (!props.dataItem) return null;
		const { belege } = props.dataItem;
		if (!belege || !belege.length || belege === '—') return keineBlaVorhanden('Belege', true);

		const handleClick = id => {
			if (isNaN(id)) {
				console.warn(7352346570);
				return null;
			}
			const newPath = `/app/${$.belegeNode}/${id}?details`;
			props.dataItem.screenProps.history.push(newPath);
		};

		return <ListViewBelege items={belege} handleClick={handleClick} />;
	};

	const Zusatzadressen = () => {
		if (!props.dataItem) return;
		const { zusatzadressen } = props.dataItem;
		if (!zusatzadressen || !zusatzadressen.length) return keineBlaVorhanden('Zusatzadressen', true);

		return <CardZusatzadressen items={zusatzadressen} />;
	};

	return [{
		ueberschrift: 'Übersicht',
		Funktion: Uebersicht
	}, {
		ueberschrift: 'Ansprechpartner',
		Funktion: MyAnsprechpartner
	}, {
		ueberschrift: 'Deals',
		Funktion: Deals
	}, {
		ueberschrift: 'Aktivitäten',
		Funktion: MyAktivitaeten
	}, {
		ueberschrift: 'Verkauf',
		Funktion: Belege
	}, {
		ueberschrift: 'Zusatzadressen',
		Funktion: Zusatzadressen
	}];
};

export default detailViewAdresse;
