import Items from '.';

class StatistikUmsatz extends Items {

	constructor(props, signal) {
		super(props, signal);
		this.path = {
			string: `auswertungen/umsatzstatistik/daten`,
			id: null
		};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		super.fetchDbOrServerGet('Umsatzstatistik', this.path).then(json => {

			if (!json.path || !json.results || !json.results.umsatzstatistik) {
				resolve([]);
				return;
			}

			const { umsatzstatistik } = json.results;
			const chartData = [];
			for (let i = 0; i < umsatzstatistik.length; i++) {
				const datensatz = umsatzstatistik[i];
				chartData.push({
					date: new Date(datensatz.jahr, datensatz.monat - 1, 1),
					summe: datensatz.summe
				});
			}

			resolve(chartData);

		}).catch(err => {
			// in der Regel ein 403 "nicht berechtigt"
			console.error(err);
			console.log(856209709560520);
			resolve([]);
		}); //super.fetchDbOrServerGet('Umsatzstatistik'
	});
}

export default StatistikUmsatz;
