import Fetch from '../fetch';
import { getCurrentFilename } from '../../functions';

class FetchBenutzerParameter {
	constructor(props, signal, path) {
		this.path = path;
		this.props = props;
		this.signal = signal;
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => new Promise((resolve, reject) => {
		new Fetch(this.props).get(this.path, this.signal, 'Benutzer Parameter').then(json => {
			resolve(json.result.wert);
		}).catch(err => {
			resolve(null);
			console.log(`[${getCurrentFilename(__filename)}]`, err);
			console.log(4666666665674);
		});
	});
}

export default FetchBenutzerParameter;